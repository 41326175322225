import { Input, Select } from "../../../components/common";
import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useState, useEffect } from "react";
import AsyncSelect from "react-select/async";
import {
  editRing,
  createRing,
  getRingLines,
  addRingRouting,
  viewRing,
} from "../../../utils/api/line-rings.api";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import Loader from "../../common/Spinner";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { loadDealershipUsers } from "../../Leads/utils";
import { Dealership_Roles } from "../../../utils/roles";
import { fetchDealershipUserById } from "../../../utils/api/users.api";
import ContactListing from "../../../pages/(dashboard-routes)/leads/lead-details/ContactListing";
import { Drawer } from "../../../components/common";
import SearchIcon from "../../icons/Search";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const General = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { id } = useParams();
  const { ringId } = useParams();
  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["ringdetail", ringId],
    queryFn: viewRing,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: ringId !== undefined,
    onSuccess(data) {
      setRingData({
        ring_group: {
          name: data?.data?.name,
          description: data?.data?.description,
        },
        message_processing: data?.data?.message_processing ?? "",
        line_ids: data?.data?.lines.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        ring_group_users_attributes: data?.data?.users.map((item: any) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        assigned_role: data?.data?.assigned_role?.map((item: any) => {
          return {
            value: item,
            label: item,
          };
        }),
      });
      setRemovedContacts(data?.data?.excluded_users);
    },
  });
  const [ringData, setRingData] = useState({
    ring_group: {
      name: data?.data?.name ?? "",
      description: data?.data?.description ?? "",
    },
    message_processing: data?.data?.message_processing ?? "",
    line_ids:
      data?.data?.lines.map((item: any) => {
        return {
          id: item.id,
          label: item.name, // Replace 'name' with 'label'
        };
      }) ?? [],
    ring_group_users_attributes:
      data?.data?.users.map((item: any) => {
        return {
          id: item.id,
          label: item.name, // Replace 'name' with 'label'
        };
      }) ?? [],
    assigned_role: data?.data?.assigned_role?.map((item: any) => item),
  });
  const mutation = useMutation(createRing);
  const editMutation = useMutation(editRing);
  const editLineMutation = useMutation(addRingRouting);
  const [removedContacts, setRemovedContacts] = useState<any[]>([]);
  const [contacts, setContacts] = useState<any[]>([]);
  const [searchStr, setSearchStr] = useState("");
  const [page, setPage] = useState(1);
  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();
  const { data: users } = useQuery({
    queryKey: [
      "rolewiseuseefetchingforlisting",
      id,
      page,
      ringData?.assigned_role?.map((itemm: any) => itemm?.value).toString(),
      searchStr,
    ],
    queryFn: () => {
      const params: any = {
        id,
        page,
        per_page: 12,
        status: "active",
        q: searchStr,
        role_titles: ringData?.assigned_role
          ?.map((item: any) => item?.value)
          .join(","),
      };

      return fetchDealershipUserById(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: ringData?.assigned_role?.length > 0,
    onSuccess(data) {
      setContacts((prev: any) => {
        const idMap = new Map(prev.map((item: any) => [item.id, item]));
        data?.users.forEach((user: any) => {
          if (!idMap.has(user.id)) {
            idMap.set(user.id, user);
          }
        });
        return Array.from(idMap.values());
      });
    },
  });
  useEffect(() => {
    if (contacts.length > 0) {
      const contactIds = contacts.map((contact) => contact.id);
      const updatedRemovedContacts = removedContacts.filter((id) =>
        contactIds.includes(id)
      );
      if (updatedRemovedContacts.length !== removedContacts.length) {
        setRemovedContacts(updatedRemovedContacts);
      }
    }
  }, [contacts]);
  const handleSubmit = () => {
    mutation.mutate(
      {
        dealership_id: id,
        ring_group: {
          name: ringData?.ring_group?.name,
          description: ringData?.ring_group?.description,
          dealership_id: id,
          message_processing: ringData?.message_processing,
          assigned_role: ringData?.assigned_role?.map(
            (item: any) => item?.value
          ),
        },
        line_ids: ringData?.line_ids?.map((item: any) => Number(item.value)),
        ring_group_users_attributes: ringData?.ring_group_users_attributes?.map(
          (item: any) => {
            return {
              user_id: item.value,
              custom: true,
            };
          }
        ),
        excluded_users: removedContacts,
      },
      {
        onSuccess() {
          toast({
            description: "Ring group created successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              navigate(`/dealerships/${id}?tab=${3}&active=${1}`);
            },
          });
        },
        onError: (error: any) => {
          toast({
            description: error?.response?.data[0] ?? "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleEdit = async () => {
    const initialUserIds = data?.data?.users.map((user: any) => user.id) || [];
    const modifiedUserIds =
      ringData?.ring_group_users_attributes.map(
        (user: any) => user.id ?? user.value
      ) || [];
    const addedUserIds = modifiedUserIds.filter(
      (id: any) => !initialUserIds.includes(id)
    );
    const removedUserIds = initialUserIds.filter(
      (id: any) => !modifiedUserIds.includes(id)
    );

    // Check if there are changes in line IDs
    const initialLineIds = data?.data?.lines.map((user: any) => user.id) || [];
    const modifiedLineIds =
      ringData?.line_ids?.map((user: any) => user.id ?? user.value) || [];
    const addedLineIds = modifiedLineIds.filter(
      (id: any) => !initialLineIds.includes(id)
    );
    const removedLineIds = initialLineIds.filter(
      (id: any) => !modifiedLineIds.includes(id)
    );

    try {
      // If there are changes in line IDs, call editLineMutation first
      if (addedLineIds.length > 0 || removedLineIds.length > 0) {
        await editLineMutation.mutate({
          id: ringId,
          dealership_id: id,
          data: {
            lines: {
              remove_lines: removedLineIds,
              add_lines: addedLineIds,
            },
          },
        });
      }

      // Call editMutation
      await editMutation.mutate({
        id: ringId,
        data: {
          ring_group: {
            name: ringData?.ring_group?.name,
            description: ringData?.ring_group?.description,
            dealership_id: id,
            message_processing: ringData?.message_processing,
          },
          users: {
            add_users: addedUserIds,
            remove_users: removedUserIds,
          },
          new_roles: ringData?.assigned_role?.map((item: any) => item?.value),
          old_roles: data?.data?.assigned_role,
          excluded_users: removedContacts,
        },
      });

      // Handle success after both mutations
      toast({
        description: "Ring group updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        // onCloseComplete() {
        //   navigate(`/dealerships/${id}`);
        // },
      });
    } catch (error: any) {
      // Handle errors
      toast({
        description: error?.response?.data[0] ?? "Something went wrong",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };
  const updateRingData = (key: any, value: any) => {
    setRingData((prevState: any) => {
      if (
        [
          "line_ids",
          "ring_group_users_attributes",
          "message_processing",
        ].includes(key)
      ) {
        return { ...prevState, [key]: value };
      }
      if (["assigned_role"].includes(key)) {
        const assignedRoleValues = value.map((role: any) => role.value);

        // Filter out users whose role matches any value in assigned_role
        const updatedUsersAttributes =
          prevState?.ring_group_users_attributes?.filter(
            (user: any) => !assignedRoleValues?.includes(user.role)
          );

        return {
          ...prevState,
          [key]: value, // Update the assigned_role
          ring_group_users_attributes: updatedUsersAttributes, // Update the users, removing matched ones
        };
      }
      if (key in prevState.ring_group) {
        return {
          ...prevState,
          ring_group: {
            ...prevState.ring_group,
            [key]: value,
          },
        };
      }
      return prevState;
    });
  };
  const loadLines = async (inputValue: string) => {
    try {
      const response = await getRingLines({
        queryKey: ["ringLines", ringId ?? "", inputValue, id],
      });
      const options = response?.data
        ?.filter((item: any) => item.belongs_to_ring_group === false)
        ?.map((user: any) => ({
          value: user.id.toString(),
          label: user.name,
        }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };

  return (
    <Box paddingTop="0.25rem" height="calc(100vh - 235px)" overflow="auto">
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack justifyContent="flex-start" w="100%" alignItems="flex-start">
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Name
          </Text>
          <VStack alignItems="flex-start" width="50%">
            <Input
              type="text"
              placeholder="Enter ring group name"
              maxW="30rem"
              value={ringData?.ring_group?.name}
              onChange={(e) => {
                updateRingData("name", e.target.value);
              }}
            />
            <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
              Use a name that is short and easily identifiable in your reports.
            </Text>
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack justifyContent="flex-start" w="100%" alignItems="flex-start">
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Description:
          </Text>
          <VStack alignItems="flex-start" width="50%">
            <Input
              type="text"
              placeholder="Type description (Optional)"
              isTextarea={true}
              maxW="30rem"
              value={ringData?.ring_group?.description}
              onChange={(e) => {
                updateRingData("description", e.target.value);
              }}
            />
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack
          justifyContent="flex-start"
          w="100%"
          alignItems="flex-start"
          position="relative"
        >
          <Text textStyle="h5" fontWeight="700" w="25%">
            Add Lines
          </Text>
          <VStack w="50%" maxW="30rem" alignItems="flex-start">
            <VStack alignItems="flex-start">
              {/* <Text textStyle="h5" fontWeight="700">
              Add Lines
              </Text> */}
              {/* <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                When calls come in, it will run through the sequence of layers
                below. Each layer must have one or more agents. You can add and
                move layers as you like.
              </Text> */}
            </VStack>

            <VStack w="100%" alignItems="flex-start">
              <VStack alignItems="flex-start" width="100%" gap="0">
                <VStack
                  alignItems="flex-start"
                  width="100%"
                  gap="1rem"
                  sx={{
                    ".async-select": {
                      width: "100%",
                    },
                  }}
                >
                  <AsyncSelect
                    loadOptions={(inputValue) => loadLines(inputValue)}
                    value={ringData?.line_ids}
                    className="async-select"
                    defaultOptions
                    onChange={(value) => {
                      updateRingData("line_ids", value);
                    }}
                    placeholder="Select Line"
                    isMulti={true}
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "100%",
                        borderRadius: "0.5rem",
                        borderColor: "var(--grey-300)",
                        padding: "0.25rem",
                      }),
                    }}
                  />
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
      <VStack
        gap="1.5rem"
        alignItems="flex-start"
        py="1.5rem"
        borderBottom="1px solid var(--grey-300)"
      >
        <HStack
          justifyContent="flex-start"
          w="100%"
          alignItems="flex-start"
          position="relative"
        >
          <Text textStyle="h5" fontWeight="700" w="25%">
            Ring Group Builder
          </Text>
          <VStack w="50%" maxW="30rem" alignItems="flex-start">
            <VStack alignItems="flex-start">
              <Text textStyle="h5" fontWeight="700">
                Add all the existing/future users of the following roles
              </Text>
            </VStack>

            <VStack w="100%" alignItems="flex-start">
              <VStack alignItems="flex-start" width="100%" gap="0">
                <VStack
                  alignItems="flex-start"
                  width="100%"
                  gap="1rem"
                  sx={{
                    ".async-select": {
                      width: "100%",
                    },
                  }}
                >
                  <Select
                    options={Dealership_Roles?.map((item: any) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                    isMulti
                    placeholder={"Select Roles"}
                    onChange={(value: any) => {
                      updateRingData("assigned_role", value);
                      setContacts([]);
                    }}
                    value={ringData?.assigned_role}
                    variant="default"
                    w="100%"
                  />
                  {ringData?.assigned_role?.length > 0 && (
                    <Button
                      variant="outlineBlue"
                      rightIcon={<ChevronRightIcon />}
                      justifyContent="space-between"
                      color="var(--grey-900)"
                      width="100%"
                      size="xl"
                      onClick={onContactsOpen}
                    >
                      <Box as="span" mr="auto">
                        Total Users:{" "}
                        {((users?.users_count || 0) - removedContacts?.length)}
                      </Box>
                    </Button>
                  )}
                  <Text textStyle="h5" fontWeight="700">
                    Add Users
                  </Text>
                  <AsyncSelect
                    key={ringData?.assigned_role}
                    loadOptions={(inputValue) =>
                      loadDealershipUsers(
                        inputValue,
                        Dealership_Roles?.filter(
                          (item: any) =>
                            !ringData?.assigned_role
                              ?.map((itemm: any) => itemm?.value)
                              ?.includes(item)
                        ).toString(),
                        id ?? ""
                      )
                    }
                    className="async-select"
                    value={ringData?.ring_group_users_attributes}
                    onChange={(value) => {
                      updateRingData("ring_group_users_attributes", value);
                    }}
                    placeholder="Select Users"
                    isMulti={true}
                    defaultOptions
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        minWidth: "100%",
                        borderRadius: "0.5rem",
                        borderColor: "var(--grey-300)",
                        padding: "0.25rem",
                      }),
                    }}
                  />
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </HStack>
      </VStack>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
      >
        <Button
          width="8rem"
          onClick={() => {
            ringId ? handleEdit() : handleSubmit();
          }}
        >
          Save
        </Button>{" "}
      </HStack>
      <Drawer
        isOpen={isContactsOpen}
        onClose={onContactsClose}
        title="Contacts"
      >
        <Box padding="0 20px" marginTop="10px">
          <Input
            type="text"
            hasIcon={true}
            isIconRight={false}
            placeholder="Search Users..."
            onChange={({ target: { value } }) => {
              setSearchStr(value);
              setPage(1);
              setContacts([]);
            }}
            width="100%"
            value={searchStr}
          />
        </Box>
        <div
          id="bulkMessageDiv"
          style={{ height: "calc(100vh - 0px)", overflow: "auto" }}
        >
          <ContactListing
            setPage={setPage}
            contacts={contacts}
            count={users?.users_count}
            setRemovedContacts={setRemovedContacts}
            removedContacts={removedContacts}
            target="bulkMessageDiv"
          />
        </div>
      </Drawer>
      {(isLoading ||
        mutation.isLoading ||
        editMutation.isLoading ||
        editLineMutation.isLoading) && <Loader />}
    </Box>
  );
};

export default General;
