import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, PaginatedTable } from "../../../components/common";
import Loader from "../../../components/common/Spinner";
import { ExcelIcon, PDFIcon } from "../../../components/icons/svg";
import { SERVICES } from "../../../config";
import { humanize } from "../../../utils";
import { getPWCommunicationLogs } from "../../../utils/api/power-dialer.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

export default function PwCommunicationLogPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  const communicationTableHeader = [
    { id: 1, value: "Number", label: "number" },
    { id: 1, value: "DateTime", label: "date" },
    { id: 1, value: "Duration", label: "duration" },
    { id: 1, value: "Status", label: "status" },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["PWcommunicationLog", id, page, perPage],
    queryFn: () => getPWCommunicationLogs({ id, dealershipId, page, perPage }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableData = useMemo(() => {
    if (!data?.data?.power_dialers?.length) {
      return [];
    }

    return data?.data?.power_dialers.map((communication: any) => ({
      ...communication,
      status: communication?.duration
        ? ""
        : humanize(
            communication?.call_status === "no_answered"
              ? "no_answer"
              : communication?.call_status || ""
          ),
      date: moment(communication.created_at).format("MM-DD-YYYY hh:mm A"),
      number: (
        <Text
          textStyle="h6"
          fontWeight="500"
          color="var(--primary-600)"
          cursor="pointer"
          onClick={() =>
            navigate(
              `/leads/lead-details?contact_id=${communication.contact_id}`,
              {
                state: {
                  activeKey: 1,
                },
              }
            )
          }
        >
          {communication.contact_phone_number}
        </Text>
      ),
      duration: communication.call_duration || 0,
    }));
  }, [data?.data?.power_dialers, navigate]);

  const totalCount = data?.data?.log_size;

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${dealership.id}/power_dialer_campaigns/${id}/dialer_logs.${value}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `power_dialer_communication_logs.${value}`);
      })
      .catch((response) => {});
  };

  return (
    <Box>
      <HStack padding="1rem" w="100%" background="white">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          gap="0.63rem"
          onClick={() => navigate(-1)}
        >
          <Box
            as="span"
            display="flex"
            alignItems="center"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="0.38rem"
          >
            <ChevronLeftIcon
              style={{
                width: "1.25rem",
                height: "1.25rem",
                color: "var(--secondary-600)",
              }}
            />
          </Box>
          Back
        </Button>
      </HStack>
      <Box background="white" borderRadius="0.5rem" m="1.5rem">
        <HStack
          background="white"
          padding="1rem"
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <VStack alignItems="flex-start">
            <Text textStyle="h2" pl="1rem" pt="1.5rem">
              Communication Logs
            </Text>
            <Text
              textStyle="caption"
              color="var(--grey-600)"
              mb="1rem"
              pl="1rem"
              pt="1.5rem"
            >
              Found ({totalCount || 0})
            </Text>
          </VStack>
          <Box m="1.5rem">
            <Dropdown
              options={ExportOptions}
              hasText={true}
              text="Export"
              onSelect={(value: any) => handleExport(value.value)}
            />
          </Box>
        </HStack>

        <PaginatedTable
          header={communicationTableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={5}
          isPaginated={true}
          hasMultiBody={false}
          lastCenter={false}
          perPageChange={(value) => {
            setPage(1);
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={totalCount || 0}
          isLoadingData={isLoading}
        />
      </Box>
    </Box>
  );
}
