import { Box, Text, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { humanize } from "../../utils";
import {
  approveCampaign,
  getBroadcastRVMs,
  rejectCampaign,
  toggleRVMCampaign,
} from "../../utils/api/broadcast.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Drawer, Dropdown, PaginatedTable } from "../common";
import DatePicker from "../common/DateRangePicker";
import Loader from "../common/Spinner";
import CreateBulkRVMForm from "./CreateBulkRVM";
import PauseModal from "./PauseModal";

const tableHeader = [
  { id: 1, value: "Total RVM", label: "total_voicemails" },
  { id: 2, value: "Name", label: "campaign_name" },
  // { id: 2, value: "Engagement Rate", label: "engagement" },
  { id: 3, value: "Failed", label: "failed_voicemails" },
  //   { id: 4, value: "Unsubscribed", label: "unsubscribed" },
  // { id: 5, value: "RVM Content", label: "content" },
  { id: 6, value: "Scheduled At", label: "start_time" },
  { id: 7, value: "ID", label: "id" },
  { id: 8, value: "Status", label: "status" },
  { id: 9, value: "Actions", label: "actions" },
];

type BroadcastRVMListingProps = {
  type: string;
  shouldRefetch?: boolean;
  setShouldRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  dateRange?: any;
  parentRefetch?: any;
  setDateRange: any;
};

const editOptions = [
  { label: "Edit", value: "edit" },
  { label: "Approve", value: "approve" },
  { label: "Reject", value: "reject" },
];

function BroadcastRVMListing({
  type,
  shouldRefetch,
  setShouldRefetch,
  dateRange,
  parentRefetch,
  setDateRange,
}: BroadcastRVMListingProps) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);

  const [isOpenMsg, setIsOpenMsg] = useState(false);

  const onOpenMsg = () => setIsOpenMsg(true);
  const onCloseMsg = () => setIsOpenMsg(false);

  const navigate = useNavigate();

  const user = useUserInfo("user");
  const previousUserId = useUserInfo("previousUserId");

  const toast = useToast();

  const [searchParam] = useSearchParams();
  const activeTab = Number(searchParam.get("active") ?? 0);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`messages-${type}`, page, perPage, dateRange, activeTab],
    queryFn: () =>
      getBroadcastRVMs({
        status: type,
        page,
        perPage,
        dealership_id: user?.dealership?.id,
        dateRange,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await toggleRVMCampaign({
          dealership_id: user?.dealership?.id,
          id: selectedCampaign,
          payload,
          status: payload.status,
          campaignId: payload.campaignId,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPauseModalOpen(false);
      refetch();
      parentRefetch && parentRefetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const statusMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (payload.status === "approve"
          ? approveCampaign
          : rejectCampaign)({
          dealership_id: user?.dealership?.id,
          id: payload.id,
          status: payload.status,
          campaignId: payload.campaignId,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPauseModalOpen(false);
      refetch();
      parentRefetch && parentRefetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
      setShouldRefetch && setShouldRefetch(false);
    }
  }, [refetch, setShouldRefetch, shouldRefetch]);

  const options: SelectOptionProps[] = useMemo(() => {
    let option = [{ label: "Communication Log", value: "logs" }];

    if (type !== "completed") {
      option.push({
        label: ["pending", "in_progress"].includes(type) ? "Pause" : "Resume",
        value: ["pending", "in_progress"].includes(type) ? "pause" : "resume",
      });
    }

    if (user?.role === "SimpSocial Owner") {
      option.push({ label: "Approve", value: "approve" });
    }

    return option;
  }, [type, user?.role]);

  const handleSelect = (option: SelectOptionProps, item: any) => {
    setSelectedCampaign(item.id);
    if (option.value === "logs") {
      return navigate(`/rvm/communication/${item.id}`);
    }

    if (option.value === "pause") {
      // return setIsPauseModalOpen(true);
      return mutation.mutate({ status: "pause", campaignId: item.campaign_id });
    }

    if (option.value === "resume") {
      return mutation.mutate({ status: "start", campaignId: item.campaign_id });
    }

    if (option.value === "edit") {
      return setIsOpenMsg(true);
    }

    if (option.value === "approve") {
      return statusMutation.mutate({
        status: "approve",
        campaignId: item.campaign_id,
        id: item.id,
        user_id: user.previousUserId,
      });
    }

    if (option.value === "reject") {
      return statusMutation.mutate({
        status: "reject",
        campaignId: item.campaign_id,
        id: item.id,
        user_id: user.previousUserId,
      });
    }
  };

  const filterOptions = (options: any, status: string) => {
    if (status === "pending") {
      return [
        ...options.filter(
          (option: any) => !["resume", "pause"].includes(option.value)
        ),
        ...(user?.full_name === "SimpSocial Engage" ? editOptions : []),
      ];
    }
    if (
      status === "completed" ||
      status === "rejected" ||
      status === "pending" ||
      type === "completed"
    ) {
      return options.filter(
        (option: any) => !["resume", "pause"].includes(option.value)
      );
    }

    return options;
  };

  const messageData = useMemo(() => {
    if (!data?.data?.ringless_voicemails?.length) {
      return [];
    }

    return data?.data?.ringless_voicemails.map((message: any) => {
      const transformedOptions = filterOptions(
        options.map((option) => {
          if (
            ["pending", "in_progress"].includes(message.status) &&
            option.value === "resume"
          ) {
            return {
              value: "pause",
              label: "Pause",
            };
          }
          return option;
        }),
        message.status
      );
      return {
        ...message,
        start_time: message.start_time
          ? moment(message.start_time).format("MM-DD-YYYY hh:mm A")
          : "- -",
        failed_sms: message?.failed_sms || 0,
        engagement: "- -",
        completed_at: message.completed_at
          ? moment(message.completed_at).format("MM-DD-YYYY hh:mm A")
          : "- -",
        actions: transformedOptions?.length ? (
          <Dropdown
            options={transformedOptions}
            onSelect={(option) => handleSelect(option, message)}
          />
        ) : (
          <></>
        ),
        status: humanize(message.status || ""),
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.ringless_voicemails, options, type]);

  const handlePause = ({
    scheduleDate,
    isIndefinitePeriod,
  }: {
    scheduleDate: string;
    isIndefinitePeriod: boolean;
  }) => {
    mutation.mutate({
      schedule_date: scheduleDate,
      indefinite_period: isIndefinitePeriod,
    });
  };

  const transformedHeaders = useMemo(() => {
    if (!type) {
      return [
        ...tableHeader,
        { id: 8, value: "Completed At", label: "completed_at" },
      ].sort((a, b) => a.id - b.id);
    }

    const filteredHeaders = tableHeader.filter(
      (value) => value.label !== "status"
    );

    if (type === "rejected") {
      return [
        ...filteredHeaders,
        { id: 8, value: "Rejection Reason", label: "notes" },
      ].sort((a, b) => a.id - b.id);
    }

    if (type === "completed") {
      return [
        ...filteredHeaders,
        { id: 8, value: "Completed At", label: "completed_at" },
      ].sort((a, b) => a.id - b.id);
    }

    return filteredHeaders;
  }, [type]);

  return (
    <div>
      {(mutation.isLoading || statusMutation.isLoading) && (
        <Loader />
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="1rem"
      >
        <Text textStyle="caption" color="var(--grey-600)">
          Broadcast Found: {data?.data?.voicemail_count || 0}
        </Text>

        <Box>
          <DatePicker
            label="Select Date Range"
            shouldShowLabel={false}
            value={{
              startDate: dateRange.start_date as unknown as Date,
              endDate: dateRange.end_date as Date,
            }}
            onChange={(range: {
              startDate: Date;
              endDate: Date;
              clear?: boolean;
            }) => {
              setDateRange({
                start_date: range?.startDate as any,
                end_date: range?.endDate,
                selected: !range.clear,
              });
            }}
            w="100%"
            shouldShowClear
            isRange
          />
        </Box>
      </Box>
      <PaginatedTable
        header={transformedHeaders}
        data={messageData}
        itemsPerPage={perPage}
        maxPageNumbers={5}
        isPaginated={true}
        hasMultiBody={false}
        lastCenter={false}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.voicemail_count || 0}
        isLoadingData={isLoading}
      />

      {isPauseModalOpen && (
        <PauseModal
          isOpen={isPauseModalOpen}
          handlePause={handlePause}
          onClose={() => setIsPauseModalOpen(false)}
        />
      )}

      {isOpenMsg && (
        <Drawer isOpen={isOpenMsg} onClose={onCloseMsg} title="Edit Bulk RVM">
          <CreateBulkRVMForm
            onCloseMsg={() => {
              onCloseMsg();
              setShouldRefetch && setShouldRefetch(true);
              refetch();
            }}
            isEdit
            id={selectedCampaign}
          />
        </Drawer>
      )}
    </div>
  );
}

export default BroadcastRVMListing;
