import { HStack, Image, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "../../../../../assets/avatar.svg";
import {
  PingingsIcon,
  SimpleDateIcon,
} from "../../../../../components/icons/svg";
import { getPingMessage } from "../../../../../utils";
import {
  getPingMessages,
  getSingleMessage,
} from "../../../../../utils/api/lead.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { PingType } from "../../../../../utils/types";
import Loader from "../../../../common/Spinner";
import MessageThread from "../History/Messages/MessageThread";
import { useSearchParams } from "react-router-dom";
import DOMPurify from 'dompurify';

type PingProps = {
  clickedRow: any;
  messageThreadProps: any;
  setClickedRecord: Dispatch<any>;
  clickedRecord: any;
  setActiveIndex: Dispatch<SetStateAction<number>>;
};

const Ping = ({
  clickedRow,
  messageThreadProps,
  setClickedRecord,
  clickedRecord,
  setActiveIndex,
}: PingProps) => {
  const { id: contactId } = clickedRow || {};
  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { id: dealershipId } = dealership || {};

  const [searchParams] = useSearchParams();

  const pingId = searchParams.get("ping_id");

  const [page, setPage] = useState(1);
  const [messages, setMessages] = useState<PingType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const perPage = 10;

  const { isLoading } = useQuery({
    queryKey: ["fetchPingMessages", page, perPage],
    queryFn: () => getPingMessages({ dealershipId, contactId, perPage, page }),
    retry: false,
    onSuccess: (data) => {
      setMessages((prev) => [...prev, ...data.data.messages]);
      setTotalCount(data.data.total_records);
    },
  });

  const { ref, setMessageThread, setInitialLoad, setMessageBody } =
    messageThreadProps || {};

  useEffect(() => {
    return () => {
      // const { ref } = messageThreadProps;
      const { current }: { current: any } = ref;
      current?.remove();
      ref.current = null;
      setMessageThread({});
      setInitialLoad(false);
      setMessageBody("");
      setActiveIndex(0);
      setClickedRecord(null);
    };
  }, [
    ref,
    setMessageThread,
    setInitialLoad,
    setMessageBody,
    setActiveIndex,
    setClickedRecord,
  ]);

  const setPingMessage = useCallback(async () => {
    try {
      const response = await getSingleMessage({ id: pingId });
      const { data } = response || {};
      // setClickedRecord({ ...data.user_message, record_type: "message" });
    } catch (error) {}
  }, [pingId]);

  useEffect(() => {
    if (pingId) {
      setPingMessage();
    }
  }, [pingId, setPingMessage]);

  return (
    <>
      {clickedRecord ? (
        <MessageThread
          clickedRecord={clickedRecord}
          setClickedRecord={setClickedRecord}
          clickedRow={clickedRow}
          type="message"
          messageThreadProps={messageThreadProps}
          ping
        />
      ) : (
        <>
          {isLoading && <Loader />}
          <VStack
            h="100%"
            background="var(--grey-50)"
            padding="1rem"
            gap="1rem"
            alignItems="flex-end"
            w="100%"
            borderTopRightRadius="1rem"
          >
            <VStack
              background="white"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="1rem"
              alignItems="flex-start"
              gap="1rem"
              h="calc(100vh - 130px)"
              overflow="auto"
              w="100%"
            >
              <HStack
                w="100%"
                justifyContent="space-between"
                pb="1rem"
                borderBottom="1px solid var(--grey-300)"
              >
                <Text textStyle="h4" fontWeight="500">
                  Ping Users
                </Text>
              </HStack>
              <VStack w="100%" gap="1rem">
                <div
                  id="scrollableDivPing"
                  style={{
                    height: "calc(100vh - 200px)",
                    overflow: "auto",
                    width: "100%",
                  }}
                >
                  <InfiniteScroll
                    dataLength={messages?.length}
                    next={() => setPage((prev) => prev + 1)}
                    hasMore={messages?.length < totalCount}
                    loader={<h4>Loading...</h4>}
                    scrollableTarget="scrollableDivPing"
                  >
                    {messages.map((item: PingType) => (
                      <HStack
                        w="100%"
                        key={item.id}
                        padding="1rem"
                        border="1px solid var(--grey-300)"
                        borderRadius="0.5rem"
                        alignItems="flex-start"
                        gap="0.5rem"
                        justifyContent="space-between"
                        marginBottom={5}
                        cursor={"pointer"}
                        onClick={() =>
                          setClickedRecord({ ...item, record_type: "message" })
                        }
                      >
                        <HStack alignItems="flex-start">
                          <Image
                            width={38}
                            height={38}
                            src={Avatar}
                            alt="avatar"
                          />
                          <VStack gap="0.25rem" alignItems="flex-start">
                            <Text
                              textStyle="h5"
                              color="var(--grey-800)"
                              fontWeight="600"
                            >
                              {item.ping_user}
                            </Text>

                            <HStack
                              sx={{
                                svg: {
                                  width: "1rem",
                                  height: "1rem",
                                },
                              }}
                            >
                              <PingingsIcon />
                              <Text
                                textStyle="h6"
                                color="var(--grey-400)"
                                fontWeight="500"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(getPingMessage(item.content)),
                                }}
                              ></Text>
                            </HStack>
                          </VStack>
                        </HStack>
                        <HStack gap="0.5rem">
                          <SimpleDateIcon />
                          <Text textStyle="h6" fontWeight="600">
                            {moment(item.created_at).format(
                              "ddd MMM D, YYYY hh:mm a"
                            )}
                          </Text>
                        </HStack>
                      </HStack>
                    ))}
                  </InfiniteScroll>
                </div>
              </VStack>
            </VStack>
          </VStack>
        </>
      )}
    </>
  );
};

export default Ping;
