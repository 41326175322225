import UsageCard from "../../../components/UsageCard";
import { Dropdown, Select, Spinner } from "../../../components/common";
import {
  Box,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { PDFIcon } from "../../../components/icons/svg";
import { useRef, useState } from "react";
import DatePicker from "../../common/DateRangePicker";
import { useReactToPrint } from "react-to-print";
import { fetchGraphData, fetchUsage } from "../../../utils/api/usage";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import StackedColumnChart from "../../Charts/StackedColumnChart";
import { humanize } from "../../../utils";
import moment from "moment";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { getDealershipData } from "../../../utils/api/dealership-setting.api";

type tiles = {
  amount: number;
  cost: number;
  title: string;
};

const keyMapper: any = {
  inbound_voice_minutes: "calls-inbound",
  inbound_calls_local_toll_free: "calls",
  outbound_voice_minutes: "calls-outbound",
  phone_numbers: "phonenumbers",
  SMS_inbound: "sms-inbound",
  SMS_outbound: "sms-outbound",
  MMS_inbound: "mms-inbound",
  MMS_outbound: "mms-outbound",
  SMS_messages_carrier_fees: "sms-messages-carrierfees",
  MMS_messages_carrier_fees: "mms-messages-carrierfees",
  A2P_registration_fees: "a2p-registration-fees",
};

const transformData = (data: any, selectedOption: string, isUsage = true) => {
  const categories = Object.keys(data);

  const cost = categories.map((category: string) => data[category].total_price);
  const count = categories.map((category: string) => data[category].count);

  return {
    categories,
    series: [
      {
        name: selectedOption,
        data: isUsage ? count : cost,
      },
    ],
  };
};

const Usage = () => {
  const { id } = useParams();
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: "",
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [selectedOption, setSelectedOption] = useState<SelectOptionProps>();
  const [activeIndex, setActiveIndex] = useState<number>(1);

  const { data: dealershipData } = useQuery({
    queryKey: ["dealershipData", id],
    queryFn: getDealershipData,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const getParams = (isGraph: boolean) => {
    let params: any = {};
    if (selectionRange && selectionRange?.selected) {
      if (selectionRange?.startDate)
        params.start_time = selectionRange?.startDate.toString();
      if (selectionRange?.endDate)
        params.end_time = selectionRange?.endDate.toString();
    }
    if (isGraph) {
      if (selectedOption) {
        params.category = selectedOption?.value;
      }
    }
    return params;
  };

  const {
    data: usageResData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["fetchUsage", id, selectionRange],
    queryFn: () => fetchUsage(id, getParams(false)),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const keys = Object.keys(data?.data?.data || {});
      setSelectedOption({
        label: humanize(keys?.[0] || "", false),
        value: keyMapper[keys?.[0] || ""],
      });
    },
  });

  const { data: usageData } = usageResData?.data || {};
  const { total_price: totalCost, ...remainingUsageInfo } = usageData || {};

  const { data, isFetching: isFetchingGraph } = useQuery({
    queryKey: [
      "fetchGraphData",
      id,
      selectionRange,
      selectedOption,
      activeIndex,
    ],
    queryFn: () => fetchGraphData(id, getParams(true)),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {},
  });

  const ExportOptions: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];
  const printRef = useRef<any>(null);

  const handleExport = useReactToPrint({
    content: () => {
      const content = printRef.current;
      if (!content) return null;

      const wrapperDiv = document.createElement("div");
      wrapperDiv.style.margin = "30px";
      const contentClone = content.cloneNode(true);
      wrapperDiv.appendChild(contentClone);
      wrapperDiv.innerHTML =
        `<div style="font-weight:bold;font-size:20px">${dealershipData?.data?.name}</div>` +
        wrapperDiv.innerHTML;
      console.log("wrapperDiv", wrapperDiv.innerHTML);
      return wrapperDiv;
    },
    documentTitle: "Print Usage",
  });

  const titleOptions = Object.keys(remainingUsageInfo || {}).map(
    (key: string) => {
      return {
        label: humanize(key, false),
        value: keyMapper[key],
      };
    }
  );

  const { categories, series } = transformData(
    data?.data?.graph_data || {},
    (selectedOption?.label || "") as any,
    activeIndex === 1
  );

  return (
    <Box>
      <HStack>
        <Text textStyle="h6" color="var(--red-600)" mb={15}>
          Last Updated at:{" "}
          <i style={{ color: "var(--grey-900)" }}>
            {moment(data?.data?.updated_at).format("MM-DD-YYYY hh:mm A")}
          </i>
        </Text>
      </HStack>
      <HStack justifyContent="space-between" mb="1rem">
        <DatePicker
          value={selectionRange}
          onChange={(value) => {
            setSelectionRange(value);
            refetch();
          }}
          shouldShowClear
          label="Select Date Range"
          shouldShowLabel={false}
        />
        <Dropdown
          options={ExportOptions}
          hasText={true}
          text="Export"
          onSelect={(value: any) => handleExport()}
        />
      </HStack>

      <VStack w="100%" mb="1rem" ref={printRef}>
        <Box w="100%" mb="1rem" h="100%" display="flex" gap="0.75rem">
          <Box
            w="40%"
            padding="1rem"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            minH="100%"
            position="relative"
            gap="4rem"
            display="flex"
            justifyContent="center"
          >
            <Box
              w="400px"
              height="200px"
              border="20px solid var(--secondary-600)"
              borderTopRightRadius="210px"
              borderTopLeftRadius="210px"
              borderBottom="0"
            ></Box>
            <VStack w="100%" position="absolute" top="50%" left="0">
              <Text textStyle="h1" fontWeight="700">
                ${Number(totalCost).toFixed(3)}
              </Text>
              <Text textStyle="h5" fontWeight="600">
                Total Cost
              </Text>
            </VStack>
          </Box>
          <VStack
            w="60%"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            position="relative"
          >
            <Tabs
              variant="pill"
              defaultIndex={activeIndex}
              onChange={(index) => setActiveIndex(index)}
              index={activeIndex}
            >
              <HStack padding="1rem 0 0">
                <TabList>
                  <Tab minW="4rem">Cost</Tab>
                  <Tab minW="4rem">Usage</Tab>
                </TabList>
                <Select
                  onChange={(value: any) => setSelectedOption(value)}
                  placeholder="Total"
                  options={titleOptions}
                  variant="default"
                  value={selectedOption?.value}
                  w={"240px"}
                />
              </HStack>
              <TabPanels>
                <TabPanel>
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                  />
                </TabPanel>
                <TabPanel>
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </Box>
        <HStack justifyContent="space-between" mb="1rem" w="100%">
          <Text textStyle="h4" fontWeight="500">
            What's included in your plan?
          </Text>
        </HStack>
        <HStack flexWrap="wrap" mb="1rem" w="100%">
          {Object.keys(remainingUsageInfo)?.map((key: string) => {
            const { total_cost, total_value } = remainingUsageInfo[key];
            const title = humanize(key, false);
            return (
              <Box w="24%" key={title}>
                <UsageCard
                  amount={total_cost}
                  heading={title}
                  count={total_value}
                />
              </Box>
            );
          })}
        </HStack>
      </VStack>
      {isFetching && <Spinner />}
    </Box>
  );
};

export default Usage;
