import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Car from "../../../../../assets/images/car.svg";
import { CloseIcon } from "../../../../../components/icons/svg";
import { getSeparatorInUrlString } from "../../../../../utils";
import {
  deleteVehicle,
  fetchVehicles,
  getUrlForDetailsInSMS,
} from "../../../../../utils/api/vehicles";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import Popup from "../../../../Charts/Popup";
import { Spinner } from "../../../../common";
import AddVehicleDrawer from "./AddVehicleDrawer/AddVehicleDrawer";

const Vehicles = (props: any) => {
  const { id } = props;
  const dealer = useUserInfo("dealership");
  const toast = useToast();
  const navigate = useNavigate();

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["fetchVehicles", id],
    queryFn: () => fetchVehicles(dealer?.id, id),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {},
  });

  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [vId, setVId] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleOnClick = async (vId: any) => {
    try {
      const response = await getUrlForDetailsInSMS(dealer?.id, id, vId);
      if (response) {
        toast({
          description:
            response?.data?.message ?? "Vehicle Url shared successfully",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error: any) {
      console.error("Error:", error);
    }
  };
  const mutation = useMutation(deleteVehicle);

  const handleDelete = (vId: any) => {
    mutation.mutate(
      { dId: dealer?.id, id, vId },
      {
        onSuccess: () => {
          refetch();
          setIsOpenDelete(false);
          toast({
            description: "Vehicle deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error deleting vehicle: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const getImage = (urlString: string) => {
    const separator = getSeparatorInUrlString(urlString);
    return separator ? urlString.split(separator)?.[0] : Car;
  };

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        // gap="1rem"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack>
            <Text textStyle="h4" fontWeight="500">
              Vehicles
            </Text>
            <Text textStyle="h4" fontWeight="500" color="var(--grey-500)">
              ({data?.data?.vehicles_count})
            </Text>
          </HStack>
          <HStack>
            <Button
              variant="outline"
              padding="0.5rem"
              minW="auto"
              onClick={onOpen}
            >
              <AddIcon
                style={{
                  color: "var(--grey-700)",
                  fontSize: "1.5rem",
                }}
              />
            </Button>
          </HStack>
        </HStack>
        <VStack gap="1rem" alignItems="flex-start" w="100%" mt="1rem">
          {data?.data?.vehicles?.map((item: any) => (
            <HStack
              key={item.id}
              w="100%"
              padding="1rem"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              alignItems="flex-start"
              gap="1rem"
              position="relative"
              onClick={() => setVId(item.id)}
            >
              <VStack gap="0.5rem" mb="1rem" w="35%">
                <Image
                  src={getImage(item?.inventory?.photo_url_list || "")}
                  width={200}
                  height={150}
                  objectFit="cover"
                  alt="car Image"
                  style={{
                    borderRadius: "0.5rem",
                  }}
                />
              </VStack>
              <VStack alignItems="flex-start" gap="0.5rem" w="65%">
                <VStack
                  alignItems="flex-start"
                  w="100%"
                  gap="0.25rem"
                  pb="0.5rem"
                  borderBottom="1px solid var(--grey-300)"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <HStack paddingRight="1rem">
                      {item?.preferred && (
                        <Text
                          textStyle="captionSmall"
                          fontWeight="500"
                          background="var(--flame-100)"
                          color="var(--flame-600)"
                          padding="0.25rem 0.75rem"
                          borderRadius="200rem"
                          mb="0.25rem"
                        >
                          Preferred
                        </Text>
                      )}
                      {item?.inventory?.status === "available" && (
                        <Text
                          textStyle="captionSmall"
                          fontWeight="500"
                          color="#12c607"
                          background="#d8f3d8"
                          padding="0.25rem 0.75rem"
                          borderRadius="200rem"
                          mb="0.25rem"
                        >
                          Available
                        </Text>
                      )}
                      {item?.inventory?.status === "unavailable" && (
                        <Text
                          textStyle="captionSmall"
                          fontWeight="500"
                          background="var(--flame-100)"
                          color="var(--flame-600)"
                          padding="0.25rem 0.75rem"
                          borderRadius="200rem"
                          mb="0.25rem"
                        >
                          Not Available
                        </Text>
                      )}
                    </HStack>
                  </Box>

                  {!item?.inventory && (
                    <Text textStyle="h4" fontWeight="500">
                      {item.year} {item.make} - {item.model}
                    </Text>
                  )}
                  {item?.inventory && (
                    <Text textStyle="h4" fontWeight="500">
                      {item.inventory.year} {item.inventory.make} -{" "}
                      {item.inventory.model}
                    </Text>
                  )}
                  <HStack
                    alignItems="flex-end"
                    justifyContent="space-between"
                    w="100%"
                  >
                    <VStack alignItems="flex-start" gap="0">
                      <Text
                        textStyle="captionSmall"
                        fontWeight="500"
                        color="var(--grey-500)"
                      >
                        {item?.inventory
                          ? item?.inventory?.interior_color
                          : item.interior_color}
                      </Text>
                      <Text
                        textStyle="captionSmall"
                        fontWeight="500"
                        color="var(--grey-500)"
                      >
                        Mileage:{" "}
                        {item?.inventory
                          ? item?.inventory?.mileage
                          : item.mileage}
                      </Text>
                      {item?.inventory && (
                        <>
                          <Text
                            textStyle="captionSmall"
                            fontWeight="500"
                            color="var(--grey-500)"
                          >
                            {item?.inventory?.vin}
                          </Text>
                          <Text
                            textStyle="captionSmall"
                            fontWeight="500"
                            color="var(--grey-500)"
                          >
                            Stock: {item?.inventory?.stock_number}
                          </Text>
                        </>
                      )}
                    </VStack>
                    <Button
                      variant="outline"
                      mb="0.25rem"
                      onClick={() =>
                        navigate(`/vehicle?slug=${item.id}&id=${id}`)
                      }
                    >
                      View Details
                    </Button>
                  </HStack>
                </VStack>
                {/* <Button
                  variant="outline"
                  padding="0.37rem 0.44rem"
                  minW="auto"
                  h="auto"
                  onClick={(event) => handleOnClick(item.id)}
                >
                  <SendIcon />
                </Button> */}
              </VStack>
              <Button
                variant="none"
                border="1px solid var(--grey-300)"
                position="absolute"
                right="0.5rem"
                top="0.5rem"
                padding="0.15rem"
                borderRadius="0.5rem"
                onClick={() => {
                  setIsOpenDelete(true);
                }}
                sx={{
                  svg: {
                    w: "1rem",
                    h: "1rem",
                    path: {
                      fill: "var(--grey-900)",
                    },
                  },
                }}
              >
                <CloseIcon />
              </Button>
            </HStack>
          ))}
        </VStack>
      </VStack>

      {isOpen && (
        <AddVehicleDrawer
          isOpen={true}
          onClose={() => {
            onClose();
            refetch();
          }}
          info={{ dId: dealer?.id, id: id }}
        />
      )}

      {isOpenDelete && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpenDelete(false)}
          onConfirm={() => handleDelete(vId)}
          title="Delete Confirmation"
          message="Are you sure you want to update delete this vehicle?"
        />
      )}
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default Vehicles;
