import moment from "moment";
import { HStack, Text } from "@chakra-ui/react";
import { SalesLeadIcon } from "../../../../icons/svg";
import Activity from "../History/Activity/Activity";
import { fmtHMS } from "../../../../../utils";

const SingleLeadCard = ({ item, index, setClickedRecord }: any) => {
  return (
    <Activity
      key={item?.id}
      id={item?.id}
      handleClick={() => setClickedRecord(item)}
      title={"Active Sales Lead"}
      dateAndTime={
        item?.created_at
          ? moment(item?.created_at).format("dddd MMM DD YY • H:mm a")
          : "-"
      }
      icon={<SalesLeadIcon />}
      content={
        <HStack
          width="100%"
          pt="10px"
          alignItems="end"
          justifyContent="space-between"
        >
          <Text textStyle="h6" fontWeight="400">
            {item?.lead_source}
          </Text>

          <HStack gap="20px">
            {item?.time_to_first_response && (
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {fmtHMS(Number(item?.time_to_first_response))}
              </Text>
            )}
          </HStack>
        </HStack>
      }
    />
  );
};

export default SingleLeadCard;
