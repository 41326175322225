import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import SettingsIcon from "@mui/icons-material/Settings";
import SquareIcon from "@mui/icons-material/Square";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dropdown,
  Input,
  PaginatedTable,
  Select,
} from "../../../../components/common";
import FilterIcon from "../../../../components/icons/Filter";
import {
  FilledPhoneIcon,
  ResumeIcon,
  SearchIcon,
} from "../../../../components/icons/svg";
import { getLines, modifyStatus } from "../../../../utils/api/line-rings.api";
import ConfirmationDrawer from "../../../common/ConfirmationDrawer";
import { CreateLine } from "./CreateLine";
export const LineListing = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState("all");
  const [advanceFilter, setAdvanceFilter] = useState<any>();
  const [lineID, setLineId] = useState("");
  const { id } = useParams();
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const toast = useToast();
  const mutation = useMutation(modifyStatus);
  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "linesapi",
      id,
      page,
      perPage,
      query.replace(/^\+/, "%2b"),
      filter,
      advanceFilter?.value ?? "",
    ],
    queryFn: getLines,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleSubmit = () => {
    mutation.mutate(
      {
        id,
        lineID: lineID,
        status: popup.value,
        query:
          popup.value === "cancel"
            ? `cancel_line`
            : popup.value === "make_default=true"
            ? `modify_line_status?${popup.value}`
            : `modify_line_status?status=${popup.value}`,
        action:
          popup.value === "cancel"
            ? "Cancel"
            : popup.value === "make_default=true"
            ? "Mark as default"
            : "Pause",
      },
      {
        onSuccess: () => {
          onClosePopupOpen();
          refetch();
          toast({
            description: "Action performed successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const [isOpenCreateTag, setIsOpenCreateTag] = useState(false);
  const onCloseCreateTag = () => setIsOpenCreateTag(false);
  const onOpenCreateTag = () => setIsOpenCreateTag(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);

  const linesHeader = [
    { id: 2, value: "Name", label: "name" },
    { id: 3, value: "Caller ID", label: "callerId" },
    { id: 4, value: "Type", label: "type" },
    { id: 5, value: "Numbers", label: "numbers" },
    { id: 6, value: "Routing", label: "routing" },
    { id: 7, value: "Status", label: "status" },
    { id: 8, value: "Actions", label: "actions" },
  ];
  const activeOptions = [
    // { id: 1, value: "activity", label: "Activity",icon:<BarChartIcon/> },
    {
      id: 2,
      value: "settings",
      label: "Settings",
      icon: (
        <SettingsIcon
          style={{
            width: "1.25rem",
            height: "1.25rem",
            color: "var(--grey-800)",
          }}
        />
      ),
    },
    { id: 3, value: "cancel", label: "Cancel", icon: <DoNotDisturbIcon /> },
    { id: 4, value: "paused", label: "Pause", icon: <ResumeIcon /> },
    {
      id: 5,
      value: "make_default=true",
      label: "Mark as Deafult",
      icon: <SquareIcon />,
    },
  ];
  const PauseOptions = [
    // { id: 1, value: "activity", label: "Activity",icon:<BarChartIcon/> },
    {
      id: 2,
      value: "settings",
      label: "Settings",
      icon: (
        <SettingsIcon
          style={{
            width: "1.25rem",
            height: "1.25rem",
            color: "var(--grey-800)",
          }}
        />
      ),
    },
    { id: 3, value: "cancel", label: "Cancel", icon: <DoNotDisturbIcon /> },
    { id: 4, value: "active", label: "Unpause", icon: <ResumeIcon /> },
    {
      id: 5,
      value: "make_default=true",
      label: "Mark as Deafult",
      icon: <SquareIcon />,
    },
  ];
  const getOptions = (
    status: string,
    defaultLine: boolean
  ): SelectOptionProps[] => {
    if (status === "paused") {
      return PauseOptions.filter(
        (item) => !(defaultLine && item.value === "make_default=true")
      )?.map((item) => ({
        label: (
          <Box as="span" display="flex" alignItems="center" gap="0.5rem">
            {item?.icon}
            {item?.label}
          </Box>
        ),
        value: item?.value,
      }));
    } else if (status === "failed" || status === "pending") {
      return activeOptions
        .filter((item) => {
          // Remove the item if it's a default item and should be filtered out, or if it's the cancel option
          return (
            !(defaultLine && item.value === "make_default=true") &&
            item.value !== "cancel"
          );
        })
        ?.map((item) => ({
          label: (
            <Box as="span" display="flex" alignItems="center" gap="0.5rem">
              {item?.icon}
              {item?.label}
            </Box>
          ),
          value: item?.value,
        }));
    }
    // Filter out "Mark as Default" option if defaultLine is true
    return activeOptions
      .filter((item) => !(defaultLine && item.value === "make_default=true"))
      .map((item) => ({
        label: (
          <Box as="span" display="flex" alignItems="center" gap="0.5rem">
            {item?.icon}
            {item?.label}
          </Box>
        ),
        value: item?.value,
      }));
  };

  const options: SelectOptionProps[] = [
    {
      label: "All",
      value: "",
    },
    {
      label: "Personal Lines",
      value: "personal_lines",
    },
    {
      label: "Toll Free",
      value: "toll_free",
    },
    {
      label: "Local Preference",
      value: "local",
    },
    {
      label: "Ring Groups",
      value: "ring_group",
    },
  ];

  const selectOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Paused", value: "paused" },
    { label: "Cancelled", value: "cancelled" },
    { label: "Pending", value: "pending" },
    { label: "Failed", value: "failed" },
  ];

  const handleSelect = (option: SelectOptionProps, lineId: string) => {
    setLineId(lineId);
    if (option.value === "cancel") {
      setPopup({
        name: "Cancel Line",
        description: "Are you sure you want to cancel this line?",
        value: option.value,
      });
      onOpenPopupOpen();
    } else if (option.value === "paused") {
      setPopup({
        name: "Pause Line",
        description: "Are you sure you want to Pause this line?",
        value: option.value,
      });
      onOpenPopupOpen();
    } else if (option.value === "active") {
      setPopup({
        name: "Unpause Line",
        description: "Are you sure you want to unpause this line?",
        value: option.value,
      });
      onOpenPopupOpen();
    } else if (option.value === "settings") {
      navigate(`/dealerships/lines-ring-groups/line-settings/${id}/${lineId}`);
    } else if (option.value === "make_default=true") {
      setPopup({
        name: "Mark as Default",
        description: "Are you sure you want to mark this line as default?",
        value: option.value,
      });
      onOpenPopupOpen();
    }
  };
  const statusColorSetter = (status: any) => {
    if (status === "active") {
      return "var(--secondary-600)";
    } else if (status === "paused") {
      return "var(--yellow-default)";
    } else if (status === "failed") {
      return "var(--red-900)";
    } else if (status === "cancelled") {
      return "var(--grey-600)";
    } else {
      return "var(--orange-default)";
    }
  };
  const linesData = data?.data?.lines?.map((line: any) => ({
    id: line?.id,
    name: line?.default_line ? `${line?.name} (default)` : line?.name,
    callerId: line?.caller_id ?? "--",
    type: (
      <Text
        padding="0.5rem"
        background="var(--grey-200)"
        borderRadius="2.5rem"
        w="max-content"
      >
        {line?.line_type}
      </Text>
    ),
    numbers: (
      <HStack>
        <FilledPhoneIcon color="var(--grey-700)" />
        <Text>{line?.phone_number}</Text>
      </HStack>
    ),
    routing: (
      <VStack alignItems="flex-start">
        <HStack>
          <Box
            as="span"
            w="0.5rem"
            h="0.5rem"
            borderRadius="50%"
            background="var(--primary-600)"
          ></Box>
          <Text>Connected To:</Text>
        </HStack>
        <Text color="var(--grey-700)">
          {line?.connected_to === "both"
            ? "user, ring group"
            : line?.connected_to}
        </Text>
      </VStack>
    ),
    status: (
      <>
        <Box
          as="span"
          py="0.25rem"
          px="0.62rem"
          color={statusColorSetter(line?.status)}
          fontSize="0.875rem"
          fontWeight="400"
          lineHeight="1.25rem"
          border={`1px solid ${statusColorSetter(line?.status)}`}
          borderRadius="2.5rem"
        >
          {line?.status}
        </Box>
      </>
    ),
    actions: (
      <Dropdown
        options={getOptions(line?.status, line?.default_line)}
        onSelect={(option) => handleSelect(option, line?.id || "")}
        disabled={line?.status === "cancelled"}
      />
    ),
  }));

  return (
    <>
      <HStack justifyContent="space-between" padding="1rem">
        <Text textStyle="caption" color="var(--grey-600)">
          Lines Found: {data?.data?.line_count ?? ""}
        </Text>
        <HStack>
          <Input
            maxW="18rem"
            minW="18rem"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Type here to search"
            onChange={(e) => setQuery(e.target.value)}
          />
          <VStack position="relative" zIndex="3">
            <Select
              variant="dropdownSm"
              options={selectOptions}
              onChange={(value: any) => setFilter(value.value)}
              placeholder="All"
              value={filter}
              label={undefined}
              w="7rem"
            />
          </VStack>

          <VStack position="relative" zIndex="3">
            <Dropdown
              options={options}
              onSelect={(val) => setAdvanceFilter(val)}
              hasText={true}
              hasChevron={false}
              text={
                <HStack padding="0.1rem">
                  <FilterIcon />
                  <Text>Advance Filters</Text>
                </HStack>
              }
              value={advanceFilter}
            />
          </VStack>

          <Button onClick={onOpenCreateTag}>Create Line</Button>
        </HStack>
      </HStack>
      <PaginatedTable
        header={linesHeader}
        data={linesData}
        itemsPerPage={perPage}
        isLoadingData={isLoading}
        currentPage={page}
        maxPageNumbers={3}
        isPaginated={true}
        rowOnClick={(rowItem) => {
          setLineId(rowItem?.id);
        }}
        hasMultiBody={false}
        setCurrentPage={setPage}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
      />
      {isOpenCreateTag && (
        <CreateLine
          isOpen={isOpenCreateTag}
          onClose={onCloseCreateTag}
          apicall={refetch}
        />
      )}
      {isPopupOpen && (
        <ConfirmationDrawer
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          content={popup}
          apiCall={handleSubmit}
          loading={mutation?.isLoading}
        />
      )}
    </>
  );
};
