import {
  Box,
  Button,
  HStack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getInitials } from "../../utils";
import { getDeskLogs } from "../../utils/api/desk-log.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { CreateAppointment } from "../MeetingCalendar/CreateAppointment";
import { PaginatedTable } from "../common";
import TooltipIfOverflow from "../common/TooltipIfOverflow";
import { AppointmentIcon, EditIcon } from "../icons/svg";
import NotesDrawer from "./NotesDrawer";

const tableHeader = [
  { id: 1, value: "Customer", label: "customer" },
  { id: 2, value: "Time", label: "time" },
  { id: 3, value: "Phone Number", label: "phoneNo" },
  { id: 4, value: "Vehicle", label: "vehicle" },
  { id: 5, value: "Trade In", label: "trade" },
  { id: 6, value: "Lead Source", label: "leadSource" },
  { id: 7, value: "Status", label: "status" },
  { id: 9, value: "Point Person", label: "point_person" },
  { id: 10, value: "T.O.", label: "to" },
  { id: 11, value: "Purchased", label: "purchased" },
  { id: 12, value: "Appt", label: "appt" },
];

type DeskLogListingProps = {
  shouldRefresh: boolean;
  setShouldRefresh: (value: boolean) => void;
  searchStr: string;
  dateRange: any;
};

function DeskLogListing({
  shouldRefresh,
  setShouldRefresh,
  searchStr,
  dateRange,
}: DeskLogListingProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedItem, setSetSelectedItem] = useState<any>(null);
  const [isAppointmentOpen, setIsAppointmentOpen] = useState<boolean>(false);

  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);

  const user = useUserInfo("user");

  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useQuery({
    queryKey: ["fetchDeskLog", searchStr, dateRange],
    queryFn: () =>
      getDeskLogs({ dealershipId: user?.dealership?.id, searchStr, dateRange }),
    retry: false,
    onSuccess: () => setShouldRefresh(false),
  });

  useEffect(() => {
    if (shouldRefresh) {
      refetch();
    }
  }, [refetch, shouldRefresh]);

  const renderStatus = (status: string) => {
    switch (status) {
      case "Sold":
        return (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0.62rem"
            border="1px solid var(--secondary-600)"
          >
            <Text textStyle="h6" color="var(--secondary-600)">
              Sold
            </Text>
          </Box>
        );

      case "Lost Since":
        return (
          <Box
            w="4.6rem"
            textAlign="center"
            borderRadius="2.5rem"
            padding="0.25rem 0"
            border="1px solid var(--red-400)"
          >
            <Text textStyle="h6" color="var(--red-800)">
              Lost Since
            </Text>
          </Box>
        );
      case "Sold Since":
        return (
          <Box
            as="span"
            width="5.875rem"
            padding="0.25rem 0.625rem"
            borderRadius="2.5rem"
            border="1px solid var(--primary-600)"
            color="var(--primary-600)"
            fontSize="0.875rem"
          >
            Sold Since
          </Box>
        );
      case "In Progress":
        return (
          <Box
            as="span"
            width="5.875rem"
            padding="0.25rem 0.625rem"
            borderRadius="2.5rem"
            border="1px solid var(--orange-500)"
            color="var(--orange-500)"
            fontSize="0.875rem"
          >
            In Progress
          </Box>
        );

      default:
        return (
          <Box
            as="span"
            // width="5.875rem"
            // width="100%"
            padding="0.25rem 0.625rem"
            borderRadius="2.5rem"
            border="1px solid var(--orange-500)"
            color="var(--orange-500)"
            fontSize="0.875rem"
          >
            {status}
          </Box>
        );
    }
  };

  const handleNoteOpen = useCallback(
    (item: any, type: string) => {
      setSetSelectedItem({ ...item, type });
      onOpen();
    },
    [onOpen]
  );

  const tableData = useMemo(() => {
    if (!data?.data?.process_sale_data?.length) {
      return [];
    }

    return data?.data?.process_sale_data.map((item: any) => {
      return {
        customer: (
          <VStack alignItems="flex-start">
            <Text textStyle="h6" fontWeight="500" color="var(--primary-600)">
              {item.customer_name}
            </Text>
            {item.notes?.length ? (
              <Button
                variant="none"
                p="0.25rem !important"
                fontSize="0.75rem"
                gap="0.25rem"
                onClick={() => handleNoteOpen(item, "plain")}
                sx={{
                  svg: {
                    fill: "var(--grey-800)",
                    width: "1rem",
                    height: "1rem",
                  },
                }}
                color="var(--grey-800)"
                display="flex"
                background="var(--grey-200)"
                borderRadius="0.375rem"
                isDisabled={user?.role !== "Manager/Admin"}
              >
                <TooltipIfOverflow
                  char={60}
                  maxWidth="50vw"
                  wordBreak="break-word"
                  placement="auto"
                >
                  {item.notes?.[0]}
                </TooltipIfOverflow>{" "}
                <EditIcon />
              </Button>
            ) : (
              <Button
                p="0.25rem"
                fontSize="0.75rem"
                h="1.5rem"
                gap="0.25rem"
                onClick={() => handleNoteOpen(item, "plain")}
                sx={{
                  ".chakra-button__icon": { marginRight: "0" },
                }}
                variant="outline"
                color="var(--primary-600)"
                isDisabled={user?.role !== "Manager/Admin"}
              >
                Add Notes
              </Button>
            )}
          </VStack>
        ),
        time: moment(item.time).format("MM-DD-YYYY hh:mm A"),
        vehicle: (
          <Text textStyle="h6" fontWeight="500">
            {item.vehicle
              ? `${item.vehicle?.make} - ${item.vehicle?.model} - ${item.vehicle?.year}`
              : "- -"}
          </Text>
        ),
        trade: (
          <Text textStyle="h6" fontWeight="500">
            {item.trade
              ? `${item.trade?.make} - ${item.trade?.model} - ${item.trade?.year}`
              : "- -"}
          </Text>
        ),
        leadSource: item.lead_source,
        status: item.disposition_statuses?.length ? (
          <>
            <HStack flexWrap="wrap">
              {item.disposition_statuses?.map((status: string) =>
                renderStatus(status)
              )}
            </HStack>
          </>
        ) : (
          <>- -</>
        ),
        to: (
          <VStack>
            {item.turn_over ? (
              <Tooltip label={item.turn_over} placement="bottom" zIndex="99999">
                <Text
                  textStyle="h6"
                  fontWeight="500"
                  border="1px solid black"
                  borderRadius="50%"
                  padding={2}
                  background="var(--grey-200)"
                >
                  {getInitials(item.turn_over)}
                </Text>
              </Tooltip>
            ) : (
              "- -"
            )}

            {item.to_notes ? (
              <Text
                color="var(--grey-800)"
                display="flex"
                background="var(--grey-200)"
                borderRadius="0.375rem"
                p="0.25rem !important"
                fontSize="0.75rem"
                gap="0.25rem"
              >
                <TooltipIfOverflow>{item.to_notes}</TooltipIfOverflow>
              </Text>
            ) : (
              <Button
                p="0.25rem"
                fontSize="0.75rem"
                h="1.5rem"
                gap="0.25rem"
                onClick={() => handleNoteOpen(item, "toNotes")}
                sx={{
                  ".chakra-button__icon": { marginRight: "0" },
                }}
                variant="outline"
                color="var(--primary-600)"
                isDisabled={user?.role !== "Manager/Admin"}
              >
                Add Notes
              </Button>
            )}
          </VStack>
        ),
        point_person: (
          <VStack>
            {item.point_person ? (
              <Tooltip
                label={item.point_person}
                placement="bottom"
                zIndex="99999"
              >
                <Text
                  textStyle="h6"
                  fontWeight="500"
                  border="1px solid black"
                  borderRadius="50%"
                  padding={2}
                  background="var(--grey-200)"
                >
                  {getInitials(item.point_person)}
                </Text>
              </Tooltip>
            ) : (
              "- -"
            )}
          </VStack>
        ),
        purchased: (
          <Text textStyle="h6" fontWeight="500">
            {item.purchased}
          </Text>
        ),
        phoneNo: item.phone_number,
        appt: (
          <Button
            variant="none"
            sx={{
              svg: {
                width: "1.25rem",
                height: "1.25rem",
              },
            }}
            onClick={() => {
              setSetSelectedItem(item);
              setIsAppointmentOpen(true);
            }}
          >
            <AppointmentIcon color="var(--grey-800)" />
          </Button>
        ),
      };
    });
  }, [data?.data?.process_sale_data, handleNoteOpen, user?.role]);

  return (
    <Box
      w="100%"
      background="white"
      borderRadius="0.5rem"
      border="1px solid var(--grey-300)"
      paddingTop="1rem"
    >
      <PaginatedTable
        header={tableHeader}
        data={tableData}
        isLoadingData={isLoading}
        itemsPerPage={perPage}
        maxPageNumbers={5}
        isPaginated={true}
        lastCenter={false}
        hasMultiBody={false}
        perPageChange={(value) => {
          setPerPage(Number(value?.label));
          setPage(1);
        }}
        currentPage={page}
        setCurrentPage={setPage}
        rowCount={data?.data?.total_count}
        tableHeight={550}
      />

      {isOpen && (
        <NotesDrawer
          isOpen={isOpen}
          onClose={() => {
            refetch();
            onClose();
          }}
          contactId={selectedItem?.contact_id}
          note={
            selectedItem.type === "plain"
              ? selectedItem.notes
              : selectedItem.to_notes
          }
          isPlainNotes={selectedItem.type === "plain"}
        />
      )}

      {isAppointmentOpen && (
        <CreateAppointment
          isOpen={isAppointmentOpen}
          onClose={() => setIsAppointmentOpen(false)}
          contactid={selectedItem?.contact_id}
          apiCall={() => {}}
          slotData={{ type: { value: "appointment" } }}
        />
      )}
    </Box>
  );
}

export default DeskLogListing;
