import { Box, Button, HStack, Text, useToast, VStack } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { Input } from "../../../components/common";
import SwitchToggle from "../../../components/common/Switch";
import { defaultOperatingHours } from "../../../utils";
import { getDealershipEmails } from "../../../utils/api/dealership-setting.api";
import {
  addRingRouting,
  addUserRouting,
  getLineSettings,
  getRings,
  modifyLineSettings,
  postRecording,
} from "../../../utils/api/line-rings.api";
import Loader from "../../common/Spinner";
import { InsertVariableDrawer } from "../../Drawers/InsertVariableDrawer";
import { sortWeekdays } from "../../GlobalDashboard/UserUtils";
import { DayComponent } from "./DayComponent";

const RoutingAndIVR = () => {
  const toast = useToast();
  const { id } = useParams();
  const { dealerId } = useParams();
  const mutation = useMutation(modifyLineSettings);
  const uploadMutation = useMutation(postRecording);
  const userMutation = useMutation(addUserRouting);
  const ringMutation = useMutation(addRingRouting);
  const { data, isLoading } = useQuery({
    queryKey: ["routingDataResponse", id, "routing_settings"],
    queryFn: getLineSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setRoutingData(data?.data?.settings_hash);
      setAudioData(
        data?.data?.settings_hash?.close_hours_recording_pre_signed_url ?? ""
      );
      setTranscribed(
        data?.data?.settings_hash?.close_hours_recording_pre_signed_url
          ? true
          : false
      );
      setAudioData2(
        data?.data?.settings_hash?.open_hours_recording_pre_signed_url ?? ""
      );
      setTranscribed2(
        data?.data?.settings_hash?.open_hours_recording_pre_signed_url
          ? true
          : false
      );
      if (data?.data?.settings_hash?.operating_hours) {
        setOperatingHours(data?.data?.settings_hash?.operating_hours);
      }
    },
  });
  const [routingData, setRoutingData] = useState(data?.data?.settings_hash);
  const [operatingHours, setOperatingHours] = useState<any>(
    defaultOperatingHours
  );
  const [copiedTime, setCopiedTime] = useState({
    start_time: null,
    end_time: null,
  });

  const handleRoutingRecord = (field: string, value: any) => {
    setRoutingData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const handleCopyTime = (day: any) => {
    setCopiedTime(operatingHours[day]);
  };
  const handlePasteTime = (day: any) => {
    if (copiedTime.start_time && copiedTime.end_time) {
      setOperatingHours({
        ...operatingHours,
        [day]: { ...operatingHours[day], ...copiedTime },
      });
    }
  };

  const [showVariable, setShowVariable] = useState<boolean>(false);
  const [transcribed, setTranscribed] = useState<boolean | undefined>(false);
  const [audioData, setAudioData] = useState<any>(
    routingData?.close_hours_recording_pre_signed_url ?? ""
  );
  const [audioUpload, setAudioUpload] = useState(false);
  const [showVariable2, setShowVariable2] = useState<boolean>(false);
  const [transcribed2, setTranscribed2] = useState<boolean | undefined>(false);
  const [audioData2, setAudioData2] = useState<any>(
    routingData?.open_hours_recording_pre_signed_url ?? ""
  );
  const [audioUpload2, setAudioUpload2] = useState(false);
  const handleToggle = (day: any, status: any) => {
    setOperatingHours((prev: any) => ({
      ...prev,
      [day]: { ...prev[day], day_status: status },
    }));
  };
  const loadEmails = async (inputValue: string) => {
    try {
      const response = await getDealershipEmails({
        queryKey: ["dealerUsers", dealerId, inputValue],
      });
      const options = response?.data?.map((user: any) => ({
        value: user.id,
        label: user.name,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  const handleSelectTime = (day: any, timeType: any, value: any) => {
    setOperatingHours((prev: any) => ({
      ...prev,
      [day]: { ...prev[day], [timeType]: value },
    }));
  };
  const handleSubmit = async () => {
    if (
      !(routingData?.ring_group && routingData?.ring_group_id) &&
      !(routingData?.user && routingData?.user_id)
    ) {
      toast({
        description: "Please choose a ring group or a user before proceeding.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } else {
      let uploadPromises = [];

      if (
        audioData &&
        audioData !== routingData?.close_hours_recording_pre_signed_url
      ) {
        uploadPromises.push(uploadAudioData(audioData, "closed_hours"));
      }

      if (
        audioData2 &&
        audioData2 !== routingData?.open_hours_recording_pre_signed_url
      ) {
        uploadPromises.push(uploadAudioData(audioData2, "open_hours"));
      }

      if (uploadPromises.length > 0) {
        await Promise.all(uploadPromises);
      } else {
        addUser();
        addRing();
      }
    }
  };

  const uploadAudioData = async (audioDataa: any, attachmentType: any) => {
    try {
      const response = await fetch(audioDataa);
      const blob = await response.blob();
      let formData = new FormData();
      formData.append("audio_file", blob);
      formData.append("attachment_type", attachmentType);

      const responseData = await uploadMutation.mutateAsync({
        id: id,
        data: formData,
        dealerId,
      });
      let data = responseData?.data;
      handleRoutingRecord(
        attachmentType === "closed_hours"
          ? "close_hours_recording_pre_signed_url"
          : "open_hours_recording_pre_signed_url",
        data?.presigned_url
      );
      if (audioDataa && attachmentType === "closed_hours") {
        console.log("first");
        setAudioUpload(true);
      } else if (audioDataa && attachmentType === "open_hours") {
        console.log("second");
        setAudioUpload2(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (audioUpload && audioUpload2) {
      addUser();
      addRing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUpload, audioUpload2]);

  const dataSubmit = () => {
    const modifiedRoutingData = {
      ...routingData, // Spread the existing properties of routingData
      operating_hours: operatingHours, // Replace operating_hours with the modified array
    };
    mutation.mutate(
      {
        id: id,
        data: {
          key: "routing_settings",
          settings_hash: modifiedRoutingData,
        },
        dealerId,
      },
      {
        onSuccess: (data) => {
          setAudioUpload(false);
          toast({
            description: "Settings updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const addUser = () => {
    userMutation.mutate({
      id: id,
      data: {
        line: {
          user_id: routingData?.user_id?.value,
        },
      },
      dealerId,
    });
  };
  const addRing = () => {
    if (routingData?.ring_group_id?.value) {
      ringMutation.mutate(
        {
          id: routingData?.ring_group_id?.value,
          data: {
            lines: {
              remove_lines: [],
              add_lines: [Number(id)],
            },
          },
          dealership_id: dealerId,
        },
        {
          onSuccess: () => {
            dataSubmit();
          },
        }
      );
    } else {
      dataSubmit();
    }
  };
  const loadRings = async (inputValue: string) => {
    try {
      let options = [];
      if (!inputValue) {
        // Load initial 5 options if no search query is provided
        const response = await getRings({
          queryKey: ["linerings", dealerId, 1, 5, ""],
        });
        options = response?.data?.ring_groups?.map((user: any) => ({
          value: user.id,
          label: user.name,
        }));
      } else {
        // Load additional options on search
        const response = await getRings({
          queryKey: ["linerings", dealerId, 1, 20, inputValue],
        });
        options = response?.data?.ring_groups?.map((user: any) => ({
          value: user.id,
          label: user.name,
        }));
      }
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };

  return (
    <VStack w="100%" position="relative" alignItems="flex-start">
      <Box
        paddingTop="0.25rem"
        height="calc(100vh - 235px)"
        w="100%"
        overflow="auto"
        pb="75px"
      >
        <VStack
          gap="1.5rem"
          alignItems="flex-start"
          py="1.5rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack alignItems="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Who should answer this call?
            </Text>
            <VStack alignItems="flex-start" width="50%" gap="1rem">
              <VStack alignItems="flex-start">
                <SwitchToggle
                  id="1"
                  label="A Ring Group"
                  onChange={(check: boolean) =>
                    handleRoutingRecord("ring_group", check)
                  }
                  defaultChecked={routingData?.ring_group}
                />
                <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                  Send the call to a group of agents. Queues are defined per
                  ring group.
                </Text>
              </VStack>

              <AsyncSelect
                isClearable={true}
                defaultOptions={true}
                loadOptions={(inputValue) => loadRings(inputValue)}
                value={routingData?.ring_group_id}
                onChange={(value: any) => {
                  handleRoutingRecord("ring_group_id", value);
                }}
                placeholder="Select Ring Group"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "425px",
                  }),
                }}
              />
              <VStack alignItems="flex-start">
                <SwitchToggle
                  id="2"
                  label="A User"
                  onChange={(check: boolean) =>
                    handleRoutingRecord("user", check)
                  }
                  defaultChecked={routingData?.user}
                />
                <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                  Send this call to this Line Owner.
                </Text>
              </VStack>
              <AsyncSelect
                isClearable={true}
                loadOptions={(inputValue) => loadEmails(inputValue)}
                value={routingData?.user_id}
                onChange={(value: any) => {
                  handleRoutingRecord("user_id", value);
                }}
                placeholder="Select User"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "425px",
                  }),
                }}
                defaultOptions
              />
            </VStack>
          </HStack>
        </VStack>
        <VStack
          gap="1.5rem"
          alignItems="flex-start"
          py="1.5rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack alignItems="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Missed Calls
            </Text>
            <VStack alignItems="flex-start" width="50%" gap="1rem">
              <Text textStyle="h5" fontWeight="700">
                Missed Calls
              </Text>
              <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                If your business is closed, the call will be missed. Here you
                can configure what happens to missed calls.
              </Text>
              <VStack alignItems="flex-start">
                <SwitchToggle
                  id="1"
                  onChange={(check: boolean) =>
                    handleRoutingRecord("close_hours_voice_prompt", check)
                  }
                  label="Closed hours voice note"
                  defaultChecked={routingData?.close_hours_voice_prompt}
                />
              </VStack>
              <>
                <Input
                  label="Recording Message"
                  type="text"
                  placeholder="Write here.."
                  onChange={(e) =>
                    handleRoutingRecord(
                      "close_hours_auto_reply_text",
                      e.target.value
                    )
                  }
                  value={routingData?.close_hours_auto_reply_text}
                  mb="10px"
                />

                {!showVariable && (
                  <Button onClick={() => setShowVariable(true)}>
                    Add Variable
                  </Button>
                )}

                {showVariable && (
                  <InsertVariableDrawer
                    isOpen={showVariable}
                    isOnRight={true}
                    onClose={() => setShowVariable(false)}
                    onVariableSelect={(variable: any) =>
                      handleRoutingRecord(
                        "close_hours_auto_reply_text",
                        `${routingData?.close_hours_auto_reply_text} {{${variable?.name}}}`
                      )
                    }
                  />
                )}
              </>
              <VStack alignItems="flex-start">
                <SwitchToggle
                  id="1"
                  onChange={(check: boolean) =>
                    handleRoutingRecord("open_hours_voice_prompt", check)
                  }
                  label="Open hours voice note"
                  defaultChecked={routingData?.open_hours_voice_prompt}
                />
              </VStack>
              <>
                <Input
                  label="Recording Message"
                  type="text"
                  placeholder="Write here.."
                  onChange={(e) =>
                    handleRoutingRecord(
                      "open_hours_auto_reply_text",
                      e.target.value
                    )
                  }
                  value={routingData?.open_hours_auto_reply_text}
                  mb="10px"
                />

                {!showVariable2 && (
                  <Button onClick={() => setShowVariable2(true)}>
                    Add Variable
                  </Button>
                )}

                {showVariable2 && (
                  <InsertVariableDrawer
                    isOpen={showVariable2}
                    isOnRight={true}
                    onClose={() => setShowVariable2(false)}
                    onVariableSelect={(variable: any) =>
                      handleRoutingRecord(
                        "open_hours_auto_reply_text",
                        `${routingData?.open_hours_auto_reply_text} {{${variable?.name}}}`
                      )
                    }
                  />
                )}
              </>
            </VStack>
          </HStack>
        </VStack>
        <VStack gap="1.5rem" alignItems="flex-start" py="1.5rem">
          <HStack alignItems="flex-start" w="100%" position="relative">
            <VStack
              w="25%"
              alignItems="flex-start"
              position="relative"
              height="100%"
              mt="1rem"
            >
              <Text textStyle="h5" fontWeight="700">
                Check Operating Hours
              </Text>
            </VStack>
            <VStack
              alignItems="flex-start"
              // width="50%"
              gap="0"
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
              padding="1.3rem"
            >
              <VStack alignItems="flex-start" width="100%" gap="1rem">
                <VStack alignItems="flex-start">
                  <SwitchToggle
                    id="1"
                    onChange={(check: boolean) =>
                      handleRoutingRecord("check_operating_hours", check)
                    }
                    label="Check Operating Hours?"
                    defaultChecked={routingData?.check_operating_hours}
                  />
                  <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                    Here you can define the open/close hours of your business.
                    If a call comes in during closed hours, it will follow your
                    'missed call' logic defined on the next page.
                  </Text>
                </VStack>
                {Object?.entries(sortWeekdays(operatingHours))?.map(
                  ([day, data]) => (
                    <DayComponent
                      key={day}
                      day={day}
                      data={data}
                      onToggle={handleToggle}
                      onSelectTime={handleSelectTime}
                      onCopy={() => handleCopyTime(day)}
                      onPaste={() => handlePasteTime(day)}
                    />
                  )
                )}
              </VStack>
            </VStack>
          </HStack>
        </VStack>
      </Box>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        position="absolute"
        background="var(--grey-100)"
        borderTop="1px solid var(--grey-300)"
      >
        <Button width="8rem" onClick={handleSubmit}>
          Save
        </Button>
      </HStack>
      {(isLoading ||
        mutation.isLoading ||
        userMutation.isLoading ||
        ringMutation.isLoading ||
        uploadMutation.isLoading) && <Loader />}
    </VStack>
  );
};

export default RoutingAndIVR;
