"use client";

import {
  AspectRatio,
  Box,
  HStack,
  Image,
  Spinner,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { InlineWidget } from "react-calendly";
import InfiniteScroll from "react-infinite-scroll-component";
import TrainingImg from "../../../assets/images/training.svg";
import { Input, Modal } from "../../../components/common";
import TooltipIfOverflow from "../../../components/common/TooltipIfOverflow";
import {
  PlayVideoIcon,
  SearchIcon,
  SparklesIcon,
} from "../../../components/icons/svg";
import {
  fetchTrainingVideos,
  updateTrainingVideoCount,
} from "../../../utils/api/training-videos.api";

export default function TraniningPage() {
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [trainings, setTrainings] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const mutation = useMutation(updateTrainingVideoCount);

  const { isLoading } = useQuery({
    queryKey: ["fetchTrainingVideos", searchValue, page],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = 20;
      } else {
        params.q = searchValue;
      }
      return fetchTrainingVideos(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.training_videos_count ?? 0);
      setTrainings([...trainings, ...data?.training_videos]);
    },
  });

  useEffect(() => {
    setTrainings([]);
    setPage(1);
  }, [searchValue]);

  const getEmbeddedUrl = (url: string) => {
    const vimeoMatch = url?.match(
      /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/?(.+)/
    );
    const youtubeMatch = url?.match(
      /(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/)([^&]+)/
    );

    if (vimeoMatch) {
      return `https://player.vimeo.com/video/${vimeoMatch[1]}`;
    }

    if (youtubeMatch) {
      return `https://www.youtube.com/embed/${youtubeMatch[2]}`;
    }

    console.error("Invalid URL");
  };

  useEffect(() => {
    if (isOpen) {
      mutation.mutate({ id: clickedRow?.id }, {});
    }
  }, [isOpen]);

  return (
    <VStack
      padding="1rem 1.5rem"
      w="100%"
      alignItems="flex-start"
      background="white"
      gap="3rem"
    >
      <HStack
        padding="1rem 1.5rem"
        background="var(--grey-50)"
        w="100%"
        justifyContent="space-between"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
      >
        <VStack alignItems="flex-start" width="30%" height="100%">
          <Text textStyle="h2" fontWeight="700" maxW="15.5rem">
            Schedule Training Session
          </Text>
          <Text textStyle="h5" maxW="27rem">
            Custom Training Sessions: Expert-led, personalized learning for your
            goals. Choose your topic, delivery mode. Elevate skills efficiently.
          </Text>
        </VStack>
        <VStack width="70%" height="100%">
          <InlineWidget
            url="https://calendly.com/simpsocial"
            // url="https://calendly.com/kalsoom-asghar"
            styles={{ width: "100%", height: "650px" }}
          ></InlineWidget>
        </VStack>
      </HStack>

      <VStack alignItems="flex-start" w="100%" gap="1.5rem" width="100%">
        <HStack w="100%" justifyContent="space-between">
          <HStack>
            <SparklesIcon />
            <Text textStyle="h3" fontWeight="700">
              Featured Training Tutorials
            </Text>
          </HStack>
          <Input
            hasIcon={true}
            icon={<SearchIcon />}
            maxW="20rem"
            placeholder="Search tutorials here..."
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
          />
        </HStack>
        <div
          id="scrollableDiv"
          style={{
            height: "calc(87vh - 120px)",
            overflow: "auto",
            scrollbarWidth: "none",
          }}
        >
          <InfiniteScroll
            dataLength={trainings?.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={trainings?.length < count}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDiv"
          >
            <HStack flexWrap="wrap" gap="1.5rem" width="100%">
              {trainings?.map((item: any) => (
                <VStack
                  key={item}
                  alignItems="flex-start"
                  alignSelf="flex-start"
                  onClick={() => setClickedRow(item)}
                >
                  <Box
                    position="relative"
                    sx={{
                      _hover: {
                        ".overlay": {
                          display: "flex",
                        },
                      },
                    }}
                  >
                    <VStack
                      justifyContent="center"
                      className="overlay"
                      display="none"
                      cursor="pointer"
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      background="rgba(0, 0, 0, 0.25)"
                      borderRadius="0.5rem"
                      onClick={onOpen}
                    >
                      <PlayVideoIcon />
                    </VStack>
                    <Image
                      borderRadius="0.5rem"
                      objectFit="cover"
                      src={item?.thumbnail_url || TrainingImg}
                      alt="training image"
                      w="400px"
                      h="180px"
                    />
                  </Box>
                  <Text textStyle="h4" fontWeight="700">
                    <TooltipIfOverflow char={60}>
                      {item?.title}
                    </TooltipIfOverflow>
                  </Text>
                </VStack>
              ))}
            </HStack>
          </InfiniteScroll>
        </div>
      </VStack>
      {isOpen && (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          contentMaxW="50vw"
          hideCloseButton={true}
        >
          {clickedRow?.vimeo_url && (
            <AspectRatio ratio={1.5}>
              <iframe
                title={clickedRow?.title}
                src={getEmbeddedUrl(clickedRow?.vimeo_url)}
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </AspectRatio>
          )}
        </Modal>
      )}

      {isLoading && <Spinner />}
    </VStack>
  );
}
