import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";

// Icons
import {
  FilledPhoneIcon,
  GeneralIcon,
  GreetIcon,
  RoutingIcon,
} from "../../../../components/icons/svg";

// Components
import CNAM from "../../../../components/LineSettings/CNAM/CNAM";
import General from "../../../../components/LineSettings/General/General";
import GreetAndRecord from "../../../../components/LineSettings/GreetAndRecord/GreetAndRecord";
import RoutingAndIVR from "../../../../components/LineSettings/RoutingAndIVR/RoutingAndIVR";
import { useQuery } from "@tanstack/react-query";
import { getLineSettings } from "../../../../utils/api/line-rings.api";
import Loader from "../../../../components/common/Spinner";

// Tab configuration
const tabComponents = [General, GreetAndRecord, RoutingAndIVR, CNAM];
const tabIcons = [GeneralIcon, GreetIcon, RoutingIcon, FilledPhoneIcon];
const tabTitles = ["General", "Greet & Record", "Routing", "CNAM"];

export default function LineSettingsGroup() {
  const navigate = useNavigate();
  const { dealerId, id } = useParams();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setActiveTabIndex(index);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["generalsettings", id, "general_settings"],
    queryFn: getLineSettings,
    refetchOnWindowFocus: false,
    retry: false,
  });

  console.log("data", data);

  return (
    <Box>
      {isLoading && <Loader />}
      <HStack
        background="white"
        padding="0.8rem 1.5rem"
        onClick={() => {
          navigate(`/dealerships/${dealerId}?tab=${3}`);
        }}
      >
        <Button
          variant="none"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="0.38rem"
          display="flex"
        >
          <KeyboardBackspaceIcon
            style={{
              color: "var(--grey-900)",
            }}
          />
        </Button>
        <Text textStyle="h4" fontWeight="700">
          Line Settings
        </Text>
      </HStack>
      <Box padding="1.5rem">
        <Tabs variant="primary" onChange={handleTabsChange}>
          <TabList>
            {tabTitles.map((title, index) => (
              <Tab
                key={index}
                background="white"
                padding="1rem"
                isDisabled={
                  title === "CNAM" &&
                  data?.data?.settings_hash?.type_of_line !== "local"
                }
              >
                <HStack>
                  {React.createElement(tabIcons[index], {
                    color: "var(--grey-900)",
                  })}
                  <Text textStyle="h6" fontWeight="700">
                    {title}
                  </Text>
                </HStack>
              </Tab>
            ))}
          </TabList>

          <TabPanels>
            {tabComponents.map((Component, index) => (
              <TabPanel key={index}>
                {activeTabIndex === index && <Component />}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
