import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { convertDate } from "../../utils";
import { getWebchat } from "../../utils/api/social-talk.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Input, PaginatedTable } from "../common";
import { SearchIcon } from "../icons/svg";

export default function Webchat() {
  const dealerId = useUserInfo("dealership");
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const { data, isFetching } = useQuery({
    queryKey: [
      "webchat",
      dealerId?.id,
      page,
      perPage,
      searchValue !== "" ? `&query=${searchValue}` : "",
    ],
    queryFn: getWebchat,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.data?.contacts_count);
    },
  });

  const tableHeader = [
    { id: 4, value: "Name", label: "name" },
    { id: 5, value: "Email", label: "email" },
    { id: 6, value: "Phone", label: "phone" },
  ];
  const createDateTimeField = (dateArray: string[]) => {
    return (
      <HStack gap="1rem">
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[0] ?? "-"}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[1] ?? null}
          </Text>
        </VStack>
      </HStack>
    );
  };

  const tableData = data?.data?.contacts?.map((item: any) => ({
    id: item.id,
    date: createDateTimeField(convertDate(item.date)),
    name: item.first_name + " " + item.last_name,
    phone: item?.phone_number,
    email: item?.email,
    type: (
      <Box
        as="span"
        py="0.25rem"
        px="0.62rem"
        color="var(--secondary-600)"
        fontSize="0.875rem"
        fontWeight="400"
        lineHeight="1.25rem"
        border="1px solid var(--secondary-600)"
        borderRadius="2.5rem"
      >
        {item.category || ""}
      </Box>
    ),
    // actions: <Dropdown options={options} onSelect={handleSelect} />,
  }));

  return (
    <>
      <Box
        background="white"
        padding="1.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <HStack>
          <Input
            w="350px"
            maxW="350px"
            type="text"
            hasIcon={true}
            isIconRight={false}
            icon={<SearchIcon />}
            placeholder="Search..."
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
        </HStack>
      </Box>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          padding="1rem"
        >
          <Text textStyle="caption" color="var(--grey-600)">
            Total Contacts ({count})
          </Text>
        </Box>
        <PaginatedTable
          isLoadingData={isFetching}
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          tableHeight={560}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
        />
      </Box>
    </>
  );
}
