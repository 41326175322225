import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import BDCCampaignPage from "../../(dashboard-routes)/bdc-campaign/page";
import DispositionSettings from "../../../components/AccountSettings/DispositionSettings/DispositionSettings";
import SaraAI from "../../../components/AccountSettings/SaraAI/SaraAI";
import TranscriptionSettingsForGlobalConfig from "../../../components/AccountSettings/TranscriptionSettings/TranscriptionSettingsForGlobalConfig";
import GlobalEmailTemplates from "../../../components/GlobalDashboard/EmailTemplates";
import InventoryFeedProvider from "../../../components/GlobalDashboard/InventoryFeedProvider/InventoryFeedProvider";
import Keyword from "../../../components/GlobalDashboard/Keyword/Keyword";
import TrainingVideos from "../../../components/GlobalDashboard/TrainingVideo/TrainingVideo";
import Variable from "../../../components/GlobalDashboard/Variable/Variable";
import SMSTemplateGlobalConfig from "../../../components/Reports/SMSTemplate/SMSTemplateGlobalConfig";
import TooltipIfOverflow from "../../../components/common/TooltipIfOverflow";
import { globalBDCApis } from "../../../utils/api/bdc-campaign.api";

export default function GlobalConfigurationsPage() {
  const [activeTab, setActiveTab] = useState(0);
  const [searchParams]= useSearchParams()
  const activeIndex= searchParams.get('active_tab')
  const navigate = useNavigate();

  useEffect(()=>{
    setActiveTab(Number(activeIndex || 0))
  }, [activeIndex])

  const handleTabChange = (index: any) => {
    setActiveTab(index);
    navigate(`${window.location.pathname}?active_tab=${index}`);
  };

  return (
    <>
      <VStack padding="1.5rem" w="100%" alignItems="flex-start">
        <Tabs
          variant="table"
          w="100%"
          background="white"
          borderRadius="0.5rem"
          defaultIndex={0}
          index={activeTab}
          onChange={handleTabChange}
        >
          <TabList
            borderTopRightRadius="0.5rem"
            display="flex"
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {[
              "Opt-Out Keywords",
              "Inventory Provider",
              "Variables",
              "Scorecard Transcription",
              "SMS Templates",
              "Email Templates",
              "Training videos",
              "Disposition Settings",
              "BDC Campaign",
              "Sara.ai Integration",
            ].map((label, index) => (
              <Tab
                key={index}
                flexGrow="1"
                flexBasis="auto"
                minW="150px"
                maxW="15%"
                border="1px solid #e0e0e0"
                whiteSpace="nowrap"
              >
                <TooltipIfOverflow>{label}</TooltipIfOverflow>
              </Tab>
            ))}
          </TabList>

          <TabPanels w="100%" h="100%">
            <TabPanel w="100%" h="100%">
              {activeTab === 0 && <Keyword />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 1 && <InventoryFeedProvider />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 2 && <Variable />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 3 && <TranscriptionSettingsForGlobalConfig />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 4 && <SMSTemplateGlobalConfig />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 5 && <GlobalEmailTemplates />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 6 && <TrainingVideos />}
            </TabPanel>

            <TabPanel w="100%" h="100%">
              {activeTab === 7 && (
                <DispositionSettings
                  mappings={["Global Configurations", "Disposition Settings"]}
                />
              )}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 8 && <BDCCampaignPage apiSet={globalBDCApis} />}
            </TabPanel>
            <TabPanel w="100%" h="100%">
              {activeTab === 9 && <SaraAI />}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
