import { Button, HStack, Tooltip, useToast, VStack } from "@chakra-ui/react";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import GifBoxIcon from "@mui/icons-material/GifBox";
import { useMutation } from "@tanstack/react-query";
import Picker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { APPOINTMENT_TEXT, CREDIT_TEXT, VIDEO_UPLOAD_TEXT } from "../../config";
import { convertType } from "../../utils";
import { uploadFile } from "../../utils/api/announcements";
import { attachFile, getTinyUrls } from "../../utils/api/lead.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Input } from "../common";
import Loader from "../common/Spinner";
import { InsertVariableDrawer } from "../Drawers/InsertVariableDrawer";
import {
  AppointmentIcon,
  AttachIcon,
  CreditAppIcon,
  ImageIcon,
  InventoryIcon,
  TemplateIcon,
  VariableIcon,
  VideoIcon,
} from "../icons/svg";
import ImageModal from "./ImageModal";
import InventoryModal from "./InventoryModal";
import TemplateModal from "./TemplateModal";

type AttachmentBarProps = {
  setBody?: (
    text: string,
    concatText?: string,
    type?: string,
    isVariable?: boolean
  ) => void;
  handleTemplate: (value: string, body?: string, attachmentUrls?: any) => void;
  type?: string;
  contactId?: string;
  gap?: string;
  parentMenu?: "global" | null;
  width?: string;
  flexWrap?: string;
};

function AttachmentBar({
  setBody,
  type = "sms",
  contactId = "[contact_id]",
  gap = "1rem",
  handleTemplate,
  parentMenu = null,
  width = "100%",
  flexWrap = "nowrap",
}: AttachmentBarProps) {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const [isInventoryOpen, setIsInventoryOpen] = useState(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [openInsertVariable, setOpenInsertVariable] = useState(false);
  const mutationAnnouncement = useMutation(uploadFile);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [pickerType, setPickerType] = useState("");
  const pickerRef = useRef<any>(null);
  const user = useUserInfo("user");
  const { dealership } = user || {};
  const { dealer_id } = useParams(); // dealership settings global portal

  const [searchParams] = useSearchParams();
  const dealershipId = searchParams.get("dealership_id");
  const dealershipUUID = convertType(searchParams.get("uuid") || "");
  const transformedDealershipUUID = dealership.uuid || dealershipUUID;

  const dealershipPlaceHolder = transformedDealershipUUID || "[dealership_id]";

  const toast = useToast();
  const handleImage = (text: string) => {
    setIsImageOpen(false);
    setBody &&
      setBody(
        text,
        undefined,
        type === "email" ? "inline-attachment" : "attachment"
      );
  };

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await getTinyUrls({
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
  });

  const handleInventory = async (text: string) => {
    const webLink = `http://${window.location.host}/guest_mode/dealership/${dealershipPlaceHolder}/contact/${contactId}/browse_inventory?inventory_id=${text}`;
    const res = await mutation.mutateAsync({ original_url: webLink });
    const tinyURL = res.data.tiny_url || webLink;
    setIsInventoryOpen(false);
    setBody && setBody(tinyURL, "{{FirstName}} Please view the vehicle");
  };

  const attachmentMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await attachFile({ payload });
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  });

  const handleVideoChat = async (e: any) => {
    const file = e.target.files[0];
    if (file && file.size <= 62914560) {
      const formData = new FormData();
      formData.append("file", file);
      // formData.append("dealership_id", dealership.id);
      const res = await attachmentMutation.mutateAsync(formData);
      const webLink = `http://${
        window.location.host
      }/video/${encodeURIComponent(res.url || "")}`;
      const res1 = await mutation.mutateAsync({ original_url: webLink });
      const tinyURL = res1.data.tiny_url || webLink;
      setBody && setBody(tinyURL, VIDEO_UPLOAD_TEXT);
    } else {
      toast({
        description: "File size exceeds the limit of 60MB.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
    e.target.value = "";
  };

  const handleUploadDocuments = async (e: any) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file.size / 1024 / 1024 > 20) {
        return toast({
          description: "Only files less than 20mb are acceptable.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }

      const formData = new FormData();
      formData.append("file", file);
      e.target.value = "";

      if (parentMenu === "global") {
        mutationAnnouncement.mutate(
          {
            payload: formData,
          },
          {
            onSuccess: (data) => {
              setBody && setBody(data?.data, undefined, "attachment");
            },
            onError: (error: any) => {
              toast({
                description:
                  error?.response?.data?.errors?.toString() ??
                  "Something went wrong",
                status: "error",
                duration: 5000,
                isClosable: true,
                position: "top",
              });
            },
          }
        );
      } else {
        formData.append(
          "dealership_id",
          user.dealership.id || dealershipId || dealer_id
        );
        try {
          const res = await attachmentMutation.mutateAsync(formData);
          setBody && setBody(res, undefined, "attachment");
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleAppointment = async () => {
    const webLink = `http://${window.location.host}/guest_mode/dealership/${dealershipPlaceHolder}/contact/${contactId}/schedule_a_visit`;
    const res = await mutation.mutateAsync({ original_url: webLink });
    const tinyURL = res.data.tiny_url || webLink;
    setBody && setBody(tinyURL, APPOINTMENT_TEXT);
  };

  const handleCreditApp = async () => {
    const webLink = `http://${window.location.host}/guest_mode/dealership/${dealershipPlaceHolder}/contact/${contactId}/apply_for_credit`;
    const res = await mutation.mutateAsync({ original_url: webLink });
    const tinyURL = res.data.tiny_url || webLink;
    setBody && setBody(tinyURL, CREDIT_TEXT);
  };
  const toggleEmojiPicker = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiSelect = (emoji: any) => {
    console.log("Selected emoji:", emoji);
    setBody && setBody(emoji?.emoji, "", type === "email" ? "emoji" : "text");
  };
  const handleClickOutside = (event: any) => {
    if (pickerRef?.current && !pickerRef?.current?.contains(event.target)) {
      setShowEmojiPicker(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleVariableSelect = (variable: any) => {
    setBody && setBody(` {{${variable?.name}}}`, undefined, "text", true);
  };

  const TooltipComponent = (props: any) => (
    <Tooltip
      hasArrow
      label={props?.label}
      borderRadius="0.5rem"
      placement="top"
      background="var(--grey-900)"
      padding="0.5rem"
      fontSize="0.75rem"
      fontWeight="600"
      color="white"
    >
      {props.children}
    </Tooltip>
  );

  return (
    <>
      {(attachmentMutation.isLoading || mutation.isLoading) && <Loader />}
      <HStack
        w="100%"
        padding="1rem"
        background="var(--secondary-50)"
        gap={gap}
        flexWrap={flexWrap === "wrap" ? "wrap" : "nowrap"}
      >
        <TooltipComponent label="GIF Picker">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            // h="2.8rem"
            minW="unset"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={() => {
              setIsImageOpen(true);
              setPickerType("gif");
            }}
          >
            <GifBoxIcon />
          </Button>
        </TooltipComponent>

        <TooltipComponent label="Image Picker">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            // h="2.8rem"
            minW="unset"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={() => {
              setIsImageOpen(true);
              setPickerType("image");
            }}
          >
            <ImageIcon color="var(--grey-800)" />
          </Button>
        </TooltipComponent>

        <TooltipComponent label="Emoji Picker">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            h="2.8rem"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={toggleEmojiPicker}
          >
            <EmojiEmotionsIcon />
          </Button>
        </TooltipComponent>
        {parentMenu !== "global" && (
          <TooltipComponent label="Video Picker">
            <Button
              variant="outline"
              w={width}
              padding="0.75rem"
              h="2.8rem"
              sx={{
                _hover: {
                  background: "var(--secondary-600)",
                  svg: {
                    color: "white",
                    path: {
                      fill: "white",
                    },
                  },
                },
              }}
              onClick={() =>
                document.getElementById(`${type}videoOpen`)?.click()
              }
            >
              <VideoIcon color="var(--grey-800)" />
            </Button>
          </TooltipComponent>
        )}
        <TooltipComponent label="Insert Variable">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            h="2.8rem"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={() => setOpenInsertVariable(true)}
          >
            <VariableIcon color="var(--grey-800)" />
          </Button>
        </TooltipComponent>
        {parentMenu !== "global" && (
          <>
            <TooltipComponent label="Insert Credit App Link">
              <Button
                variant="outline"
                w={width}
                padding="0.75rem"
                h="2.8rem"
                sx={{
                  _hover: {
                    background: "var(--secondary-600)",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                onClick={handleCreditApp}
              >
                <CreditAppIcon color="var(--grey-800)" />
              </Button>
            </TooltipComponent>
            <TooltipComponent label="Insert Appointment">
              <Button
                variant="outline"
                w={width}
                padding="0.75rem"
                h="2.8rem"
                sx={{
                  _hover: {
                    background: "var(--secondary-600)",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                onClick={handleAppointment}
              >
                <AppointmentIcon color="var(--grey-800)" />
              </Button>
            </TooltipComponent>
            <TooltipComponent label="Insert Inventory">
              <Button
                variant="outline"
                w={width}
                padding="0.75rem"
                h="2.8rem"
                sx={{
                  _hover: {
                    background: "var(--secondary-600)",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                onClick={() => setIsInventoryOpen(true)}
              >
                <InventoryIcon color="var(--grey-800)" />
              </Button>
            </TooltipComponent>
          </>
        )}
        <TooltipComponent label="Insert Template">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            h="2.8rem"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={() => setIsTemplateOpen(true)}
          >
            <TemplateIcon color="var(--grey-800)" />
          </Button>
        </TooltipComponent>
        <TooltipComponent label="Attach File">
          <Button
            variant="outline"
            w={width}
            padding="0.75rem"
            h="2.8rem"
            sx={{
              _hover: {
                background: "var(--secondary-600)",
                svg: {
                  color: "white",
                  path: {
                    fill: "white",
                  },
                },
              },
            }}
            onClick={() =>
              document.getElementById(`${type}broadCastAttachmentBar`)?.click()
            }
          >
            <AttachIcon color="var(--grey-800)" />
          </Button>
        </TooltipComponent>
      </HStack>
      <Input
        maxW="20rem"
        type="file"
        hidden
        accept="video/mp4,video/x-m4v,video/*"
        id={`${type}videoOpen`}
        onChange={handleVideoChat}
      />
      <Input
        maxW="20rem"
        type="file"
        hidden
        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
        id={`${type}broadCastAttachmentBar`}
        onChange={handleUploadDocuments}
      />
      {isImageOpen && (
        <ImageModal
          isOpen={isImageOpen}
          onClose={() => setIsImageOpen(false)}
          handleImage={handleImage}
          parentMenu={parentMenu}
          type={pickerType}
        />
      )}
      {isInventoryOpen && (
        <InventoryModal
          isOpen={isInventoryOpen}
          onClose={() => setIsInventoryOpen(false)}
          handleInventory={handleInventory}
        />
      )}
      {showEmojiPicker && (
        <VStack pos="absolute" bottom="8%" zIndex="9" left="19%">
          <div ref={pickerRef}>
            <Picker
              onEmojiClick={handleEmojiSelect}
              // lazyLoadEmojis={true}
              // emojiStyle={EmojiStyle.NATIVE}
            />
          </div>
        </VStack>
      )}
      {isTemplateOpen && (
        <TemplateModal
          isOpen={isTemplateOpen}
          type={type}
          onClose={() => setIsTemplateOpen(false)}
          handleTemplate={handleTemplate}
          parentMenu={parentMenu}
        />
      )}
      {openInsertVariable && (
        <InsertVariableDrawer
          isOpen={openInsertVariable}
          isOnRight={true}
          onClose={() => setOpenInsertVariable(false)}
          onVariableSelect={handleVariableSelect}
        />
      )}
    </>
  );
}

export default AttachmentBar;
