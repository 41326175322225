import React, { Dispatch, SetStateAction } from "react";
import Activity from "../Activity/Activity";
import moment from "moment";
import { HStack, Text, VStack } from "@chakra-ui/react";
import VoicemailIcon from "@mui/icons-material/Voicemail";

type SingleRVMCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleRVMCard({ item, setClickedRecord }: SingleRVMCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => setClickedRecord(item)}
      title={"RVM"}
      dateAndTime={moment(item.details.start_time).format(
        "dddd MMM DD, YY • hh:mm a"
      )}
      icon={<VoicemailIcon />}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created At:
              </Text>
              <Text color="var(--primary-800)" textStyle="h6" fontWeight="500">
                {moment(item?.created_at).format("dddd MMM DD, YY • hh:mm a")}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Campaign Name:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.campaign_name}
              </Text>
            </HStack>
          </VStack>
        </>
      }
    />
  );
}

export default SingleRVMCard;
