import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash"; // To perform deep comparison of objects
import { useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useParams } from "react-router-dom";
import { days } from "../../config/constants/days";
import { validateAudioFileSize, validateAudioFileType } from "../../utils";
import { getDealershipHours } from "../../utils/api/bdc-campaign.api";
import {
  addVoicemail,
  fetchSettingsIO,
  fetchUserSettingsDP,
  updateUserSettingsDP,
} from "../../utils/api/user-settings-dp.api";
import { useTwilioDispatch } from "../../utils/context/TwilioContext";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { CustomRadio, PhoneInput, Select, Spinner } from "../common";
import SimpleToggle from "../common/SimpleToggle";
import AudioSettingsBox from "./AudioSettingsBox";

interface WorkingHours {
  [key: string]: {
    end_time: string;
    day_status: boolean;
    start_time: string;
  };
}
interface ApiRequest {
  key: string;
  settings_hash: {
    notify_caller: boolean;
    follow_up_agent: boolean;
    working_hours: WorkingHours;
    personal_voicemail: {
      do_nothing: boolean;
      send_voicemail: boolean;
      voicemail_tab: {
        id: any;
        content: string;
      };
    };
    answer_type: {
      browser: boolean;
      mobile: boolean;
      send_texts_to_custom_numbers: boolean;
      custom_number: string;
    };
    working_hours_updated?: boolean;
  };
}
interface ApiResult {
  data: any;
}
interface ApiRequestUpload {
  voicemail: {
    voicemail_type: string;
  };
  audio: Blob;
}
export const InboundSetting = (props: any) => {
  const { id: userIdFromParam } = useParams();
  const userID = useUserInfo("id");
  const dealerId = useUserInfo("dealership");

  const dispatch = useTwilioDispatch();

  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [selectedRadio, setSelectedRadio] = useState("");
  const [selectedTab, setSelectedTab] = useState("");
  const [settings, setSettings] = useState<ApiRequest["settings_hash"]>({
    notify_caller: false,
    follow_up_agent: false,
    working_hours: {
      friday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      monday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      sunday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      tuesday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      saturday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      thursday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
      wednesday: {
        end_time: "12:00",
        day_status: true,
        start_time: "09:00",
      },
    },
    personal_voicemail: {
      do_nothing: true,
      send_voicemail: false,
      voicemail_tab: {
        id: 0,
        content: "",
      },
    },
    answer_type: {
      browser: true,
      mobile: true,
      send_texts_to_custom_numbers: true,
      custom_number: "",
    },
  });
  const [audioData, setAudioData] = useState("");
  const [transcribed, setTranscribed] = useState(false);
  const [input, setInput] = useState("");
  const [errors, setErrors] = useState<any>({});

  const options = Array.from({ length: 24 }, (_, index) => {
    const value = index.toString().padStart(2, "0");
    return {
      label: value,
      value,
    };
  });
  const toast = useToast();

  const showToast = (description: any, status: any) => {
    toast({
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };
  //...............all flag...........
  const [all, setAll] = useState<boolean>(true);
  const [none, setNone] = useState<boolean>(false);

  const handleAllChange = () => {
    setNone(false);
    setSettings((prev: any) => ({
      ...prev,
      answer_type: {
        ...prev.anwer_type,
        browser: true,
        mobile: true,
        send_texts_to_custom_numbers: true,
      },
    }));
    setAll(!all);
  };
  const handleNoneChange = () => {
    setAll(false);
    setSettings((prev: any) => ({
      ...prev,
      answer_type: {
        custom_number: "",
        browser: false,
        mobile: false,
        send_texts_to_custom_numbers: false,
      },
    }));
    setPhone("");
    setPhoneError("");
    setNone(!none);
  };
  useEffect(() => {
    if (settings) {
      const areAllChecked =
        settings.answer_type.browser &&
        settings.answer_type.mobile &&
        settings.answer_type.send_texts_to_custom_numbers;

      const areAllUnchecked =
        settings.answer_type?.browser === false &&
        settings.answer_type?.mobile === false &&
        settings.answer_type?.send_texts_to_custom_numbers === false;

      setAll(areAllChecked);
      setNone(areAllUnchecked);
    }
  }, [settings]);
  //.............fetch calls............
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["inbound_call_settings", userIdFromParam, userID],
    queryFn: () =>
      fetchUserSettingsDP(
        dealerId?.id,
        userIdFromParam ?? userID,
        "inbound_call_settings"
      ),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setSettings(data?.settings_hash);
      setPhone(data?.settings_hash?.answer_type?.custom_number);
      setSelectedRadio(
        data?.settings_hash?.personal_voicemail?.do_nothing
          ? "nothing"
          : "voicemail"
      );
      setInput(
        data?.settings_hash?.personal_voicemail?.voicemail_tab?.id === 0
          ? data?.settings_hash?.personal_voicemail?.voicemail_tab?.content
          : ""
      );
      setSelectedTab(
        data?.settings_hash?.personal_voicemail?.voicemail_tab?.id
      );
    },
  });
  const { data: businessHours } = useQuery<any>({
    queryKey: ["dealershiphours", dealerId?.id],
    queryFn: getDealershipHours,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: dealerId?.id !== undefined,
  });
  const { data: voicemails, isLoading: isLoadingVoicemail } = useQuery({
    queryKey: ["inbound", dealerId?.id, userIdFromParam ?? userID],
    queryFn: fetchSettingsIO,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: any) {
      setAudioData(data?.voicemails[0]?.recording_url);
      setTranscribed(data?.voicemails[0]?.recording_url ? true : false);
    },
  });
  //...............file upload...................
  const handleMutationSuccess = () => {
    refetch();
    dispatch({
      type: "SET_SHOULD_REFETCH_IDENTITIES",
      payload: { shouldRefetchIdentities: true },
    });
    showToast("Updated successfully.", "success");
  };

  // Function to handle mutation error
  const handleMutationError = (error: any) => {
    refetch();
    showToast(
      `Error: ${
        error?.response?.data?.errors?.toString() ?? "Something went wrong"
      }`,
      "error"
    );
  };
  const uploadMutation = useMutation<
    ApiResult,
    Error,
    ApiRequestUpload,
    unknown
  >({
    mutationFn: async (payload) => {
      try {
        const response = await addVoicemail(
          ["inbound", dealerId?.id, userIdFromParam ?? userID],
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {},
    onError: (error: any) => {
      handleMutationError(error);
    },
  });

  const uploadHandleSubmit = async () => {
    const response = await fetch(audioData);
    const blob = await response.blob();

    if (audioData === voicemails?.voicemails[0]?.recording_url) {
      return;
    }

    if (!validateAudioFileType(blob)) {
      showToast(
        "Invalid file type. Please upload a valid audio file.",
        "error"
      );
      return;
    }

    if (!validateAudioFileSize(blob)) {
      showToast("File size exceeds 8 MB limit.", "error");
      return;
    }

    const requestData: ApiRequestUpload = {
      voicemail: {
        voicemail_type: "inbound",
      },
      audio: blob,
    };

    uploadMutation.mutate(requestData);
  };
  //.................save button........

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateUserSettingsDP(
          payload,
          dealerId?.id,
          userIdFromParam ?? userID
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      handleMutationSuccess();
    },
    onError: (error: any) => {
      handleMutationError(error);
    },
  });
  const validatePhoneNumber = () => {
    if (!phone) {
      setPhoneError("Phone number is required");
      return false;
    }

    if (!isValidPhoneNumber(phone)) {
      setPhoneError("Please enter a valid phone number");
      return false;
    }

    return true;
  };

  const validateVoicemail = (text?: string) => {
    if (!transcribed || !audioData) {
      showToast(text || "Please add a voicemail", "error");
      return false;
    }
    return true;
  };

  const validateTextToSpeech = () => {
    if (!input || input === "") {
      showToast("Please enter text to speech input", "error");
      return false;
    }
    return true;
  };
  const isTimeValid = (day: any, startTime: any, endTime: any) => {
    const [startHour, startMinute] = startTime?.split(":")?.map(Number);
    const [endHour, endMinute] = endTime?.split(":")?.map(Number);

    let businessStart, businessEnd;

    if (day === "saturday" || day === "sunday") {
      if (!businessHours?.data?.business_hours?.weekend_timings) {
        return false; // Weekend timings are disabled
      }
      businessStart = businessHours?.data?.business_hours?.weekend_start_time
        .split(":")
        .map(Number);
      businessEnd = businessHours?.data?.business_hours?.weekend_end_time
        .split(":")
        .map(Number);
    } else {
      businessStart = businessHours?.data?.business_hours?.week_start_time
        .split(":")
        .map(Number);
      businessEnd = businessHours?.data?.business_hours?.week_end_time
        .split(":")
        .map(Number);
    }

    // Check if start time is less than business end time and end time is greater than business start time
    return (
      (startHour > businessStart[0] ||
        (startHour === businessStart[0] && startMinute >= businessStart[1])) &&
      (endHour < businessEnd[0] ||
        (endHour === businessEnd[0] && endMinute <= businessEnd[1]))
    );
  };
  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (settings?.answer_type?.send_texts_to_custom_numbers) {
      if (!validatePhoneNumber()) {
        return;
      }
    }
    if (settings?.personal_voicemail?.send_voicemail === true) {
      if (settings?.personal_voicemail?.voicemail_tab?.id === 0) {
        if (
          !validateTextToSpeech() ||
          !validateVoicemail("Please transcribe the text")
        ) {
          return;
        }
      } else if (settings?.personal_voicemail?.voicemail_tab?.id === 1) {
        if (!validateVoicemail()) {
          return;
        }
        setInput("");
      }
      uploadHandleSubmit();
    }

    let requestData: ApiRequest = {
      key: "inbound_call_settings",
      settings_hash: settings,
    };
    if (
      !_.isEqual(data?.settings_hash?.working_hours, settings?.working_hours)
    ) {
      requestData = {
        ...requestData,
        settings_hash: {
          ...settings,
          working_hours_updated: true,
        },
      };
    }
    let isValid = true;
    const newErrors: { [key: string]: string } = {};

    // Validate each day's working hours

    days.forEach((item) => {
      const startTime = settings?.working_hours[item.value]?.start_time;
      const endTime = settings?.working_hours[item.value]?.end_time;
      const dayStatus = settings?.working_hours[item.value]?.day_status; // Assuming `day_status` is a boolean

      // Handle weekend timings
      if (item.value === "saturday" || item.value === "sunday") {
        // Check if weekend timings should be considered
        if (businessHours?.weekend_timings) {
          if (!isTimeValid(item.value, startTime, endTime)) {
            isValid = false;
            newErrors[item.value] = `Invalid weekend hours for ${item.label}`;
          }
        } else {
          // If weekend timings are not allowed, ensure the weekend is marked as closed
          if (dayStatus !== false) {
            // Check if it's not "Closed", assuming dayStatus is a boolean
            isValid = false;
            newErrors[
              item.value
            ] = `${item.label} should be marked as closed since weekend timings are disabled`;
          }
        }
      } else {
        // Handle regular weekdays (Monday - Friday)
        if (!isTimeValid(item.value, startTime, endTime)) {
          isValid = false;
          newErrors[item.value] = `Invalid hours for ${item.label}`;
        }
      }
    });
    // Set errors in state
    setErrors(newErrors);

    if (isValid) {
      // Proceed with save
      mutation.mutate(requestData);
    }
  };

  //..............change handlers
  const handleChangeCheckbox = (key: string) => {
    setSettings((prev: any) => {
      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };
  const handleChangeRadio = (value: string) => {
    setSettings((prev) => ({
      ...prev,
      personal_voicemail: {
        ...prev.personal_voicemail,
        do_nothing: value === "nothing",
        send_voicemail: value === "voicemail",
      },
    }));
    setSelectedRadio(value);
    if (value === "nothing") {
      setInput("");
      setTranscribed(false);
      setAudioData("");
    }
  };
  const handleChangeToggle = (day: string, value: string) => {
    setSettings((prev) => ({
      ...prev,
      working_hours: {
        ...prev.working_hours,
        [day]: {
          ...prev.working_hours[day],
          day_status: value === "Open",
        },
      },
    }));
  };
  const handleStartTimeChange = (day: string, value: string) => {
    setSettings((prev) => ({
      ...prev,
      working_hours: {
        ...prev.working_hours,
        [day]: {
          ...prev.working_hours[day],
          start_time: `${value}:00`,
        },
      },
    }));
  };
  const handleEndTimeChange = (day: string, value: string) => {
    setSettings((prev) => ({
      ...prev,
      working_hours: {
        ...prev.working_hours,
        [day]: {
          ...prev.working_hours[day],
          end_time: `${value}:00`,
        },
      },
    }));
  };
  const handleChange = (key: string) => {
    setSettings((prev: any) => {
      if (key === "send_texts_to_custom_numbers") {
        setPhoneError("");
        setPhone("");
        return {
          ...prev,
          answer_type: {
            ...prev.answer_type,
            [key]: !prev.answer_type[key],
            custom_number: "",
          },
        };
      } else {
        return {
          ...prev,
          answer_type: {
            ...prev.answer_type,
            [key]: !prev.answer_type[key],
          },
        };
      }
    });
  };

  //.............use effects
  useEffect(() => {
    setSettings((prev) => ({
      ...prev,
      personal_voicemail: {
        ...prev.personal_voicemail,
        voicemail_tab: {
          content: input,
          id: selectedTab,
        },
      },
    }));
  }, [selectedTab, input]);

  return (
    <VStack w="100%" alignItems="flex-start" position="relative">
      <HStack
        gap="1rem"
        alignItems="flex-start"
        height="calc(100vh - 240px)"
        overflow="auto"
        pb="75px"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <VStack gap="1rem" w="50%">
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            gap="1rem"
          >
            <Text textStyle="h4" fontWeight="500">
              Personal Voicemail
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Should SimpSocial LLC take a voicemail when this user is not
              available and their extension is dialed?
            </Text>
            <CustomRadio
              options={[
                { label: "Do Nothing", value: "nothing" },
                { label: "Voicemail", value: "voicemail" },
              ]}
              label="When a direct call is missed:"
              setValue={handleChangeRadio}
              value={selectedRadio}
            />
            {selectedRadio === "voicemail" && (
              <AudioSettingsBox
                defaultIndex={settings?.personal_voicemail?.voicemail_tab?.id}
                input={input}
                setInput={setInput}
                setSelectedTab={setSelectedTab}
                setTranscribed={setTranscribed}
                transcribed={transcribed}
                setAudioData={setAudioData}
                audioData={audioData}
              />
            )}
          </VStack>
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            gap="1rem"
          >
            <Text textStyle="h4" fontWeight="500">
              Answer Type
            </Text>
            <HStack w="100%" gap="50%">
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={handleAllChange}
                isChecked={all}
              >
                All
              </Checkbox>
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={handleNoneChange}
                isChecked={none}
              >
                None
              </Checkbox>
            </HStack>

            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() => handleChange("browser")}
              isChecked={settings?.answer_type?.browser}
            >
              Answer on browser
            </Checkbox>
            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() => handleChange("mobile")}
              isChecked={settings?.answer_type?.mobile}
            >
              Answer on mobile app
            </Checkbox>
            <HStack justifyContent="space-between" w="100%">
              <Checkbox
                variant="whiteCheck"
                size="lg"
                onChange={() => handleChange("send_texts_to_custom_numbers")}
                isChecked={settings?.answer_type?.send_texts_to_custom_numbers}
              >
                Answer via direct line
              </Checkbox>
              <HStack w="42%">
                <PhoneInput
                  onChange={(value) => {
                    setPhone(value);
                    setSettings((prev: any) => {
                      setPhoneError("");
                      return {
                        ...prev,
                        answer_type: {
                          ...prev.answer_type,
                          custom_number: value,
                        },
                      };
                    });
                  }}
                  isSmall={true}
                  isRequired={false}
                  disabled={
                    !settings?.answer_type?.send_texts_to_custom_numbers
                  }
                  value={phone}
                  error={phoneError}
                />
              </HStack>
            </HStack>
          </VStack>
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            gap="1rem"
          >
            <Text textStyle="h4" fontWeight="500">
              Follow Up
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              Send a message to the attempted agent who missed a call. The lead
              information (phone number) will be automatically appended.
            </Text>
            <Checkbox
              variant="whiteCheck"
              size="lg"
              onChange={() => handleChangeCheckbox("follow_up_agent")}
              isChecked={settings?.follow_up_agent}
            >
              If the call is missed, send a text message to this agent.
            </Checkbox>
          </VStack>
        </VStack>
        <VStack gap="1rem" w="50%">
          <VStack
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
            padding="1rem"
            w="100%"
            alignItems="flex-start"
            gap="1rem"
          >
            <Text textStyle="h4" fontWeight="500">
              Working Hours
            </Text>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              This setting sets the working hours for this user. If a call comes
              in outside of these hours it won't ring this user. Note that the
              times are local to your Dealership Timezone
            </Text>
            <VStack gap="0" w="100%">
              {days.map((item: any) => {
                const startTime =
                  settings?.working_hours[item.value]?.start_time?.split(
                    ":"
                  )[0] || "01";
                const endTime =
                  settings?.working_hours[item.value]?.end_time?.split(
                    ":"
                  )[0] || "01";

                const isError: any = errors[item.value];

                return (
                  <FormControl key={item.value} isInvalid={!!isError}>
                    <HStack w="100%" borderTop="1px solid var(--grey-300)">
                      <Text
                        textStyle="h6"
                        fontWeight="500"
                        w="26%"
                        padding="1.25rem"
                        borderRight="1px solid var(--grey-300)"
                        paddingLeft="0"
                      >
                        {item.label}
                      </Text>
                      <HStack w="60%">
                        <Select
                          label=""
                          onChange={(value: any) =>
                            handleStartTimeChange(item.value, value?.value)
                          }
                          options={options}
                          variant="default"
                          w="100%"
                          maxW="10rem"
                          value={startTime}
                        />
                        <Select
                          label=""
                          onChange={(value: any) =>
                            handleEndTimeChange(item.value, value?.value)
                          }
                          options={options}
                          variant="default"
                          w="100%"
                          maxW="10rem"
                          value={endTime}
                        />
                      </HStack>
                      <SimpleToggle
                        value1="Open"
                        value2="Close"
                        onChange={(value) =>
                          handleChangeToggle(item.value, value)
                        }
                        selectedValue={
                          settings?.working_hours[item.value]?.day_status
                            ? "Open"
                            : "Close"
                        }
                      />
                    </HStack>

                    {/* Display error message */}
                    {isError && <FormErrorMessage>{isError}</FormErrorMessage>}
                  </FormControl>
                );
              })}
            </VStack>
          </VStack>
        </VStack>
      </HStack>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        background="white"
        position="absolute"
        borderTop="1px solid var(--grey-300)"
        opacity={props.allowed ? 1 : 0.5} // Set opacity based on props.allowed
        pointerEvents={props.allowed ? "auto" : "none"} // Enable/disable pointer events based on props.allowed
      >
        <Button
          width="8rem"
          onClick={handleSubmit}
          // isDisabled={!props?.allowed}
        >
          Save
        </Button>
      </HStack>

      {(isLoading || isLoadingVoicemail || mutation.isLoading) && <Spinner />}
    </VStack>
  );
};
