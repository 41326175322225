import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchDealerships = async (params: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/dealerships_with_domain`, {
    params,
  });

export const fetchUsers = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/dealership_users_for_announcements`, {
    params: queryKey[1],
  });
export const fetchAnnouncement = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/email_announcements`, {
    params: queryKey[1],
  });
export const fetchAnnouncementById = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/email_announcements/${queryKey[1]}`);

export const fetchCommunicationLogs = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/email_announcements/${queryKey[1]}/communication_logs`,
    { params: queryKey[2] }
  );
export const createAnnouncement = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/email_announcements`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.payload?.subMenu,
      },
    }
  );

export const editAnnouncement = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/email_announcements/${payload.id}`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.payload?.subMenu,
      },
    }
  );

export const deleteAnnouncement = async (payload: any) =>
  axios.delete(`${SERVICES.apiBaseUrl}/api/email_announcements/${payload.id}`, {
    headers: {
      menu: "Announcements",
      "Sub-Menu": payload?.subMenu,
    },
  });

export const pauseAnnouncement = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/email_announcements/${payload.id}/pause_emails`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.subMenu,
      },
    }
  );

export const resumeAnnouncement = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/email_announcements/${payload.id}/resume_emails`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.subMenu,
      },
    }
  );

export const uploadFile = async (payload: any) =>
  axios.post(`${SERVICES.apiBaseUrl}/api/upload_file`, payload.payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const fetchSmsAnnouncement = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/sms_announcements`, {
    params: queryKey[1],
  });

export const fetchSmsCommunicationLogs = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/sms_announcements/${queryKey[1]}/logs`,
    { params: queryKey[2] }
  );

export const fetchSmsAnnouncementById = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/api/sms_announcements/${queryKey[1]}`);

export const createSmsAnnouncement = async (payload: any) =>
  axios.post(`${SERVICES.apiBaseUrl}/api/sms_announcements`, payload.payload, {
    headers: {
      menu: "Announcements",
      "Sub-Menu": payload?.payload?.subMenu,
    },
  });

export const editSmsAnnouncement = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/sms_announcements/${payload.id}`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.payload?.subMenu,
      },
    }
  );

export const deleteSmsAnnouncement = async (payload: any) =>
  axios.delete(`${SERVICES.apiBaseUrl}/api/sms_announcements/${payload.id}`, {
    headers: {
      menu: "Announcements",
      "Sub-Menu": payload?.subMenu,
    },
  });

export const pauseSmsAnnouncement = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/sms_announcements/${payload.id}/pause_campaign`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.subMenu,
      },
    }
  );

export const resumeSmsAnnouncement = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/sms_announcements/${payload.id}/resume_campaign`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.subMenu,
      },
    }
  );

export const createTemplate = async (payload: any) =>
  axios.post(`${SERVICES.apiBaseUrl}/global_template`, payload.payload, {
    headers: {
      menu: "Announcements",
      "Sub-Menu": payload?.payload?.subMenu,
    },
  });

export const createSmsTemplate = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/admin/sms_templates`,
    payload.payload,
    {
      headers: {
        menu: "Announcements",
        "Sub-Menu": payload?.payload?.subMenu,
      },
    }
  );
