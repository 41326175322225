import React, { Dispatch, SetStateAction } from "react";
import Activity from "../Activity/Activity";
import { humanize } from "../../../../../../utils";
import moment from "moment";
import { HStack, Text, VStack } from "@chakra-ui/react";
import { AppointmentIcon } from "../../../../../icons/svg";

type SingleAppointmentCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function SingleAppointmentCard({
  item,
  setClickedRecord,
}: SingleAppointmentCardProps) {
  return (
    <Activity
      key={item.id}
      id={item.id}
      // handleClick={() => setClickedRecord(item)}
      title={item?.details?.title}
      dateAndTime={moment(item.details.start_time).format(
        "dddd MMM DD YY • hh:mm a"
      )}
      icon={<AppointmentIcon color="var(--grey-800)" />}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Created At:
              </Text>
              <Text color="var(--primary-800)" textStyle="h6" fontWeight="500">
                {moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")}
              </Text>
            </HStack>
            {item?.details?.created_by?.name && (
              <HStack gap="0.25rem">
                <Text textStyle="h6" fontWeight="600">
                  Created By:
                </Text>
                <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                  {item?.details?.created_by?.name}
                </Text>
              </HStack>
            )}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Status:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {humanize(item?.details?.status)}
              </Text>
            </HStack>
          </VStack>
        </>
      }
    />
  );
}

export default SingleAppointmentCard;
