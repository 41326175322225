import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import { isValidPhoneNumber } from "react-phone-number-input";
import * as XLSX from "xlsx";
import { SearchAndFilterForm } from "../components/Drawers/SearchAndFilterDrawer/utils";
import {
  Dealership_Roles,
  Dealership_Super,
  Simpsocial_Roles,
  Simpsocial_Super,
} from "./roles";
import { NestedObject } from "./types";

declare module "jspdf" {
  interface jsPDF {
    autoTable: (options: any) => jsPDF;
  }
}

export const percentage = (x: number, y: number) => {
  return 100 / (y / x);
};
export const capitalize = (str: string | null | undefined) => {
  if (str && str?.length > 0) {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
  return "";
};
export const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
};
export const isValidEmail = (email: string) => {
  // Simple email validation using regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email.length <= 320 && emailRegex.test(email);
};

export const validatePassword = (password: string) => {
  const minLength = 8;
  const hasNumber = /\d/.test(password);
  const hasLetter = /[a-zA-Z]/.test(password);

  return password.length >= minLength && hasNumber && hasLetter;
};

export const roleChecker = (role: any) => {
  return Simpsocial_Roles.includes(role);
};
export const dealershipRoleChecker = (role: any) => {
  return Dealership_Roles.includes(role);
};
export const simpAdminChecker = (role: any) => {
  return Simpsocial_Super.includes(role);
};

export const dealershipAdminChecker = (role: any) => {
  return Dealership_Super.includes(role);
};
export const get24HourTimeArray = () => {
  const timeArray = [];
  for (let hour = 0; hour < 24; hour++) {
    const labelHour = hour % 12 === 0 ? 12 : hour % 12;
    const amPm = hour < 12 ? "AM" : "PM";
    const label = `${labelHour}:${"00"} ${amPm}`;
    const value = `${hour < 10 ? "0" + hour : hour}:${"00"}`;
    timeArray.push({ label, value });
  }
  return timeArray;
};
export const defaultOperatingHours = {
  monday: { end_time: null, day_status: false, start_time: null },
  tuesday: { end_time: null, day_status: false, start_time: null },
  wednesday: { end_time: null, day_status: false, start_time: null },
  thursday: { end_time: null, day_status: false, start_time: null },
  friday: { end_time: null, day_status: false, start_time: null },
  saturday: { end_time: null, day_status: false, start_time: null },
  sunday: { end_time: null, day_status: false, start_time: null },
};
export const isValidURL = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidVimeoURL = (url: string) => {
  return /^(https?:\/\/)?(www\.)?vimeo\.com\/(?:channels\/\w+\/)?(\d+)/.test(
    url
  );
};
export const isValidYouTubeURL = (url: string) => {
  return /^(https?:\/\/)?(www\.)?(youtube\.com\/(?:watch\?v=|shorts\/|playlist\?list=)|youtu\.be\/)[\w\-]+/.test(
    url
  );
};

export const extractVimeoVideoId = (url: string) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(?:channels\/\w+\/)?(\d+)/
  );
  return match ? match[1] : null;
};
export const extractYouTubeVideoId = (url: string) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/|embed\/|v\/|user\/\w+\/)|youtu\.be\/)([\w\-]{11})/
  );
  return match ? match[1] : null;
};

const DATE_FORMAT = "MM/DD/YYYY h:mm A";
export const convertDate = (date: string | null) => {
  const days = require("dayjs");
  if (date) {
    const parsedDate = days(date);
    const formattedDate = parsedDate.format(DATE_FORMAT);
    const parts = formattedDate.split(" ");
    return [parts[0], parts.slice(1).join(" ")];
  }
  return [];
};

const DATE_FORMAT_CONTACTS = "MMM DD YYYY - h:mm A";
export const convertDateContacts = (
  date: string | null,
  DATE_FORMAT: string | null
) => {
  const days = require("dayjs");
  if (date) {
    const parsedDate = days(date);
    return parsedDate.format(DATE_FORMAT ?? DATE_FORMAT_CONTACTS);
  }
  return "";
};
export const validateAudioFileType = (file: Blob) => {
  const validateType = ["audio/wav", "audio/mp3", "audio/mpeg", "audio/webm"];
  return validateType.includes(file.type);
};

export const validateAudioFileSize = (file: Blob) => {
  return file.size <= 8 * 1024 * 1024;
};
export const isValidIP = (ip: string): boolean => {
  const ipRegExp =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
  return ipRegExp.test(ip);
};

export const exportToExcel = (formattedData: any, filename: string) => {
  const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(formattedData);
  const wb: XLSX.WorkBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Activity Log");

  const wbout: ArrayBuffer = XLSX.write(wb, {
    type: "array",
    bookType: "xlsx",
  });

  try {
    saveAs(
      new Blob([wbout], { type: "application/octet-stream" }),
      `${filename}.xlsx`
    );
    return true;
  } catch (error) {
    return false;
  }
};

export const exportToPDF = (formattedData: any, filename: string) => {
  const doc = new jsPDF();
  const columns = Object.keys(formattedData[0]);
  const rows = formattedData.map((row: any) => Object.values(row));

  doc.autoTable({
    head: [columns],
    body: rows,
    columnStyles: {
      0: { columnWidth: 15 },
      1: { columnWidth: 18 },
      2: { columnWidth: 15 },
      3: { columnWidth: 15 },
      4: { columnWidth: 20 },
      5: { columnWidth: 12 },
      6: { columnWidth: 15 },
      7: { columnWidth: 12 },
      8: { columnWidth: 45 },
      9: { columnWidth: 20 },
    },
    styles: {
      cellWidth: "wrap",
      valign: "middle",
      fontSize: 6,
    },
  });

  try {
    doc.save(`${filename}.pdf`);
    return true;
  } catch (error) {
    return false;
  }
};

export const isValidName = (name: string, setNameError: any, label: string) => {
  const alphanumericRegExp = /^[a-zA-Z0-9\s]+$/;

  if (!name?.trim()) {
    setNameError(`${label} cannot be empty`);
    return false;
  } else if (!alphanumericRegExp.test(name)) {
    setNameError(`${label} can only be alphanumeric`);
    return false;
  }

  setNameError("");
  return true;
};

export const isValidEmailFunc = (email: string, setEmailError: any) => {
  if (!email?.trim()) {
    setEmailError("Email cannot be empty");
    return false;
  } else if (!isValidEmail(email)) {
    setEmailError("Email is invalid");
    return false;
  }

  setEmailError("");
  return true;
};

export const isValidPhoneFunc = (phone: string, setPhoneError: any) => {
  if (phone && !isValidPhoneNumber(phone)) {
    setPhoneError("Phone number is invalid");
    return false;
  }
  setPhoneError("");
  return true;
};

export const humanize = (str = "", shouldLowerCase = true) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .split(" ")
    .map((txt: string) =>
      shouldLowerCase
        ? txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        : txt.charAt(0).toUpperCase() + txt.substr(1)
    )
    .join(" ");
};
export const convertDateTime = (date: any, time: any) => {
  const [year, month, day] = date.split("-");
  const [hour, minute] = time.split(":");

  // Create a new Date object
  const convertedDate = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hour),
    Number(minute)
  );

  // Format the date as YYYY-MM-DDTHH:MM:SS
  const formattedDate = `${convertedDate.getFullYear()}-${String(
    convertedDate.getMonth() + 1
  ).padStart(2, "0")}-${String(convertedDate.getDate()).padStart(
    2,
    "0"
  )}T${String(convertedDate.getHours()).padStart(2, "0")}:${String(
    convertedDate.getMinutes()
  ).padStart(2, "0")}:${String(convertedDate.getSeconds()).padStart(2, "0")}`;

  return formattedDate;
};
export const extractTime = (datetime: any) => {
  // this will extract time in 24 hours format from datetime string
  const datetimeString = datetime;

  const date = new Date(datetimeString);

  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedTime = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;

  return formattedTime;
};
export const calculateStartDate = (date: Date, view: string) => {
  switch (view) {
    case "day":
      return moment(date).startOf("day").format("YYYY-MM-DD");
    case "week":
      return moment(date).startOf("week").format("YYYY-MM-DD");
    case "month":
      return moment(date).startOf("month").format("YYYY-MM-DD");
    default:
      return moment(date).format("YYYY-MM-DD");
  }
};

export const calculateEndDate = (date: Date, view: string) => {
  switch (view) {
    case "day":
      return moment(date).endOf("day").format("YYYY-MM-DD");
    case "week":
      return moment(date).endOf("week").format("YYYY-MM-DD");
    case "month":
      return moment(date).endOf("month").format("YYYY-MM-DD");
    default:
      return moment(date).format("YYYY-MM-DD");
  }
};

export const fmtHMS = (s: number) => {
  var h = Math.floor(s / 3600);
  var m = Math.floor((s % 3600) / 60);
  var sec = s % 60;

  var parts = [];

  // Adding hours if present
  if (h > 0) {
    parts.push(h + "h");
  }

  // Adding minutes if present
  if (m > 0 || h > 0) {
    parts.push(m + "m");
  }

  // Adding seconds
  parts.push(sec + "s");

  return parts.join(" ");
};

export const arrayToStr = (arr?: any[], key = "value") => {
  return (
    arr?.reduce(
      (acc, value, index) =>
        (acc += `${(value as any)[key]}${index === arr.length - 1 ? "" : ","}`),
      ""
    ) || ""
  );
};

export const removeFalseKeys = (obj: any) => {
  const newObj: NestedObject = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (typeof value === "object") {
        const nestedObj = removeFalseKeys(value);
        if (Object.keys(nestedObj).length !== 0) {
          newObj[key] = nestedObj;
        }
      } else if (value !== false) {
        newObj[key] = value;
      }
    }
  }
  return newObj;
};

export const getTransformedFilters = (
  obj: SearchAndFilterForm["filter_and_sort"]
) => {
  //
  const transformedDatRange = obj.date_range.start_date
    ? {
        date_range: {
          start_date: new Date(obj.date_range.start_date).toDateString(),
          end_date: new Date(obj.date_range.end_date).toDateString(),
        },
      }
    : {};

  let tempObj = {
    ...obj,
    ...transformedDatRange,
    contact_attributes: {
      ...obj.contact_attributes,
      disposition_status: arrayToStr(obj.contact_attributes.disposition_status),
      lead_source: arrayToStr(obj.contact_attributes.lead_source),
      tags: arrayToStr(obj.contact_attributes.tags),
      roles: arrayToStr(obj.contact_attributes.roles, "label"),
      users: arrayToStr(obj.contact_attributes.users),
      lines: arrayToStr(obj.contact_attributes.lines),
      batch_id: arrayToStr(obj.contact_attributes.batch_id),
    },
  };

  return removeFalseKeys(tempObj);
};

export const strToArray = (str: string, key = "value") => {
  if (!str) return [];
  return str.split(",").map((value) => ({ [key]: value }));
};

export const reverseTransformedFilters = (
  obj: any
): SearchAndFilterForm["filter_and_sort"] => {
  let tempObj = {
    ...obj,
    contact_attributes: {
      ...obj.contact_attributes,
      disposition_status: strToArray(obj.contact_attributes.disposition_status),
      lead_source: strToArray(obj.contact_attributes.lead_source),
      tags: strToArray(obj.contact_attributes.tags),
      roles: strToArray(obj.contact_attributes.roles, "label"),
      users: strToArray(obj.contact_attributes.users),
      lines: strToArray(obj.contact_attributes.lines),
    },
  };

  return tempObj;
};

export const fileToDataUrl = (file: File) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file); // Read the file as data URL
  });
};

export const addZeroIfNeeded = (num: number) => {
  return String(num).padStart(2, "0");
};

export const getPingMessage = (originalString: string, truncate = false) => {
  // Extracting the username from the original string
  let regex = /\@\[([^\]]+)\]\(\d+\)/g;

  let transformedString = originalString;
  let match;
  while ((match = regex.exec(originalString)) !== null) {
    let username = match[1];
    transformedString = transformedString.replace(
      /\@\[([^\]]+)\]\(\d+\)/,
      `<span>@${username}</span>`
    );
  }

  // Applying bold style to the span
  transformedString = transformedString.replace(
    /<span>(.*?)<\/span>/g,
    "<span style='font-weight: bold;'>$1</span>"
  );

  const urlRegex =
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  transformedString = transformedString.replace(urlRegex, (url) => {
    return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
  });

  return transformedString;
};
export const convertUnderscoreToLabelArray = (data: string[]): string[] =>
  Object.keys(data)?.map((item: any) =>
    item
      .replace("_", " ") // replace the underscores with spaces
      .split(" ") // make an array of each word
      .map(
        // capitalize the first letter of every word and add the remaining part by slicing
        (word: string) => word?.charAt(0)?.toUpperCase() + word.slice(1)
      )
      .join(" ")
  );

export const convertUnderscoreToLabel = (item: string): string =>
  item
    .replace("_", " ")
    .split(" ")
    .map((word: string) => word?.charAt(0)?.toUpperCase() + word.slice(1))
    .join(" ");

export const downloadBlob = (data: any, name: string) => {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  const link = createDownloadLink(url, name);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
};

export const createDownloadLink = (url: string, name: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = name;
  return link;
};

export const makeDataPoints = (timeStamps: string[], values: number[]) => {
  if (
    !timeStamps ||
    !values ||
    timeStamps?.length === 0 ||
    values?.length === 0 ||
    timeStamps?.length !== values?.length
  ) {
    return [];
  }
  return timeStamps.map((timestamp, index) => ({
    x: timestamp,
    y: values[index],
  }));
};

export const filterByUniqueKey = (arr: any[], key: string) => {
  return arr.filter(
    (item, index, self) => index === self.findIndex((t) => t[key] === item[key])
  );
};

export const filterLinks = (links: string[], filterForImages = true) => {
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".svg",
    ".webp",
    ".tiff",
    ".ico",
  ];

  return links.filter((link) => {
    const urlWithoutQuery = link.split("?")[0].toLowerCase(); // Remove query parameters
    const isImageLink = imageExtensions.some((ext) =>
      urlWithoutQuery.endsWith(ext)
    );
    return filterForImages ? isImageLink : !isImageLink;
  });
};

export const isImageLink = (link: string) => {
  const imageExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".bmp",
    ".svg",
    ".webp",
    ".tiff",
    ".ico",
  ];

  const urlWithoutQuery = link.split("?")[0].toLowerCase();
  return imageExtensions.some((ext) => urlWithoutQuery.endsWith(ext));
};

export function isVideoLink(url: string) {
  const videoExtensions = [
    ".mp4",
    ".avi",
    ".mov",
    ".wmv",
    ".flv",
    ".mkv",
    ".webm",
    ".m4v",
    ".3gp",
  ];
  const urlWithoutQuery = url.split("?")[0].toLowerCase(); // Remove query parameters

  return videoExtensions.some((ext) => urlWithoutQuery.endsWith(ext));
}

export const getFileNameFromURL = (url: string) => {
  const parts = url.split("/");
  return parts.pop(); // Get the last part of the array
};
const InternalAction: Record<string, string> = {
  outbound: "Outbound",
  campaign_outbound: "Campaign Outbound",
  inbound: "Inbound",
  inbound_email: "Inbound",
  inbound_call_log: "Inbound",
};

export const getLastActivity = (lastActivity: any): string => {
  const { record_type: recordType } = lastActivity || {};
  const action =
    InternalAction[
      recordType === "email_log"
        ? lastActivity?.details?.internal_action
        : lastActivity?.sub_type
    ];

  if (recordType === "email_log") {
    return `${action} Email`;
  } else if (recordType === "call") {
    return `${action}  Call
    `;
  } else if (recordType === "message") {
    return `${action} SMS
    `;
  }
  return "";
};

export const getInitials = (name = "") => {
  return name
    .split(" ")
    .map((word) => word[0]?.toUpperCase())
    .join("");
};

export const convertType = function (value: string) {
  if (value === "undefined") return undefined;
  if (value === "null") return null;
  if (value === "true") return true;
  if (value === "false") return false;
  var v = Number(value);
  return isNaN(v) ? value : v;
};

export const isTimeBetween = (
  firstTime: string,
  secondTime: string,
  time: string
) => {
  const timeToCheck = moment(time, "HH:mm");
  const startTime = moment(firstTime, "HH:mm");
  const endTime = moment(secondTime, "HH:mm");

  const isBetween = timeToCheck.isBetween(startTime, endTime, "minutes", "[]");
  console.log("isBetween", isBetween, timeToCheck, firstTime, secondTime);

  return isBetween;
};

export const isInBusinessHours = (business_hours: any) => {
  if (!business_hours) {
    return true;
  }
  const weekDay = moment().day();
  const isWeekend = weekDay === 6 || weekDay === 0;
  const {
    week_end_time,
    week_start_time,
    weekend_start_time,
    weekend_end_time,
  } = business_hours || {};
  return isWeekend
    ? isTimeBetween(
        weekend_start_time,
        weekend_end_time,
        moment().format("HH:mm")
      )
    : isTimeBetween(week_start_time, week_end_time, moment().format("HH:mm"));
};
export const titleMapping = {
  outbound: "Outbound",
  inbound_email: "Inbound",
  campaign_outbound: "Campaign Outbound",
  email_service_outbound: "Email Blast Outbound",
};

export const formatTimestamp = (timestamp: any): string => {
  const date = new Date(timestamp * 1000);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid date";
  }

  // Extract date and time parts
  const weekday = date.toLocaleString("en-US", { weekday: "short" });
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const year = date.getFullYear();
  const time = date
    .toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    })
    .toLowerCase();

  // Combine the parts
  return `${weekday} ${month} ${day}, ${year}, ${time}`;
};

const separator = [",", "|", ";"];
export const getSeparatorInUrlString = (urlString: string) => {
  return separator.find((d) => urlString?.includes(d));
};
