import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";

import { useState } from "react";
import AddEmpInfo from "./AddEmpInfo";
import PersonalInfo from "./PersonalInfo";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { fetchContact } from "../../../utils/api/guest-mode";
import { Spinner } from "../../../components/common";

const ApplyForCredit = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { id, dId } = useParams();
  const [frameContent, setFrameContent] = useState("");

  const handleTabChange = (index: number) => {
    setActiveTab(index);
  };
  const { data, isFetching } = useQuery({
    queryKey: ["fetchContact", { contact_uuid: id }, dId],
    queryFn: fetchContact,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== ":id",
  });

  return (
    <VStack w="100%">
      <VStack
        alignItems="flex-start"
        w={{ base: "100%", md: data?.data?.credit_app_link ? "100%" : "45%" }}
        padding="1rem"
        h="100%"
      >
        {data?.data?.credit_app_link ? (
          <VStack w="100%" h="100%" className="iframeContainer">
            <iframe
              id="applyForCreditIframeId"
              className="applyForCreditIframe"
              src={data?.data?.credit_app_link}
              title="Apply for Credit Link"
              scrolling="no"
              style={{
                border: "none",
                marginTop: "68px",
                // width: "99%",
                paddingRight: "34px",
                paddingLeft: "34px",
                paddingTop: "24px",
                background: "#fff",
              }}
            />
          </VStack>
        ) : (
          <Tabs
            variant="primary"
            w="100%"
            h="calc(100% - 3rem)"
            onChange={handleTabChange}
            defaultIndex={0}
          >
            <TabList>
              <Tab w="100%" fontSize={{ md: "14px", base: "12px" }}>
                Personal Info
              </Tab>
              <Tab w="100%" fontSize={{ md: "14px", base: "12px" }}>
                Address / Employee Info
              </Tab>
            </TabList>
            <TabPanels h="100%">
              <TabPanel h="100%">
                {activeTab === 0 && <PersonalInfo />}
              </TabPanel>
              <TabPanel>{activeTab === 1 && <AddEmpInfo />}</TabPanel>
            </TabPanels>
          </Tabs>
        )}
      </VStack>
      {isFetching && <Spinner />}
    </VStack>
  );
};

export default ApplyForCredit;
