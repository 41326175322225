import { Input, Select } from "../../../../components/common";
import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { fetchTimezones } from "../../../../utils/api/users.api";
import { createRef, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import SwitchToggle from "../../../common/Switch";
import Loader from "../../../common/Spinner";
import {
  editDealershipData,
  getsettingsApi,
  updatesettingApi,
} from "../../../../utils/api/dealership-setting.api";
import { useParams } from "react-router-dom";
import { defaultValues, GeneralForm, generalFormValidation } from "./utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TimeIcon } from "../../../icons/svg";
import { getTCPA } from "../../../../utils/api/line-rings.api";

const General = ({ detail, refetch: parentFetch }: any) => {
  const toast = useToast();
  const { id } = useParams();
  const mainDivRef = createRef<HTMLDivElement>();

  const { handleSubmit, control, setValue, trigger } = useForm<GeneralForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(generalFormValidation) as any,
  });

  const weekend_timings = useWatch({
    control,
    name: "business_hours.weekend_timings",
  });

  useQuery({
    queryKey: ["timezones"],
    queryFn: () => fetchTimezones(),
    onSuccess(timezones) {
      setTimezoneOptions(
        timezones?.map(({ id, name }: any) => ({ label: id, value: name })) ||
          []
      );
    },
  });

  const { data } = useQuery({
    queryKey: ["settingsdata", id, "general_settings"],
    queryFn: getsettingsApi,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (detail) {
      setValue("name", detail?.name || "");
      setValue("website", detail?.website || "");
      setValue("address", detail?.address || "");
      setValue("time_zone", detail?.time_zone || "");
      setValue("credit_app_link",detail?.credit_app_link || "");
    }
  }, [detail, setValue]);

  useEffect(() => {
    if (data) {
      setValue("business_hours", data?.data?.settings_hash?.business_hours);
      setValue("mms", data?.data?.settings_hash?.mms ?? false);
      setValue("business_hours.tcpa_disposition", data?.data?.settings_hash?.business_hours?.tcpa_disposition);
      setValue(
        "business_hours.thirty_days_opt_in",
        data?.data?.settings_hash?.business_hours?.thirty_days_opt_in
      );
      setValue(
        "business_hours.initial_sms_opt_in",
        data?.data?.settings_hash?.business_hours?.initial_sms_opt_in
      );
      setValue(
        "business_hours.thirty_days_opt_message",
        data?.data?.settings_hash?.business_hours?.thirty_days_opt_message
      );
      setValue(
        "business_hours.initial_sms_opt_message",
        data?.data?.settings_hash?.business_hours?.initial_sms_opt_message
      );
    }
  }, [data, setValue]);

  const mutation = useMutation(editDealershipData);
  const settingMutation = useMutation(updatesettingApi);
  const [timezoneOptions, setTimezoneOptions] = useState<SelectOptionProps[]>(
    []
  );

  const handleFormSubmit = async (values: GeneralForm) => {
    const { business_hours, mms, ...restValues } = values;
    try {
      // Use Promise.all to execute both mutations concurrently
      await Promise.all([
        mutation.mutateAsync({
          data: {
            dealership: restValues,
          },
          id: id,
          sub_menu: "Dealership settings/Dealer Profile/General",
        }),
        settingMutation.mutateAsync({
          data: {
            key: "general_settings",
            settings_hash: {
              business_hours,
              ...restValues,
              mms: mms,
            },
          },
          id: id,
          sub_menu: "Dealership settings/Dealer Profile/General",
        }),
      ]);

      // Handle the responses as needed
      toast({
        description: "Settings updated successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
        onCloseComplete() {
          parentFetch();
        },
      });

      // Additional logic after both mutations succeed
    } catch (error) {
      // Handle errors if any mutation fails
      toast({
        description: "Something went wrong ",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const { data: Tcpa } = useQuery({
    queryKey: ["tcpa", id],
    queryFn: getTCPA,
    refetchOnWindowFocus: false,
    retry: false,
  });

  return (
    <>
      <VStack background="white" gap="1.5rem">
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
          ref={mainDivRef}
        >
          <Text fontWeight="700" textStyle="h4">
            Company Details
          </Text>
          <VStack w="100%" gap="1.25rem" alignItems="flex-start">
            <HStack w="100%" gap="1.25rem" alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="100%"
                      label="Company Name"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="name"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="100%"
                      label="Website"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="website"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </HStack>
            <HStack w="100%" gap="1.25rem">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="50%"
                      label="Address"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                name="address"
                control={control}
                rules={{
                  required: true,
                }}
              />

              {/* <Input
                maxW="50%"
                label="Email"
                placeholder="Email"
                value={detail?.email}
                isDisabled
              /> */}
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      maxW="50%"
                      label="Credit App Link"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                    />
                  );
                }}
                name="credit_app_link"
                control={control}
                // rules={{
                //   required: true,
                // }}
              />
            </HStack>
          </VStack>
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Timezone
          </Text>
          <Text textStyle="h6" fontWeight="600" mb="0.25rem">
            Account's main timezone. Sequences, business hours, and some more
            controls follow this timezone. This is also the base timezone for
            your reports.
          </Text>
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label=""
                  onChange={(value: any) => setValue("time_zone", value.value)}
                  placeholder="Select Option"
                  options={timezoneOptions}
                  variant="default"
                  w="100%"
                  value={value}
                  error={error?.message}
                />
              );
            }}
            name="time_zone"
            control={control}
            rules={{
              required: true,
            }}
          />
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Business Hours
          </Text>
          <HStack
            gap="0.31rem"
            mb="0.5rem"
            alignItems="center"
            w="100%"
            sx={{
              ".input-wrapper": {
                height: "5rem",
              },
            }}
          >
            <Text
              textStyle="h6"
              fontWeight="500"
              mb="0.75rem"
              maxW="12rem"
              w="100%"
            >
              Monday-Friday:
            </Text>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    error={error?.message}
                    label="Start Time"
                    onChange={onChange}
                    value={value}
                    type="time"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    maxW="15rem"
                  />
                );
              }}
              name="business_hours.week_start_time"
              control={control}
              rules={{
                required: true,
              }}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    error={error?.message || ""}
                    label="End Time"
                    onChange={onChange}
                    value={value}
                    type="time"
                    hasIcon={true}
                    isIconRight={true}
                    icon={<TimeIcon />}
                    maxW="15rem"
                  />
                );
              }}
              name="business_hours.week_end_time"
              control={control}
              rules={{
                required: true,
              }}
            />
          </HStack>
          <HStack
            gap="0.31rem"
            alignItems="center"
            h="5rem"
            w="100%"
            sx={{
              ".chakra-form-control": {
                width: "12rem",
              },

              ".input-wrapper": {
                height: "5rem",
              },
            }}
          >
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SwitchToggle
                    id="1"
                    label="Weekend Timings"
                    defaultChecked={value}
                    onChange={(check: boolean) =>
                      setValue("business_hours.weekend_timings", check)
                    }
                  />
                );
              }}
              name="business_hours.weekend_timings"
              control={control}
              rules={{
                required: true,
              }}
            />
            {weekend_timings && (
              <>
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        error={error?.message}
                        label="Start Time"
                        onChange={onChange}
                        value={value}
                        type="time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        maxW="15rem"
                      />
                    );
                  }}
                  name="business_hours.weekend_start_time"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <Input
                        error={error?.message}
                        label="End Time"
                        onChange={onChange}
                        value={value}
                        type="time"
                        hasIcon={true}
                        isIconRight={true}
                        icon={<TimeIcon />}
                        maxW="15rem"
                      />
                    );
                  }}
                  name="business_hours.weekend_end_time"
                  control={control}
                  rules={{
                    required: true,
                  }}
                />
              </>
            )}
          </HStack>
          {/* <HStack gap="0.31rem" mb="1rem" alignItems="flex-end">
            <Select
              label="Start Day"
              maxW="9rem"
              onChange={(value) => handleStartTimeChange("day", value)}
              placeholder="Select Day"
              options={days}
              variant="default"
              value={selectedStartTime?.day}
            />
            <Select
              label="Start Time"
              maxW="9rem"
              onChange={(value) => handleStartTimeChange("hour", value)}
              placeholder="09"
              options={hoursArray}
              variant="default"
              value={selectedStartTime?.hour}
            />
            <Select
              label=""
              maxW="9rem"
              onChange={(value) => handleStartTimeChange("minute", value)}
              placeholder="00"
              options={minutesArray}
              variant="default"
              value={selectedStartTime?.minute}
            />
            <Select
              label=""
              maxW="9rem"
              onChange={(value) => handleStartTimeChange("period", value)}
              placeholder="AM"
              options={time}
              variant="default"
              value={selectedStartTime?.period}
            />
          </HStack>
          <HStack gap="0.31rem" alignItems="flex-end">
            <Select
              label="End Day"
              maxW="9rem"
              onChange={(value) => handleEndTimeChange("day", value)}
              placeholder="Select Day"
              options={days}
              variant="default"
              value={selectedEndTime?.day}
            />
            <Select
              label="End Time"
              maxW="9rem"
              onChange={(value) => handleEndTimeChange("hour", value)}
              placeholder="09"
              options={hoursArray}
              variant="default"
              value={selectedEndTime?.hour}
            />
            <Select
              label=""
              maxW="9rem"
              onChange={(value) => handleEndTimeChange("minute", value)}
              placeholder="00"
              options={minutesArray}
              variant="default"
              value={selectedEndTime?.minute}
            />
            <Select
              label=""
              maxW="9rem"
              onChange={(value) => handleEndTimeChange("period", value)}
              placeholder="AM"
              options={time}
              variant="default"
              value={selectedEndTime?.period}
            />
          </HStack> */}
        </VStack>
        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            Other Details
          </Text>
          <Box
            padding="1rem"
            background="var(--grey-50)"
            borderRadius="0.5rem"
            border="1px solid var(--grey-300)"
          >
            <Text textStyle="h4" fontWeight="500" mb="1rem">
              Send long text messages as MMS
            </Text>
            <Text
              textStyle="h6"
              fontWeight="400"
              mb="1.12rem"
              color="var(--grey-800)"
            >
              Messages longer than 160 characters will automatically send as an
              MMS rather than multiple SMS segments. This setting can also be
              made on individual Line Settings.
            </Text>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <SwitchToggle
                    id="1"
                    onChange={(check: boolean) => setValue("mms", check)}
                    label="Enable Send long text messages as MMS"
                    defaultChecked={value}
                  />
                );
              }}
              name="mms"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
        </VStack>

        <VStack
          alignItems="flex-start"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          padding="1rem"
          borderRadius="0.5rem"
          w="100%"
        >
          <Text textStyle="h4" fontWeight="500" mb="1rem">
            TCPA: Text Authorization
          </Text>
          <VStack alignItems="flex-start" width="100%" gap="1rem">
            <VStack alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      id="1"
                      label="Initial SMS Opt-In"
                      onChange={(check: boolean) =>
                        setValue("business_hours.initial_sms_opt_in", check)
                      }
                      defaultChecked={value}
                    />
                  );
                }}
                name="business_hours.initial_sms_opt_in"
                control={control}
              />
            </VStack>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    type="text"
                    placeholder="Write here.."
                    onChange={onChange}
                    value={value}
                    isTextarea={true}
                  />
                );
              }}
              name="business_hours.initial_sms_opt_message"
              control={control}
            />

            <VStack alignItems="flex-start">
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <SwitchToggle
                      id="1"
                      label="30-Day Opt-In"
                      onChange={(check: boolean) =>
                        setValue("business_hours.thirty_days_opt_in", check)
                      }
                      defaultChecked={value}
                    />
                  );
                }}
                name="business_hours.thirty_days_opt_in"
                control={control}
              />
            </VStack>
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    type="text"
                    placeholder="Write here.."
                    onChange={onChange}
                    value={value}
                    isTextarea={true}
                  />
                );
              }}
              name="business_hours.thirty_days_opt_message"
              control={control}
            />
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    label="TCPA disposition"
                    onChange={(value: any) =>
                      setValue("business_hours.tcpa_disposition", value.value)
                    }
                    options={
                      Tcpa?.data?.contact_dispositions?.map((tcpa: any) => ({
                        label: tcpa.name,
                        value: tcpa.id,
                      })) ?? []
                    }
                    w="100%"
                    value={value}
                    variant="default"
                    isClearable
                  />
                );
              }}
              name="business_hours.tcpa_disposition"
              control={control}
            />
          </VStack>
        </VStack>

        <HStack
          position="fixed"
          bottom="1.5rem"
          w="calc(100% - 585px)"
          right="1.5rem"
          background="white"
          justifyContent="flex-end"
          padding="1rem"
          borderTop="1px solid var(--grey-300)"
        >
          <Button
            width="8rem"
            onClick={async () => {
              const isError = await trigger();
              if (!isError)
                return mainDivRef.current?.scrollIntoView({
                  behavior: "smooth",
                });
              handleSubmit((values: GeneralForm) => handleFormSubmit(values))();
            }}
          >
            Save
          </Button>
        </HStack>
        {mutation?.isLoading && <Loader />}
      </VStack>
    </>
  );
};

export default General;
