import {
  Box,
  Button,
  DrawerFooter,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { Drawer } from "../../../components/common";
import MakeInput from "../../../components/common/MakeInput";
import ModelInput from "../../../components/common/ModelInput";
import NameInput from "../../../components/common/NameInput";
import YearInput from "../../../components/common/YearInput";
import { fetchDMS } from "../../../utils/api/dms-equity-mining";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { loadOutboundLine } from "../bdc-campaign/CreateCampaign/utils";
import { ContactsDrawer } from "./ContactsDrawer";
import DateInput from "./DateInput";
import EmailContent from "./EmailContent";
import SmsContent from "./SmsContent";

const schema = (isSms: boolean) => {
  return yup.object().shape({
    name: yup.string().nullable().required("Enter name"),
    content: isSms
      ? yup.string().nullable().required("Enter Message").max(1599)
      : yup
          .string()
          .nullable()
          .required("Enter Message")
          .test(
            "max-text-length",
            "Content cannot exceed 2499 characters in plain text",
            function (value) {
              if (!value) return true; // if no content, validation passes

              // Create a temporary element to convert HTML to plain text
              const tempElement = document.createElement("div");
              tempElement.innerHTML = value;
              const textContent =
                tempElement.innerText || tempElement.textContent || "";

              // Check if the plain text length exceeds 2499 characters
              return textContent.length <= 2499;
            }
          ),
    year: yup
      .number()
      .nullable()
      .transform((value, originalValue) =>
        originalValue === "" ? null : value
      )
      .integer("Year must be an integer"),
    make: yup.string().nullable(),
    model: yup.string().nullable(),
  });
};

const ServicesDMSEquityFilter = (props: any) => {
  const { onClose, refetch, id = null, isSms } = props;
  const [formData, setFormData] = useState(null);
  const [attachmentUrls, setAttachmentUrls] = useState<string[]>([]);

  const {
    isOpen: isContactsOpen,
    onOpen: onContactsOpen,
    onClose: onContactsClose,
  } = useDisclosure();
  const dId = useUserInfo("dealership")?.id;

  const { control, handleSubmit, watch, setValue, getValues } = useForm<any>({
    resolver: yupResolver(schema(isSms)),
  });

  const values = watch();

  useEffect(() => {
    setValue("date", {
      startDate: null,
      endDate: null,
      key: "selection",
      selected: false,
    });
    setValue("dollarAmount", [0, 9999999]);
  }, []);

  const onSubmit = (data: any) => {
    const { date, dollarAmount, ...rest } = data;
    const transformedData: any = {};
    if (
      date &&
      date?.selected &&
      date?.startDate &&
      date?.endDate &&
      String(date?.startDate) !== String(date?.endDate)
    ) {
      transformedData.start_date = date.startDate;
      transformedData.end_date = date.endDate;
    }
    // if (dollarAmount && dollarAmount.length === 2) {
    //   transformedData.start_payment = dollarAmount[0];
    //   transformedData.end_payment = dollarAmount[1];
    // }
    setFormData({ ...transformedData, ...rest });

    onContactsOpen();
  };

  //..........setting the default id during creation...........
  useEffect(() => {
    const fetchDefaultOption = async () => {
      const options = await loadOutboundLine("", dId);
      const defaultOption = options.find(
        (option: any) => option.default === true
      );
      if (defaultOption && !id) {
        setValue("line_id", defaultOption);
      }
    };

    fetchDefaultOption();
  }, [id, setValue]);

  //..................................

  const { isLoading } = useQuery({
    queryKey: ["fetchDMS", dId, id],
    queryFn: fetchDMS,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setValue("name", data?.data?.name);
      setValue("content", data?.data?.content);
      setValue("subject", data?.data?.subject);
      setValue("line_id", {
        value: data?.data?.line?.id,
        label: data?.data?.line?.phone_number,
      });
      setAttachmentUrls(
        data?.data?.media_urls?.map((item: any) => ({
          url: item,
          filename: item,
        }))
      );
      const formData = data?.data?.filters;
      setValue("date", {
        startDate: formData?.start_date ? new Date(formData?.start_date) : null,
        endDate: formData?.end_date ? new Date(formData?.end_date) : null,
        key: "selection",
        selected: true,
      });
      setValue("year", formData?.year);
      setValue("make", formData?.make);
      setValue("model", formData?.model);
      let dollarAmount = [0, 9999999];
      if (formData?.start_payment && formData?.end_payment) {
        dollarAmount = [formData?.start_payment, formData?.end_payment];
      }
      setValue("dollarAmount", dollarAmount);
    },
  });
  const EmailContentComponent = (
    <EmailContent
      control={control}
      setValue={setValue}
      getValues={getValues}
      attachmentUrls={attachmentUrls}
      setAttachmentUrls={setAttachmentUrls}
    ></EmailContent>
  );
  const SMSContentComponent = (
    <SmsContent
      control={control}
      setValue={setValue}
      getValues={getValues}
      attachmentUrls={attachmentUrls}
      setAttachmentUrls={setAttachmentUrls}
    ></SmsContent>
  );
  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title={id ? "Edit DMS Equity Mining" : "Create DMS Equity Mining"}
      isLoading={id ? isLoading : false}
    >
      <Box as="form">
        <Box padding="1.25rem">
          <Box mb="1rem">
            <NameInput control={control} />
          </Box>
          <Box mb="1rem">
            <DateInput
              control={control}
              label="Date of Last Closed Repair Order"
            />
          </Box>

          <Box mb="1rem">
            <YearInput control={control} />
          </Box>

          <Box mb="1rem">
            <MakeInput control={control} />
          </Box>

          <Box mb="1rem">
            <ModelInput control={control} />
          </Box>
          <Box mb="1rem">
            <Controller
              name="dollarAmount"
              control={control}
              render={({ field }) => (
                <VStack alignItems="flex-start">
                  <Text textStyle="h6">
                    Select Dollar Amount (Current Selection : {field.value[0]} -{" "}
                    {field.value[1]})
                  </Text>
                  <RangeSlider
                    min={0}
                    max={9999999}
                    step={1}
                    w="100%"
                    h="0.5rem"
                    value={field.value}
                    onChange={(val) => field.onChange(val)}
                  >
                    <RangeSliderTrack
                      h="0.5rem"
                      background="var(--grey-300)"
                      borderRadius="0.25rem"
                    >
                      <RangeSliderFilledTrack background="var(--primary-600)" />
                    </RangeSliderTrack>
                    <RangeSliderThumb
                      index={0}
                      w="1.5rem"
                      h="1.5rem"
                      border="1px solid var(--primary-600)"
                    />
                    <RangeSliderThumb
                      index={1}
                      w="1.5rem"
                      h="1.5rem"
                      border="1px solid var(--primary-600)"
                    />
                  </RangeSlider>
                </VStack>
              )}
            />
          </Box>

          {isSms ? SMSContentComponent : EmailContentComponent}
        </Box>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
          background="white"
        >
          <Button
            w="100%"
            size="xl"
            type="submit"
            onClick={handleSubmit(onSubmit)}
            isDisabled={
              !(
                values?.name &&
                values?.content &&
                (isSms ? true : values?.subject)
              )
            }
          >
            Next
          </Button>
        </DrawerFooter>
      </Box>
      {isContactsOpen && formData && (
        <ContactsDrawer
          onClose={onClose}
          refetch={refetch}
          id={id}
          isContactsOpen={isContactsOpen}
          onContactsClose={onContactsClose}
          formData={formData}
          category="services"
          isSms={isSms}
        ></ContactsDrawer>
      )}
    </Drawer>
  );
};

export default ServicesDMSEquityFilter;
