import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//..................Keywords..............
export const fetchKeywords = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/keywords?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );

export const fetchKeywordsDealership = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/keywords?page=${queryKey[2]}&per_page=${queryKey[3]}&q=${queryKey[4]}`
  );
export const fetchKeywordById = async ({ queryKey }: any) =>
  await axios.get(`${SERVICES.apiBaseUrl}/api/admin/keywords/${queryKey[1]}`);

export const fetchKeywordDealershipById = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api//dealerships/${queryKey[2]}/keywords/${queryKey[1]}`
  );

export const updateKeyword = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/keywords/${payload.id}`,
    payload.payload,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Keywords",
      },
    }
  );
export const updateKeywordDealership = async (payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/keywords/${payload.id}`,
    payload.payload,
    {
      headers: {
        menu: "Dealership Settings",
        "Sub-Menu": "Keywords",
      },
    }
  );

export const deleteKeyword = async (payload: any) =>
  await axios.delete(
    `${SERVICES.apiBaseUrl}/api/admin/keywords/${payload.id}`,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Keywords",
      },
    }
  );
export const deleteKeywordDealership = async (payload: any) =>
  await axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/keywords/${payload.id}`,
    {
      headers: {
        menu: "Dealership Settings",
        "Sub-Menu": "Keywords",
      },
    }
  );

export const createKeyword = async (payload: any) =>
  await axios.post(
    `${SERVICES.apiBaseUrl}/api/admin/keywords`,
    payload.payload,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Keywords",
      },
    }
  );
export const createKeywordDealership = async (payload: any) =>
  await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.dId}/keywords`,
    payload.payload,
    {
      headers: {
        menu: "Dealership Settings",
        "Sub-Menu": "Keywords",
      },
    }
  );
//..................Inventory Feed Provider..............
export const fetchInventoryFeedProvider = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const updateInventoryFeedProvider = async (id: any, payload: any) =>
  await axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`,
    payload,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Inventory Feed Providers",
      },
    }
  );
export const deleteInventoryFeedProvider = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Inventory Feed Provider",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createInventoryFeedProvider = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers`,
    payload,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Inventory Feed Providers",
      },
    }
  );
export const fetchInventoryFeedProviderById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
//..................Load Souce..............
export const fetchLeadSource = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchBatch = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/import_contacts`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateLeadSource = async (id: any, payload: any, dId: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const deleteLeadSource = async (id: any, dId: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const createLeadSource = async (payload: any, dId: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const mergeLeadSource = async (id: any, dId: any, payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}/merge`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": "Settings/Dealership Settings/Lead Sources",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchLeadSourceById = async (id: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/lead_sources/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchTags = async (params: any, dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/tags`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
