import { Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import {
  assignTag,
  fetchTagsForContact,
  unAssignTag,
} from "../../../../../utils/api/contact-details";
import Popup from "../../../../Charts/Popup";
import { Input, Spinner } from "../../../../common";
import CustomDrawer from "../../../../common/Drawer";
import TooltipIfOverflow from "../../../../common/TooltipIfOverflow";

export const AssignUnassignTags = ({
  isOpen,
  onClose,
  clickedRow,
  dealership_id,
}: any) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    tagId: "",
    hasInput: false,
  });
  const [days, setDays] = useState(null);
  const [error, setError] = useState("");
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["fetchTagsForContact", dealership_id, clickedRow?.id],
    queryFn: fetchTagsForContact,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const mutationAssign = useMutation(assignTag);
  const mutationUnassign = useMutation(unAssignTag);
  const toast = useToast();
  const updateStatus = (tagId: any, isAssign: boolean) => {
    const mutFunc = isAssign ? mutationAssign : mutationUnassign;

    mutFunc?.mutate(
      {
        dId: dealership_id,
        id: clickedRow?.id,
        tagId: tagId,
        ...(days ? { days } : {}),
      },
      {
        onSuccess() {
          refetch();
          onClosePopupOpen();
          toast({
            description: isAssign
              ? "Tag assigned successfully."
              : "Tag unassigned successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <CustomDrawer
        isOpen={isOpen}
        onClose={onClose}
        title="Assign/Unassign Tags"
        isLoading={isFetching}
      >
        <VStack alignItems="flex-start" padding="1rem 0.5rem" w="100%">
          {data?.data?.tags?.map((item: any) => (
            <HStack
              justifyContent="space-between"
              w="100%"
              padding="0.5rem"
              borderRadius="md"
              boxShadow="md"
            >
              <Text textStyle="h6" fontWeight="500">
                <TooltipIfOverflow>{item?.tag_name}</TooltipIfOverflow>
              </Text>
              <Button
                w="100px"
                onClick={() => {
                  onOpenPopupOpen();
                  setPopup({
                    name: item?.assigned ? "Unassign Tag" : "Assign Tag",
                    description: item?.assigned
                      ? "Are you sure you want to unassign this tag from this contact?"
                      : "Are you sure you want to assign this tag to this contact?",
                    tagId: item?.id,
                    hasInput: !item?.assigned,
                  });
                }}
                backgroundColor={item?.assigned ? "" : "var(--secondary-600)"}
                _hover={{
                  backgroundColor: item?.assigned ? "" : "var(--secondary-700)",
                  border: item?.assigned
                    ? "2px solid var(--primary-color)"
                    : "2px solid var(--secondary-500)",
                }}
                border={"2px solid transparent"}
              >
                {item?.assigned ? "Unassign" : "Assign"}
              </Button>
            </HStack>
          ))}
        </VStack>
      </CustomDrawer>
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={() => updateStatus(popup?.tagId, popup?.hasInput)}
          title={popup.name}
          message={popup.description}
          loading={mutationAssign?.isLoading || mutationUnassign?.isLoading}
          children={
            popup?.hasInput ? (
              <>
                <Input
                  placeholder="Enter expiry days"
                  onChange={(value) => {
                    if (value >= 1 && value <= 9999) {
                      setDays(value);
                      setError("");
                    } else {
                      setError("Please enter a number between 1 and 9999.");
                    }
                  }}
                  isNumeric
                  w="100%"
                  type="number"
                  maxNumeric={9999}
                  minNumeric={1}
                  error={error}
                />
              </>
            ) : null
          }
        ></Popup>
      )}
      {mutationAssign?.isLoading ||
        (mutationUnassign?.isLoading && <Spinner />)}
    </>
  );
};
