import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

type UserListingProps = {
  users?: any[];
  setPage: Dispatch<SetStateAction<number>>;
  count?: number;
  foundIndex?: number;
  onClick?: (item: any, index: number) => void;
  removedUsers?: any[];
  setRemovedUsers?: Dispatch<SetStateAction<any[]>>;
  target?: string;
  type: string | null;
};

function UserListing({
  users,
  setPage,
  count,
  foundIndex,
  onClick,
  setRemovedUsers,
  removedUsers,
  target = "scrollableDiv",
  type = null,
}: UserListingProps) {
  const shouldShowTooltip = !!setRemovedUsers;

  return (
    <InfiniteScroll
      dataLength={users?.length || 0}
      next={() => {
        setPage((prev: any) => prev + 1);
      }}
      hasMore={(users?.length || 0) < (count || 0)}
      loader={<h4>Loading...</h4>}
      scrollableTarget={target}
    >
      <VStack gap="0.5" padding="1rem">
        {users?.map((item: any, index: number) => {
          const isItemDeselected = removedUsers?.includes(item.id);
          return (
            <Box
              key={item?.id}
              w="100%"
              padding="1rem"
              cursor="pointer"
              background={foundIndex === index ? "var(--primary-50)" : ""}
              onClick={() => {
                if (setRemovedUsers)
                  isItemDeselected
                    ? setRemovedUsers(
                        removedUsers?.filter(
                          (contactId) => contactId !== item.id
                        ) || []
                      )
                    : setRemovedUsers((prev) => [...prev, item.id]);
                onClick && onClick(item, index);
              }}
              style={
                removedUsers && isItemDeselected
                  ? { background: "#FF7F7F" }
                  : {}
              }
              borderRadius="md"
              boxShadow="md"
            >
              <Tooltip
                hasArrow
                label={
                  shouldShowTooltip
                    ? `Click to ${
                        isItemDeselected ? "Add" : "Remove"
                      } this contact`
                    : ""
                }
                borderRadius="0.5rem"
                placement="top"
                background="var(--grey-900)"
                padding="0.5rem"
                fontSize="0.75rem"
                fontWeight="600"
                color="white"
              >
                <HStack alignItems="flex-start" gap="0.75rem">
                  <VStack gap="0.25rem" alignItems="flex-start">
                    <Text
                      textStyle="h5"
                      fontWeight="700"
                      color="var(--grey-800)"
                    >
                      {item?.first_name} {item.last_name}
                    </Text>
                    {type ? (
                      <Text
                        textStyle="h5"
                        fontWeight="600"
                        color="var(--grey-800)"
                      >
                        {item?.phone}
                      </Text>
                    ) : (
                      <Text
                        textStyle="h5"
                        fontWeight="600"
                        color="var(--grey-800)"
                      >
                        {item?.email}
                      </Text>
                    )}
                    <Text textStyle="h5" fontWeight="600" color="#050571">
                      {item?.dealership_name}
                    </Text>
                  </VStack>
                </HStack>
              </Tooltip>
            </Box>
          );
        })}
      </VStack>
    </InfiniteScroll>
  );
}

export default UserListing;
