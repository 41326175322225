import { Box, Button, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Input } from "../../../components/common";
import {
  fetchSettings,
  updateSettings,
} from "../../../utils/api/dealership-setting.api";
import Loader from "../../common/Spinner";
import LeadCenterEmails from "./LeadCenterEmails";
import LeadDistribution from "./LeadDistribution";
import { prioritiesOption } from "./constants";
import {
  LeadCenterSettingsForm,
  defaultValues,
  leadCenterSettingsValidation,
  priorityMapping,
} from "./utils";

const LeadCenterSettings = () => {
  const [priorityOpen, setPriorityOpen] = useState(false);

  const toast = useToast();

  const { id } = useParams();
  const queryClient = useQueryClient();

  const { control, setValue, reset, getValues, trigger } =
    useForm<LeadCenterSettingsForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(leadCenterSettingsValidation) as any,
      mode: "all",
    });

  const formValues = useWatch({ control });

  const { isFetching: isLoading } = useQuery({
    queryKey: ["fetchLCSettings", id],
    queryFn: () => fetchSettings(id, "lead_center_settings"),
    retry: false,
    onSuccess: (data) => {
      const { settings_hash } = data || {};
      const {
        priority_one_follow_up,
        priority_two_follow_up,
        priority_three_follow_up,
        ...otherSettings
      } = settings_hash;

      reset({
        priorities: [
          priority_one_follow_up,
          priority_two_follow_up,
          priority_three_follow_up,
        ],
        ...otherSettings,
      });
    },
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateSettings(
          payload,
          id,
          "Dealership Settings/Lead Center Settings"
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      queryClient.invalidateQueries(["fetchLCSettings", id]);
      setPriorityOpen(false);
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleFormSubmit = (values: LeadCenterSettingsForm) => {
    const transformedPriorities = values.priorities.reduce<any>(
      (acc, priority, index) => {
        acc[priorityMapping[index]] = priority;
        return acc;
      },
      {}
    );

    const transformedValues = { ...values, ...transformedPriorities };
    const { priorities, ...settings } = transformedValues;

    const requestData = {
      key: "lead_center_settings",
      settings_hash: settings,
    };

    mutation.mutate(requestData);
  };

  return (
    <>
      {(isLoading || mutation?.isLoading) && <Loader />}
      <VStack position="relative" w="100%" alignItems="flex-start">
        <HStack
          gap="1rem"
          alignItems="flex-start"
          height="calc(100vh - 270px)"
          overflow="auto"
          width="100%"
          pb="32px"
        >
          <VStack gap="0.62rem" w="50%" pb="5rem">
            {prioritiesOption.map(({ title }, index) => {
              const { start, end } = formValues.priorities?.[index] || {};
              return (
                <Box
                  padding="1rem"
                  background="var(--grey-50)"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  w="100%"
                >
                  <Text textStyle="h4" fontWeight="500" mb="0.63rem">
                    {title}
                  </Text>
                  <Text
                    textStyle="h6"
                    fontWeight="500"
                    mb="0.63rem"
                    color="var(--grey-800)"
                  >
                    Leads {start}-{end} Days - Not Sold/No Appointment Set
                  </Text>

                  <Text
                    textStyle="h6"
                    fontWeight="500"
                    mb="0.63rem"
                    color="var(--grey-800)"
                  >
                    Enter Age
                  </Text>

                  <Box display="flex" gap="5px">
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            label="Start"
                            placeholder="Enter Start"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            type="number"
                            w="100%"
                          />
                        );
                      }}
                      name={`priorities.${index}.start`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />

                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Input
                            label="End"
                            placeholder="Enter End"
                            value={value}
                            onChange={onChange}
                            error={error?.message}
                            type="number"
                            w="100%"
                          />
                        );
                      }}
                      name={`priorities.${index}.end`}
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </Box>
                </Box>
              );
            })}

            <LeadCenterEmails control={control} setValue={setValue} />
          </VStack>
          <VStack w="50%" gap="0.62rem">
            <LeadDistribution control={control} setValue={setValue} />
          </VStack>
        </HStack>
        <HStack
          position="absolute"
          bottom="0"
          w="100%"
          right="0"
          background="white"
          justifyContent="flex-end"
          padding="1rem"
          borderTop="1px solid var(--grey-300)"
        >
          <Button
            size="xl"
            mt={4}
            onClick={async () => {
              const isValid = await trigger();
              if (isValid) handleFormSubmit(getValues());
            }}
          >
            Save
          </Button>
        </HStack>
      </VStack>
      {/* {priorityOpen && (
        <Drawer
          isOpen={priorityOpen}
          onClose={() => setPriorityOpen(false)}
          title="Filter & Sort"
        >
          <LeadSettingsFiltersDrawer
            selectedIndex={selectedIndex}
            control={control}
            setValue={setValue}
            reset={reset}
            onClose={() => setPriorityOpen(false)}
            applyFilters={async () => {
              const isValid = await trigger(`priorities.${selectedIndex}`);
              if (isValid) handleFormSubmit(getValues());
            }}
          />
        </Drawer>
      )} */}
    </>
  );
};

export default LeadCenterSettings;
