import {
  Button,
  DrawerFooter,
  Text,
  VStack,
  HStack,
  Box,
  Grid,
  Checkbox,
  GridItem,
  useDisclosure,
  IconButton,
} from "@chakra-ui/react";
import { useState } from "react";
import { Drawer, Input, Select } from "../../../../components/common";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createLines,
  getTwilloNumber,
} from "../../../../utils/api/line-rings.api";
import { SearchIcon } from "../../../icons/svg";
import { UserListDrawer } from "./UserListDrawer";
import { lineCountries as countries } from "../../../../utils/countries";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import Loader from "../../../common/Spinner";
import SwitchToggle from "../../../common/Switch";
import { RefreshIcon } from "../../../icons/svg";

export const CreateLine = (props: any) => {
  const { id } = useParams();
  const toast = useToast();
  const [searchState, setSearchState] = useState({
    in_region: "", // To store the selected state
    near_number: "", // To store the new/existing number
    area_code: "", // To store the area code
    contains: "", // To store the number contains
    line_type: "", // To store the selected type
  });
  const [line, setLine] = useState({
    name: "",
    connected_to: "",
    user_id: "",
    username: "",
    ringname: "",
    ring_group_id: "",
  });
  const [connectedTo, setConnectedTo] = useState("");
  const [selectedPhoneNumbers, setSelectedPhoneNumbers] = useState<any>([]);
  const { data, isFetching, refetch } = useQuery({
    queryKey: [
      "twillonumber",
      id,
      searchState.in_region,
      searchState.near_number,
      searchState.area_code,
      searchState.contains,
      searchState.line_type,
    ],
    queryFn: getTwilloNumber,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
  });
  const [disabled, setDisabled] = useState(false);
  const handleStateChange = (key: string, value: any) => {
    setSearchState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleLineChange = (key: string, value: any) => {
    setLine((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const handleCheckboxClick = (phoneNumber: any) => {
    if (selectedPhoneNumbers.includes(phoneNumber)) {
      // If already selected, remove from the list
      setSelectedPhoneNumbers((prevSelected: any) =>
        prevSelected.filter((selected: any) => selected !== phoneNumber)
      );
    } else {
      // If not selected, add to the list
      setSelectedPhoneNumbers((prevSelected: any) => [
        ...prevSelected,
        phoneNumber,
      ]);
    }
  };
  const mutation = useMutation(createLines);
  const handleSubmit = () => {
    mutation.mutate(
      {
        line: {
          name: line?.name,
          connected_to: line?.connected_to,
          line_type: searchState.line_type,
          dealership_id: id,
          location: searchState.in_region,
          user_id: line?.user_id,
          phone_numbers: selectedPhoneNumbers,
          ring_group_id: line?.ring_group_id,
        },
      },
      {
        onSuccess: () => {
          setDisabled(true);
          toast({
            description:
              "Line purchased request submitted. Lines will appear in the list in a while.",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "top",
            onCloseComplete() {
              props?.onClose();
              props?.apicall();
            },
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const radioOptions = [
    { label: "Connect to User", value: "user" },
    { label: "Connect to Ring Group", value: "ring_group" },
  ];
  const typeOptions = [
    { label: "Local", value: "local" },
    { label: "Toll Free", value: "toll_free" },
  ];
  return (
    <Drawer
      isOpen={props?.isOpen}
      onClose={props?.onClose}
      title="Purchase New Line"
    >
      <VStack w="100%" alignItems="flex-start" gap="0">
        <Text
          textStyle="h4"
          padding="1rem 1.5rem"
          background="var(--secondary-50)"
          fontWeight="600"
          w="100%"
        >
          Name and Call Routing
        </Text>
        <VStack
          w="100%"
          alignItems="flex-start"
          padding="1rem 1.5rem"
          gap="0.81rem"
        >
          <Input
            label="Name"
            type="text"
            maxW="lg"
            onChange={(e) => handleLineChange("name", e.target.value)}
            isRequired
          />
          {/* <CustomRadio
            setValue={(value: string) =>
              handleLineChange("connected_to", value)
            }
            value={line?.connected_to}
            name="Call Routing"
            options={radioOptions}
            direction="column"
            label="Call Routing"
          /> */}
          <SwitchToggle
            id="1"
            label="Connect to User"
            onChange={(check: boolean) => {
              if (check) {
                if (line?.connected_to === "ring_group") {
                  handleLineChange("connected_to", "both");
                } else {
                  handleLineChange("connected_to", "user");
                }
              } else {
                handleLineChange(
                  "connected_to",
                  line?.connected_to === "both" ? "ring_group" : ""
                );
              }
            }}
            defaultChecked={
              line?.connected_to === "user" || line?.connected_to === "both"
            }
          />
          <Button
            variant="none"
            w="100%"
            cursor="pointer"
            onClick={() => {
              setConnectedTo("user");
              onOpen();
            }}
          >
            <VStack w="100%" alignItems="flex-start">
              <Text textStyle="h6" fontWeight="500">
                {"Select User"}
                <Box as="span" color="var(--red-600)"></Box>
              </Text>
              <Text
                padding="0.75rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                w="100%"
                textStyle="h5"
              >
                {line?.username ? line?.username : "Select User"}
              </Text>
            </VStack>
          </Button>
          <SwitchToggle
            id="1"
            label="Connect to Ring Group"
            onChange={(check: boolean) => {
              if (check) {
                if (line?.connected_to === "user") {
                  handleLineChange("connected_to", "both");
                } else {
                  handleLineChange("connected_to", "ring_group");
                }
              } else {
                handleLineChange(
                  "connected_to",
                  line?.connected_to === "both" ? "user" : ""
                );
              }
            }}
            defaultChecked={
              line?.connected_to === "ring_group" ||
              line?.connected_to === "both"
            }
          />
          <Button
            variant="none"
            w="100%"
            cursor="pointer"
            onClick={() => {
              setConnectedTo("ring_group");
              onOpen();
            }}
          >
            <VStack w="100%" alignItems="flex-start">
              <Text textStyle="h6" fontWeight="500">
                Select Ring Group
              </Text>
              <Text
                padding="0.75rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                w="100%"
                textStyle="h5"
              >
                {line?.ringname ? line?.ringname : "Select Ring Group"}
              </Text>
            </VStack>
          </Button>
        </VStack>
        <Text
          textStyle="h4"
          padding="1rem 1.5rem"
          background="var(--secondary-50)"
          fontWeight="600"
          w="100%"
        >
          Search Phone Number
        </Text>
        <VStack padding="1rem 1.5rem" w="100%" gap="0.81rem">
          {/* Update state for the selected state */}
          <Select
            label="Country"
            placeholder="Select Country"
            onChange={(value) => handleStateChange("in_region", value.value)}
            options={countries}
            variant="default"
            w="100%"
            isDisabled={searchState?.line_type === "toll_free"}
            value={searchState?.in_region}
          />

          {/* Update state for the new/existing number */}
          <Input
            label="Near Number"
            type="number"
            maxW="lg"
            // placeholder="e.g. 283"
            onChange={(e) => handleStateChange("near_number", e.target.value)}
            info="It find a geographically close number within distance miles. Distance defaults to 25 miles"
          />

          {/* Update state for the area code */}
          <HStack w="100%">
            <Input
              label="Area Code"
              type="number"
              maxW="lg"
              // placeholder="e.g. 283"
              onChange={(e) => handleStateChange("area_code", e.target.value)}
            />

            {/* Update state for the number contains */}
            <Input
              label="Number Contains"
              type="number"
              maxW="lg"
              // placeholder="e.g. 283"
              onChange={(e) => handleStateChange("contains", e.target.value)}
            />
          </HStack>

          {/* Update state for the selected type */}
          <Select
            label="Select Type"
            placeholder="Select Type"
            onChange={(value) => {
              handleStateChange("line_type", value.value);
              if (value.value === "toll_free") {
                handleStateChange("in_region", "");
              }
            }}
            options={typeOptions}
            value={searchState.line_type}
            variant="default"
            w="100%"
          />

          <HStack w="100%" justifyContent="flex-end">
            <Button
              sx={{
                svg: {
                  path: {
                    stroke: "white",
                  },
                  circle: {
                    stroke: "white",
                  },
                },
              }}
              leftIcon={<SearchIcon />}
              onClick={() => refetch()}
              isDisabled={searchState.line_type === ""}
            >
              Search
            </Button>
          </HStack>
        </VStack>

        <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%">
          {data?.data?.length > 0 && (
            <HStack>
              <Text textStyle="h6" fontWeight="500">
                List of searched numbers
              </Text>
              <IconButton
                sx={{
                  svg: {
                    path: {
                      stroke: "white",
                    },
                    circle: {
                      stroke: "white",
                    },
                  },
                }}
                aria-label="Add Row"
                icon={<RefreshIcon />}
                onClick={() => refetch()}
              />{" "}
            </HStack>
          )}
          <VStack
            padding="0.75rem"
            background="var(--grey-50)"
            w="100%"
            borderRadius="0.5rem"
            alignItems="flex-start"
            gap="1rem"
          >
            {data?.data?.length > 0 && (
              <Checkbox
                variant="whiteCheck"
                flex="1"
                onChange={() => {
                  // Handle "Select All" checkbox click
                  const allPhoneNumbers = data?.data?.map(
                    (item: any) => item?.phone_number
                  );
                  // Toggle selection based on whether all items are already selected
                  setSelectedPhoneNumbers(
                    selectedPhoneNumbers.length === data?.data?.length
                      ? []
                      : allPhoneNumbers
                  );
                }}
                isChecked={
                  selectedPhoneNumbers.length === data?.data?.length &&
                  selectedPhoneNumbers.length !== 0
                }
              >
                Select All
              </Checkbox>
            )}
            <Grid
              templateColumns="repeat(3, 1fr)"
              gap="1rem"
              alignItems="flex-start"
              w="100%"
            >
              {data?.data?.map((item: any) => (
                <GridItem colSpan={1}>
                  <Checkbox
                    variant="whiteCheck"
                    flex="1"
                    isChecked={selectedPhoneNumbers.includes(
                      item?.phone_number
                    )}
                    onChange={() => handleCheckboxClick(item?.phone_number)}
                  >
                    <VStack gap="0.13" alignItems="flex-start">
                      <Text textStyle="h6">{item?.phone_number}</Text>
                      <Text
                        textStyle="captionSmall"
                        color="var(--grey-700"
                        fontWeight="500"
                      >
                        {item?.region}
                      </Text>
                    </VStack>
                  </Checkbox>
                </GridItem>
              ))}
              {data?.data?.length === 0 && (
                <VStack gap="0.13" alignItems="flex-start">
                  <Text textStyle="h6">No results found</Text>
                </VStack>
              )}
            </Grid>
          </VStack>
        </VStack>
      </VStack>

      {isOpen && (
        <UserListDrawer
          isOpen={isOpen}
          onClose={onClose}
          id={id}
          onSelect={(val: any, name: string) => {
            if (connectedTo === "ring_group") {
              handleLineChange("ring_group_id", val);
              handleLineChange("ringname", name);
            } else {
              handleLineChange("user_id", val);
              handleLineChange("username", name);
            }
            onClose();
          }}
          user_id={line.user_id}
          type={connectedTo === "ring_group" ? "ring" : "user"}
        />
      )}

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        {/* <Button variant="outline" w="100%" size="xl">
          Cancel
        </Button> */}
        <Button
          w="100%"
          size="xl"
          onClick={handleSubmit}
          isDisabled={
            disabled ||
            line?.name === "" ||
            line?.connected_to === "" ||
            searchState.line_type === "" ||
            (line?.user_id === "" && line?.ring_group_id === "") ||
            selectedPhoneNumbers.length === 0
          }
        >
          Activate Line
        </Button>
      </DrawerFooter>
      {(mutation?.isLoading || isFetching) && <Loader />}
    </Drawer>
  );
};
