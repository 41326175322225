import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { Drawer, Input, Select } from "../../../components/common";
import DatePicker from "../../../components/common/DateRangePicker";
import { CustomSelect } from "../../../theme/components";
import { isValidIP } from "../../../utils";
import {
  fetchActions,
  fetchCountries,
  fetchMenus,
  fetchStates,
  fetchSubMenus,
} from "../../../utils/api/activity-log";
import { loadDealershipsApi, loadUsersApi } from "../../../utils/api/users.api";

export default function AdvanceFilters(props: any) {
  const {
    isOpen,
    onClose,
    refetchParent,
    firstIp,
    setFirstIp,
    lastIp,
    setLastIp,
    dealerships,
    setDealerships,
    users,
    setUsers,
    selectionRange,
    setSelectionRange,
    setSelectedMenus,
    setSelectedSubmenus,
    setSelectedActions,
    selectedActions,
    selectedMenus,
    selectedSubmenus,
    selectedState,
    selectedCountry,
    setSelectedState,
    setSelectedCountry,
    setReset,
    isBdc = false,
  } = props;

  const [menus, setMenus] = useState<SelectOptionProps[]>([]);
  const [submenus, setSubmenus] = useState<SelectOptionProps[]>([]);
  const [actions, setActions] = useState<SelectOptionProps[]>([]);
  const [countries, setCountries] = useState<SelectOptionProps[]>([]);
  const [states, setStates] = useState<SelectOptionProps[]>([]);

  const [firstIpEerror, setfirstIpError] = useState("");
  const [lastIpEerror, setLastIpError] = useState("");

  function ipToNumber(ip: string): number {
    const parts = ip.split(".");
    return parts.reduce(
      (acc, part, i) => acc + parseInt(part, 10) * Math.pow(256, 3 - i),
      0
    );
  }

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    let hasErrors = false;

    if (!firstIp && lastIp) {
      setfirstIpError("Please enter the First IP.");
      hasErrors = true;
    }
    if (!lastIp && firstIp) {
      setLastIpError("Please enter the Last IP.");
      hasErrors = true;
    }
    if (lastIp && firstIp) {
      if (!isValidIP(firstIp)) {
        setfirstIpError("Please enter a valid First IP.");
        hasErrors = true;
      }
      if (!isValidIP(lastIp)) {
        setLastIpError("Please enter a valid Last IP.");
        hasErrors = true;
      }

      if (isValidIP(firstIp) && isValidIP(lastIp)) {
        const firstIpNum = ipToNumber(firstIp);
        const lastIpNum = ipToNumber(lastIp);
        if (firstIpNum > lastIpNum) {
          setfirstIpError("First IP must be less than Last IP.");
          hasErrors = true;
        }
      }
    }

    if (hasErrors) return;

    refetchParent();
    onClose();
  };

  const resetFields = () => {
    setFirstIp("");
    setLastIp("");
    setUsers([]);
    setDealerships([]);
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      selected: false,
    });
    setSelectedMenus([]);
    setSelectedSubmenus([]);
    setSelectedActions([]);
    setSelectedCountry(null);
    setSelectedState(null);
  };

  const handleReset = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    resetFields();
    setReset(true);
    onClose();
  };

  const loadDealerships = async (inputValue: string) => {
    try {
      const response = await loadDealershipsApi({
        status: "active",
        searchValue: inputValue,
      });
      const options = response?.dealerships?.map((dealership: any) => ({
        value: dealership.id.toString(),
        label: dealership.name,
      }));
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  const loadSimpSocialActiveUsers = async (inputValue: string) => {
    try {
      const response = await loadUsersApi({
        role_titles: "SimpSocial Owner,SimpSocial Admin,Customer Service Agent",
        status: "active",
        q: inputValue,
      });
      const options = response?.users?.map((user: any) => {
        return {
          value: user.full_name,
          label: user.full_name,
        };
      });
      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };

  //................menu,submenu,action apis..........

  const { data: menuData, isLoading: isLoadingMenus } = useQuery({
    queryKey: ["fetchMenus", isBdc],
    queryFn: fetchMenus,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setMenus(
        data?.map((item: any) => {
          return { label: item["menu"], value: item["menu"] };
        })
      );
    },
  });
  const { data: submenuData, isLoading: isLoadingSubmenus } = useQuery({
    queryKey: ["fetchSubMenus", isBdc],
    queryFn: fetchSubMenus,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setSubmenus(
        data?.map((item: any) => {
          return { label: item["sub_menu"], value: item["sub_menu"] };
        })
      );
    },
  });
  const predefinedActionList = [
    {
      label: "Create",
      value: "Create",
    },
    {
      label: "Update",
      value: "Update",
    },
    {
      label: "Delete",
      value: "Delete",
    },
  ];
  const { isLoading: isLoadingActions } = useQuery({
    queryKey: ["fetchActions", isBdc],
    queryFn: fetchActions,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      const fetchedActions = data
        ?.map((item: any) => ({ label: item, value: item }))
        .filter((action: any) => action.value !== "");
      setActions([...predefinedActionList, ...fetchedActions]);
    },
  });
  const { isLoading: isLoadingCountries } = useQuery({
    queryKey: ["fetchCountries"],
    queryFn: fetchCountries,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCountries(
        data?.map((item: any) => {
          return { label: item["country"], value: item["country"] };
        })
      );
    },
  });

  const { isLoading: isLoadingStates } = useQuery({
    queryKey: ["fetchStates", selectedCountry?.value],
    queryFn: fetchStates,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setStates(
        data?.map((item: any) => {
          return { label: item["state"], value: item["state"] };
        })
      );
    },
  });
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Advance Filters"
      isLoading={
        isLoadingActions ||
        isLoadingMenus ||
        isLoadingSubmenus ||
        isLoadingCountries ||
        isLoadingStates
      }
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <HStack w="100%" alignItems="flex-start">
          <Input
            placeholder="From IP"
            label="From IP"
            onChange={(e: any) => {
              setfirstIpError("");
              setFirstIp(e.target.value);
            }}
            value={firstIp}
            error={firstIpEerror}
          />
          <Input
            placeholder="To IP"
            label="To IP"
            onChange={(e: any) => {
              setLastIpError("");
              setLastIp(e.target.value);
            }}
            value={lastIp}
            error={lastIpEerror}
          />
        </HStack>
        <Box maxW="100%" minW="auto" w="100%" height="auto">
          <Text
            textStyle="h4"
            fontWeight="600"
            fontSize="0.875rem"
            color="var(--grey-900)"
          >
            Select Users
          </Text>
          <Box position="relative" zIndex={99}>
            <AsyncSelect
              styles={{ ...CustomSelect?.styles?.customSelect?.default }}
              loadOptions={(inputValue) =>
                loadSimpSocialActiveUsers(inputValue)
              }
              onChange={(value: any) => setUsers(value)}
              placeholder="Enter the users"
              isMulti={true}
              value={users}
              defaultOptions
            />
          </Box>
        </Box>
        <Box maxW="100%" minW="auto" w="100%" height="auto">
          <Text
            textStyle="h4"
            fontWeight="600"
            fontSize="0.875rem"
            color="var(--grey-900)"
          >
            Select Dealerships
          </Text>
          <AsyncSelect
            styles={{ ...CustomSelect?.styles?.customSelect?.default }}
            loadOptions={(inputValue) => loadDealerships(inputValue)}
            onChange={(value: any) => setDealerships(value)}
            placeholder="Enter the dealerships"
            isMulti={true}
            value={dealerships}
            defaultOptions
          />
        </Box>
        <Box w="100%">
          <Text
            color="var(--grey-900)"
            fontSize="0.875rem"
            font-weight="600"
            lineHeight="1.25rem"
            mr={1}
            style={{ marginBottom: "0.31rem" }}
            w="100%"
          >
            Select Date Range
          </Text>
          <DatePicker
            value={selectionRange}
            onChange={(value) => setSelectionRange(value)}
            w="100%"
          />
        </Box>

        <Box w="100%" position="relative" zIndex={5}>
          <Select
            label="Menu"
            onChange={(value: any) => setSelectedMenus(value)}
            placeholder="Select Menu"
            options={menus}
            variant="default"
            isMulti={true}
            w="100%"
            value={selectedMenus}
          />
        </Box>

        <Select
          label="Sub-Menu"
          onChange={(value: any) => setSelectedSubmenus(value)}
          placeholder="Select Sub-Menu"
          options={submenus}
          variant="default"
          isMulti={true}
          w="100%"
          value={selectedSubmenus}
        />

        <Select
          label="Action"
          onChange={(value: any) => setSelectedActions(value)}
          placeholder="Select Action"
          options={actions}
          variant="default"
          isMulti={true}
          value={selectedActions}
          w="100%"
        />
        <Select
          label="Country"
          onChange={(value: any) => setSelectedCountry(value)}
          placeholder="Select Country"
          options={countries}
          variant="default"
          w="100%"
          value={selectedCountry?.value}
        />
        {selectedCountry &&
          (selectedCountry?.value === "United States of America" ||
            selectedCountry?.value === "Canada") && (
            <Select
              label="State"
              onChange={(value: any) => setSelectedState(value)}
              placeholder="Select State"
              options={states}
              variant="default"
              w="100%"
              value={selectedState?.value}
            />
          )}
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={handleReset}>
          Reset Filters
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Apply filters
        </Button>
      </DrawerFooter>
    </Drawer>
  );
}
