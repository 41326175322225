import { Button, DrawerFooter, Text, useToast, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  deleteKeyword,
  deleteKeywordDealership,
} from "../../../utils/api/global-config.api";
import { Drawer } from "../../common";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};

const DeleteKeyword = (props: data) => {
  const { isOpen, onClose, refetchParent, id } = props;
  const toast = useToast();
  const { id: dId } = useParams();
  const mutation = useMutation(deleteKeyword);
  const mutationDealership = useMutation(deleteKeywordDealership);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const mutationFunc = dId ? mutationDealership : mutation;

    mutationFunc.mutate(
      { id, dId },
      {
        onSuccess: () => {
          refetchParent();
          onClose();
          toast({
            description: "Keyword deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error deleting keyword: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Delete Keyword"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to delete the keyword?
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default DeleteKeyword;
