import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  Dropdown,
  Input,
  PaginatedTable,
  Spinner,
} from "../../../components/common";
import TooltipIfOverflow from "../../../components/common/TooltipIfOverflow";
import FilterIcon from "../../../components/icons/Filter";
import { ExcelIcon, PDFIcon, SearchIcon } from "../../../components/icons/svg";
import { convertDate, exportToExcel, exportToPDF } from "../../../utils";
import { getActivityLog } from "../../../utils/api/activity-log";
import AdvanceFilters from "./AdvanceFilters";
interface ActivityLogPageProps {
  isBdc?: boolean;
  firstIp: string;
  setFirstIp: (value: string) => void;
  lastIp: string;
  setLastIp: (value: string) => void;
  dealerships: SelectOptionProps[];
  setDealerships: (value: SelectOptionProps[]) => void;
  users: SelectOptionProps[];
  setUsers: (value: SelectOptionProps[]) => void;
  selectionRange: {
    startDate: Date;
    endDate: Date;
    key: string;
    selected: boolean;
  };
  setSelectionRange: (value: {
    startDate: Date;
    endDate: Date;
    key: string;
    selected: boolean;
  }) => void;
  selectedMenus: SelectOptionProps[];
  setSelectedMenus: (value: SelectOptionProps[]) => void;
  selectedSubmenus: SelectOptionProps[];
  setSelectedSubmenus: (value: SelectOptionProps[]) => void;
  selectedActions: SelectOptionProps[];
  setSelectedActions: (value: SelectOptionProps[]) => void;
  selectedCountry?: SelectOptionProps | null;
  setSelectedCountry: (value: SelectOptionProps | null) => void;
  selectedState?: SelectOptionProps | null;
  setSelectedState: (value: SelectOptionProps | null) => void;
}

export default function ActivityLogPage({
  isBdc = false,
  firstIp,
  setFirstIp,
  lastIp,
  setLastIp,
  dealerships,
  setDealerships,
  users,
  setUsers,
  selectionRange,
  setSelectionRange,
  selectedMenus,
  setSelectedMenus,
  selectedSubmenus,
  setSelectedSubmenus,
  selectedActions,
  setSelectedActions,
  selectedCountry,
  setSelectedCountry,
  selectedState,
  setSelectedState,
}: ActivityLogPageProps) {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const toast = useToast();

  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [reset, setReset] = useState(false);
  const [exportLoader, setExportLoader] = useState(false);
  const ExportOptions: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <ExcelIcon />
          Excel
        </Box>
      ),
      value: "excel",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];

  const tableHeader = [
    { id: 4, value: "Date & Time (EST)", label: "dateTime" },
    { id: 3, value: "Dealership", label: "dealership" },
    { id: 2, value: "User Name", label: "userName" },
    { id: 5, value: "Menu", label: "menu" },
    { id: 5, value: "Sub-Menu", label: "submenu" },
    { id: 5, value: "Action", label: "action" },
    // { id: 5, value: "Country", label: "country" },
    // { id: 5, value: "State", label: "state" },
    { id: 6, value: "Activity Description ", label: "activityDescription" },
    { id: 1, value: "IP Address", label: "ipAddress" },
  ];
  const { data, isFetching, refetch } = useQuery({
    queryKey: ["getActivityLog", page, perPage, searchValue, isBdc],
    queryFn: () => {
      let params: any = {
        ...(isBdc ? { isBdc } : {}),
      };
      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.search = searchValue;
      }
      params = getParams(params);

      return getActivityLog(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: any) {
      setReset(false);
      setCount(data?.versions_count);
    },
  });

  const getParams = (params: any) => {
    if (firstIp) params.first_ip = firstIp;
    if (lastIp) params.last_ip = lastIp;

    if (users.length > 0)
      params.user_names = users.map((user: any) => user.label);

    if (dealerships.length > 0)
      params.dealership_names = dealerships.map(
        (dealership: any) => dealership.label
      );

    if (selectionRange && selectionRange?.selected) {
      if (selectionRange?.endDate)
        params.end_date = selectionRange?.endDate.toString();
      if (selectionRange?.startDate)
        params.start_date = selectionRange?.startDate.toString();
    }

    if (selectedMenus.length > 0)
      params.menus = selectedMenus.map((item: any) => item.label);

    if (selectedSubmenus.length > 0)
      params.sub_menus = selectedSubmenus.map((item: any) => item.label);

    if (selectedActions.length > 0)
      params.action_names = selectedActions.map((item: any) => item.label);

    if (selectedCountry) params.country = selectedCountry?.value;
    if (selectedCountry?.value === "United States of America" && selectedState)
      params.state = selectedState?.value;

    return params;
  };

  useEffect(() => {
    if (reset) {
      refetch();
    }
  }, [reset]);

  const showToast = (description: any, status: any) => {
    toast({
      description,
      status,
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };

  const handleExport = async (format: string) => {
    let params: any = {};
    params = getParams(params);
    setExportLoader(true);
    const totalData = await getActivityLog(params);
    const formattedData = totalData?.versions?.map((item: any) => ({
      "Date & Time (EST)": item.created_at || "-",
      Dealership: item?.dealership_object?.name || "-",
      "User Name": item?.user_name || "-",
      Menu: item.menu || "-",
      "Sub-Menu": item.sub_menu || "-",
      Action: item.action_name || "-",
      Country: item.country || "-",
      State:
        item.country === "United States of America"
          ? item.state?.split("-")[1] || "-"
          : "-",
      "Activity Description": item?.description,
      "IP Address": item.ip_address || "-",
    }));

    if (format === "pdf") {
      const status = exportToPDF(formattedData, "Activity Log");
      showToast(
        status ? "Exporting PDF" : "Error exporting PDF",
        status ? "success" : "error"
      );
    } else if (format === "excel") {
      const status = exportToExcel(formattedData, "Activity Log");
      showToast(
        status ? "Exporting Excel FIle" : "Error exporting Excel FIle",
        status ? "success" : "error"
      );
    }
    setExportLoader(false);
  };

  const descriptionComponent = (descriptionArray: string[]) => {
    return (
      <HStack gap="1rem">
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="800">
            <TooltipIfOverflow char={100}>
              {descriptionArray[0]}
            </TooltipIfOverflow>
          </Text>
          {descriptionArray.slice(1).map((desc: any, index: any) => (
            <Text key={index} textStyle="h6" fontWeight="500">
              <TooltipIfOverflow char={100}>{`• ${desc}`}</TooltipIfOverflow>
            </Text>
          ))}
        </VStack>
      </HStack>
    );
  };

  const createDateTimeField = (dateArray: string[]) => {
    return (
      <HStack gap="1rem">
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[0] ?? "-"}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {dateArray?.[1] ?? null}
          </Text>
        </VStack>
      </HStack>
    );
  };
  const tableData = data?.versions?.map((item: any) => {
    let description;
    if (item?.description && item.description.includes(",")) {
      description = descriptionComponent(item?.description?.split(","));
    } else {
      description = item?.description || "-";
    }

    return {
      dateTime: createDateTimeField(
        convertDate(item?.created_at?.replace("Z", ""))
      ),
      dealership: item?.dealership_name || "-",
      userName: item?.user_name || "-",
      menu: item?.menu || "-",
      submenu: item?.sub_menu || "-",
      action: item?.action_name || "-",
      activityType: item?.item_type || "-",
      activityDescription: description,
      ipAddress: item.ip_address || "-",
      country: item.country || "-",
      state:
        item.country === "United States of America"
          ? item.state?.split("-")[1] || "-"
          : "-",
    };
  });

  return (
    <>
      <VStack w="100%" alignItems="flex-start">
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
          borderRadius="0.5rem"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search Logs..."
              value={searchValue}
              onChange={(e: any) => {
                setSearchValue(e.target.value);
              }}
            />
            <HStack borderRadius="0.5rem">
              <Button
                onClick={onOpen}
                color="var(--grey-900)"
                variant="outline"
                leftIcon={
                  <FilterIcon
                    style={{
                      color: "var(--grey-900)",
                      fontSize: "1.25rem",
                    }}
                  />
                }
              >
                Advance Filters
              </Button>
            </HStack>
          </HStack>
          <VStack position="relative" zIndex="3">
            <Dropdown
              options={ExportOptions}
              hasText={true}
              text="Export"
              onSelect={(value: any) => handleExport(value.value)}
            />
          </VStack>
        </HStack>
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          tableHeight={350}
          perPageChange={(value) => {
            setPage(1);
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
          isLoadingData={isFetching}
        />
      </VStack>

      {isOpen && (
        <AdvanceFilters
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
          firstIp={firstIp}
          lastIp={lastIp}
          setFirstIp={setFirstIp}
          setLastIp={setLastIp}
          dealerships={dealerships}
          setDealerships={setDealerships}
          users={users}
          setUsers={setUsers}
          selectionRange={selectionRange}
          setSelectionRange={setSelectionRange}
          selectedActions={selectedActions}
          selectedMenus={selectedMenus}
          selectedSubmenus={selectedSubmenus}
          setSelectedMenus={setSelectedMenus}
          setSelectedSubmenus={setSelectedSubmenus}
          setSelectedActions={setSelectedActions}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          setReset={setReset}
          isBdc={isBdc}
        ></AdvanceFilters>
      )}

      {exportLoader && <Spinner />}
    </>
  );
}
