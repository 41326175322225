import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getIPSettings = async ({queryKey}:any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/users/${queryKey[1]}/user_ip_settings`
  );
export const updateIPSettings = async (params: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/admin/users/${params.userID}/update_user_ip_settings`,
    params.payload
  );
