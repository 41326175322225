import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  data: any;
  headers: any;
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//-----Dealership profile settings apis
export const getDealershipData = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}`);
export const editDealershipData = async (payload: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}`,
      payload.data,
      {
        headers: {
          "Content-Type": "multipart/form-data", // Set the correct content type for file upload
          menu: "Dealerships",
          "Sub-Menu": payload.sub_menu,
          Dealership: payload.id,
        },
      }
    );

    // Assuming the response contains the updated data and image URL
    return response.data;
  } catch (error) {
    // Handle errors
    throw error; // Propagate the error to the caller
  }
};
export const getsettingsApi = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${queryKey[1]}/setting?key=${queryKey[2]}`
  );
export const getFeedUrlApi = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/admin/dealerships/${queryKey[1]}/inventory_feed_urls`
  );
export const getCarPreview = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/inventories?${queryKey[4]}=true&page=${queryKey[2]}&per_page=${queryKey[3]}&media_type=${queryKey[5]}`
  );
export const setasDefaultImage = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/inventories/${payload?.invID}/set_default_image/${payload?.type}`,
    payload.data
  );
export const updatesettingApi = async (payload: any): Promise<ApiResult> =>
  axios.patch(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${payload.id}/update_setting`,
    payload.data,
    {
      headers: {
        menu: "Dealerships",
        "Sub-Menu": payload.sub_menu,
        Dealership: payload.id,
      },
    }
  );
export const getDealershipCrm = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/dealerships/crm`);
export const getDealershipDms = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/dealerships/dms`);
export const getDealershipVinSolution = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/vs/get-dealers`
  );
export const getDealershipVinSolutionUsers = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/vs/get-dealer-${queryKey[2]}-users`
  );
export const getInventoryProvider = async (): Promise<ApiResult> =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/inventory_feed_providers`);
export const sendInventorymsg = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload.id}/send_email_to_inventory_feed_provider`,
    payload.payload,
    {
      headers: {
        menu: "Dealership",
        "Sub-Menu": "Account Settings/Dealer Profile/Integration",
      },
    }
  );
export const getDealershipEmails = async ({ queryKey }: any): Promise<any> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/search?query=${queryKey[2]}`
  );

//in the key we provide the menu of dealership settings
export const fetchSettings = async (dId: any, key: string) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/settings/dealership/${dId}/setting?key=${key}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const disableOpenPhone = async ({ queryKey }: any) =>
  await axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/within_working_hours`
  );

//in the payload we provide the key which decides which setting to update
export const updateSettings = async (payload: any, dId: any, sub_menu: any) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/settings/dealership/${dId}/update_setting`,
      payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": sub_menu,
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateBillingInfo = async (params: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/usage_billing_infos/create_or_update`,
      params.payload,
      {
        headers: {
          menu: "Dealerships",
          "Sub-Menu": params.sub_menu,
          Dealership: params.dealershipId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchBillingInfo = async (dId: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/dealerships/${dId}/usage_billing_infos`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
