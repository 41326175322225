import {
  Box,
  Button,
  HStack,
  Spinner,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../../components/Charts/Popup";
import { Dropdown, Input, PaginatedTable } from "../../../components/common";
import { SearchIcon } from "../../../components/icons/svg";
import {
  deleteAnnouncement,
  deleteSmsAnnouncement,
  fetchAnnouncement,
  fetchSmsAnnouncement,
  pauseAnnouncement,
  pauseSmsAnnouncement,
  resumeAnnouncement,
  resumeSmsAnnouncement,
} from "../../../utils/api/announcements";

const MainListing = ({ type }: any) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [searchValue, setSearchValue] = useState<string>("");
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [clickedRow, setClickedRow] = useState<any>(null);
  const [isOpen, setIsOpen] = useState(false);

  const mutation = useMutation(deleteAnnouncement);
  const mutationSms = useMutation(deleteSmsAnnouncement);
  const mutationPause = useMutation(pauseAnnouncement);
  const mutationPauseSms = useMutation(pauseSmsAnnouncement);
  const mutationResume = useMutation(resumeAnnouncement);
  const mutationResumeSms = useMutation(resumeSmsAnnouncement);

  const errorToast = (error: any) => {
    toast({
      description: error ?? "Something went wrong",
      status: "error",
      duration: 5000,
      isClosable: true,
      position: "top",
    });
  };
  const successToast = (msg: any) => {
    toast({
      description: msg,
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };
  const {
    data: announcements,
    isFetching: isLoadingAnnouncements,
    refetch,
  } = useQuery({
    queryKey: [
      "fetchAnnouncement",
      {
        page,
        per_page: perPage,
        search: searchValue,
      },
    ],
    queryFn: type ? fetchSmsAnnouncement : fetchAnnouncement,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const tableHeader = [
    {
      value: type ? "Message" : "Subject",
      label: type ? "message" : "subject",
    },
    { value: "Dealerships", label: "dealerships" },
    { value: "Status", label: "status" },
    { value: "Recipients", label: "recipients" },
    { value: "Sent Date", label: "sentDate" },
    { value: "Actions", label: "actions" },
  ];
  const options: Record<string, SelectOptionProps[]> = {
    pending: [
      { label: "Edit", value: "edit" },
      { label: "Delete", value: "delete" },
    ],
    in_progress: [
      { label: "Pause", value: "pause" },
      { label: "Communication Logs", value: "logs" },
    ],
    paused: [
      { label: "Resume", value: "resume" },
      { label: "Communication Logs", value: "logs" },
    ],
    default: [{ label: "Communication Logs", value: "logs" }],
  };

  const getOptions = (status: string) => options[status] || options.default;

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  const navigateToLogs = (id: any) =>
    type
      ? navigate(`/announcements/communication/${id}?type=sms`)
      : navigate(`/announcements/communication/${id}`);

  useEffect(() => {
    if (activeModal === "edit") {
      navigate(
        type
          ? `/announcements/compose?id=${clickedRow?.id}&&type=sms`
          : `/announcements/compose?id=${clickedRow?.id}`
      );
    } else if (
      activeModal === "delete" ||
      activeModal === "pause" ||
      activeModal === "resume"
    ) {
      setIsOpen(true);
    } else if (activeModal === "logs") {
      navigateToLogs(clickedRow?.id);
    }
  }, [activeModal]);

  const bubbleColorsMap: Record<string, string> = {
    pending: "orange",
    rejected: "red",
    in_progress: "grey",
    paused: "#b902d3",
    completed: "var(--secondary-600)",
    default: "black",
  };

  const getBubbleColors = (status: string) =>
    bubbleColorsMap[status] || bubbleColorsMap.default;

  const getTableObj = (item: any) => ({
    id: item?.id,
    subject: item?.subject,
    message: item?.content,
    dealerships: (
      <Text
        cursor={"pointer"}
        color={"blue"}
        onClick={() => navigateToLogs(item?.id)}
      >
        {item?.dealership_count}
      </Text>
    ),
    status: (
      <Box
        w="6.6rem"
        textAlign="center"
        borderRadius="2.5rem"
        padding="0.25rem 0.62rem"
        border="1px solid"
        borderColor={getBubbleColors(type ? item?.status : item?.job_status)}
      >
        <Text
          textTransform="capitalize"
          color={getBubbleColors(type ? item?.status : item?.job_status)}
        >
          {type
            ? item?.status?.replace(/_/g, " ")
            : item?.job_status?.replace(/_/g, " ")}
        </Text>
      </Box>
    ),

    recipients: (
      <Text
        cursor={"pointer"}
        color={"blue"}
        onClick={() => navigateToLogs(item?.id)}
      >
        {item?.contacts_count || 0} Users
      </Text>
    ),
    sentDate: moment(item?.created_at).format("MMM DD, YYYY "),
    actions: (
      <Dropdown
        options={getOptions(type ? item.status : item.job_status)}
        onSelect={handleSelect}
      />
    ),
  });

  const tableData = type
    ? announcements?.data?.messages?.map((item: any) => getTableObj(item))
    : announcements?.data?.emails?.map((item: any) => getTableObj(item));

  const rowCount = type
    ? announcements?.data?.messages_count
    : announcements?.data?.emails_count;

  const handleSubmit = () => {
    if (activeModal === "delete") {
      handleDelete();
    } else if (activeModal === "resume") {
      handleResume();
    } else if (activeModal === "pause") {
      handlePause();
    }
  };
  const handleDelete = () => {
    const mutationFunc = type ? mutationSms : mutation;
    mutationFunc.mutate(
      {
        id: clickedRow?.id,
        subMenu: type ? "SMS" : "Email",
      },
      {
        onSuccess: (data) => {
          refetch();
          onClose();
          successToast("Announcement deleted successfully.");
        },
        onError: (error: any) => {
          errorToast(error?.response?.data?.errors?.toString());
        },
      }
    );
  };
  const handlePause = () => {
    const mutationPauseFunc = type ? mutationPauseSms : mutationPause;
    mutationPauseFunc.mutate(
      {
        id: clickedRow?.id,
        payload: {
          schedule_date: new Date(),
          indefinite_period: true,
        },
        subMenu: type ? "SMS" : "Email",
      },
      {
        onSuccess: (data) => {
          refetch();
          onClose();
          successToast("Announcement paused successfully.");
        },
        onError: (error: any) => {
          errorToast(error?.response?.data?.errors?.toString());
        },
      }
    );
  };
  const handleResume = () => {
    const mutationResumeFunc = type ? mutationResumeSms : mutationResume;
    mutationResumeFunc.mutate(
      {
        id: clickedRow?.id,
        subMenu: type ? "SMS" : "Email",
      },
      {
        onSuccess: (data) => {
          refetch();
          onClose();
          successToast("Announcement resumed successfully.");
        },
        onError: (error: any) => {
          errorToast(error?.response?.data?.errors?.toString());
        },
      }
    );
  };
  const onClose = () => {
    setIsOpen(false);
    setActiveModal("");
  };

  return (
    <VStack
      padding="1.5rem"
      w="100%"
      alignItems="flex-start"
      borderRadius="0.5rem"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="0rem 1.5rem"
        background="white"
        borderRadius="0.5rem"
      >
        <Input
          maxW="20rem"
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Search..."
          value={searchValue}
          onChange={(e: any) => {
            setSearchValue(e.target.value);
          }}
        />
        <Box>
          <Button
            border="1px solid var(--grey-300)"
            justifyContent="space-between"
            width="100%"
            fontWeight="500"
            onClick={() =>
              navigate(
                type
                  ? `/announcements/compose?type=sms`
                  : `/announcements/compose`
              )
            }
          >
            Compose Announcement
          </Button>
        </Box>
      </HStack>
      <VStack w="100%" alignItems="flex-start">
        <Text
          textStyle="caption"
          padding="1.5rem 1rem 0.75rem"
          w="100%"
          color="var(--grey-600)"
        >
          Total {type ? "SMS" : "Emails"} (
          {type
            ? announcements?.data?.messages_count
            : announcements?.data?.emails_count}
          )
        </Text>
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          tableHeight={350}
          perPageChange={(value) => {
            setPage(1);
            setPerPage(Number(value?.label));
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={rowCount}
          isLoadingData={isLoadingAnnouncements}
          rowOnClick={(rowItem) => {
            setClickedRow(rowItem);
          }}
        />
      </VStack>
      {isOpen && (
        <Popup
          isOpen={true}
          onClose={onClose}
          onConfirm={handleSubmit}
          title="Confirmation Popup"
          message={`Are you sure you want to ${activeModal} this announcement?`}
        />
      )}
      {(mutation?.isLoading ||
        mutationSms?.isLoading ||
        mutationPause?.isLoading ||
        mutationPauseSms?.isLoading ||
        mutationResume?.isLoading ||
        mutationResumeSms?.isLoading) && <Spinner />}
    </VStack>
  );
};

export default MainListing;
