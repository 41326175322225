import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getActivityLog = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/latest_versions`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchMenus = async ({ queryKey }: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/menus?isBdc=${queryKey[1]}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchSubMenus = async ({ queryKey }: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/sub_menus?isBdc=${queryKey[1]}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchActions = async ({ queryKey }: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/action_names?isBdc=${queryKey[1]}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchCountries = async () => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/countries`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const fetchStates = async ({ queryKey }: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/v1/activity_logs/states?country=${queryKey[1]}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
