import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchEmailById } from "../../../../../utils/api/contact-details";
import { getHistoryCount } from "../../../../../utils/api/history.api";
import {
  useHistoryDispatch,
  useHistoryState,
} from "../../../../../utils/context/HistoryContext";
import { useTwilioDispatch } from "../../../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import AllActivity from "./AllActivity";
import Appointment from "./Appointment";
import Calls from "./Calls";
import CallDetail from "./Calls/CallDetail";
import Deals from "./Deals";
import Emails from "./Emails";
import EmailDetail from "./Emails/EmailDetail";
import Meeting from "./Meeting";
import Messages from "./Messages";
import MessageThread from "./Messages/MessageThread";
import RVM from "./RVM";
import RVMDetail from "./RVM/RVMDetail";

type HistoryProps = {
  clickedRow: any;
  activeIndexHistory: Number;
  messageThreadProps: any;
  setClickedRecord: Dispatch<any>;
  clickedRecord: any;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
};

const History = ({
  clickedRow,
  activeIndexHistory,
  messageThreadProps,
  setClickedRecord,
  clickedRecord,
  activeIndex,
  setActiveIndex,
}: HistoryProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeKey = location?.state?.activeKey;
  const dispatch = useTwilioDispatch();

  const { id: contactId } = clickedRow || {};

  const dealer = useUserInfo("dealership");

  const { shouldRefetch } = useHistoryState();
  const historyDispatch = useHistoryDispatch();

  const { ref, setMessageThread, setInitialLoad, setMessageBody } =
    messageThreadProps || {};

  useEffect(() => {
    return () => {
      const { current }: { current: any } = ref;
      current?.remove();
      ref.current = null;
      setMessageThread({});
      setInitialLoad(false);
      setMessageBody("");
      setActiveIndex(0);
      setClickedRecord(null);
    };
  }, [
    ref,
    setMessageThread,
    setInitialLoad,
    setMessageBody,
    setActiveIndex,
    setClickedRecord,
  ]);

  const { data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail", dealer?.id],
    queryFn: () => fetchEmailById(dealer?.id),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: clickedRow?.id !== undefined,
  });
  const { data: historyCount, refetch } = useQuery({
    queryKey: ["fetchCount", dealer?.id, clickedRow?.id],
    queryFn: getHistoryCount,
    refetchOnWindowFocus: false,
    enabled: clickedRow?.id !== undefined,
    retry: false,
  });

  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};

  useEffect(() => {
    if (shouldRefetch) {
      refetch();
    }

    historyDispatch({
      type: "SET_SHOULD_REFETCH",
      payload: { shouldRefetch: false },
    });
  }, [historyDispatch, refetch, shouldRefetch]);

  useEffect(() => {
    if (activeKey && activeIndex === 3) {
      setClickedRecord({ ...clickedRow, record_type: "email_log" });
    }
  }, [clickedRow, activeIndex]);

  useEffect(() => {
    if (contactId) setClickedRecord(null);
  }, [contactId]);

  const detailView = [
    {
      name: "call",
      content: (
        <CallDetail
          clickedRecord={clickedRecord}
          setClickedRecord={setClickedRecord}
        />
      ),
    },
    {
      name: "rvm",
      content: (
        <RVMDetail
          clickedRecord={clickedRecord}
          setClickedRecord={setClickedRecord}
        />
      ),
    },
    {
      name: "email_log",
      content: (
        <EmailDetail
          clickedRecord={clickedRecord}
          setClickedRecord={setClickedRecord}
        />
      ),
    },
    {
      name: "message",
      content: (
        <MessageThread
          clickedRecord={clickedRecord}
          setClickedRecord={setClickedRecord}
          clickedRow={clickedRow}
          type="message"
          messageThreadProps={messageThreadProps}
        />
      ),
    },
    {
      name: "pre-sale",
      content: <></>,
    },
  ];

  useEffect(() => {
    setActiveIndex(Number(activeKey || activeIndexHistory));
  }, [activeKey]);
  return (
    <Box w="100%" background="var(--grey-50)">
      <Tabs
        variant="primary"
        w="100%"
        onChange={(index) => {
          setClickedRecord(null);
          dispatch({
            type: "OPEN_APPOINTMENT",
            payload: { openAppointment: false },
          });
          setActiveIndex(index);
          navigate(location.pathname);
        }}
        isLazy
        lazyBehavior="unmount"
        index={activeIndex}
      >
        <TabList w="100%" padding="1rem 1.3rem" flexWrap="wrap">
          <Tab
            borderRight="0"
            flex="1"
            minW="25%"
            maxW="25%"
            borderRadius="0 !important"
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.total_records}
              </Text>
              <Text textStyle="h6" fontWeight="600" whiteSpace="nowrap">
                All Activity
              </Text>
            </VStack>
          </Tab>
          <Tab
            borderRight="0"
            flex="1"
            minW="25%"
            maxW="25%"
            isDisabled={historyCount?.data?.CallHistory === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.CallHistory}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Calls
              </Text>
            </VStack>
          </Tab>
          <Tab
            flex="1"
            borderRight={0}
            minW="25%"
            maxW="25%"
            isDisabled={historyCount?.data?.Message === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.Message}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Messages
              </Text>
            </VStack>
          </Tab>
          <Tab
            flex="1"
            borderRight={0}
            minW="25%"
            maxW="25%"
            isDisabled={historyCount?.data?.rvm === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.rvm}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                RVM
              </Text>
            </VStack>
          </Tab>
          <Tab
            flex="1"
            minW="25%"
            maxW="25%"
            isDisabled={!userEmail || historyCount?.data?.EmailLog === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.EmailLog}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Emails
              </Text>
            </VStack>
          </Tab>

          <Tab
            borderTop="0"
            flex="1"
            minW="25%"
            maxW="25%"
            isDisabled={historyCount?.data?.appointment === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.appointment}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Appointments
              </Text>
            </VStack>
          </Tab>
          <Tab
            borderTop="0"
            borderRight={0}
            flex="1"
            minW="25%"
            maxW="25%"
            isDisabled={historyCount?.data?.meeting === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.meeting}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Meetings
              </Text>
            </VStack>
          </Tab>
          <Tab
            flex="1"
            minW="25%"
            maxW="25%"
            borderRadius="0 !important"
            borderTop="0"
            isDisabled={historyCount?.data?.deals === 0}
          >
            <VStack>
              <Text textStyle="h4" fontWeight="700">
                {historyCount?.data?.deals}
              </Text>
              <Text textStyle="h6" fontWeight="600">
                Deals
              </Text>
            </VStack>
          </Tab>
        </TabList>
        <TabPanels
          overflow="auto"
          background={clickedRecord ? "var(--grey-50)" : "var(--grey-200)"}
        >
          <TabPanel>
            {!clickedRecord ? (
              <AllActivity
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            ) : (
              <>
                {
                  detailView?.find(
                    (item) => item?.name === (clickedRecord as any).record_type
                  )?.content
                }
              </>
            )}
          </TabPanel>
          <TabPanel>
            {clickedRecord && clickedRecord.record_type === "call" ? (
              <CallDetail
                clickedRecord={clickedRecord}
                setClickedRecord={setClickedRecord}
              />
            ) : (
              <Calls
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            )}
          </TabPanel>
          <TabPanel>
            {clickedRecord && clickedRecord.record_type === "message" ? (
              <MessageThread
                clickedRecord={clickedRecord}
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
                type="message"
                messageThreadProps={messageThreadProps}
              />
            ) : (
              <Messages
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            )}
          </TabPanel>
          <TabPanel>
            {clickedRecord && clickedRecord.record_type === "rvm" ? (
              <RVMDetail
                clickedRecord={clickedRecord}
                setClickedRecord={setClickedRecord}
              />
            ) : (
              <RVM
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            )}
          </TabPanel>
          <TabPanel>
            {clickedRecord && clickedRecord.record_type === "email_log" ? (
              <EmailDetail
                clickedRecord={clickedRecord}
                setClickedRecord={setClickedRecord}
              />
            ) : (
              <Emails
                setClickedRecord={setClickedRecord}
                clickedRow={clickedRow}
              />
            )}
          </TabPanel>
          <TabPanel>
            <Appointment
              setClickedRecord={setClickedRecord}
              clickedRow={clickedRow}
            />
          </TabPanel>
          <TabPanel>
            <Meeting
              setClickedRecord={setClickedRecord}
              clickedRow={clickedRow}
            />
          </TabPanel>
          <TabPanel>
            <Deals
              setClickedRecord={setClickedRecord}
              clickedRow={clickedRow}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default History;
