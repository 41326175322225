import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { Input, PhoneInput, Spinner } from "../../../../components/common";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  AppointmentIcon,
  CloseIcon,
  MinimizeIcon,
} from "../../../../components/icons/svg";
import { useState } from "react";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import { useMutation } from "@tanstack/react-query";
import { createInboundCall } from "../../../../utils/api/inboundCall";
import { isValidPhoneFunc } from "../../../../utils";
import Popup from "../../../Charts/Popup";
import { useHistoryDispatch } from "../../../../utils/context/HistoryContext";

const InboundCall = (props: any) => {
  const dealer = useUserInfo("dealership");
  const toast = useToast();
  const {
    isExpandChat,
    setIsExpandChat,
    setIsInBoundCall,
    clickedRow,
    refetchParent,
    setIsAppointmentOpen,
  } = props;
  const [userNum, setUserNum] = useState(clickedRow?.phone_number);
  const [num, setNum] = useState("");
  const [feedback, setFeedback] = useState("");
  const [phoneToError, setToPhoneError] = useState("");
  const [phoneFromError, setFromPhoneError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isAppoitmentComplete, setIsAppoitmentCompleten] = useState(false);

  const dispatch= useHistoryDispatch()

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await createInboundCall(
          dealer?.id,
          clickedRow?.id,
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetchParent();
      setIsInBoundCall(false);
      if (isAppoitmentComplete) {
        setIsAppointmentOpen(true);
      }
      toast({
        description: "Inbound Call created successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({ type:'SET_SHOULD_REFETCH', payload: { shouldRefetch: true } })
    },
    onError: (error: any) => {
      toast({
        description: `Error creating Inbound Call: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = async () => {
    let to = false;
    let from = false;
    if (!userNum) {
      // setFromPhoneError("Enter phone number");
    } else {
      to = isValidPhoneFunc(userNum, setFromPhoneError);
    }
    if (!num) {
      setToPhoneError("Enter phone number");
    } else {
      from = isValidPhoneFunc(num, setToPhoneError);
    }

    if (!to || !from) {
      return;
    }
    mutation.mutate({
      inbound_call_log: {
        to: num,
        from: userNum,
        summary: feedback,
      },
    });
  };
  const handleSubmitAppointment = async () => {
    let to = false;
    let from = false;
    if (!userNum) {
      setFromPhoneError("Enter phone number");
    } else {
      to = isValidPhoneFunc(userNum, setFromPhoneError);
    }
    if (!num) {
      setToPhoneError("Enter phone number");
    } else {
      from = isValidPhoneFunc(num, setToPhoneError);
    }

    if (!to || !from) {
      return;
    }
    setIsOpen(true);
  };
  return (
    <Drawer
      isOpen={true}
      onClose={() => setIsInBoundCall(false)}
      placement="bottom"
      id="email-drawer"
    >
      <DrawerContent
        padding="0"
        borderTopRightRadius="0.5rem"
        borderTopLeftRadius="0.5rem"
      >
        <DrawerBody padding="0">
          <VStack w="100%" alignItems="flex-start" gap="0">
            <HStack
              justifyContent="space-between"
              w="100%"
              background="var(--secondary-600)"
              borderTopRightRadius="0.5rem"
              borderTopLeftRadius="0.5rem"
              padding="0.69rem 1rem"
            >
              <Text color="white" textStyle="h5" fontWeight="600">
                Log In Inbound Call
              </Text>
              <HStack>
                {isExpandChat ? (
                  <Button
                    variant="none"
                    padding="0.38rem"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    onClick={() => setIsExpandChat(false)}
                    sx={{
                      svg: {
                        path: {
                          fill: "white",
                        },
                      },
                    }}
                  >
                    <MinimizeIcon />
                  </Button>
                ) : (
                  <Button
                    variant="none"
                    padding="0.25rem 0.3rem"
                    paddingBottom="0"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    onClick={() => setIsExpandChat(true)}
                    sx={{
                      svg: {
                        path: {
                          fill: "white",
                        },
                      },
                    }}
                  >
                    <ExpandMoreIcon
                      style={{
                        transform: "rotate(180deg)",
                      }}
                    />
                  </Button>
                )}
                <Button
                  variant="none"
                  padding="0.38rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  onClick={() => setIsInBoundCall(false)}
                  sx={{
                    svg: {
                      path: {
                        fill: "white",
                      },
                    },
                  }}
                >
                  <CloseIcon />
                </Button>
              </HStack>
            </HStack>
            {isExpandChat && (
              <VStack
                w="100%"
                alignItems="flex-start"
                gap="0"
                position="relative"
              >
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  padding="1rem 1.5rem"
                  gap="1rem"
                  mb="72px"
                  height="40rem"
                  overflow="auto"
                >
                  <VStack w="100%" alignItems="flex-start" gap="1rem" pb="1rem">
                    <HStack w="100%" justifyContent="space-between">
                      <Text textStyle="h5" fontWeight="500" w="80%">
                        What number did they call from?
                      </Text>
                      <PhoneInput
                        isRequired={true}
                        onChange={(value) => {
                          setFromPhoneError("");
                          setUserNum(value);
                        }}
                        error={phoneFromError}
                        value={userNum}
                      />
                    </HStack>
                    <HStack w="100%" justifyContent="space-between">
                      <Text textStyle="h5" fontWeight="500" w="80%">
                        What number did they call you on?
                      </Text>
                      <PhoneInput
                        isRequired={true}
                        onChange={(value) => {
                          setToPhoneError("");
                          setNum(value);
                        }}
                        error={phoneToError}
                        value={num}
                      />
                    </HStack>
                  </VStack>

                  <HStack
                    w="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    pb="1rem"
                  >
                    <Text textStyle="h5" fontWeight="500" w="80%">
                      Please tell us about the call
                    </Text>
                    <Input
                      isTextarea={true}
                      placeholder="Type Something"
                      h="12rem"
                      onChange={(e: any) => setFeedback(e.target.value)}
                      value={feedback}
                    />
                  </HStack>
                </VStack>
                <HStack
                  w="100%"
                  padding="1rem"
                  background="var(--grey-200)"
                  position="absolute"
                  bottom="0"
                  left="0"
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outline"
                    onClick={() => handleSubmitAppointment()}
                  >
                    <AppointmentIcon color="var(--grey-800)" />
                  </Button>

                  <Button
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.preventDefault();
                      handleSubmit();
                    }}
                  >
                    Save
                  </Button>
                </HStack>
              </VStack>
            )}
          </VStack>
        </DrawerBody>
      </DrawerContent>
      {isOpen && (
        <Popup
          isOpen={true}
          onClose={() => setIsOpen(false)}
          onConfirm={() => {
            mutation.mutate({
              inbound_call_log: {
                to: num,
                from: userNum,
                summary: feedback,
              },
            });
            setIsAppoitmentCompleten(true);
            setIsOpen(false);
          }}
          title="Inbound Confirmation"
          message="Do you want to save the data before going to appointments?"
        />
      )}
      {mutation?.isLoading && <Spinner />}
    </Drawer>
  );
};

export default InboundCall;
