import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { useState ,useEffect} from "react";
import { LineListing } from "./LineListing";
import { RingsListing } from "./RingsListing";
import { useLocation,useNavigate } from "react-router-dom";

const LinesAndRingGroup = () => {
  const navigate=useNavigate()
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tabParam = queryParams.get("active");
  const [activeTab, setActiveTab] = useState(Number(tabParam) ?? 0);
  useEffect(() => {
    if (tabParam) {
      queryParams.delete("active");
      const newUrl = location.pathname + "?" + queryParams.toString();
      navigate(newUrl, { replace: true });
    }
  }, [tabParam, navigate, location.pathname, queryParams]);
  const handleTabChange = (index: any) => {
    setActiveTab(index);
  };
  return (
    <>
      <Box
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        margin="1.5rem"
      >
        <Tabs
          variant="vertical"
          display="flex"
          onChange={handleTabChange}
          defaultIndex={0}
          index={activeTab}
        >
          <TabList>
            <Text
              p="1rem"
              textStyle="h6"
              fontWeight="600"
              color="var(--grey-500)"
              borderBottom="1px solid var(--grey-300)"
              w="100%"
            >
              Lines and Ring Groups
            </Text>
            <Tab w="18rem">Lines</Tab>
            <Tab w="18rem">Ring Groups</Tab>
            {/* <Tab w="18rem">Call Settings</Tab> */}
          </TabList>

          <TabPanels>
            <TabPanel>{activeTab === 0 && <LineListing />}</TabPanel>
            <TabPanel>{activeTab === 1 && <RingsListing />}</TabPanel>
            {/* <TabPanel padding="1rem">
            {activeTab===2&&   <CallingSettings />}
            </TabPanel> */}
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default LinesAndRingGroup;
