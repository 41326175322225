import { Device, Call } from "@twilio/voice-sdk";

export interface Devices {
  device: Device | null;
  label: string | null;
}

export interface TwilioDevice {
  device: Device | null;
  label: string;
  phoneNumber: string;
  is_default?: boolean;
}

export interface SMSParams {
  called: string;
  caller: string;
}

export interface TwoLeggedCall {
  start: boolean;
  from: string;
  customersPhone: string;
  callSid: string;
}

export interface BusinessHours {
  week_end_time: string;
  week_start_time: string;
  weekend_start_time: string;
  weekend_end_time: string;
}

export interface CampaignParams {
  running: boolean;
  from: string;
  contacts: any[];
  id: string;
  warmUpDuration: number;
  firstContact: boolean;
  business_hours?: BusinessHours;
}

export interface ScriptParams {
  open: boolean;
  title: string;
  description: string;
}

export interface TwilioState {
  currentDevice: TwilioDevice;
  devices: TwilioDevice[];
  startCallTimestamp: string;
  call: Call | null;
  incomingCall: Call | null;
  caller: string;
  openSMS: boolean;
  smsParams: SMSParams;
  openAppointment: boolean;
  twoLeggedCall: TwoLeggedCall;
  campaignParams: CampaignParams;
  shouldUpdateCampaignContacts: boolean;
  campaignInterval: any;
  shouldUpdatePWListing: boolean;
  scriptParams: ScriptParams;
  shouldRefetchIdentities: boolean;
  selectedCampaign: string;
}

export type TwilioAction =
  | {
      type: "UPDATE_CURRENT_DEVICE";
      payload: {
        currentDevice: TwilioDevice;
      };
    }
  | {
      type: "SET_DEVICES";
      payload: {
        devices: TwilioDevice[];
      };
    }
  | {
      type: "SET_START_CALL_TIMESTAMP";
      payload: {
        startCallTimestamp: string;
      };
    }
  | {
      type: "SET_CALL";
      payload: {
        call: Call | null;
      };
    }
  | {
      type: "SET_INCOMING_CALL";
      payload: {
        incomingCall: Call | null;
      };
    }
  | {
      type: "SET_CALLER";
      payload: {
        caller: string;
      };
    }
  | {
      type: "OPEN_SMS";
      payload: {
        openSMS: boolean;
      };
    }
  | {
      type: "SET_SMS_PARAMS";
      payload: {
        smsParams: SMSParams;
      };
    }
  | {
      type: "OPEN_APPOINTMENT";
      payload: {
        openAppointment: boolean;
      };
    }
  | {
      type: "START_TWO_LEGGED_CALL";
      payload: {
        twoLeggedCall: TwoLeggedCall;
      };
    }
  | {
      type: "SET_CAMPAIGN_PARAMS";
      payload: {
        campaignParams: CampaignParams;
      };
    }
  | {
      type: "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS";
      payload: {
        shouldUpdateCampaignContacts: boolean;
      };
    }
  | {
      type: "SET_CAMPAIGN_INTERVAL";
      payload: {
        campaignInterval: any;
      };
    }
  | {
      type: "SET_SHOULD_UPDATE_PW_LISTING";
      payload: {
        shouldUpdatePWListing: boolean;
      };
    }
  | {
      type: "SET_SCRIPT_PARAMS";
      payload: {
        scriptParams: ScriptParams;
      };
    }
  | {
      type: "SET_SHOULD_REFETCH_IDENTITIES";
      payload: {
        shouldRefetchIdentities: boolean;
      };
    }
  | {
      type: "SET_SELECTED_CAMPAIGN";
      payload: {
        selectedCampaign: string;
      };
    };

export const initialState: TwilioState = {
  currentDevice: { device: null, phoneNumber: "", label: "" },
  devices: [],
  startCallTimestamp: "",
  call: null,
  incomingCall: null,
  caller: "",
  openSMS: false,
  smsParams: { called: "", caller: "" },
  openAppointment: false,
  twoLeggedCall: { customersPhone: "", from: "", start: false, callSid: "" },
  campaignParams: {
    running: false,
    from: "",
    contacts: [],
    id: "",
    warmUpDuration: 0,
    firstContact: true,
    business_hours: {
      week_end_time: "",
      week_start_time: "",
      weekend_end_time: "",
      weekend_start_time: "",
    },
  },
  shouldUpdateCampaignContacts: false,
  campaignInterval: null,
  shouldUpdatePWListing: false,
  scriptParams: { open: false, title: "", description: "" },
  shouldRefetchIdentities: false,
  selectedCampaign: "",
};

export const TwilioReducer = (
  state: TwilioState,
  action: TwilioAction
): TwilioState => {
  switch (action.type) {
    case "SET_DEVICES":
      return {
        ...state,
        devices: action.payload.devices,
      };
    case "UPDATE_CURRENT_DEVICE":
      return {
        ...state,
        currentDevice: action.payload.currentDevice,
      };
    case "SET_START_CALL_TIMESTAMP":
      return {
        ...state,
        startCallTimestamp: action.payload.startCallTimestamp,
      };
    case "SET_CALL":
      return {
        ...state,
        call: action.payload.call,
      };
    case "SET_INCOMING_CALL":
      return {
        ...state,
        incomingCall: action.payload.incomingCall,
      };
    case "SET_CALLER":
      return {
        ...state,
        caller: action.payload.caller,
      };
    case "OPEN_SMS":
      return {
        ...state,
        openSMS: action.payload.openSMS,
      };
    case "SET_SMS_PARAMS":
      return {
        ...state,
        smsParams: action.payload.smsParams,
      };
    case "OPEN_APPOINTMENT":
      return {
        ...state,
        openAppointment: action.payload.openAppointment,
      };
    case "START_TWO_LEGGED_CALL":
      return {
        ...state,
        twoLeggedCall: action.payload.twoLeggedCall,
      };
    case "SET_CAMPAIGN_PARAMS":
      return {
        ...state,
        campaignParams: action.payload.campaignParams,
      };
    case "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS":
      return {
        ...state,
        shouldUpdateCampaignContacts:
          action.payload.shouldUpdateCampaignContacts,
      };
    case "SET_CAMPAIGN_INTERVAL":
      return {
        ...state,
        campaignInterval: action.payload.campaignInterval,
      };
    case "SET_SHOULD_UPDATE_PW_LISTING":
      return {
        ...state,
        shouldUpdatePWListing: action.payload.shouldUpdatePWListing,
      };
    case "SET_SCRIPT_PARAMS":
      return {
        ...state,
        scriptParams: action.payload.scriptParams,
      };
    case "SET_SHOULD_REFETCH_IDENTITIES":
      return {
        ...state,
        shouldRefetchIdentities: action.payload.shouldRefetchIdentities,
      };
    case "SET_SELECTED_CAMPAIGN":
      return {
        ...state,
        selectedCampaign: action.payload.selectedCampaign,
      };
    default:
      const _exhaustiveCheck: never = action;
      throw new Error(`Unhandled action type: ${_exhaustiveCheck}`);
  }
};
