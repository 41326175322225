import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Checkbox,
  HStack,
  Text,
} from "@chakra-ui/react";
import { ReactNode, useRef } from "react";
import Loader from "../common/Spinner";
import { WarningIcon } from "../icons/svg";

interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  variant?: "delete" | "confirm";
  loading?: boolean;
  hasCheckbox?: boolean;
  checkboxContent?: string;
  onCheckboxChange?: (val: boolean) => void;
  checkboxValue?: boolean;
  children?: ReactNode; // Add the children prop here
}

const Popup: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  variant = "confirm",
  loading = false,
  hasCheckbox = false,
  checkboxContent = "",
  onCheckboxChange,
  checkboxValue = false,
  children,
}) => {
  const ref = useRef(null);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={ref}
        onClose={onClose}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              <Text textStyle="h4" fontWeight="700" textAlign="center">
                {title}
              </Text>
            </AlertDialogHeader>

            <AlertDialogBody>
              <HStack
                w="100%"
                padding="1rem"
                position="relative"
                paddingLeft="2rem"
                background="#FFF3E0"
              >
                <Box
                  height="100%"
                  width="0.75rem"
                  position="absolute"
                  background="#FB8C00"
                  left="0"
                  top="0"
                ></Box>
                <WarningIcon />
                <Text>{message}</Text>
              </HStack>

              {hasCheckbox && (
                <HStack
                  w="100%"
                  padding="1rem"
                  position="relative"
                  paddingLeft="2rem"
                  background="#FFF3E0"
                >
                  <Box
                    height="100%"
                    width="0.75rem"
                    position="absolute"
                    background="#FB8C00"
                    left="0"
                    top="0"
                  ></Box>
                  <Checkbox
                    variant="whiteCheck"
                    size="lg"
                    onChange={({ target: { checked } }) =>
                      onCheckboxChange && onCheckboxChange(checked)
                    }
                    isChecked={checkboxValue}
                  >
                    {checkboxContent}
                  </Checkbox>
                </HStack>
              )}

              {children && (
                <Box w="100%" mt="1rem">
                  {children}
                </Box>
              )}
            </AlertDialogBody>

            <HStack w="100%" padding="1.5rem">
              <Button ref={ref} onClick={onClose} variant="outline" w="100%">
                Cancel
              </Button>
              <Button
                onClick={onConfirm}
                ml={3}
                w="100%"
                borderColor={
                  variant === "confirm"
                    ? "var(--primary-600)"
                    : "var(--red-500)"
                }
                _hover={{
                  background:
                    variant === "confirm"
                      ? "var(--primary-600)"
                      : "var(--red-500) !important",

                  borderColor:
                    variant === "confirm"
                      ? "var(--primary-600)"
                      : "var(--red-500)",
                }}
                background={
                  variant === "confirm"
                    ? "var(--primary-600)"
                    : "var(--red-500)"
                }
              >
                {variant === "confirm" ? "Confirm" : "Delete"}
              </Button>
            </HStack>
            {loading && <Loader />}
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default Popup;
