import {
    Box,
    Button,
    HStack,
    Text,
    VStack
} from "@chakra-ui/react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import AudioPlayer from "../../../../../common/AudioPlayer";
import TooltipIfOverflow from "../../../../../common/TooltipIfOverflow";
import { SimpleDateIcon } from "../../../../../icons/svg";

type RVMDetailType = {
  clickedRecord: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
};

function RVMDetail({ clickedRecord, setClickedRecord }: RVMDetailType) {
  const { details } = clickedRecord || {};

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      background="var(--grey-50)"
      padding="1rem 1.3rem"
      pb="3rem"
      height="calc(100vh - 290px)"
    >
      <VStack
        w="100%"
        alignItems="flex-start"
        borderTop="1px solid var(--grey-300)"
      >
        <HStack w="100%" justifyContent="space-between" padding="1rem 0">
          <Button
            variant="none"
            display="flex"
            alignItems="center"
            gap="0.63rem"
            onClick={() => setClickedRecord(null)}
          >
            <Box
              as="span"
              display="flex"
              alignItems="center"
              borderRadius="0.5rem"
              border="1px solid var(--grey-300)"
              padding="0.38rem"
            >
              <ChevronLeftIcon
                style={{
                  width: "1.25rem",
                  height: "1.25rem",
                  color: "black",
                }}
              />
            </Box>
            Back
          </Button>
        </HStack>
        <VStack
          w="100%"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          gap="0"
        >
          <HStack
            alignItems="flex-start"
            justifyContent="space-between"
            background="var(--grey-200)"
            padding="1rem"
            borderTopRightRadius="0.5rem"
            borderTopLeftRadius="0.5rem"
            borderBottom="1px solid var(--grey-300)"
            width="100%"
          >
            <HStack alignItems="flex-start" gap="0.69rem">
              <Box
                sx={{
                  svg: {
                    width: "1.25rem",
                    height: "1.25rem",
                  },
                }}
              >
                <VoicemailIcon />{" "}
              </Box>
              <Text textStyle="h6" fontWeight="500">
                RVM
              </Text>
            </HStack>
            <HStack gap="0.5rem" w="100%" justifyContent="flex-end">
              <SimpleDateIcon />
              <Text textStyle="h6" fontWeight="600">
                {moment(clickedRecord?.created_at).format(
                  "dddd MMM DD, YY • hh:mm a"
                )}
              </Text>
            </HStack>
          </HStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            gap="0"
            background="white"
            borderBottomRightRadius="0.5rem"
            borderBottomLeftRadius="0.5rem"
          >
            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  Campaign Name
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {details?.campaign_name}
                </Text>
              </Box>
            </HStack>
            <HStack w="100%" borderBottom="1px solid var(--grey-300)">
              <Box padding="0.75rem" w="7.5rem">
                <Text textStyle="h6" fontWeight="500">
                  Start At:
                </Text>
              </Box>
              <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                <Text textStyle="h6" fontWeight="500">
                  {moment(details?.start_time).format(
                    "dddd MMM DD, YY • hh:mm a"
                  )}
                </Text>
              </Box>
            </HStack>
            {details?.text_to_speech && (
              <HStack w="100%" borderBottom="1px solid var(--grey-300)">
                <Box padding="0.75rem" w="7.5rem">
                  <Text textStyle="h6" fontWeight="500">
                    Content:
                  </Text>
                </Box>
                <Box padding="0.75rem" borderLeft="1px solid var(--grey-300)">
                  <Text textStyle="h6" fontWeight="500">
                    <TooltipIfOverflow>
                      {details?.text_to_speech}
                    </TooltipIfOverflow>
                  </Text>
                </Box>
              </HStack>
            )}

            {details?.url && (
              <Box padding="1rem 0" w="100%">
                <AudioPlayer
                  audioSrc={details?.url}
                  transcribed={true}
                  isDownload
                />
              </Box>
            )}
          </VStack>
        </VStack>
      </VStack>
    </VStack>
  );
}

export default RVMDetail;
