import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Box, Button, HStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const navigate = useNavigate();

  return (
    <HStack justifyContent="space-between" w="100%">
      <Button
        variant="none"
        display="flex"
        alignItems="center"
        gap="0.63rem"
        fontWeight="500"
        onClick={() => navigate("/announcements")}
      >
        <Box
          as="span"
          display="flex"
          alignItems="center"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="0.38rem"
        >
          <ChevronLeftIcon
            style={{ width: "1.25rem", height: "1.25rem", color: "black" }}
          />
        </Box>
        Back
      </Button>
    </HStack>
  );
};

export default Header;
