import { Box, Flex, Progress, Text } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { RichTextEditorDocumentToolbar } from "../../config";
import { fileToDataUrl } from "../../utils";
import { attachFile } from "../../utils/api/lead.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import Loader from "./Spinner";
import TranslateButton from "./TranslateButton";

type TextEditorProps = {
  value: string;
  onChange: (value: string) => void;
  error?: string;
  style?: any;
  toolbar?: any[][];
  editorRef?: any;
  showCharCount?: boolean; // New prop to control the display of character count
  top?: string;
};

export default function TextEditor({
  value,
  onChange,
  error,
  style = {},
  toolbar = RichTextEditorDocumentToolbar,
  editorRef,
  showCharCount = false, // Default is false, no character count unless specified
  top = "11%",
}: TextEditorProps) {
  const quillRef = useRef<ReactQuill | null>(null);
  const [contentLength, setContentLength] = useState(0);

  const maxCharLimit = 2500; // Set your max character limit
  const isLimitReached = contentLength >= maxCharLimit;

  const user = useUserInfo("user");
  const dealership = user?.dealership;

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await attachFile({
          payload,
          dealershipId: dealership.id,
        });
        return response.data.url;
      } catch (error) {
        throw error;
      }
    },
  });

  // Strip HTML tags and calculate character count
  const calculateContentLength = (htmlString: string) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = htmlString;
    const textContent = tempElement.innerText || tempElement.textContent || "";
    setContentLength(textContent.length);
  };

  // Call this function every time the content changes
  useEffect(() => {
    if (showCharCount) {
      calculateContentLength(value);
    }
  }, [value, showCharCount]);

  const imageHandler = useCallback(async () => {
    try {
      const editor = quillRef?.current?.getEditor();
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      if (input) {
        input.onchange = async () => {
          const file = input?.files && (input?.files[0] as File);
          const base64 = await fileToDataUrl(file as File);
          if (/^image\//.test(file?.type as string)) {
            const formData = new FormData();
            formData.append("file", file as File);
            editor?.insertEmbed(
              editor?.getSelection()?.index as number,
              "image",
              base64
            );
            const res = await mutation.mutateAsync(formData);

            const imageElements = document.querySelectorAll(
              'img[src="' + base64 + '"]'
            );
            imageElements.forEach((imageElement) => {
              imageElement.setAttribute("src", res as string);
            });
          } else {
            console.log("You can only upload images.");
          }
        };
      }
    } catch (error) {
      console.log(error, "Error uploading");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: toolbar,
        handlers: {
          image: imageHandler,
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageHandler]
  );

  useEffect(() => {
    if (quillRef.current) {
      if (editorRef) editorRef.current = quillRef;
    }
  }, [editorRef]);

  return (
    <>
      {mutation.isLoading && <Loader />}
      <ReactQuill
        theme="snow"
        ref={quillRef}
        value={value}
        onChange={onChange}
        modules={modules}
        style={{
          width: "100%",
          minHeight: "70%",
          height: "100%",
          borderBottomRightRadius: "0.5rem",
          borderBottomLeftRadius: "0.5rem",
          ...style,
        }}
      />
      <Box
        position="absolute"
        top={top}
        zIndex="1"
        right="5px"
        className="translateIcon"
      >
        <TranslateButton value={value} onChange={onChange} />
      </Box>

      {showCharCount && (
        <Box mt="0.5rem">
          <Progress
            value={(contentLength / maxCharLimit) * 100}
            size="xs"
            colorScheme={isLimitReached ? "red" : "green"} // Turns red if limit reached
          />

          <Flex
            justifyContent="space-between"
            alignItems="center"
            mt="0.25rem"
            px={2} // Add left and right padding
          >
            {/* Show error message if character limit is reached */}
            {isLimitReached && (
              <Text fontSize="0.75rem" color="var(--red-600)">
                The message body has reached the allowed character limit.
              </Text>
            )}
            <Text
              fontSize="0.75rem"
              color={isLimitReached ? "var(--red-600)" : "var(--grey-500)"}
              as="h6"
              mb="0.25rem"
            >
              {contentLength} / {maxCharLimit}
            </Text>
          </Flex>
        </Box>
      )}

      {error && (
        <Text
          textStyle="captionSmall"
          color="var(--red-600)"
          style={{ display: "flex", alignItems: "flex-start", marginTop: 15 }}
        >
          {error}
        </Text>
      )}
    </>
  );
}
