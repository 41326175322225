import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";
import Input from "../../../components/common/Input";

interface SubjectProps {
  control: any;
  type: any;
}

const Subject: React.FC<SubjectProps> = ({ control, type }) => (
  <HStack
    w="100%"
    alignItems="flex-start"
    borderBottom="1px solid var(--grey-300)"
    gap="0"
  >
    <Text textStyle="h6" padding="1rem" w="20%" fontWeight="500">
      Enter {type ? "Name" : "Subject"}{" "}
      <Box as="span" color="var(--red-600)">
        *
      </Box>
    </Text>
    <Controller
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Input
          value={value}
          onChange={onChange}
          maxW="100%"
          border="0"
          borderLeft="1px solid var(--grey-300)"
          height="48px"
          borderRadius="0"
          fontWeight="500"
          error={error?.message || ""}
          borderBottom={error?.message ? "1px solid var(--grey-300)" : "0"}
          errorStyle={{
            borderLeft: "1px solid var(--grey-300)",
            paddingTop: "6px",
            paddingLeft: "8px",
          }}
        />
      )}
      name="subject"
      control={control}
      rules={{ required: true }}
    />
  </HStack>
);

export default Subject;
