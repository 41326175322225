import { Box, HStack, Text, VStack, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getPingMessage } from "../../../utils";
import { markMessageAsRead } from "../../../utils/api/header-notifications.api";
import { getPingMessages } from "../../../utils/api/lead.api";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { PingingsIcon } from "../../icons/svg";
import DOMPurify from "dompurify";

interface PingsProps {
  page?: number;
  perPage?: number;
  refetch?: boolean;
  onClose?: () => void;
}

export const PingCard = ({ page, perPage, refetch, onClose }: PingsProps) => {
  const { refetchNotifications } = useNotificationContext();
  const dealerId = useUserInfo("dealership");
  const navigate = useNavigate();
  const mutation = useMutation(markMessageAsRead);

  const { data } = useQuery({
    queryKey: ["pingNotification", dealerId?.id, page, perPage, refetch],
    queryFn: () =>
      getPingMessages({ dealershipId: dealerId?.id, perPage, page }),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: page !== undefined,
  });

  const toast = useToast();

  const handleMarkAsRead = async (item: any) => {
    if (!item?.read_status) {
      await mutation.mutateAsync(
        { dId: dealerId?.id, id: item?.message_id },
        {
          onSuccess: (data: any) => {
            refetchNotifications(); // This will trigger the refetch of the parent's query
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.errors?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }

    if (onClose) {
      onClose();
    }

    if (item?.contact_id) {
      navigate(
        `/leads/lead-details?contact_id=${item?.contact_id}&ping_id=${item?.id}&active_index=10`
      );
    }
  };

  return (
    <>
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0.59rem"
        maxH="calc(100vh - 450px)"
        overflow="auto"
        padding="1rem"
        pt="0"
      >
        {data?.data?.messages?.map((item: any) => (
          <HStack
            padding="1rem"
            w="100%"
            key={item}
            background={item?.read ? "white" : "var(--grey-100)"}
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            alignItems="flex-start"
            position="relative"
            onClick={() => {
              handleMarkAsRead(item);
            }}
            cursor="pointer"
          >
            {!item?.read_status && (
              <Box
                w="0.47rem"
                h="0.47rem"
                borderRadius="50%"
                background="#F44336"
                position="absolute"
                top="0.5rem"
                left="0.5rem"
              ></Box>
            )}
            <Box
              padding="0.5rem"
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              background="white"
              sx={{
                svg: {
                  path: {
                    fill: "var(--secondary-600)",
                  },
                },
              }}
            >
              <PingingsIcon />
            </Box>
            <VStack gap="0.25rem" w="100%" alignItems="flex-start">
              <HStack alignItems="flex-start" w={"100%"}>
                {/* <Image width={38} height={38} src={Avatar} alt="avatar" /> */}
                <VStack gap="0.25rem" alignItems="flex-start" w="100%">
                  <Text textStyle="h5" color="var(--grey-800)" fontWeight="600">
                    From: {item.pinged_initiator}
                  </Text>
                  {/* <Text
                      textStyle="h5"
                      color="var(--grey-700)"
                      fontWeight="500"
                    >
                      +92 341 9785311
                    </Text> */}
                  <HStack
                    sx={{
                      svg: {
                        width: "1rem",
                        height: "1rem",
                      },
                    }}
                    w="100%"
                    justifyContent="space-between"
                  >
                    <>
                      <Text
                        textStyle="h6"
                        color="var(--grey-400)"
                        fontWeight="500"
                      >
                        Ping you In:
                      </Text>
                      <Text
                        textStyle="h6"
                        color="var(--grey-400)"
                        fontWeight="500"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        width="70%"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            getPingMessage(item.content)
                          ),
                        }}
                      ></Text>
                    </>
                  </HStack>
                </VStack>
              </HStack>
              <HStack gap="0.5rem">
                <Text textStyle="h6" fontWeight="600">
                  {moment(item.created_at).format("ddd MMM D, YYYY hh:mm a")}
                </Text>
              </HStack>
            </VStack>
          </HStack>
        ))}
      </VStack>
    </>
  );
};
