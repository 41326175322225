import {
  AttachIconSimple,
  SimpleDateIcon,
  UploadMediaIcon,
} from "../../../../../components/icons/svg";
import { Button, HStack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getDocuments,
  deleteDocuments,
} from "../../../../../utils/api/lead-deatail.api";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import { AddDocument } from "./AddDocument";
import moment from "moment";
import SlideRange from "../../../../common/SlideRange";
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import Loader from "../../../../common/Spinner";
import { useToast } from "@chakra-ui/react";
import Popup from "../../../../Charts/Popup";
import { dealershipAdminChecker } from "../../../../../utils";
import NoItem from "../History/NoItem";
import InfiniteScroll from "react-infinite-scroll-component";

const Documents = ({ clickedRow }: any) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dealership = useUserInfo("dealership");
  const role = useUserInfo("role");
  const mutation = useMutation(deleteDocuments);
  const [page, setPage] = useState(1);
  const [days, setDays] = useState(30);
  const [documentData, setDocumentData] = useState<any>([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ["documents", dealership?.id, clickedRow, page, "10", days],
    queryFn: getDocuments,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data: any) => {
      setDocumentData((prevData: any) => {
        const newData = data?.data?.documents;
        const existingIds = prevData?.map((item: any) => item.id);
        const filteredNew = newData?.filter(
          (item: any) => !existingIds.includes(item.id)
        );
        const mergedData = [...prevData, ...filteredNew];
        // Sort mergedData based on the created_at field in ascending order
        mergedData?.sort(
          (a: any, b: any) =>
            new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        return mergedData;
      });
    },
  });
  useEffect(() => {
    setPage(1);
    setDocumentData([]);
  }, [clickedRow]);
  const sliderChange = (val: any) => {
    setDays(val);
  };
  const handleSubmit = () => {
    mutation.mutate(
      {
        id: dealership?.id,
        contact_id: clickedRow,
        document_id: popup.value,
      },
      {
        onSuccess: () => {
          onClosePopupOpen();
          setDocumentData((prevData: any) => {
            const newData = prevData.filter(
              (doc: any) => doc.id != popup.value
            );
            return newData;
          });
          toast({
            description: "Document deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const getMimeType = (url:any) => {
    const urlParams = new URLSearchParams(url?.split('?')[1]);
    return urlParams.get('response-content-type');
  };
  const handleDownload = (documentUrl: any) => {
    fetch(documentUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement<any>("a");
        link.href = url;
        const extension = documentUrl.split(".").pop().split("?")[0];
        link.setAttribute("download", `${"name"}.${extension}`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading document:", error);
        // Handle error
      });
  };

  return (
    <VStack
      h="100%"
      background="var(--grey-50)"
      padding="1rem"
      gap="1rem"
      alignItems="flex-end"
      w="100%"
      borderTopRightRadius="1rem"
    >
      <VStack
        background="white"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        alignItems="flex-start"
        gap="0"
        h="calc(100vh - 130px)"
        overflow="auto"
        w="100%"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          pb="1rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <Text textStyle="h4" fontWeight="500">
            Documents
          </Text>
          <Button
            variant="outline"
            padding="0.5rem"
            minW="auto"
            sx={{
              svg: {
                w: "1.25rem",
                h: "1.25rem",
              },
            }}
            onClick={onOpen}
          >
            <UploadMediaIcon />
          </Button>
        </HStack>
        <HStack background="var(--grey-200)" padding="1rem" w="100%">
          <SlideRange handleClick={sliderChange} />
        </HStack>{" "}
        <VStack alignItems="flex-start" gap="1rem" w="100%" mt="1rem">
          <VStack alignItems="flex-start" gap="1rem" w="100%">
            <div
              id="scrollableDivDoc"
              style={{
                width: "100%",
                overflow: "auto",
                height: "calc(100vh - 345px)",
              }}
            >
              <InfiniteScroll
                dataLength={documentData?.length}
                next={() => {
                  setPage((prev) => prev + 1);
                }}
                hasMore={documentData?.length < data?.data?.document_count}
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollableDivDoc"
              >
                {documentData?.map((item: any) => (
                  <VStack
                    w="100%"
                    alignItems="flex-start"
                    key={item}
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    justifyContent="space-between"
                    minWidth={"100%"}
                  >
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      padding="1rem"
                      background="var(--primary-50)"
                    >
                      <Text textStyle="h5" fontWeight="600">
                        Document Name
                      </Text>
                      {dealershipAdminChecker(role) && (
                        <Button
                          variant="none"
                          onClick={() => {
                            setPopup({
                              name: "Delete Document",
                              description:
                                "Are you sure you want to delete this document?",
                              value: item?.id,
                            });
                            onOpenPopupOpen();
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                    </HStack>
                    <HStack
                      w="100%"
                      justifyContent="space-between"
                      padding="1rem"
                      alignItems="flex-start"
                    >
                      <VStack alignItems="flex-start">
                        <HStack
                          gap="0.25rem"
                          onClick={() => handleDownload(item?.document_url)}
                          cursor="pointer"
                        >
                          <AttachIconSimple />
                          <Text textStyle="h6" color="var(--primary-600)">
                            {item?.name}
                          </Text>
                        </HStack>
                      </VStack>
                      <VStack alignItems="flex-end">
                        <Text
                          textStyle="h6"
                          fontStyle="italic"
                          color="var(--primary-600)"
                        >
                          uploaded by {item?.uploaded_by}
                        </Text>
                        <HStack gap="0.5rem">
                          <SimpleDateIcon />
                          <Text textStyle="h6" fontWeight="600">
                            {moment(item?.created_at).format(
                              "dddd D MMMM YY • h:mm a"
                            )}
                          </Text>
                        </HStack>
                      </VStack>
                    </HStack>
                    <HStack
                      w="100%"
                      justifyContent="center"
                      padding="1rem"
                      alignItems="center"
                    >
                    <VStack alignItems="center" justifyContent="center">
                        <HStack
                          alignItems="center"
                          justifyContent="center"
                        > 
    {getMimeType(item?.document_url)?.includes('image')&&<img src={item?.document_url}  style={{ whiteSpace: "nowrap", borderRadius: "0.5rem" }} />}
                        </HStack>
                      </VStack>
                      </HStack>
                  </VStack>
                ))}
              </InfiniteScroll>
              {documentData?.length === 0 && !isFetching && !isLoading && (
                <NoItem />
              )}
            </div>
          </VStack>
        </VStack>
      </VStack>
      {isOpen && (
        <AddDocument
          isOpen={isOpen}
          onClose={onClose}
          clickedRow={clickedRow}
          refetch={refetch}
        />
      )}
      {isFetching && <Loader />}

      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={handleSubmit}
          title={popup.name}
          message={popup.description}
          loading={mutation?.isLoading}
        />
      )}

    </VStack>
  );
};

export default Documents;
