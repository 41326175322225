import { HStack, VStack, Spinner, Center } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getHistory } from "../../../../../../utils/api/history.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import SlideRange from "../../../../../common/SlideRange";
import Export from "../Export";
import NoItem from "../NoItem";
import SingleAppointmentCard from "./SingleAppointmentCard";
import {
  useHistoryDispatch,
  useHistoryState,
} from "../../../../../../utils/context/HistoryContext";

type AppointmentType = {
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
};

function Appointment({ setClickedRecord, clickedRow }: AppointmentType) {
  const dealership = useUserInfo("dealership");
  const [appointmentActivities, setAppointmentActivities] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState("30");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const { shouldRefetch } = useHistoryState();
  const dispatch = useHistoryDispatch();

  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["appoitmrnt", clickedRow?.id, sliderValue, page],
    queryFn: () =>
      getHistory({
        dealershipId: dealership?.id,
        contactId: clickedRow?.id,
        sub_type: "appointment",
        days: sliderValue,
        page,
      }),
    retry: false,
    onSuccess: (data) => {
      if (appointmentActivities[0]?.contact_id !== clickedRow?.id) {
        setAppointmentActivities(data.data.histories || []);
        setPage(1);
      } else {
        const filteredNewHistories = data?.data?.histories?.filter(
          (newHistory: any) =>
            !appointmentActivities?.some(
              (existing) => existing.id === newHistory.id
            )
        );

        setAppointmentActivities((prev) => [...prev, ...filteredNewHistories]);
      }
      setTotalCount(data.data.histories_count);
    },
  });

  useEffect(() => {
    setPage(1);
    setAppointmentActivities([]);
    refetch();
  }, [clickedRow?.id, refetch, sliderValue]);

  useEffect(() => {
    if (shouldRefetch) {
      setPage(1);
      setAppointmentActivities([]);
      refetch();
    }

    dispatch({ type: "SET_SHOULD_REFETCH", payload: { shouldRefetch: false } });
  }, [dispatch, refetch, shouldRefetch]);

  return (
    <>
      {" "}
      <Export sub_type="appointment" days={sliderValue} id={clickedRow?.id} type="AppointmentHistory"/>
      <HStack background="var(--grey-200)" padding="1rem" w="100%">
        <SlideRange handleClick={(value) => setSliderValue(value)} />
      </HStack>{" "}
      {(isFetching&&page===1) && (
        <Center w="100%" h="calc(100vh - 375px)">
          <Spinner />
        </Center>
      )}{" "}
      {!!appointmentActivities.length &&
      !!appointmentActivities.filter((item) => !!item.details).length ? (
        <div
          id="scrollableDivAppointment"
          style={{ height: "calc(100vh - 375px)", overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={appointmentActivities.length}
            next={() => {
              setPage((prev) => prev + 1);
            }}
            hasMore={appointmentActivities.length < totalCount}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDivAppointment"
          >
            <VStack
              alignItems="flex-start"
              gap="1rem"
              padding="1rem"
              background="var(--grey-200)"
            >
              {appointmentActivities.map((item) => {
                return (
                  <SingleAppointmentCard
                    item={item}
                    setClickedRecord={setClickedRecord}
                  />
                );
              })}
            </VStack>
          </InfiniteScroll>
        </div>
      ) : (
        !isLoading && !isFetching && <NoItem />
      )}
    </>
  );
}

export default Appointment;
