import { Box, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Select } from "../../../components/common";
import { fetchRoles } from "../../../utils/api/users.api";
import { loadDealerships, throttlingOptions } from "./utils";

interface CommonFieldsProps {
  control: any;
  setValue: any;
  handleOnChange: (value: any) => void;
  status: any;
  countEdit: boolean;
  users: number;
  removedUsers: any[];
  onOpen: () => void;
}

const CommonFields: React.FC<CommonFieldsProps> = ({
  control,
  setValue,
  handleOnChange,
  status,
  countEdit,
  users = 0,
  removedUsers,
  onOpen,
}) => {
  const [role, setRole] = useState<any>(null);
  const statuses = [
    { label: "Active", value: "active" },
    { label: "Suspended", value: "suspended" },
  ];

  const { data: roles, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: () => fetchRoles(),
  });
  useEffect(() => {
    setRole([
      { value: "all", label: "All" },
      ...(roles?.roles
        ? roles.roles.map((role: any) => ({
            value: role.id.toString(),
            label: role.title,
          }))
        : []),
    ]);
  }, [roles]);

  return (
    <>
      <HStack
        w="100%"
        alignItems="flex-start"
        borderBottom="1px solid var(--grey-300)"
        gap="0"
      >
        <Text textStyle="h6" padding="1rem" w={"20%"} fontWeight="500">
          Status{" "}
          <Box as="span" color="var(--red-600)">
            *
          </Box>
        </Text>
        <Box
          borderLeft="1px solid var(--grey-300)"
          width={"100%"}
          p="4px 6px 7px 7px"
        >
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                onChange={(value: any) => {
                  setValue("status", value);
                  handleOnChange(value);
                }}
                placeholder="Select Status"
                options={statuses}
                variant="default"
                w="100%"
                value={value}
                isRequired
                isMulti
                error={error?.message}
              />
            )}
            name="status"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </HStack>

      <HStack
        w="100%"
        alignItems="flex-start"
        borderBottom="1px solid var(--grey-300)"
        gap="0"
      >
        <Text textStyle="h6" padding="1rem" w={"20%"} fontWeight="500">
          Dealerships{" "}
          <Box as="span" color="var(--red-600)">
            *
          </Box>
        </Text>
        <Box
          width="100%"
          p="4px 6px 7px 7px"
          borderLeft="1px solid var(--grey-300)"
        >
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <AsyncSelect
                key={status}
                loadOptions={async (inputValue) => {
                  if (status?.length > 0) {
                    const dealerships = await loadDealerships(
                      inputValue,
                      status
                    );
                    return [{ value: "all", label: "All" }, ...dealerships];
                  }
                  return [];
                }}
                onChange={(value: any) => {
                  setValue("dealerships", value);
                  handleOnChange(value);
                }}
                placeholder="Enter the dealerships"
                isMulti={true}
                value={value}
                defaultOptions
                isDisabled={status?.length <= 0}
              />
            )}
            name="dealerships"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </HStack>

      <HStack
        w="100%"
        alignItems="flex-start"
        borderBottom="1px solid var(--grey-300)"
        gap="0"
      >
        <Text textStyle="h6" padding="1rem" w={"20%"} fontWeight="500">
          Roles{" "}
          <Box as="span" color="var(--red-600)">
            *
          </Box>
        </Text>
        <Box
          width="100%"
          borderLeft="1px solid var(--grey-300)"
          p="4px 6px 7px 7px"
        >
          <Controller
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Select
                onChange={(value: any) => {
                  setValue("role", value);
                  handleOnChange(value);
                }}
                placeholder="Select Role"
                options={role}
                variant="default"
                w="100%"
                value={value}
                isRequired
                isMulti
                error={error?.message}
              />
            )}
            name="role"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </HStack>

      <HStack
        w="100%"
        alignItems="flex-start"
        borderBottom="1px solid var(--grey-300)"
        gap="0"
      >
        <Text textStyle="h6" padding="1rem" w={"20%"} fontWeight="500">
          Recipients
        </Text>
        <Box
          w="100%"
          p="0.75rem"
          borderLeft="1px solid var(--grey-300)"
          height="100%"
        >
          <Controller
            render={({ field: { value }, fieldState: { error } }) => (
              <Text onClick={onOpen}>
                {countEdit
                  ? Number(value) - removedUsers.length
                  : users - removedUsers.length}
              </Text>
            )}
            name="users"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </HStack>

      <HStack
        w="100%"
        alignItems="flex-start"
        borderBottom="1px solid var(--grey-300)"
        gap="0"
      >
        <Text textStyle="h6" padding="1rem" w={"20%"} fontWeight="500">
          Throttling
        </Text>
        <Box
          width="100%"
          borderLeft="1px solid var(--grey-300)"
          p="4px 6px 7px 7px"
        >
          <Controller
            render={({ field: { value }, fieldState: { error } }) => (
              <Select
                onChange={(value: any) =>
                  setValue("throttling_rate", value.value)
                }
                placeholder="Select Throttling"
                options={throttlingOptions}
                variant="default"
                w="100%"
                value={value}
                isRequired
                error={error?.message}
              />
            )}
            name="throttling_rate"
            control={control}
            rules={{ required: true }}
          />
        </Box>
      </HStack>
    </>
  );
};

export default CommonFields;
