import {
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
  Image,
} from "@chakra-ui/react";
import { RouteIcon } from "../../../../icons/svg";
import { PaginatedTable } from "../../../../common";
import { Modal, Input } from "../../../../../components/common";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  tiktokcheckoutUrl,
  getTiktokSubs,
  createSubs,
  removeSubs,
  importLeads,
} from "../../../../../utils/api/php-endpoints.api";
import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import Loader from "../../../../common/Spinner";
import Popup from "../../../../Charts/Popup";
import DatePicker from "../../../../common/DateRangePicker";
import SwitchToggle from "../../../../common/Switch";
import moment from "moment";
const TiktokIntegration = ({ userId, id, value, onChange, enabled }: any) => {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["tiktoksubs", id],
    queryFn: getTiktokSubs,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [pageid, setPageid] = useState();
  const mutation = useMutation(createSubs);
  const removemutation = useMutation(removeSubs);
  const importmutation = useMutation(importLeads);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const onClosePopupOpen = () => setIsPopupOpen(false);
  const onOpenPopupOpen = () => setIsPopupOpen(true);
  const [lifetime, setLifetime] = useState<boolean>(false);
  const [popup, setPopup] = useState({
    name: "",
    description: "",
    value: "",
  });
  const today = new Date();
  const [range, setRange] = useState({
    start_date: new Date(today.setDate(today.getDate() - 90)),
    end_date: new Date(),
  });

  const tableHeader = [
    { value: "Subscription Type", label: "subscriptionType" },
    { value: "Page ID", label: "pageId" },
    { value: "Actions", label: "actions" },
  ];

  const tableData = data?.data?.data?.list?.map((item: any) => ({
    subscriptionType: item?.subscribe_entity,
    pageId: item?.subscription_detail?.page_id,
    actions: (
      <HStack>
        <Button
          background="var(--secondary-600)"
          _hover={{
            background: "var(--secondary-600)",
          }}
          border="none"
          onClick={() => {
            setPopup({
              name: "Import Leads",
              description: "Are you sure you want to import leads?",
              value: item?.subscription_detail?.page_id,
            });
            onOpenPopupOpen();
          }}
        >
          Import Leads
        </Button>
        <Button
          background="var(--red-400)"
          _hover={{
            background: "var(--red-400)",
          }}
          border="none"
          onClick={() => {
            setPopup({
              name: "Unsubscribe",
              description: "Are you sure you want to unsubscribe?",
              value: item?.subscription_id,
            });
            onOpenPopupOpen();
          }}
        >
          Unsubscribe
        </Button>
      </HStack>
    ),
  }));
  const createSubscription = () => {
    mutation.mutate(
      {
        id: id,
        payload: {
          page_id: pageid,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          toast({
            description: "Subscription created successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          // setPageid('')
          onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.message?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const removeSubscription = () => {
    removemutation.mutate(
      {
        id: id,
        tid: popup?.value,
        payload: {
          subscription_id: popup?.value,
        },
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          onClosePopupOpen();
          setPopup({
            name: "",
            description: "",
            value: "",
          });
          toast({
            description: "Subscription removed successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          // setPageid('')
          onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.message?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const importLeadsCall = () => {
    importmutation.mutate(
      {
        id: id,
        tid: popup.value,
        range:lifetime?undefined: [
          moment(range?.start_date).format("YYYY-MM-DD"),
          moment(range?.end_date).format("YYYY-MM-DD"),
        ],
      },
      {
        onSuccess(data, variables, context) {
          refetch();
          onClosePopupOpen();
          setPopup({
            name: "",
            description: "",
            value: "",
          });
          toast({
            description: "Leads imported successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          // setPageid('')
          onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.message?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <VStack p="1rem 0" gap="1rem">
        <HStack w="100%" justifyContent="flex-start" alignItems="flex-end">
          <Input
            maxW="50%"
            label="Tiktok Advertiser ID"
            defaultValue="5381347978"
            value={value}
            onChange={onChange}
          />
          <Button
            rightIcon={<RouteIcon />}
            padding="1.3rem 2rem"
            sx={{
              svg: {
                path: {
                  fill: "white",
                },
              },
            }}
            isDisabled={!enabled}
            onClick={() => window.location.replace(tiktokcheckoutUrl(id))}
          >
            {!data ? "Connect to Tiktok" : "Reconnect to Tiktok"}
          </Button>
        </HStack>
        {data?.data?.data?.account && (
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            background="white"
            gap="0"
          >
            <HStack
              w="100%"
              padding="1rem"
              borderBottom="1px solid var(--grey-300)"
            >
              <Text textStyle="h5" fontWeight="600" w="100%">
                Connected User Information
              </Text>
            </HStack>
            <VStack padding="2rem" alignItems="flex-start" gap="2rem">
              <Image
                src={data?.data?.data?.account?.avatar_url}
                style={{
                  width: "80px",
                  height: "80px",
                  borderRadius: "50%",
                }}
                alt="avatar"
              />
              <Text textStyle="h5" fontWeight="700">
                {data?.data?.data?.account?.display_name}
              </Text>
            </VStack>
          </VStack>
        )}
        {data && (
          <VStack
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            w="100%"
            alignItems="flex-start"
            background="white"
          >
            <HStack w="100%" padding="1rem" justifyContent="space-between">
              <Text textStyle="h5" fontWeight="600">
                Subscriptions
              </Text>
              <Button onClick={onOpen}>New Subscription</Button>
            </HStack>
            <HStack w="100%" padding="1rem"justifyContent="flex-start" alignItems="flex-end">
          <DatePicker 
          label="Select Range For Lead Import"
           w="100%"
           value={{
             startDate: range?.start_date??null,
             endDate: range?.end_date??null,
           }}
           onChange={(range: { startDate: Date; endDate: Date }) => {
             setRange({
               start_date: range?.startDate,
               end_date: range?.endDate,
             });
           }}
           isDisabled={lifetime}/>
          
        </HStack>
        <HStack w="100%" paddingLeft="1rem" paddingBottom="1rem" justifyContent="flex-start" alignItems="flex-end">
          <SwitchToggle
                id="1"
                label="Lifetime (Max past 90 days)"          
                defaultChecked={lifetime}
                onChange={(check: boolean) => {
                  setLifetime(check)
                }}
              />
        </HStack>
            {data?.data?.data?.list?.length ? (
              <PaginatedTable
                header={tableHeader}
                data={tableData}
                itemsPerPage={10}
                maxPageNumbers={10}
                isLoadingData={isLoading}
                //   hasPadding={false}
              />
            ) : (
              <VStack p="1rem" paddingTop="0">
                <Text fontWeight="600" textStyle="h5">
                  There are currently no subscription
                </Text>
              </VStack>
            )}
          </VStack>
        )}
      </VStack>
      <Modal isOpen={isOpen} onClose={onClose} title="Tiktok Subscription">
        <VStack padding="0 1.5rem 1.5rem" gap="1.5rem">
          <Input label="Page Id" onChange={(e) => setPageid(e.target.value)} />
          <HStack w="100%" justifyContent="flex-end">
            <Button onClick={createSubscription} isDisabled={!pageid}>
              Save
            </Button>
          </HStack>
        </VStack>
        {mutation?.isLoading && <Loader />}
      </Modal>
      {(removemutation?.isLoading || importmutation?.isLoading) && <Loader />}
      {isPopupOpen && (
        <Popup
          isOpen={isPopupOpen}
          onClose={onClosePopupOpen}
          onConfirm={
            popup?.name === "Unsubscribe" ? removeSubscription : importLeadsCall
          }
          title={popup.name}
          message={popup.description}
          loading={
            popup?.name === "Unsubscribe"
              ? removemutation?.isLoading
              : importmutation?.isLoading
          }
        />
      )}
    </>
  );
};

export default TiktokIntegration;
