import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const fetchHome = async ({ queryKey }: any) =>
  axios.get(`${SERVICES.apiBaseUrl}/guest_mode/dealership/${queryKey[2]}`, {
    params: queryKey[1],
  });

export const fetchContact = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${queryKey[2]}/my_profile`,
    {
      params: queryKey[1],
    }
  );

export const updateContact = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${payload.dId}/my_profile`,
    payload.payload,
    {
      params: { contact_uuid: payload.contact_uuid },
    }
  );

export const fetchGarage = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${queryKey[2]}/my_garage`,
    { params: queryKey[1] }
  );

export const scheduleAVisit = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${payload.dId}/schedule_a_visit`,
    payload.payload,
    {
      params: { contact_uuid: payload.id },
    }
  );
export const fetchBusinessHours = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/settings/dealership/${queryKey[1]}/setting?key=general_settings&&uuid=true`
  );
export const fetchDocuments = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${queryKey[1]}/document?page=${queryKey[2]}&per_page=${queryKey[3]}&days=${queryKey[4]}&contact_uuid=${queryKey[5]}`
  );
export const addDocument = async (payload: any) =>
  axios.post(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${payload.dId}/document`,
    payload.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { contact_uuid: payload.id },
    }
  );
export const fetchInventory = async ({ queryKey }: any) =>
  axios.get(
    `${SERVICES.apiBaseUrl}/guest_mode/dealership/${queryKey[1]}/browse_inventory`,
    { params: queryKey[2] }
  );
