import * as yup from "yup";
export const defaultValues={
    title:'',
    description:'',
    script_type:''
 }
 export type CreateScriptForm = {
    title?: string;
    description:string,
    script_type:string
  
  };
  export const CategoryValues=[
    {label:'Sales',value:'sales', },
    {label:'Service',value:'service', },
    {label:'Buy Back',value:'buy_back', },
    {label:'CSI',value:'csi', },
    {label:'Appointment Confirmation',value:'appointment_confirmation', }
]
 export const createScriptValidation = yup.object().shape({
    title: yup
    .string()
    .nullable()
    .required("Title is required."),
    description: yup
    .string()
    .nullable()
    .required("Body is required."),
    script_type: yup
    .string()
    .nullable()
    .required("Category is required."),

 })