import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  data: any;
  headers: any;
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const getSaraSettings = async () =>
  axios.get(`${SERVICES.apiBaseUrl}/api/admin/sarah_ai_settings`);

export const updateSaraSettings = async (payload: any) =>
  axios.put(
    `${SERVICES.apiBaseUrl}/api/admin/update_sarah_ai_settings`,
    payload,
    {
      headers: {
        menu: "Global Configurations",
        "Sub-Menu": "Sara.ai Integrations",
      },
    }
  );
