import { HStack, VStack, Spinner, Center ,Box,  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getHistory } from "../../../../../../utils/api/history.api";
import { useUserInfo } from "../../../../../../utils/hooks/useUserInfo";
import SlideRange from "../../../../../common/SlideRange";
import Export from "../Export";
import NoItem from "../NoItem";
import SingleDealsCard from "./SingleDealsCard";

type DealsType = {
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
};

function Deals({ setClickedRecord, clickedRow }: DealsType) {
  const dealership = useUserInfo("dealership");
  const [meetingActivities, setMeetingActivities] = useState<any[]>([]);
  const [sliderValue, setSliderValue] = useState("30");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [activeTab, setActiveTab] = useState("sales");
  const { isLoading, isFetching, refetch } = useQuery({
    queryKey: ["meetingHistory", clickedRow?.id, sliderValue, page,activeTab],
    queryFn: () =>
      getHistory({
        dealershipId: dealership?.id,
        contactId: clickedRow?.id,
        type:'process_sale',
        sub_type: activeTab,
        days: sliderValue,
        page,
      }),
    retry: false,
    onSuccess: (data) => {
      if (meetingActivities[0]?.contact_id !== clickedRow?.id) {
        setMeetingActivities(data.data.histories || []);
        setPage(1);
      } else {
        const filteredNewHistories = data?.data?.histories?.filter(
          (newHistory: any) =>
            !meetingActivities?.some(
              (existing) => existing.id === newHistory.id
            )
        );
        setMeetingActivities((prev) => [...prev, ...filteredNewHistories]);
      }
      setTotalCount(data.data.histories_count);
    },
  });

  useEffect(() => {
    setPage(1);
    setMeetingActivities([]);
  }, [clickedRow?.id, refetch, sliderValue,activeTab]);

  return (
    <>
    <Tabs onChange={(index) => {setPage(1); setActiveTab(['sales', 'services', 'ro_tradeup'][index])}} variant="table">
      <TabList>
        <Tab>Sales</Tab>
        {/* <Tab>Services</Tab>
        <Tab>R.O. TradeUp</Tab> */}
      </TabList>
    </Tabs>

    <Box background="white" w="100%">
      <Export type="DealsHistory" sub_type={activeTab} days={sliderValue} id={clickedRow?.id} />
      <HStack background="var(--grey-200)" padding="1rem" w="100%">
        <SlideRange handleClick={(value) => setSliderValue(value)} />
      </HStack>
      {(isFetching && page === 1) && (
        <Center w="100%" h="calc(100vh - 375px)">
          <Spinner />
        </Center>
      )}
      {!!meetingActivities.length && !!meetingActivities.filter((item) => !!item.details).length ? (
        <div
          id="scrollableDivMeeting"
          style={{ height: "calc(100vh - 475px)", overflow: "auto" }}
        >
          <InfiniteScroll
            dataLength={meetingActivities.length}
            next={() => setPage((prev) => prev + 1)}
            hasMore={meetingActivities.length < totalCount}
            loader={<h4>Loading...</h4>}
            scrollableTarget="scrollableDivMeeting"
          >
            <VStack
              alignItems="flex-start"
              gap="1rem"
              padding="1rem"
              background="var(--grey-200)"
            >
              {meetingActivities.map((item) => (
                <SingleDealsCard
                  key={item.id}
                  item={item}
                  setClickedRecord={setClickedRecord}
                  clickedRow={clickedRow}
                  />
              ))}
            </VStack>
          </InfiniteScroll>
        </div>
      ) : (
        !isLoading && !isFetching && <NoItem />
      )}
    </Box>
  </>  );
}

export default Deals;
