import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

export const checkAuthentication = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/messenger/check-authentication`
  );
export const getConversations = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/messenger/get-conversations`
  );
export const getConversationsByNext = async ({
  queryKey,
}: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/messenger/get-conversations-by-page-next-url?page_next_url=${queryKey[2]}`
  );
export const sendMessage = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/messenger/send-message`,
    payload.data
  );
export const loadMoreMessages = async (param: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${param.dealership_id}/messenger/load-more-message?page_next_url=${param.encodedLink}`
  );
export const uploadAttachment = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${params.id}/upload-file`,
    params.data,
    {
      headers: {
        "Content-Type": "multipart/form-data", // Set the correct content type for file upload
      },
    }
  );
export const sendMessageWithAttachment = async (
  payload: any
): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/messenger/send-message-attachment?recipient_id=${payload?.recipient_id}&type=${payload?.type}&url=${payload?.url}`
  );
export const markAsSeen = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${payload?.id}/messenger/sender-action?recipient_id=${payload?.recipient_id}&sender_action=MARK_SEEN`
  );
export const getWebchat = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/list?page=${queryKey[2]}&per_page=${queryKey[3]}${queryKey[4]}`
  );
export const getMessengerCheckout = (id: any) => {
  return `${SERVICES.apiBaseUrl}/p/v1/messenger/redirect/${id}`;
};
export const loginTiktok = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/tiktok/listening/login`,
    {
      platform: "tiktok",
    }
  );
export const getadds = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${queryKey[1]}/tiktok/listening/get-ads?platform=tiktok&date_range[0]=${queryKey[2]}&date_range[1]=${queryKey[3]}&secondary_status=${queryKey[4]}&page=${queryKey[5]}`
  );
export const getTiktokVideo = async (dealership_id: any, video_id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${dealership_id}/tiktok/listening/get-tiktok-video?video_id=${video_id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const getTiktokAddGroupComments = async (
  dealership_id: any,
  group_id: any
) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/p/api/v1/dealership/${dealership_id}/tiktok/listening/get-tiktok-adgroup-comments?adgroup_id=${group_id}&platform=tiktok`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const translatemsg = async (payload: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/translate?text=${payload?.text}`
  );

export const translateContent = async (payload: any): Promise<ApiResult> =>
  axios.post(`${SERVICES.apiBaseUrl}/api/translate`, payload.payload);
