import * as yup from "yup";
import { fetchLeadSource } from "../../../../utils/api/global-config.api";
import { fetchDispositionDS } from "../../../../utils/api/disposition-settings.api";
import { getOutbooundLine } from "../../../../utils/api/bdc-campaign.api";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../../../components/Drawers/SearchAndFilterDrawer/utils";
export type CreateBDCCampaignForm = {
  name: string;
  description: string;
  outbound_line: any;
  lead_intake_email: string;
  throttle: string;
  category: string;
  lead_form_IDs: Array<{ id: number; value: string }>;
  lead_form_source: Array<{
    value: number;
    label: string;
  }>;
  dispositions: Array<{
    value: number;
    label: string;
  }>;
  disenroll_if_disposition_changed_to_appointment_set: boolean;
  disenroll_if_disposition_changed_to_sold: boolean;
  disenroll_if_the_contact_responds: boolean;
  run_on_nation_holidays: boolean;
  bdc_campaign_hours: string;
  days: {
    label: string;
    checked: boolean;
    startTime: string;
    endTime: string;
  }[];
  filterApplied: boolean;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
};
export const throttlingOptions = [
  { label: "200 per hour", value: "200" },
  { label: "400 per hour", value: "400" },
  { label: "600 per hour", value: "600" },
  { label: "800 per hour", value: "800" },
  { label: "Or Max", value: "max" },
];
export const loadLeadSource = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchLeadSource({ q: searchStr }, id);
    const options = response?.lead_sources?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const loadOutboundLine = async (searchStr: string, id?: string) => {
  try {
    const response = await getOutbooundLine({
      dealership_id: id,
      q: searchStr,
    });
    const options = response?.data?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.phone_number,
      default: leadSource.default,  // Assuming 'default' comes in the response
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const loadDispositionSettings = async (
  searchStr: string,
  id?: string
) => {
  try {
    const response = await fetchDispositionDS(id || "", { q: searchStr });
    const options = response?.contact_dispositions?.map(
      (contactDisposition: any) => ({
        value: contactDisposition.id,
        label: contactDisposition.name,
      })
    );

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const defaultValues = {
  name: "",
  description: "",
  outbound_line: "",
  lead_intake_email: "",
  throttle: "",
  category: "",
  lead_form_IDs: [],
  lead_form_source: [],
  dispositions: [],
  disenroll_if_disposition_changed_to_appointment_set: false,
  disenroll_if_disposition_changed_to_sold: false,
  disenroll_if_the_contact_responds: false,
  run_on_nation_holidays: false,
  bdc_campaign_hours: "default",
  filterApplied: false,
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  days: [
    { label: "Monday", checked: false, startTime: "", endTime: "" },
    { label: "Tuesday", checked: false, startTime: "", endTime: "" },
    { label: "Wednesday", checked: false, startTime: "", endTime: "" },
    { label: "Thursday", checked: false, startTime: "", endTime: "" },
    { label: "Friday", checked: false, startTime: "", endTime: "" },
    { label: "Saturday", checked: false, startTime: "", endTime: "" },
    { label: "Sunday", checked: false, startTime: "", endTime: "" },
  ],
};
const daySchema = (businessHours: any) =>
  yup.object().shape({
    label: yup.string().required(),
    checked: yup.boolean(),
    startTime: yup.string().when("checked", {
      is: true,
      then: yup.string()
        .required("Start time is required when day is checked")
        .test("is-within-business-hours", "Start time must be within business hours", function (value: any) {
          const { label } = this.parent;
          if (!businessHours) {
            return true;
          }
          if (["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].includes(label)) {
            return value >= businessHours.week_start_time && value <= businessHours.week_end_time;
          }
          if (["Saturday", "Sunday"].includes(label)) {
            return value >= businessHours.weekend_start_time && value <= businessHours.weekend_end_time;
          }
          return true;
        }),
    }),
    endTime: yup.string().when("checked", {
      is: true,
      then: yup.string()
        .required("End time is required when day is checked")
        .test("is-within-business-hours", "End time must be within business hours", function (value: any) {
          const { label } = this.parent;
          if (!businessHours) {
            return true;
          }
          if (["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].includes(label)) {
            return value >= businessHours.week_start_time && value <= businessHours.week_end_time;
          }
          if (["Saturday", "Sunday"].includes(label)) {
            return value >= businessHours.weekend_start_time && value <= businessHours.weekend_end_time;
          }
          return true;
        })
        .test("is-greater-than-start-time", "End time must be greater than start time", function (value: any) {
          const { startTime } = this.parent;
          // Ensure both startTime and endTime are available before validating
          if (!startTime || !value) {
            return true; // skip validation if startTime or endTime is not provided
          }
          return value > startTime;
        }),
    }),
  });



export const CreateBDCCampaignFormSchema = (businessHours:any) =>
  yup.object().shape({
    name: yup.string().required("Name is required"),
    category: yup.string().required("Category is required"),
    days: yup.array().of(daySchema(businessHours)),
  });
export const CreateBDCCampaignFormSettingsSchema = (businessHours:any) =>yup.object().shape({
  name: yup.string().required("Name is required"),
  days: yup.array().of(daySchema(businessHours)),
});
