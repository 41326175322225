import React, { Dispatch, SetStateAction, useState } from "react";
import Activity from "../Activity/Activity";
import {
  HStack,
  Text,
  VStack,
  Modal,
  Box,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ProcessSaleIcon } from "../../../../../icons/svg";
import { capitalize } from "../../../../../../utils";

type SingleDealsCardProps = {
  item: any;
  setClickedRecord: Dispatch<SetStateAction<any>>;
  clickedRow: any;
};

function SingleDealsCard({ item }: SingleDealsCardProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Activity
      key={item.id}
      id={item.id}
      handleClick={() => {
        setIsOpen(true);
      }}
      title="Deals"
      icon={<ProcessSaleIcon />}
      content={
        <>
          <VStack alignItems="flex-start" gap="0.25rem">
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Primary Sales Person:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.sales_person_details
                  ?.map((item: any) => item?.first_name + " " + item?.last_name)
                  .join(", ")}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Status:
              </Text>
              <Box
                as="span"
                py="0.25rem"
                px="0.62rem"
                color={"var(--orange-default)"}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="1.25rem"
                border={`1px solid ${"var(--orange-default)"}`}
                borderRadius="2.5rem"
              >
                {capitalize(item?.details?.sale_status)}
              </Box>
             {item?.details?.dms_confirmed&& <Box
                as="span"
                py="0.25rem"
                px="0.62rem"
                color={"var(--secondary-default)"}
                fontSize="0.875rem"
                fontWeight="400"
                lineHeight="1.25rem"
                border={`1px solid ${"var(--secondary-default)"}`}
                borderRadius="2.5rem"
              >
                {'DMS Confirmed'}
              </Box>}
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Delivery Status:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.status}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Year:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.vehicle_details?.year}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Make:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.vehicle_details?.make}
              </Text>
            </HStack>{" "}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Model:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.vehicle_details?.model}
              </Text>
            </HStack>{" "}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Vin:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.vehicle_details?.vin}
              </Text>
            </HStack>{" "}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Stock No:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.vehicle_details?.stock_number}
              </Text>
            </HStack>{" "}
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Process Sales Type:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.sale_type}
              </Text>
            </HStack>
            <HStack gap="0.25rem" alignItems="flex-start">
              <Text textStyle="h6" fontWeight="600" whiteSpace="nowrap">
                Deal Notes:
              </Text>
              <Text
                color="var(--grey-800)"
                textStyle="h6"
                fontWeight="500"
                maxW="492px"
                w="100%"
              >
                {item?.details?.notes}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Payment Type:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.payment_type}
              </Text>
            </HStack>
            <HStack gap="0.25rem">
              <Text textStyle="h6" fontWeight="600">
                Term:
              </Text>
              <Text color="var(--grey-800)" textStyle="h6" fontWeight="500">
                {item?.details?.term}
              </Text>
            </HStack>
            {isOpen && (
              <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent maxH="calc(100vh - 128px)" overflow="auto">
                  <ModalHeader>Raw Data</ModalHeader>
                  <ModalBody maxH="calc(100vh - 124px)" overflow="auto">
                    <Text whiteSpace="pre-wrap">
                      {item?.details?.raw_data &&
                        JSON.stringify(
                          JSON.parse(item?.details?.raw_data),
                          null,
                          5
                        )}
                    </Text>
                  </ModalBody>
                </ModalContent>
              </Modal>
            )}
          </VStack>
        </>
      }
    />
  );
}

export default SingleDealsCard;
