import axios from "axios";
import { SERVICES } from "../../config";

axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//................dealership settings............

export const fetchDisposition = async (params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/contact_dispositions`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchDispositionById = async (id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/contact_dispositions/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createDisposition = async (payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/contact_dispositions`,
      payload,
      {
        headers: {
          menu: "Global Configuration",
          "Sub-Menu": "Disposition Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateDisposition = async (
  id: any,
  payload: any,
  mappings: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/contact_dispositions/${id}`,
      payload,
      {
        headers: {
          menu: mappings[0] ?? "",
          "Sub-Menu": mappings[1] ?? "",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const deleteDisposition = async (id: any) => {
  try {
    const response = await axios.delete(
      `${SERVICES.apiBaseUrl}/api/admin/contact_dispositions/${id}`,
      {
        headers: {
          menu: "Global Configurations",
          "Sub-Menu": "Disposition Settings",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

//................dealership settings............

export const fetchDispositionDS = async (dId: string, params: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/contact_dispositions`,
      {
        params,
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
export const fetchDispositionByIdDS = async (dId: string, id: any) => {
  try {
    const response = await axios.get(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/contact_dispositions/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const createDispositionDS = async (dId: string, payload: any) => {
  try {
    const response = await axios.post(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/contact_dispositions`,
      payload,
      {
        headers: {
          menu: "Dealership",
          "Sub-Menu": "Settings/Dealership Settings/Disposition Settings",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const updateDispositionDS = async (
  dId: string,
  id: any,
  payload: any,
  mappings: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}/api/admin/dealerships/${dId}/contact_dispositions/${id}`,
      payload,
      {
        headers: {
          menu: mappings[0] ?? "",
          "Sub-Menu": mappings[1] ?? "",
          Dealership: dId,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const suspendOrReactivate = async (
  endpoint: string,
  payload: any,
  mappings: any,
  dId: any
) => {
  try {
    const response = await axios.patch(
      `${SERVICES.apiBaseUrl}${endpoint}`,
      payload,
      {
        headers: {
          menu: mappings[0] ?? "",
          "Sub-Menu": mappings[1] ?? "",
          Dealership: dId ?? "",
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
