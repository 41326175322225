import {
  Box,
  Button,
  Checkbox,
  DrawerFooter,
  HStack,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Drawer } from "../../../components/common";
import { updateSaraSettings } from "../../../utils/api/sara-ai";
import { fetchDealershipListing } from "../../../utils/api/users.api";

const DealershipListingDrawer = ({ onClose, settingsHash, refetch }: any) => {
  const [page, setPage] = useState(1);
  const [dealerships, setDealerships] = useState<any[]>([]);
  const [selectedDealerships, setSelectedDealerships] = useState<any[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const mutation = useMutation(updateSaraSettings);
  const toast = useToast();

  const { data, isLoading } = useQuery({
    queryKey: ["fetchDealershipListing", page],
    queryFn: () => {
      const queryParameters: any = {
        status: "active",
        page: page,
        per_page: 13,
      };
      return fetchDealershipListing(queryParameters);
    },
    onSuccess(data) {
      setDealerships((prev) => [...prev, ...data?.dealerships]);
      setSelectedDealerships((prev) =>
        selectAll
          ? [...prev, ...data?.dealerships?.map((d: any) => d.id)]
          : [...prev]
      );
    },
  });

  useEffect(() => {
    if (dealerships.length > 0) {
      setSelectAll(
        dealerships?.every((item) => selectedDealerships.includes(item.id))
      );
    }
  }, [selectedDealerships, dealerships]);

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedDealerships(
      !selectAll ? dealerships?.map((d: any) => d.id) || [] : []
    );
  };

  const handleDealershipSelect = (id: number) => {
    setSelectedDealerships((prev) =>
      prev.includes(id) ? prev.filter((dId) => dId !== id) : [...prev, id]
    );
  };

  const handleSubmit = () => {
    mutation.mutate(
      {
        select_all: selectAll,
        dealership_ids: selectedDealerships,
        setting: {
          settings_hash: {
            chat_gpt: {
              ...settingsHash,
            },
          },
        },
      },
      {
        onSuccess: () => {
          refetch();
          onClose();
          toast({
            description: "Settings updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer
      isOpen={true}
      onClose={onClose}
      title="Dealerships"
      isLoading={isLoading}
    >
      <Box padding="1rem">
        <Checkbox
          isChecked={selectAll}
          onChange={handleSelectAll}
          colorScheme="teal"
        >
          Select All
        </Checkbox>
      </Box>

      <div
        id="scrollableDiv"
        style={{
          height: "calc(87vh - 120px)",
          overflow: "auto",
        }}
      >
        <InfiniteScroll
          dataLength={dealerships?.length || 0}
          next={() => {
            setPage((prev: any) => prev + 1);
          }}
          hasMore={dealerships?.length < data?.dealerships_count}
          loader={<h4>Loading...</h4>}
          scrollableTarget={"scrollableDiv"}
        >
          <VStack gap="0.5" padding="1rem">
            {dealerships?.map((item: any) => {
              const isSelected = selectedDealerships?.includes(item.id);
              return (
                <Box
                  key={item?.id}
                  w="100%"
                  padding="1rem"
                  cursor="pointer"
                  onClick={() => handleDealershipSelect(item.id)}
                  borderRadius="md"
                  boxShadow="md"
                >
                  <HStack alignItems="center" justifyContent="space-between">
                    <Text textStyle="h4" fontWeight="600">
                      {item?.name}
                    </Text>
                    <Checkbox
                      isChecked={isSelected}
                      onChange={() => handleDealershipSelect(item.id)}
                    />
                  </HStack>
                </Box>
              );
            })}
          </VStack>
        </InfiniteScroll>
      </div>

      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default DealershipListingDrawer;
