import { useToast } from "@chakra-ui/react";
import {
  makeOutgoingCall,
  useTwilioDispatch,
  useTwilioState,
} from "../context/TwilioContext";
import { useUserInfo } from "./useUserInfo";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Device } from "@twilio/voice-sdk";
import {
  getPowerDialerContacts,
  lockPowerDialer,
  togglePWCampaign,
} from "../api/power-dialer.api";
import { useQuery } from "@tanstack/react-query";
import { SERVICES } from "../../config";
import { filterByUniqueKey, isTimeBetween } from "../helper";
import moment from "moment";

const timeStrMapping: any = {
  15000: "15 Seconds",
  30000: "30 seconds",
};

export const usePWCampaign = () => {
  const {
    campaignParams,
    devices,
    campaignInterval,
    shouldUpdateCampaignContacts,
  } = useTwilioState();

  const dispatch = useTwilioDispatch();

  const {
    contacts,
    from,
    id,
    running,
    warmUpDuration,
    firstContact,
    business_hours,
  } = campaignParams;

  const lineDevices = filterByUniqueKey(
    devices.filter((device) => device.phoneNumber),
    "phoneNumber"
  );

  const user = useUserInfo("user");
  const toast = useToast();
  const navigate = useNavigate();

  const { refetch } = useQuery({
    queryKey: ["fetchDialerMainContacts", id],
    queryFn: () =>
      getPowerDialerContacts({
        dealership_id: user?.dealership?.id,
        id,
        page: 1,
        perPage: 10,
      }),
    retry: false,
    onSuccess(data) {
      if (
        !/^\/power-dialer\/\d+$/.test(window.location.pathname) &&
        campaignParams.running
      ) {
        dispatch({
          type: "SET_CAMPAIGN_PARAMS",
          payload: {
            campaignParams: {
              ...campaignParams,
              contacts: data?.data?.contacts,
              running: campaignParams.running
                ? !!contacts?.length
                : campaignParams.running,
            },
          },
        });
      }
    },
    enabled:
      !!id &&
      !/^\/power-dialer\/\d+$/.test(window.location.pathname) &&
      campaignParams.running,
  });

  const getIsPWCampaignInBusinessHours = (business_hours: any) => {
    if (!business_hours) {
      return true;
    }
    const weekDay = moment().day();
    const isWeekend = weekDay === 6 || weekDay === 0;
    const {
      week_end_time,
      week_start_time,
      weekend_start_time,
      weekend_end_time,
    } = business_hours || {};
    return isWeekend
      ? isTimeBetween(
          weekend_start_time,
          weekend_end_time,
          moment().format("HH:mm")
        )
      : isTimeBetween(week_start_time, week_end_time, moment().format("HH:mm"));
  };

  useEffect(() => {
    let interval: any;
    const handleCallWithDispatch = () => {
      handleCall();
      dispatch({
        type: "SET_CAMPAIGN_PARAMS",
        payload: {
          campaignParams: { ...campaignParams, firstContact: false },
        },
      });
    };

    const handleCall = async () => {
      const outOfBusinessHours = getIsPWCampaignInBusinessHours(business_hours);
      if (contacts[0]?.id && outOfBusinessHours) {
        makeOutgoingCall(
          dispatch,
          lineDevices[0]?.device as Device,
          contacts[0]?.phone_number,
          from,
          user,
          contacts[0]?.id || "",
          "Power Dialer Campaign",
          id,
          user?.dealership?.id || ""
        );
      } else if (running) {
        await togglePWCampaign({
          dealership_id: user?.dealership?.id,
          id: id,
          status: "pause",
          userId: user?.id,
        });
        await lockPowerDialer({
          dealership_id: user?.dealership?.id,
          campaignId: id,
          status: "unlock",
          userId: user?.id,
        });
        dispatch({
          type: "SET_CAMPAIGN_PARAMS",
          payload: {
            campaignParams: {
              contacts: [],
              firstContact: true,
              from: "",
              id: "",
              running: false,
              warmUpDuration: 0,
            },
          },
        });
        if (outOfBusinessHours) {
          toast({
            description: `Campaign is out of business hours, can't dial next call, redirecting to main listing...`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
        clearInterval(interval);
        /^\/power-dialer\/\d+$/.test(window.location.pathname) &&
          navigate("/power-dialer");
      }
    };
    if (running) {
      interval = setInterval(handleCallWithDispatch, warmUpDuration);

      !campaignInterval &&
        dispatch({
          type: "SET_CAMPAIGN_INTERVAL",
          payload: {
            campaignInterval: interval,
          },
        });
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    campaignInterval,
    campaignParams,
    contacts,
    dispatch,
    firstContact,
    from,
    id,
    lineDevices,
    navigate,
    running,
    toast,
    user,
    warmUpDuration,
  ]);

  useEffect(() => {
    function handler(e: BeforeUnloadEvent) {
      e.preventDefault();
      if (!running) {
        return;
      }

      e.returnValue =
        "The Power Dialer campaign is currently running. Are you sure you want to refresh the page?";
    }

    const unloadHandler = (e: any) => {
      e.preventDefault();
      if (!running) {
        return;
      }

      try {
        fetch(
          `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${id}/pause_resume_dialer?status=pause`,
          {
            body: JSON.stringify({ userId: user?.id }),
            method: "POST",
            keepalive: true,
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        fetch(
          `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/power_dialer_campaigns/${id}/toggle_lock?lock_status=unlock&user_id=${user.id}`,
          {
            body: JSON.stringify({ userId: user?.id }),
            method: "POST",
            keepalive: true,
            headers: {
              Authorization: `Bearer ${user?.token}`,
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {}

      clearInterval(campaignInterval);
    };

    if (running) {
      window.addEventListener("beforeunload", handler);
      window.addEventListener("unload", unloadHandler);
    }
    return () => {
      window.removeEventListener("beforeunload", handler);
      window.removeEventListener("unload", unloadHandler);
    };
  }, [
    campaignInterval,
    id,
    running,
    user?.dealership?.id,
    user.id,
    user?.token,
  ]);

  const getIsMainRoute = () => {
    return !/^\/power-dialer\/\d+$/.test(window.location.pathname);
  };

  useEffect(() => {
    if (running && shouldUpdateCampaignContacts) {
      toast({
        description: !contacts[1]?.id
          ? "Power Dialer campaign completed, redirecting to listing..."
          : !getIsPWCampaignInBusinessHours(business_hours)
          ? `Campaign is out of business hours, can't dial next call, redirecting to main listing...`
          : warmUpDuration !== 3000 || !warmUpDuration
          ? `Call completed, dialing the next call in ${timeStrMapping[warmUpDuration]}...`
          : `Call completed, dialing the next call now...`,
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      getIsMainRoute() && refetch();
      dispatch({
        type: "SET_SHOULD_UPDATE_CAMPAIGN_PARAMS",
        payload: { shouldUpdateCampaignContacts: false },
      });
    }
  }, [
    business_hours,
    contacts,
    dispatch,
    refetch,
    running,
    shouldUpdateCampaignContacts,
    toast,
    warmUpDuration,
  ]);
};
