export type BusinessHours = {
  week_end_time: string;
  week_start_time: string;
  weekend_timings: boolean | null;
  weekend_end_time: string;
  weekend_start_time: string;
};

export const convertToMilitaryTime = (time: string): string => {
  if (!time) return "";
  const [timePart, modifier] = time?.split(/(am|pm)/i);
  let [hours, minutes] = timePart?.split(":").map(Number);

  // hours = (hours % 12) + (modifier.toLowerCase() === "pm" ? 12 : 0);
  hours =
    hours === 12 && modifier.toLowerCase() === "pm"
      ? 12
      : hours === 12 && modifier.toLowerCase() === "am"
      ? 0
      : hours;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const generateHalfHourSlots = (
  startDateTime: Date,
  endDateTime: Date
): string[] => {
  const slots: string[] = [];
  for (
    let current = new Date(startDateTime);
    current <= endDateTime;
    current.setMinutes(current.getMinutes() + 30)
  ) {
    const hours = current.getHours();
    const minutes = current.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHour = hours % 12 || 12;
    const formattedMinutes = minutes === 0 ? "00" : minutes;
    const slotTime = `${formattedHour}:${formattedMinutes} ${amPm}`;
    slots.push(slotTime);
  }

  return slots;
};

export const pickSlotTimes = (
  businessHours: BusinessHours,
  date: Date
): string[] => {
  const dayOfWeek = date?.getDay();
  const isWeekend = dayOfWeek === 0 || dayOfWeek === 6; // 0 (Sunday) to 6 (Saturday)

  let startTime =
    isWeekend && businessHours?.weekend_timings
      ? businessHours?.weekend_start_time
      : !isWeekend
      ? businessHours.week_start_time
      : null;
  let endTime =
    isWeekend && businessHours?.weekend_timings
      ? businessHours?.weekend_end_time
      : !isWeekend
      ? businessHours.week_end_time
      : null;

  if (!startTime || !endTime) return [];

  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);
  const startDateTime = new Date(date.setHours(startHour, startMinute, 0, 0));
  const endDateTime = new Date(date.setHours(endHour, endMinute, 0, 0));

  return generateHalfHourSlots(startDateTime, endDateTime);
};
