import {
  Button,
  Checkbox,
  DrawerFooter,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm, useWatch } from "react-hook-form";
import { getBrand, updateBrand } from "../../../utils/api/brand.api";
import { Drawer, Input, PhoneInput, Select } from "../../common";
import {
  BUSINESS_INDUSTRY_OPTIONS,
  BUSINESS_TYPE_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  JOB_TITLES,
  REGION_OF_OPERATIONS,
} from "./constants";
import { CreateBrandForm, createBrandValidation, defaultValues } from "./util";
import Loader from "../../common/Spinner";

type data = {
  isSimpSocial?: boolean;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
  isFromDealershipMenu?: boolean;
  selectedBrandId: string;
};

interface ApiResult {
  data: any;
}

const CreateBrand = ({ isOpen, onClose, selectedBrandId }: data) => {
  const toast = useToast();

  const queryClient = useQueryClient();

  const { handleSubmit, control, setValue, reset } = useForm<CreateBrandForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(createBrandValidation) as any,
  });

  const { isLoading } = useQuery({
    // queryKey: ["brandsApi", id, page, perPage, query, filter,advanceFilter?.value??''],
    queryKey: ["brandApi", selectedBrandId],
    queryFn: () => getBrand(selectedBrandId),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data: { data: CreateBrandForm }) {
      reset(data.data);
      setValue(
        "business_regions_of_operation",
        data.data?.business_regions_of_operation?.[0]
      );
    },
  });

  const addRepresentative = useWatch({ control, name: "add_representative" });
  const companyType = useWatch({ control, name: "company_type" });

  const mutation = useMutation<ApiResult, Error, CreateBrandForm, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateBrand(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      onClose();
      toast({
        description: "Brand registration process started successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error registering brand: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(["brandsApi"]);
    },
  });

  const handleFormSubmit = (values: CreateBrandForm) => {
    if (!values.add_representative) {
      delete values.representative_2_business_title;
      delete values.representative_2_email;
      delete values.representative_2_first_name;
      delete values.representative_2_job_position;
      delete values.representative_2_last_name;
      delete values.representative_2_phone_number;
    }

    mutation.mutate({
      ...values,
      id: selectedBrandId,
      business_regions_of_operation: [
        values.business_regions_of_operation,
      ] as any,
    });
  };

  console.log("companyType", companyType);

  return (
    <>
      {mutation.isLoading && <Loader />}
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        title="Register Brand"
        isLoading={isLoading}
      >
        <VStack
          alignItems="flex-start"
          padding="1rem 1.5rem"
          w="100%"
          gap="1rem"
        >
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Legal Business Name"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="business_name"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Text textStyle="h6" color="var(--grey-800)">
            Enter the exact legal business name, as registered with the EIN.
            E.g. Twilio Inc. rather than Twilio
          </Text>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Profile Friendly Name"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="brand_name"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Text textStyle="h3" fontWeight="600">
            Physical Business Address
          </Text>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Customer Name"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="customer_name"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Customer Email"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="email"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Country"
                  onChange={(value: any) =>
                    setValue("iso_country", value.value)
                  }
                  placeholder="Select Country"
                  options={[{ value: "US", label: "United States" }]}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="iso_country"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Street"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="street"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="City"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="city"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="State"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="region"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Zip"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="postal_code"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Text textStyle="h3" fontWeight="600">
            Business Information
          </Text>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Business Type"
                  onChange={(value: any) =>
                    setValue("business_type", value.value)
                  }
                  placeholder="Select Business Type"
                  options={BUSINESS_TYPE_OPTIONS}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="business_type"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Business Industry"
                  onChange={(value: any) =>
                    setValue("business_industry", value.value)
                  }
                  placeholder="Select Business Industry"
                  options={BUSINESS_INDUSTRY_OPTIONS}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="business_industry"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Business Registration Number"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="business_registration_number"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Business Region of Operations"
                  onChange={(value: any) => {
                    setValue("business_regions_of_operation", value.value);
                  }}
                  placeholder="Select Business Regions of Operation"
                  options={REGION_OF_OPERATIONS}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="business_regions_of_operation"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Business Website"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="website_url"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Social Media Profile Url"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                />
              );
            }}
            name="social_media_profile_urls"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  label="Organization Type"
                  onChange={(value: any) =>
                    setValue("company_type", value.value)
                  }
                  placeholder="Select Organization Type"
                  options={COMPANY_TYPE_OPTIONS}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="company_type"
            control={control}
            rules={{
              required: true,
            }}
          />

          {companyType === "public_company" && (
            <>
              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Stock Exchange"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="stock_exchange"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Stock Ticker"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="stock_ticker"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </>
          )}

          <Text textStyle="h3" fontWeight="600">
            Point of Contact
          </Text>

          <Text textStyle="h5" fontWeight="600">
            Authorized Representative #1
          </Text>

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="representative_1_first_name"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="representative_1_last_name"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Email"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="representative_1_email"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <PhoneInput
                  label="Phone Number"
                  placeholder="Enter phone number"
                  onChange={onChange}
                  isRequired
                  value={value}
                  error={error?.message}
                />
              );
            }}
            name="representative_1_phone_number"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Input
                  label="Business Title"
                  value={value}
                  onChange={onChange}
                  error={error?.message}
                  isRequired
                />
              );
            }}
            name="representative_1_business_title"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Select
                  isRequired
                  label="Job Title"
                  onChange={(value: any) =>
                    setValue("representative_1_job_position", value.value)
                  }
                  placeholder="Select Job Title"
                  options={JOB_TITLES}
                  variant="default"
                  w="100%"
                  value={value}
                />
              );
            }}
            name="representative_1_job_position"
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Checkbox
                  variant="whiteCheck"
                  size="lg"
                  onChange={({ target: { checked } }) =>
                    setValue("add_representative", checked)
                  }
                  isChecked={value}
                >
                  Add 2nd Auth Rep
                </Checkbox>
              );
            }}
            name="add_representative"
            control={control}
            rules={{
              required: true,
            }}
          />

          {addRepresentative && (
            <>
              <Text textStyle="h5" fontWeight="600">
                Authorized Representative #2
              </Text>

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="First Name"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="representative_2_first_name"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Last Name"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="representative_2_last_name"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Email"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="representative_2_email"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <PhoneInput
                      label="Phone Number"
                      placeholder="Enter phone number"
                      onChange={onChange}
                      isRequired
                      value={value}
                      error={error?.message}
                    />
                  );
                }}
                name="representative_2_phone_number"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Input
                      label="Business Title"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      isRequired
                    />
                  );
                }}
                name="representative_2_business_title"
                control={control}
                rules={{
                  required: true,
                }}
              />

              <Controller
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => {
                  return (
                    <Select
                      isRequired
                      label="Job Title"
                      onChange={(value: any) =>
                        setValue("representative_2_job_position", value.value)
                      }
                      placeholder="Select Job Title"
                      options={JOB_TITLES}
                      variant="default"
                      w="100%"
                      value={value}
                    />
                  );
                }}
                name="representative_2_job_position"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </>
          )}
        </VStack>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Register
          </Button>
        </DrawerFooter>
      </Drawer>
    </>
  );
};

export default CreateBrand;
