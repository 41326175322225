import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import AsyncSelect from "react-select/async";
import { Drawer, Input } from "../../../../../../components/common";
import { CustomSelect } from "../../../../../../theme/components";
import { getVehicles } from "../../../../../../utils/api/inventory-mapping.api";
import { createVehicle } from "../../../../../../utils/api/vehicles";

interface AddVehicleDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  info: any;
}

const AddVehicleDrawer = ({ isOpen, onClose, info }: AddVehicleDrawerProps) => {
  const [searchValue, setSearchValue] = useState<any>(null);
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [mileage, setMileage] = useState("");
  const [yearError, setYearError] = useState("");
  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");

  const mutation = useMutation(createVehicle);
  const toast = useToast();

  const handleSubmit = (event: any) => {
    if (!searchValue) {
      let yearErr = false;
      let makeErr = false;
      let modelErr = false;

      if (!year) {
        setYearError("Please enter years");
        yearErr = true;
      }
      if (!make) {
        setMakeError("Please enter make");
        makeErr = true;
      }
      if (!model) {
        setModelError("Please enter model");
        modelErr = true;
      }

      if (yearErr || makeErr || modelErr) {
        return;
      }
    }
    mutation.mutate(
      {
        ...info,
        payload: {
          vehicle: {
            year,
            make,
            model,
            exterior_color: exteriorColor,
            interior_color: interiorColor,
            mileage,
            inventory_id: searchValue?.value,
          },
        },
      },
      {
        onSuccess: () => {
          onClose();
          toast({
            description: "Vehicle added successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error adding vehicle: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const loadVehicles = async (searchStr: string, id?: string) => {
    try {
      const response = await getVehicles({ searchStr, dealershipId: id });
      const options = response?.data?.map((vehicle: any) => ({
        value: `${vehicle["id"]}`,
        label: `${vehicle["year"]} - ${vehicle["make"]} - ${vehicle["model"]}`,
      }));

      return options;
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Add a Vehicle">
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <div style={{ width: "100%" }}>
          <AsyncSelect
            styles={{
              ...CustomSelect?.styles?.customSelect?.default,
              indicatorsContainer: (provided) => ({
                ...provided,
                display: "none",
              }),
            }}
            loadOptions={(inputValue) => loadVehicles(inputValue, info?.dId)}
            value={searchValue}
            onChange={(value: any) => {
              setSearchValue(value);
            }}
            placeholder="Type here to search a vehicle"
            isClearable
            isDisabled={
              !!year || !!make || !!model || !!interiorColor || !!exteriorColor
            }
            defaultOptions
          />
        </div>
      </VStack>
      <HStack w="100%" gap="1.5rem">
        <Box
          as="span"
          width="100%"
          height="1px"
          background="var(--grey-300)"
        ></Box>
        <Box
          as="span"
          fontSize="1.125rem"
          fontWeight="600"
          color="var(--secondary-600)"
          borderRadius="6.25rem"
          background="var(--secondary-50)"
          padding="0.31rem 1.19rem"
        >
          Or
        </Box>
        <Box
          as="span"
          width="100%"
          height="1px"
          background="var(--grey-300)"
        ></Box>
      </HStack>
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Enter Year"
          label="Year"
          onChange={(e: any) => {
            setYear(e.target.value);
            setYearError("");
          }}
          type="number"
          isRequired={!searchValue}
          isDisabled={!!searchValue}
          error={searchValue ? "" : yearError}
        />
        <Input
          placeholder="Enter Make"
          label="Make"
          onChange={(e: any) => {
            setMake(e.target.value);
            setMakeError("");
          }}
          isRequired={!searchValue}
          isDisabled={!!searchValue}
          error={searchValue ? "" : makeError}
        />
        <Input
          placeholder="Enter Model"
          label="Model"
          onChange={(e: any) => {
            setModel(e.target.value);
            setModelError("");
          }}
          isRequired={!searchValue}
          isDisabled={!!searchValue}
          error={searchValue ? "" : modelError}
        />
        <Input
          placeholder="Enter Exterior Color"
          label="Exterior Color"
          onChange={(e: any) => {
            setExteriorColor(e.target.value);
          }}
          isDisabled={!!searchValue}
        />
        <Input
          placeholder="Enter Interior Color"
          label="Interior Color"
          onChange={(e: any) => {
            setInteriorColor(e.target.value);
          }}
          isDisabled={!!searchValue}
        />
        <Input
          placeholder="Enter Mileage"
          label="Mileage"
          onChange={(e: any) => {
            setMileage(e.target.value);
          }}
          isDisabled={!!searchValue}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default AddVehicleDrawer;
