import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { convertDate } from "../../../utils";
import { fetchTrainingVideos } from "../../../utils/api/training-videos.api";
import { Dropdown, PaginatedTable, Spinner } from "../../common";
import CustomInput from "../../common/Input";
import { EditIcon, SearchIcon } from "../../icons/svg";
import DeleteTrainingVideos from "./DeleteTrainingVideo";
import EditVideo from "./EditVideo";
import UploadVideo from "./UploadVideo";

const TrainingVideos = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];

  const tableHeader = [
    { value: "Video Title", label: "title" },
    { value: "Description", label: "description" },
    { value: "Upload Date", label: "date" },
    { value: "Views Count", label: "viewsCount" },
    { value: "Actions", label: "actions" },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchTrainingVideos", searchValue, page, perPage],
    queryFn: () => {
      const params: any = {};

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }
      return fetchTrainingVideos(params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(data?.training_videos_count ?? 0);
    },
  });
  const createDateTimeField = (dateArray: string[]) => (
    <HStack gap="1rem">
      <VStack alignItems="flex-start" gap="0">
        <Text textStyle="h6" fontWeight="500">
          {dateArray?.[0] ?? "-"}
        </Text>
        <Text textStyle="h6" fontWeight="500">
          {dateArray?.[1] ?? null}
        </Text>
      </VStack>
    </HStack>
  );

  const tableData = data?.training_videos?.map((item: any) => {
    return {
      id: item.id,
      title: (
        <HStack gap="1rem">
          {item?.thumbnail_url ? (
            <Image
              src={item.thumbnail_url}
              width={38}
              height={38}
              style={{
                borderRadius: "50%",
              }}
              alt="avatar"
            />
          ) : (
            <AccountCircleIcon />
          )}
          <VStack alignItems="flex-start" gap="0">
            <Text textStyle="h6" fontWeight="500">
              {item.title}
            </Text>
          </VStack>
        </HStack>
      ),
      description: item.description,
      date: (
        <Text textStyle="h6" fontWeight="500" whiteSpace="nowrap">
          {createDateTimeField(convertDate(item.upload_date)) ?? "-"}
        </Text>
      ),
      viewsCount: item.views_count ?? 0,
      actions: <Dropdown options={options} onSelect={handleSelect} />,
    };
  });

  return (
    <VStack w="100%" alignItems="flex-start" gap="0">
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1rem"
        background="white"
      >
        <CustomInput
          maxW="20rem"
          type="text"
          hasIcon={true}
          isIconRight={false}
          icon={<SearchIcon />}
          placeholder="Type here to search.."
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
        />
        <Button onClick={onOpen}>Upload Video</Button>
      </HStack>

      <Box w="100%" alignItems="flex-start" background="white">
        <Text
          textStyle="caption"
          padding="0rem 1rem 0.75rem"
          w="100%"
          color="var(--grey-600)"
        >
          Total Videos ({count})
        </Text>
        <PaginatedTable
          header={tableHeader}
          data={tableData}
          tableHeight={420}
          itemsPerPage={perPage}
          maxPageNumbers={3}
          isPaginated={true}
          lastCenter={false}
          hasMultiBody={false}
          rowOnClick={(rowItem) => {
            setClickedRow(rowItem);
          }}
          perPageChange={(value) => {
            setPerPage(Number(value?.label));
            setPage(1);
          }}
          currentPage={page}
          setCurrentPage={setPage}
          rowCount={count}
          isLoadingData={isLoading}
        />
      </Box>

      {isOpen && (
        <UploadVideo
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        />
      )}

      {activeModal === "edit" && (
        <EditVideo
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}

      {activeModal === "delete" && (
        <DeleteTrainingVideos
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
        />
      )}
    </VStack>
  );
};

export default TrainingVideos;
