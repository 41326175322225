import { useMutation } from "@tanstack/react-query";
import { Drawer, Input } from "../../common";
import { Box, Button, DrawerFooter, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Select } from "../../../components/common";
import {
  CategoryValues,
  defaultValues,
  CreateScriptForm,
  createScriptValidation,
} from "./utils";
import { Controller, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createScript,editScript } from "../../../utils/api/scripts.api";
import { useEffect } from "react";
import Loader from "../../common/Spinner";
type data = {
  isOpen: boolean;
  onClose: () => void;
  editData:any;
  refetch: () => void;
};

const CreateNewScript = (props: data) => {
  const { id: dId } = useParams();
  const { isOpen, onClose } = props;
  const toast = useToast();
  const mutation = useMutation(props?.editData?editScript:createScript);
  const { handleSubmit, control, setValue, reset } = useForm<CreateScriptForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(createScriptValidation) as any,
  });
  useEffect(()=>{
    if(props?.editData){
      reset(props?.editData)
    }


  },[props?.editData])
  const handleFormSubmit = (values: CreateScriptForm) => {
    mutation.mutate(
      {
        id: dId,
        script_id:props?.editData?.id,
        data: {
          description: values?.description,
          script_type: values?.script_type,
          title: values?.title
        },
      },
      {
        onSuccess(data, variables, context) {
          onClose();
          props?.refetch();
          toast({
            description: props?.editData?"Script updated successfully.":"Script created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Create Script"
      isLoading={mutation?.isLoading}
    >
      <Box padding="1.25rem">
        <Box
          background="var(--grey-50)"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          padding="1rem"
        >
             <Box mb="1rem">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Select
                    label="Category"
                    options={CategoryValues}
                    placeholder="Select Category"
                    onChange={(value) => onChange(value.value)}
                    variant="default"
                    w="100%"
                    value={value}
                    error={error?.message}
                    isRequired
                  />
                );
              }}
              name="script_type"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
          <Box mb="1rem">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Title"
                    type="text"
                    maxW="lg"
                    isRequired={true}
                    error={error?.message}
                    value={value}
                    onChange={onChange}
                  />
                );
              }}
              name="title"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
          <Box mb="1rem">
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Input
                    label="Text Body "
                    isTextarea={true}
                    placeholder="Type Here.."
                    maxW="lg"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    isRequired
                  />
                );
              }}
              name="description"
              control={control}
              rules={{
                required: true,
              }}
            />
          </Box>
       
        </Box>

        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button
            w="100%"
            size="xl"
            onClick={(event) => {
              event.preventDefault();
              handleSubmit((values) => handleFormSubmit(values))();
            }}
          >
            Confirm
          </Button>
        </DrawerFooter>
      </Box>
      {mutation?.isLoading&&<Loader/>}
    </Drawer>
  );
};

export default CreateNewScript;
