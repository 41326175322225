import { useRef } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Text,
  HStack,
  Box,
  VStack,
} from "@chakra-ui/react";
import { WarningIcon } from "../icons/svg";

interface AlertBoxProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  message: string;
  list?: any;
  listTitle?: string;
}

const AlertBox: React.FC<AlertBoxProps> = ({
  isOpen,
  onClose,
  title,
  message,
  listTitle,
  list = [], // Default to empty array if no list is provided
}) => {
  const ref = useRef(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={ref}
      onClose={onClose}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <Text mb={4}>
              {listTitle}
            </Text>
            {/* Render the list of people */}
            {list?.length > 0 && (
              <VStack align="start" spacing={2} mb={4} pl={4}>
                {list.map((item: any, index: any) => (
                  <Text key={index} fontWeight="500" fontSize="md">
                    {item?.name}
                  </Text>
                ))}
              </VStack>
            )}
            {/* Warning message box */}
            <HStack
              w="100%"
              padding="1rem"
              position="relative"
              paddingLeft="2rem"
              background="#FFF3E0"
              borderRadius="md"
              mb={4}
            >
              <Box
                height="100%"
                width="0.75rem"
                position="absolute"
                background="#FB8C00"
                left="0"
                top="0"
                borderTopLeftRadius="md"
                borderBottomLeftRadius="md"
              ></Box>
              <WarningIcon />
              <Text ml={2} fontSize="sm">
                {message}
              </Text>
            </HStack>
          </AlertDialogBody>

          <HStack w="100%" padding="1.5rem">
            <Button ref={ref} onClick={onClose} variant="outline" w="100%">
              Close
            </Button>
          </HStack>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default AlertBox;
