import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { simpAdminChecker } from "../../../utils";
import {
  fetchDisposition,
  fetchDispositionDS,
} from "../../../utils/api/disposition-settings.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import {
  Dropdown,
  Input,
  PaginatedTable,
  Popover,
  Spinner,
} from "../../common";
import { InfoIcon, SearchIcon } from "../../icons/svg";
import CreateDispositionSettings from "./CreateDispositionSettings";
import EditDispositionSettings from "./EditDispositionSettings";
import SuspendOrReActivate from "./SuspendOrReActivate";

const DispositionSettings = (props: any) => {
  const { mappings, dp = false } = props;
  const { id: dId } = useParams();
  const dealerId = useUserInfo("dealership");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [searchValue, setSearchValue] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);
  const [count, setCount] = useState<number>(0);
  const [clickedRow, setClickedRow] = useState<any>({
    id: 0,
    name: "",
  });
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [isActive, setIsActive] = useState<boolean>(true);

  const tableHeader = [
    { value: "Synced with CRM", label: "synced" },
    { value: "Name", label: "name" },
    { value: "Description", label: "description" },
    ...(dId || dealerId?.id ? [{ value: "Type", label: "type" }] : []),
    { value: "Actions", label: "actions" },
  ];
  const role = useUserInfo("user")?.role;
  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";

  const isEditableRole = impSSRole
    ? simpAdminChecker(impSSRole)
    : simpAdminChecker(role);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      "fetchDispositionSettings",
      isActive,
      searchValue,
      page,
      perPage,
    ],
    queryFn: () => {
      const params: any = { status: isActive ? "active" : "suspended" };

      if (!searchValue) {
        params.page = page;
        params.per_page = perPage;
      } else {
        params.q = searchValue;
      }

      return dId
        ? fetchDispositionDS(dId, params)
        : dealerId?.id
        ? fetchDispositionDS(dealerId?.id, params)
        : fetchDisposition(params);
    },
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setCount(data?.contact_dispositions_count ?? 0);
    },
  });

  const optionsActive: SelectOptionProps[] = [
    { label: "Edit ", value: "edit" },
    { label: "Suspend", value: "suspend" },
  ];
  const optionsSuspended: SelectOptionProps[] = [
    { label: "Edit ", value: "edit" },
    { label: "Re-Activate", value: "reActivate" },
  ];

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };
  const createActionField = (
    options: SelectIconOptionProps[],
    handleSelect: (selectedOption: SelectIconOptionProps) => void,
    type: string,
    hardcoded: boolean
  ) => {
    return (
      <Dropdown
        options={options}
        onSelect={handleSelect}
        disabled={
          ((dId || dealerId?.id) && type === "simpsocial_provided") ||
          !isEditableRole ||
          hardcoded
        }
      />
    );
  };

  const tableData = data?.contact_dispositions.map((item: any) => ({
    id: item.id || 0,
    name: item.name || "-",
    synced: item.synced,
    description: item.description,
    type:
      item.type === "simpsocial_provided"
        ? "SimpSocial Provided"
        : "Dealership Provided",
    actions: createActionField(
      isActive ? optionsActive : optionsSuspended,
      handleSelect,
      item.type,
      item?.hardcoded
    ),
  }));

  //.....combining common elements.........
  const renderCommonContent = () => (
    <>
      {isOpen && (
        <CreateDispositionSettings
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
        ></CreateDispositionSettings>
      )}

      {activeModal === "edit" && (
        <EditDispositionSettings
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          mappings={[...(mappings || []), "Update"]}
        />
      )}

      {activeModal === "reActivate" && (
        <SuspendOrReActivate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          susccessToast="Re-Activated successfully."
          failureToast="Error re-activating."
          payload={{
            contact_disposition: {
              status: "active",
            },
          }}
          title="Re-Activate Disposition Setting"
          bodyText="Are you sure you want to re-activate the disposition setting?"
          endpoint={
            dId
              ? `/api/admin/dealerships/${dId}/contact_dispositions/${clickedRow?.id}`
              : dealerId?.id
              ? `/api/admin/dealerships/${dealerId?.id}/contact_dispositions/${clickedRow?.id}`
              : `/api/admin/contact_dispositions/${clickedRow?.id}`
          }
          mappings={[...(mappings || []), "Re-Activate"]}
          dId={dId ? dId : dealerId?.id ? dealerId?.id : ""}
        />
      )}
      {activeModal === "suspend" && (
        <SuspendOrReActivate
          isOpen={true}
          onClose={() => setActiveModal(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          susccessToast="Suspended successfully."
          failureToast="Error suspending."
          payload={{
            contact_disposition: {
              status: "suspended",
            },
          }}
          title="Suspend Disposition Setting"
          bodyText="Are you sure you want to suspend the disposition setting?"
          endpoint={
            dId
              ? `/api/admin/dealerships/${dId}/contact_dispositions/${clickedRow?.id}`
              : dealerId?.id
              ? `/api/admin/dealerships/${dealerId?.id}/contact_dispositions/${clickedRow?.id}`
              : `/api/admin/contact_dispositions/${clickedRow?.id}`
          }
          mappings={[...(mappings || []), "Suspend"]}
          dId={dId ? dId : dealerId?.id ? dealerId?.id : ""}
        />
      )}
    </>
  );
  if (dId) {
    return (
      <Box>
        <HStack justifyContent="space-between" padding="1.25rem 1rem">
          <HStack border="1px solid var(--grey-300)" borderRadius="0.5rem">
            <Button
              variant="none"
              padding="0.5rem 1rem"
              onClick={() => setIsActive(true)}
              borderRadius="0.5rem"
              h="100%"
              fontWeight="500"
              background={isActive ? "var(--secondary-600)" : "white"}
              color={isActive ? "white" : "var(--grey-900)"}
            >
              Active
            </Button>
            <Button
              variant="none"
              h="100%"
              padding="0.5rem 1rem"
              borderRadius="0.5rem"
              fontWeight="500"
              onClick={() => setIsActive(false)}
              color={!isActive ? "white" : "var(--grey-900)"}
              background={!isActive ? "var(--secondary-600)" : "white"}
            >
              Suspended
            </Button>
          </HStack>

          {isEditableRole && (
            <Button onClick={onOpen}>Create Disposition Setting</Button>
          )}
        </HStack>
        <Text
          textStyle="h6"
          color=" var(--grey-600)"
          mt="20px"
          padding="0rem 1rem 0.75rem"
        >
          Total Disposition Settings ({count})
        </Text>
        <Box mt="1rem">
          <PaginatedTable
            header={tableHeader}
            data={tableData}
            itemsPerPage={perPage}
            maxPageNumbers={3}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
            rowOnClick={(rowItem) => {
              setClickedRow(rowItem);
            }}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
              setPage(1);
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={count}
            isLoadingData={isLoading}
          />
        </Box>
        {renderCommonContent()}
      </Box>
    );
  } else {
    return (
      <VStack
        w="100%"
        alignItems="flex-start"
        gap="0"
        overflow="auto"
        h="calc(100vh - 172px)"
      >
        <HStack
          w="100%"
          justifyContent="space-between"
          padding="1.25rem 1.5rem"
          background="white"
        >
          <HStack gap="0.625rem" w="100%" justifyContent="flex-start">
            <Input
              maxW="20rem"
              type="text"
              hasIcon={true}
              isIconRight={false}
              icon={<SearchIcon />}
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
            <HStack border="1px solid var(--grey-300)" borderRadius="0.5rem">
              <Button
                variant="none"
                padding="0.5rem 1rem"
                onClick={() => setIsActive(true)}
                borderRadius="0.5rem"
                h="100%"
                fontWeight="500"
                background={isActive ? "var(--secondary-600)" : "white"}
                color={isActive ? "white" : "var(--grey-900)"}
              >
                Active
              </Button>
              <Button
                variant="none"
                h="100%"
                padding="0.5rem 1rem"
                borderRadius="0.5rem"
                fontWeight="500"
                onClick={() => setIsActive(false)}
                color={!isActive ? "white" : "var(--grey-900)"}
                background={!isActive ? "var(--secondary-600)" : "white"}
              >
                Suspended
              </Button>
            </HStack>
          </HStack>
          {isEditableRole && (
            <Button onClick={onOpen} padding="0 2.25rem">
              Create Disposition Setting
            </Button>
          )}
          {dp && (
            <Popover
              btnVariant="outline"
              variant="custom"
              trigger={<InfoIcon color="var(--grey-800)" />}
              contentStyle={{ width: "28rem", borderRadius: "1rem" }}
            >
              <Box padding="2rem">
                <Text
                  textStyle="h6"
                  lineHeight="1.5rem"
                  color="var(--grey-800)"
                  mb="1.25rem"
                >
                  By using lead dispositions, you can streamline their sales
                  processes, prioritize follow-ups, and analyze the
                  effectiveness of their lead management strategies.
                </Text>
                <Text
                  textStyle="h6"
                  lineHeight="1.5rem"
                  color="var(--grey-800)"
                >
                  This classification system helps in fostering more efficient
                  communication and ensuring that each lead receives appropriate
                  attention and effort from the sales team.
                </Text>
              </Box>
            </Popover>
          )}
        </HStack>
        <VStack w="100%" alignItems="flex-start" padding="1.5rem">
          <Box
            w="100%"
            alignItems="flex-start"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
          >
            <Text
              textStyle="caption"
              padding="1.5rem 1rem 0.75rem"
              w="100%"
              color="var(--grey-600)"
            >
              Total Disposition Settings ({count})
            </Text>
            <PaginatedTable
              header={tableHeader}
              data={tableData}
              itemsPerPage={perPage}
              maxPageNumbers={3}
              isPaginated={true}
              lastCenter={false}
              hasMultiBody={false}
              rowOnClick={(rowItem) => {
                setClickedRow(rowItem);
              }}
              perPageChange={(value) => {
                setPerPage(Number(value?.label));
                setPage(1);
              }}
              currentPage={page}
              setCurrentPage={setPage}
              rowCount={count}
              isLoadingData={isLoading}
            />
          </Box>
        </VStack>
        {renderCommonContent()}
      </VStack>
    );
  }
};

export default DispositionSettings;
