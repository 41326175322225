import { Button } from "@chakra-ui/button";
import { VStack } from "@chakra-ui/layout";
import { DrawerFooter } from "@chakra-ui/modal";
import { useToast } from "@chakra-ui/toast";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Drawer, Input } from "../../../../components/common";
import { saveAsTag } from "../../../../utils/api/contact-details";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import { getTransformedFilters } from "../../../../utils";

const SaveAsTagDrawer = ({ isOpen, onClose, contactIds }: any) => {
  const toast = useToast();
  const dId = useUserInfo("dealership")?.id;
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const mutation = useMutation(saveAsTag);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!name?.trim()) {
      setNameError("Tag Name cannot be empty");
      return;
    }

    setNameError("");

    const searchAndFilters = JSON.parse(
      localStorage.getItem("search_and_filter") || "null"
    );

    mutation.mutate(
      {
        dId,
        payload: {
          contact: {
            tag_name: name,
          },
          filter_and_sort: getTransformedFilters(searchAndFilters),
        },
      },
      {
        onSuccess: () => {
          onClose();
          toast({
            description: "Tag saved successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error saving tag: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Save As Tag"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Write Tag"
          label="Enter Tag"
          onChange={(e: any) => setName(e.target.value)}
          value={name}
          isRequired={true}
          error={nameError}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default SaveAsTagDrawer;
