import { Badge, Box, HStack, Text, useToast } from "@chakra-ui/react";
import { Dropdown, Input, PaginatedTable } from "../../common";
import { ExcelIcon, PDFIcon, SearchIcon } from "../../icons/svg";

import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import fileDownload from "js-file-download";
import moment from "moment";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVICES } from "../../../config";
import { humanize } from "../../../utils";
import {
  fetchEmailBlast,
  pauseEmailCampaign,
  resumeEmailCampaign,
} from "../../../utils/api/broadcast.api";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import PauseModal from "../../BroadcastMessage/PauseModal";
import EmailDetail from "../../LeadCenter/LeadDetails/DetailTabs/History/Emails/EmailDetail";
import Loader from "../../common/Spinner";

const options: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

const getOption = (status: string) => {
  switch (status) {
    case "pending":
      return [{ label: "Pause", value: "pause" }];
    case "paused":
      return [{ label: "Resume", value: "resume" }];
    case "in_progress":
      return [{ label: "Pause", value: "pause" }];
    case "completed":
      return [];
    default:
      return [];
  }
};

const BDCCampaignHeader = [
  { id: 2, value: "Type", label: "type" },
  { id: 2, value: "To", label: "to" },
  { id: 3, value: "Subject", label: "subject" },
  { id: 4, value: "Date & Time", label: "date" },
  { id: 4, value: "Status", label: "status" },
  { id: 4, value: "Open Rate", label: "open_rate" },
  { id: 5, value: "Actions", label: "actions" },
];

const Completed = () => {
  const [tableView, setTableView] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const [searchStr, setSearchStr] = useState("");
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);

  const user = useUserInfo("user");
  const toast = useToast();

  const navigate = useNavigate();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["emailBlast", page, perPage, searchStr],
    queryFn: () =>
      fetchEmailBlast({
        dealershipId: user?.dealership?.id,
        page,
        perPage,
        search: searchStr,
      }),
    retry: false,
  });

  const { emails = [], emails_count = 0 } = data?.data || {};

  const handleSelect = (option: SelectOptionProps) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${user?.dealership?.id}/outbound_emails_list.${option.value}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `EmailBlast.${option.value}`);
      })
      .catch((response) => {});
    setSelectedOption(option);
  };

  const handleSelectOption = (option: SelectOptionProps, id: string) => {
    setSelectedCampaign(id);

    if (option.value === "pause") {
      return setIsPauseModalOpen(true);
    }

    if (option.value === "resume") {
      return resumeMutation.mutate({});
    }
  };

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await pauseEmailCampaign({
          dealership_id: user?.dealership?.id,
          id: selectedCampaign,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPauseModalOpen(false);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const resumeMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await resumeEmailCampaign({
          id: selectedCampaign,
          dealership_id: user?.dealership?.id,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      setIsPauseModalOpen(false);
      refetch();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handlePause = ({
    scheduleDate,
    isIndefinitePeriod,
  }: {
    scheduleDate: string;
    isIndefinitePeriod: boolean;
  }) => {
    mutation.mutate({
      schedule_date: scheduleDate,
      indefinite_period: isIndefinitePeriod,
    });
  };

  const emailBlastItems = useMemo(() => {
    if (!emails?.length) {
      return [];
    }

    return emails.map((item: any) => {
      const ccCount = item.cc_address?.split(",")?.length;
      return {
        ...item,
        from: <Text whiteSpace="nowrap">{item.from_address}</Text>,
        to:
          item.internal_action === "campaign_outbound" ||
          (item.internal_action === "outbound" && !!item.cc_address) ? (
            <HStack>
              <Text
                color="var(--primary-600)"
                cursor="pointer"
                onClick={() => navigate(`/emails/communication/${item.id}`)}
              >
                See Contacts
              </Text>
              {(item?.contacts_count || ccCount) && (
                <Badge
                  ml="0.5rem"
                  colorScheme="red"
                  borderRadius="full"
                  w="24px"
                  h="24px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="0.75rem"
                >
                  {item?.contacts_count || ccCount}
                </Badge>
              )}
            </HStack>
          ) : (
            <Text whiteSpace="nowrap">{item.to_address}</Text>
          ),
        type: humanize(
          item.internal_action === "campaign_outbound"
            ? "Blast"
            : item.internal_action === "outbound"
            ? "Single"
            : ""
        ),
        message: item.body,
        subject: item.subject || "- -",
        date: moment(item.created_at).format("MM-DD-YYYY - hh:mm A"),
        status:
          item.internal_action === "campaign_outbound"
            ? humanize(item.job_status)
            : humanize(item.status),
        open_rate: item?.open_rate,
        actions:
          item.internal_action === "campaign_outbound" &&
          item.job_status !== "completed" ? (
            <Dropdown
              options={getOption(item.job_status)}
              onSelect={(option) => handleSelectOption(option, item.id)}
            />
          ) : (
            <></>
          ),
      };
    });
  }, [emails]);

  return (
    <Box>
      {tableView && (
        <>
          <HStack justifyContent="space-between" mb="1rem">
            <Text textStyle="h4" color="var(--grey-900)" fontWeight="500">
              Emails ({emails_count})
            </Text>
            <Box
              display="flex"
              alignItems="center"
              width="27.5rem"
              className="emailInboxSearch"
            >
              <Input
                maxW="20rem"
                type="text"
                hasIcon={true}
                isIconRight={false}
                icon={<SearchIcon />}
                placeholder="Search..."
                onChange={({ target: { value } }) => setSearchStr(value)}
                value={searchStr}
                marginRight={5}
                height="35px"
              />
              <Dropdown
                options={options}
                onSelect={handleSelect}
                hasText={true}
                text="Export"
              />
            </Box>
          </HStack>
          <PaginatedTable
            header={BDCCampaignHeader}
            data={emailBlastItems}
            itemsPerPage={perPage}
            maxPageNumbers={5}
            isPaginated={true}
            hasMultiBody={false}
            lastCenter={false}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
              setPage(1);
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={emails_count}
            rowOnClick={(item: any) => {
              setSelectedItem(item);
              setTableView(false);
            }}
            columnMinWidths={["", "", "10rem", "", "", "", ""]}
            isLoadingData={isLoading}
          />
        </>
      )}
      {!tableView && (
        <EmailDetail
          clickedRecord={selectedItem}
          setClickedRecord={(value: any) => {
            setSelectedItem(value);
            setTableView(true);
          }}
        />
      )}

      {isPauseModalOpen && (
        <PauseModal
          isOpen={isPauseModalOpen}
          handlePause={handlePause}
          onClose={() => setIsPauseModalOpen(false)}
        />
      )}
    </Box>
  );
};

export default Completed;
