import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

interface DataPoint {
  period: string;
  messageCount?: number;
  avgResponseTime?: number;
  engagement_count?: number;
  hour?: string;
  messagesSent?: number;
  repliesReceived?: number;
}

interface LeadEngagementChartProps {
  data: DataPoint[];
}

const chartContainerStyle: React.CSSProperties = {
  padding: "20px",
  margin: "20px 0",
  border: "1px solid #ddd",
  borderRadius: "8px",
  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
  backgroundColor: "#fff",
  width: "100%",
};

const LeadEngagementChart: React.FC<LeadEngagementChartProps> = ({ data }) => {
  const [chartOptions, setChartOptions] = useState<ApexCharts.ApexOptions>({});
  const [chartSeries, setChartSeries] = useState<ApexAxisChartSeries>([]);

  useEffect(() => {
    const seriesData = prepareChartData(data, "engagement_count");

    setChartOptions({
      chart: {
        type: "bar",
        toolbar: {
          show: false
        }
      },
      xaxis: {
        type: "datetime", // Use datetime type for x-axis
        categories: seriesData.categories || [],
        labels: {
          format: "dd MMM", // Format the labels as needed
          rotate: -45, // Rotate labels for better readability
        },
        tickAmount: 10, // Adjust the number of ticks on the x-axis
      },
      title: {
        text: "Lead Messages per Day of the Month",
      },
      yaxis: {
        title: {
          text: "Number of Messages",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
    });

    setChartSeries([
      {
        name: "Engagements",
        data: seriesData.values || [],
      },
    ]);
  }, [data]);

  const prepareChartData = (data: DataPoint[], key: keyof DataPoint) => {
    const categories: string[] = [];
    const values: number[] = [];
    data?.forEach((item) => {
      categories.push(new Date(item.period).toISOString());
      if (item[key] !== undefined) {
        values.push(item[key] as number);
      }
    });
    return { categories, values };
  };

  return (
    <div style={chartContainerStyle}>
      <Chart
        options={chartOptions}
        series={chartSeries}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default LeadEngagementChart;
