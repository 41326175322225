export interface HistoryState {
  shouldRefetch: boolean;
  shouldRefetchDetail:boolean;
}

export type HistoryAction = 
  | { type: "SET_SHOULD_REFETCH", payload: { shouldRefetch: boolean } }
  | { type: "SET_SHOULD_REFETCH_DETAIL", payload: { shouldRefetchDetail: boolean } };

export const initialState: HistoryState = {
  shouldRefetch: false,
  shouldRefetchDetail:false
};

export const HistoryReducer = (
  state: HistoryState,
  action: HistoryAction
): HistoryState => {
  switch (action.type) {
    case "SET_SHOULD_REFETCH":
      return {
        ...state,
        shouldRefetch: action.payload.shouldRefetch,
      };
    case "SET_SHOULD_REFETCH_DETAIL":
      return{
        ...state,
        shouldRefetchDetail: action.payload.shouldRefetchDetail,
      };
    default:
      const _exhaustiveCheck: HistoryAction = action;
      throw new Error(`Unhandled action type: ${_exhaustiveCheck}`);
  }
};
