import { Input, PaginatedTable } from "../../../components/common";
import SwitchToggle from "../../../components/common/Switch";
import { LocationIcon } from "../../../components/icons/svg";
import { Box, Button, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  getLineSettings,
  modifyLineSettings,
} from "../../../utils/api/line-rings.api";
import { useState } from "react";
import Loader from "../../common/Spinner";
import { useToast } from "@chakra-ui/react";

const General = () => {
  const { id, dealerId } = useParams();
  const toast = useToast();
  const mutation = useMutation(modifyLineSettings);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["generalsettings", id, "general_settings"],
    queryFn: getLineSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setGeneralData(data?.data?.settings_hash);
    },
  });
  const [generalData, setGeneralData] = useState(data?.data?.settings_hash);

  const handleGeneralData = (field: string, value: any) => {
    setGeneralData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const tableHeader = [
    { id: 1, value: "Phone Numbers", label: "phone" },
    { id: 2, value: "Type", label: "type" },
    { id: 3, value: "Location", label: "location" },
    { id: 4, value: "Compliance Met", label: "complianceMet" },
  ];
  const tableData = [
    {
      phone: `${generalData?.phone_number ?? ""}`,
      type: (
        <HStack>
          <Text
            padding="0.5rem"
            background="var(--grey-200)"
            borderRadius="2.5rem"
            w="max-content"
          >
            {generalData?.type_of_line}
          </Text>
          {/* <Text
            padding="0.5rem"
            background="var(--grey-200)"
            borderRadius="2.5rem"
            w="max-content"
          >
            Primary
          </Text> */}
        </HStack>
      ),
      location: (
        <HStack>
          <LocationIcon />
          <Text>{generalData?.location}</Text>
        </HStack>
      ),
      complianceMet: (
        <VStack>
          {generalData?.compliance_met?.map((item: any) => (
            <HStack>
              <Box
                w="0.5rem"
                h="0.5rem"
                borderRadius="50%"
                background="var(--primary-600)"
              ></Box>
              <Text>{item}</Text>
            </HStack>
          ))}
          {/* <HStack>
            <Box
              w="0.5rem"
              h="0.5rem"
              borderRadius="50%"
              background="var(--red-500)"
            ></Box>
            <Text>A2P 10DLC</Text>
          </HStack> */}
        </VStack>
      ),
    },
  ];
  const handleSubmit = () => {
    mutation.mutate(
      {
        id: id,
        model: "general_settings",
        data: {
          key: "general_settings",
          settings_hash: generalData,
        },
        dealerId,
      },
      {
        onSuccess: () => {
          toast({
            description: "Settings updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <VStack w="100%" position="relative" alignItems="flex-start">
      <Box
        paddingTop="0.25rem"
        height="calc(100vh - 235px)"
        overflow="auto"
        w="100%"
      >
        <VStack
          gap="1.5rem"
          alignItems="flex-start"
          py="1.5rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack justifyContent="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Type:
            </Text>
            <Box
              as="span"
              py="0.25rem"
              px="0.62rem"
              color="var(--secondary-600)"
              fontSize="0.875rem"
              fontWeight="400"
              lineHeight="1.25rem"
              border="1px solid var(--secondary-600)"
              borderRadius="2.5rem"
              background="white"
            >
              {generalData?.type_of_line}
            </Box>
          </HStack>
          <HStack justifyContent="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Add Name:
            </Text>
            <Input
              type="text"
              maxW="30rem"
              placeholder="Enter your name"
              value={generalData?.line_name}
              onChange={(e) => handleGeneralData("line_name", e.target.value)}
            />
          </HStack>
        </VStack>
        <VStack
          gap="1.5rem"
          alignItems="flex-start"
          py="1.5rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack alignItems="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Add Description:
            </Text>
            <VStack alignItems="flex-start" width="50%">
              <Input
                type="text"
                isTextarea={true}
                maxW="30rem"
                placeholder="Type description"
                value={generalData?.description}
                onChange={(e) =>
                  handleGeneralData("description", e.target.value)
                }
              />
              <Text textStyle="h6" color="var(--grey-700)">
                Adding Description is optional
              </Text>
            </VStack>
          </HStack>
          <HStack alignItems="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Send long text messages as MMS:
            </Text>
            <VStack alignItems="flex-start" width="50%">
              <SwitchToggle
                id="1"
                label="Enable Send long text messages as MMS"
                defaultChecked={generalData?.send_message_as_mms}
                onChange={(check: boolean) =>
                  handleGeneralData("send_message_as_mms", check)
                }
              />
              <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                Messages longer than 160 characters will automatically send as
                an MMS rather than multiple SMS segments. This setting can also
                be applied globally in Account Settings
              </Text>
            </VStack>
          </HStack>
        </VStack>
        <VStack
          gap="1.5rem"
          alignItems="flex-start"
          py="1.5rem"
          borderBottom="1px solid var(--grey-300)"
        >
          <HStack alignItems="flex-start" w="100%">
            <Text textStyle="h5" fontWeight="700" w="25%">
              Phone Numbers
            </Text>
            <VStack alignItems="flex-start" width="50%">
              <Text textStyle="h6" color="var(--grey-700)">
                These are the phone numbers that your customers call or send
                message to.
              </Text>
              <PaginatedTable
                header={tableHeader}
                data={tableData}
                itemsPerPage={10}
                maxPageNumbers={5}
                isPaginated={false}
                hasPadding={false}
                hasMultiBody={false}
                isBlue={true}
                isLoadingData={(isLoading || mutation.isLoading)}
              />
            </VStack>
          </HStack>
        </VStack>
      </Box>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        background="var(--grey-100)"
        position="absolute"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
      >
        <Button width="8rem" onClick={handleSubmit}>
          Save
        </Button>
      </HStack>
    </VStack>
  );
};

export default General;
