import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import CallEndIcon from "@mui/icons-material/CallEnd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Device } from "@twilio/voice-sdk";
import { useEffect } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { filterByUniqueKey } from "../../../utils";
import { startTwoLeggedCall } from "../../../utils/api/twilio.api";
import { fetchUserById } from "../../../utils/api/users.api";
import { useHistoryDispatch } from "../../../utils/context/HistoryContext";
import {
  handleHangUp,
  makeOutgoingCall,
  useTwilioDispatch,
  useTwilioState,
} from "../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { CustomRadio, Input, PhoneInput, Select } from "../../common";
import Stopwatch from "../../common/Stopwatch";
import { CloseIcon, NotesIcon, VoicemailIcon } from "../../icons/svg";
import {
  OutboundCallForm,
  defaultValues,
  outboundCallFormValidation,
} from "./utils";

type OutboundCallProps = {
  isOpen: boolean;
  onClose: () => void;
  clickedRow?: any;
};

function OutboundCall({ isOpen, onClose, clickedRow }: OutboundCallProps) {
  const { handleSubmit, control, setValue } = useForm<OutboundCallForm>({
    defaultValues: defaultValues,
    resolver: yupResolver(outboundCallFormValidation) as any,
  });

  const mode = useWatch({ control, name: "mode" });
  const isClickToCall = mode === "clickToCall";

  const user = useUserInfo("user");

  const { devices, call, startCallTimestamp } = useTwilioState();
  const dispatch = useTwilioDispatch();
  const historyDispatch = useHistoryDispatch();

  const lineDevices = filterByUniqueKey(
    devices.filter((device) => device.phoneNumber),
    "phoneNumber"
  );

  useEffect(() => {
    const defaultLine =
      lineDevices?.find((line) => line.is_default)?.phoneNumber ||
      lineDevices?.[0].phoneNumber;
    setValue("from", defaultLine);
  }, [lineDevices, setValue]);

  const [customersPhone] = useWatch({
    control,
    name: ["customersPhone"],
  });

  const { data: userObj } = useQuery({
    queryKey: ["userbyid"],
    queryFn: () => fetchUserById(user?.id),
  });

  const mutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await startTwoLeggedCall(payload);
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
  });

  useEffect(() => {
    if (userObj) {
      setValue("to1", userObj.phone || "");
    }
  }, [setValue, userObj]);

  useEffect(() => {
    if (clickedRow) {
      setValue("customersPhone", clickedRow.phone_number);
    }
  }, [setValue, clickedRow]);

  const handleTwoLeggedCall = async (values: OutboundCallForm) => {
    const res = await mutation.mutateAsync({
      contactId: clickedRow?.id || "",
      to1: values.to2 ? values.to2 : values.to1,
      to2: values.customersPhone,
      from: values.from,
      full_name: user?.full_name,
      userId: user?.id,
    });

    dispatch({
      type: "START_TWO_LEGGED_CALL",
      payload: {
        twoLeggedCall: {
          customersPhone: values.customersPhone,
          from: values.from,
          start: true,
          callSid: res.data.call_sid,
        },
      },
    });
  };

  const handleFormSubmit = async (values: OutboundCallForm) => {
    if (values.mode === "clickToCall") {
      handleTwoLeggedCall(values);
    } else {
      const currentDevice = lineDevices.find(
        (device) => device.phoneNumber === values.from
      )?.device;
      makeOutgoingCall(
        dispatch,
        currentDevice as Device,
        values.customersPhone,
        values.from,
        user,
        clickedRow?.id || "",
        "Outbound Call"
      );
    }

    onClose && onClose();
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      placement="bottom"
      id="email-drawer"
    >
      <DrawerContent
        padding="0"
        borderTopRightRadius="0.5rem"
        borderTopLeftRadius="0.5rem"
      >
        <DrawerBody padding="0">
          {!call ? (
            <VStack w="100%" alignItems="flex-start" gap="0">
              <HStack
                justifyContent="space-between"
                w="100%"
                background="var(--secondary-600)"
                borderTopRightRadius="0.5rem"
                borderTopLeftRadius="0.5rem"
                padding="0.69rem 1rem"
              >
                <Text color="white" textStyle="h5" fontWeight="600">
                  Outbound Call
                </Text>
                <HStack>
                  <Button
                    variant="none"
                    padding="0.38rem"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    onClick={onClose}
                    sx={{
                      svg: {
                        path: {
                          fill: "white",
                        },
                      },
                    }}
                  >
                    <CloseIcon />
                  </Button>
                </HStack>
              </HStack>
              <VStack
                w="100%"
                alignItems="flex-start"
                gap="0"
                position="relative"
              >
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  padding="1rem 1.5rem"
                  gap="1rem"
                >
                  <HStack
                    w="100%"
                    justifyContent="space-between"
                    borderBottom="1px solid var(--grey-300)"
                    pb="1rem"
                  >
                    <Text textStyle="h5" fontWeight="500" w="50%">
                      Mode
                    </Text>
                    <Box display="flex" gap={20}>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) => {
                          return (
                            <Box display="flex">
                              <CustomRadio
                                options={[
                                  {
                                    label: "Click To Call",
                                    value: "clickToCall",
                                  },
                                  { label: "Headset", value: "headset" },
                                ]}
                                value={value}
                                setValue={(value: string) => {
                                  setValue("mode", value);
                                }}
                              />
                            </Box>
                          );
                        }}
                        name="mode"
                        control={control}
                        rules={{
                          required: true,
                        }}
                      />
                    </Box>
                  </HStack>
                </VStack>
                <VStack
                  w="100%"
                  alignItems="flex-start"
                  padding="1rem 1.5rem"
                  gap="1rem"
                  mb="72px"
                >
                  {isClickToCall && (
                    <>
                      <HStack w="100%" justifyContent="space-between" pb="1rem">
                        <Text textStyle="h5" fontWeight="500" w="50%">
                          What number are we calling you on?
                        </Text>
                        <Controller
                          render={({
                            field: { onChange, value },
                            fieldState: { error },
                          }) => {
                            return (
                              <PhoneInput
                                placeholder="Enter phone number"
                                onChange={onChange}
                                isRequired
                                value={value}
                                error={error?.message}
                                styles={{ w: "75%", marginLeft: 100 }}
                                isSmall
                                disabled={!isClickToCall}
                              />
                            );
                          }}
                          name="to1"
                          control={control}
                          rules={{
                            required: true,
                          }}
                        />
                      </HStack>
                      <VStack
                        w="100%"
                        alignItems="flex-start"
                        gap="1rem"
                        pb="1rem"
                      >
                        <HStack w="100%" justifyContent="space-between">
                          <Text textStyle="h5" fontWeight="500" w="100%">
                            Other Phone
                          </Text>
                          <Controller
                            render={({
                              field: { onChange, value },
                              fieldState: { error },
                            }) => {
                              return (
                                <PhoneInput
                                  placeholder="Enter phone number"
                                  onChange={onChange}
                                  isRequired
                                  value={value}
                                  error={error?.message}
                                  isSmall
                                  disabled={!isClickToCall}
                                />
                              );
                            }}
                            name="to2"
                            control={control}
                            rules={{
                              required: true,
                            }}
                          />
                        </HStack>
                      </VStack>
                    </>
                  )}

                  <HStack w="100%" justifyContent="space-between" pb="1rem">
                    <Text textStyle="h5" fontWeight="500" w="50%">
                      What number will you call from?
                    </Text>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <Select
                            onChange={(value: any) =>
                              setValue("from", value.value, {
                                shouldValidate: true,
                              })
                            }
                            placeholder="Select Line"
                            options={
                              lineDevices?.map((lineDevice) => ({
                                value: lineDevice.phoneNumber,
                                label: lineDevice.phoneNumber,
                              })) || []
                            }
                            variant="default"
                            w="50%"
                            value={value}
                            error={error?.message || ""}
                          />
                        );
                      }}
                      name="from"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </HStack>

                  <HStack w="100%" justifyContent="space-between" pb="1rem">
                    <Text textStyle="h5" fontWeight="500" w="50%">
                      Customer's Number
                    </Text>
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => {
                        return (
                          <PhoneInput
                            placeholder="Enter phone number"
                            onChange={onChange}
                            isRequired
                            value={value}
                            error={error?.message}
                            styles={{ w: "75%", marginLeft: 100 }}
                            isSmall
                          />
                        );
                      }}
                      name="customersPhone"
                      control={control}
                      rules={{
                        required: true,
                      }}
                    />
                  </HStack>

                  <HStack
                    w="100%"
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Text textStyle="h5" fontWeight="500" w="100%">
                      Add Note
                    </Text>
                    <Input
                      isTextarea={true}
                      placeholder="Type Something"
                      w="100%"
                      h="12rem"
                    />
                  </HStack>
                </VStack>
                <HStack
                  w="100%"
                  padding="1rem"
                  background="var(--grey-200)"
                  position="absolute"
                  bottom="0"
                  left="0"
                  justifyContent="flex-end"
                >
                  {/* <Button variant="outline">
                    <ChatIcon />
                  </Button>
                  <Button variant="outline">
                    <AppointmentIcon color="var(--grey-800)" />
                  </Button>
                  <Button
                    variant="outline"
                    paddingTop="0.85rem"
                    paddingBottom="0.85rem"
                  >
                    <VoicemailIcon />
                  </Button> */}
                  <Button
                    onClick={() =>
                      handleSubmit((values) => handleFormSubmit(values))()
                    }
                  >
                    Begin Call
                  </Button>
                </HStack>
              </VStack>
            </VStack>
          ) : (
            <VStack w="100%" alignItems="flex-start" padding="0.69rem 0.94rem">
              <HStack display={"flex"} alignSelf={"center"}>
                <Text fontWeight="700" textStyle="h4">
                  {customersPhone}
                </Text>
              </HStack>

              <Box border={"1px solid lightgray"} w="100%" />

              <HStack display={"flex"} alignSelf={"center"}>
                <Text fontWeight="500" textStyle="h6">
                  {startCallTimestamp ? "In Progress..." : "Calling..."}
                </Text>
              </HStack>

              {startCallTimestamp && (
                <HStack display={"flex"} alignSelf={"center"} mt="1rem">
                  <Text fontWeight="500" textStyle="h6">
                    <Stopwatch />
                  </Text>
                </HStack>
              )}

              <HStack height="250px" />

              <HStack display={"flex"} justifyContent={"space-around"} w="100%">
                <Button
                  fontSize="0.875rem"
                  background="var(--grey-500)"
                  textAlign="center"
                  // onClick={handleCall}
                  border={"none"}
                >
                  <HStack w="100%" gap="0.5rem" justifyContent="center">
                    <NotesIcon />
                  </HStack>
                </Button>

                <Button
                  fontSize="0.875rem"
                  background="var(--red-700)"
                  textAlign="center"
                  onClick={() => {
                    handleHangUp(dispatch, call, startCallTimestamp);
                    historyDispatch({
                      type: "SET_SHOULD_REFETCH",
                      payload: { shouldRefetch: true },
                    });
                  }}
                  border={"none"}
                  borderRadius="100%"
                >
                  <HStack w="100%" gap="0.5rem" justifyContent="center">
                    <CallEndIcon />
                  </HStack>
                </Button>

                <Button
                  fontSize="0.875rem"
                  background="var(--grey-500)"
                  textAlign="center"
                  // onClick={handleCall}
                  border={"none"}
                >
                  <HStack w="100%" gap="0.5rem" justifyContent="center">
                    <VoicemailIcon />
                  </HStack>
                </Button>
              </HStack>
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

export default OutboundCall;
