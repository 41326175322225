import { useMutation } from "@tanstack/react-query";
import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  VStack,
  useToast,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import Input from "../../../../common/Input";
import { Drawer } from "../../../../common";
import { createTradeIns } from "../../../../../utils/api/trade-in";
import ImageUploader from "./ImageUploader";
import ImageGrid from "./ImageGrid";
import { CameraIcon } from "../../../../icons/svg";
import CaptureImage from "../Documents/CaptureImage";

interface AddTradeInDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  info: any;
}

const AddTradeInDrawer = ({ isOpen, onClose, info }: AddTradeInDrawerProps) => {
  const [year, setYear] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [vin, setVin] = useState("");
  const [exteriorColor, setExteriorColor] = useState("");
  const [interiorColor, setInteriorColor] = useState("");
  const [mileage, setMileage] = useState("");
  const [notes, setNotes] = useState("");
  const [images, setImages] = useState<File[]>([]);
  const [showCameraPopup, setShowCameraPopup] = useState(false);
  const [capturedImages, setCapturedImages] = useState<string[]>([]);
  const [yearError, setYearError] = useState("");
  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");

  const mutation = useMutation(createTradeIns);
  const toast = useToast();

  const handleSubmit = (event: any) => {
    let yearErr = false;
    let makeErr = false;
    let modelErr = false;

    if (!year) {
      setYearError("Please enter years");
      yearErr = true;
    }
    if (!make) {
      setMakeError("Please enter make");
      makeErr = true;
    }
    if (!model) {
      setModelError("Please enter model");
      modelErr = true;
    }

    if (yearErr || makeErr || modelErr) {
      return;
    }

    mutation.mutate(
      {
        ...info,
        payload: {
          trade_in: {
            year,
            make,
            model,
            vin,
            exterior_color: exteriorColor,
            interior_color: interiorColor,
            mileage,
            notes,
            images:
              capturedImages && capturedImages.length > 0
                ? capturedImages
                : images,
          },
        },
      },
      {
        onSuccess: () => {
          onClose();
          toast({
            description: "Trade-in added successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error adding trade-in: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  return (
    <>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        title="Add a Trade-In"
        isLoading={mutation.isLoading}
      >
        <VStack
          alignItems="flex-start"
          padding="1rem 1.5rem"
          w="100%"
          gap="1rem"
        >
          <Input
            placeholder="Enter Year"
            label="Year"
            onChange={(e: any) => {
              const inputYear = e.target.value;
              if (inputYear.length <= 4) {
                setYear(inputYear);
                setYearError("");
              }
            }}
            onInput={(e: any) => {
              const inputYear = e.target.value;
              if (inputYear.length > 4) {
                e.target.value = inputYear.slice(0, 4);
              }
            }}
            isRequired
            error={yearError}
            value={year}
            type="number"
            maxLength={4}
          />
          <Input
            placeholder="Enter Make"
            label="Make"
            onChange={(e: any) => {
              setMake(e.target.value);
              setMakeError("");
            }}
            value={make}
            error={makeError}
            isRequired
          />
          <Input
            placeholder="Enter Model"
            label="Model"
            onChange={(e: any) => {
              setModel(e.target.value);
              setModelError("");
            }}
            value={model}
            error={modelError}
            isRequired
          />
          <Input
            placeholder="Enter Mileage"
            label="Mileage (Optional)"
            onChange={(e: any) => {
              setMileage(e.target.value);
            }}
            type="number"
          />
          <Input
            placeholder="Enter Exterior Color"
            label="Exterior Color (Optional)"
            onChange={(e: any) => {
              setExteriorColor(e.target.value);
            }}
          />

          <Input
            placeholder="Enter Interior Color"
            label="Interior Color (Optional)"
            onChange={(e: any) => {
              setInteriorColor(e.target.value);
            }}
          />
          <Input
            placeholder="Enter VIN"
            label="VIN (Optional)"
            value={vin}
            onChange={(e: any) => {
              setVin(e.target.value);
            }}
          />
          <Input
            placeholder="Enter Notes"
            label="Notes (Optional)"
            onChange={(e: any) => {
              setNotes(e.target.value);
            }}
            value={notes}
            isTextarea={true}
          />

          <ImageUploader
            images={images}
            setImages={setImages}
            maxImages={15}
            isDisabled={capturedImages && capturedImages.length > 0}
          />
          {capturedImages.length <= 0 && images && images.length > 0 && (
            <ImageGrid
              images={images}
              onDelete={(indexToRemove: number) => {
                setImages(images.filter((_, index) => index !== indexToRemove));
              }}
            />
          )}
          <HStack w="100%" gap="1.5rem">
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
            <Box
              as="span"
              fontSize="1.125rem"
              fontWeight="600"
              color="var(--secondary-600)"
              borderRadius="6.25rem"
              background="var(--secondary-50)"
              padding="0.31rem 1.19rem"
            >
              Or
            </Box>
            <Box
              as="span"
              width="100%"
              height="1px"
              background="var(--grey-300)"
            ></Box>
          </HStack>
          <VStack
            background="var(--grey-100)"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="1.5rem 1rem"
            w="100%"
            justifyContent="center"
          >
            <>
              <HStack padding="1rem" background="white" borderRadius="50%">
                <CameraIcon />
              </HStack>
              <Text textStyle="h5" fontWeight="500">
                Do you want to take a photo?
              </Text>
              <Button
                variant="outline"
                color="var(--secondary-600)"
                borderColor="var(--secondary-600)"
                w="11.4rem"
                onClick={() => setShowCameraPopup(true)}
                isDisabled={
                  (images && images.length > 0) || capturedImages?.length >= 15
                }
              >
                Click Here
              </Button>
            </>
            {capturedImages && capturedImages.length > 0 && (
              <ImageGrid
                images={capturedImages}
                onDelete={(indexToRemove: number) => {
                  setCapturedImages(
                    capturedImages.filter((_, index) => index !== indexToRemove)
                  );
                }}
                isString={true}
              />
            )}
          </VStack>
        </VStack>
        <DrawerFooter
          position="absolute"
          bottom="0"
          left="0"
          width="100%"
          borderTop="1px solid var(--grey-300)"
          display="flex"
          alignItems="center"
          gap="0.81rem"
        >
          <Button variant="outline" w="100%" size="xl" onClick={onClose}>
            Cancel
          </Button>
          <Button w="100%" size="xl" onClick={handleSubmit}>
            Submit
          </Button>
        </DrawerFooter>
      </Drawer>
      {showCameraPopup && (
        <CaptureImage
          onClose={() => setShowCameraPopup(false)}
          isOpen={showCameraPopup}
          setCapturedImage={(image: any) =>
            setCapturedImages([...capturedImages, image])
          }
        />
      )}
    </>
  );
};

export default AddTradeInDrawer;
