import * as yup from "yup";
import { fetchDealerships } from "../../../utils/api/announcements";

export type ComposeBlastForm = {
  subject: string;
  messageBody: string;
  throttling_rate: string;
  role: string[];
  dealerships: string[];
  users: string;
  status: string[] | SelectIconOptionProps[];
};

export const defaultValues = {
  subject: "",
  messageBody: "",
  throttling_rate: "",
  role: [],
  dealerships: [],
  users: "",
};

export const composeBlastValidation = (type?: string) =>
  yup.object().shape({
    // subject: yup.string().required("Subject is required"),
    messageBody: type
      ? yup.string().required("Body is required").max(1599)
      : yup
          .string()
          .required("Body is required")
          .test(
            "max-text-length",
            "Email content cannot exceed 2499 characters in plain text",
            function (value) {
              if (!value) return true; // if no content, validation passes

              // Create a temporary element to convert HTML to plain text
              const tempElement = document.createElement("div");
              tempElement.innerHTML = value;
              const textContent =
                tempElement.innerText || tempElement.textContent || "";

              // Check if the plain text length exceeds 2499 characters
              return textContent.length <= 2499;
            }
          ),
    // throttling_rate: yup.string().required("Throttling Type is required."),
  });
export const getPayload = (
  dateFields: any,
  formVals: any,
  removedUsers: any,
  attachmentUrls: any,
  type: any,
  id: any
) => {
  const commonPayloadFields = {
    roles: formVals.role?.map((role: any) =>
      role.label === "All" ? role.value : role.label
    ),
    dealership_ids: formVals.dealerships?.map((i: any) => i.value),
    status: formVals.status?.map((i: any) => i.value),
    removed_users: removedUsers,
  };
  const commonPayloadInnerFields = {
    throttling_rate: formVals.throttling_rate,
    internal_action: "campaign_outbound",
    ...dateFields,
  };
  const smsPayload = {
    payload: {
      message_campaign: {
        name: formVals.subject,
        content: formVals.messageBody,
        media_urls: attachmentUrls.map((attachment: any) => attachment.url),
        ...commonPayloadInnerFields,
      },
      subMenu: "SMS",
      ...commonPayloadFields,
    },
  };
  const emailPayload = {
    payload: {
      email_log: {
        subject: formVals.subject,
        body: formVals.messageBody,
        attachment_urls: attachmentUrls.map(
          (attachment: any) => attachment.url
        ),
        ...commonPayloadInnerFields,
      },
      subMenu: "Email",
      ...commonPayloadFields,
    },
  };

  if (id) {
    return {
      id,
      ...(type ? smsPayload : emailPayload),
    };
  }
  return type ? smsPayload : emailPayload;
};

export const loadDealerships = async (inputValue: string, status: string[]) => {
  try {
    const response = await fetchDealerships({
      search: inputValue,
      status: status,
    });
    const options = response?.data?.dealerships?.map((dealership: any) => ({
      value: dealership.id.toString(),
      label: dealership.name,
    }));
    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};
export const throttlingOptions = [
  { label: "200 per hour", value: "two_hundred" },
  { label: "400 per hour", value: "four_hundred" },
  { label: "600 per hour", value: "six_hundred" },
  { label: "800 per hour", value: "eight_hundred" },
  { label: "Or Max", value: "all_time" },
];
