import {
  Box,
  Button,
  HStack,
  Link,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import {
  approveCampaign,
  approveMessageCampaign,
  rejectCampaign,
  rejectMessageCampaign,
} from "../../../utils/api/broadcast.api";
import {
  fetchNotifications,
  markGlobalNotificationAsRead,
  markReminderAsRead,
} from "../../../utils/api/header-notifications.api";
import { impersonateUser } from "../../../utils/api/users.api";
import { useAuthDispatch } from "../../../utils/context/AuthContext";
import { useNotificationContext } from "../../../utils/context/NotificationContext/NotificationContext";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import { Spinner } from "../../common";
import { NotificationIcon } from "../../icons/svg";

const NotificationCard = (props: any) => {
  const dealerId = useUserInfo("dealership");
  const user = useUserInfo("user");
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const mutation = useMutation(
    props?.api ? markGlobalNotificationAsRead : markReminderAsRead
  );
  const toast = useToast();
  const { refetchNotifications } = useNotificationContext();

  const statusMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (payload.status === "approve"
          ? approveCampaign
          : rejectCampaign)({
          dealership_id: payload.dealershipId,
          id: payload.id,
          status: payload.status,
          campaignId: payload.campaignId,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetchNotifications();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const messageStatusMutation = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await (payload.status === "approve"
          ? approveMessageCampaign
          : rejectMessageCampaign)({
          dealership_id: payload.dealershipId,
          id: payload.id,
          status: payload.status,
          campaignId: payload.campaignId,
          user_id: user.id,
          payload,
        });
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      toast({
        description: "Updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      refetchNotifications();
    },
    onError: (error: any) => {
      toast({
        description: `Error Updating: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const { data, isLoading } = useQuery<any>({
    queryKey: ["fetchNotifications", dealerId?.id, props?.page, props?.perPage],
    queryFn: props?.api || fetchNotifications,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const handleMarkAsRead = (item: any) => {
    mutation.mutate(
      { dId: dealerId?.id, id: item?.id },
      {
        onSuccess: (data: any) => {
          refetchNotifications(); // This will trigger the refetch of the parent's query
          props.onClose();
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleUserImpersonate = async (id: any, isBroadcast = false) => {
    await impersonateUser(
      id,
      dispatch,
      true,
      {
        menu: "Users",
        "Sub-Menu": "Dealership Users",
      },
      user?.role,
      user?.id
    );
    window.open(
      `${window.location.origin}/broadcast?active=${isBroadcast ? 0 : 1}`,
      "_self"
    );
  };

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      gap="0.59rem"
      overflow="auto"
      padding="1rem"
      pt="0"
      maxH="calc(100vh - 450px)"
      overflowY="auto"
    >
      {data?.data?.notifications?.map((item: any) => (
        <HStack
          padding="1rem"
          w="100%"
          key={item}
          background={item?.read ? "white" : "var(--grey-100)"}
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          alignItems="flex-start"
          position="relative"
          onClick={() => {
            handleMarkAsRead(item);
          }}
          cursor="pointer"
        >
          {!item?.read && (
            <Box
              w="0.47rem"
              h="0.47rem"
              borderRadius="50%"
              background="#F44336"
              position="absolute"
              top="0.5rem"
              left="0.5rem"
            ></Box>
          )}
          <Box
            padding="0.5rem"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            background="white"
            sx={{
              svg: {
                path: {
                  fill: "var(--secondary-600)",
                },
              },
            }}
          >
            <NotificationIcon />
          </Box>
          <VStack gap="0.25rem" w="102%" alignItems="flex-start">
            <Text textStyle="h5" fontWeight="500">
              {item?.message}
            </Text>
            <Text textStyle="captionSmall" fontWeight="500">
              {moment(item?.created_at).format("dddd MMM DD YY • hh:mm a")}
            </Text>
            {item.notification_object.ringless_voicemail &&
            item.notification_object.visible ? (
              !(
                item.notification_object?.voicemial_approval ||
                item.notification_object?.voicemial_rejected
              ) ? (
                <HStack>
                  <Button
                    w="100%"
                    size="sm"
                    onClick={() =>
                      handleUserImpersonate(
                        item.notification_object?.dealership_id
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline"
                    w="100%"
                    size="sm"
                    background="green"
                    color="white"
                    onClick={() =>
                      statusMutation.mutate({
                        status: "approve",
                        campaignId: item?.notification_object?.campaign_id,
                        id: item?.notification_object?.ringless_voicemail_id,
                        dealershipId: item?.notification_object?.dealership_id,
                        user_id: user.id,
                      })
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    w="100%"
                    size="sm"
                    background="var(--red-700)"
                    onClick={() =>
                      statusMutation.mutate({
                        status: "reject",
                        campaignId: item?.notification_object?.campaign_id,
                        id: item?.notification_object?.ringless_voicemail_id,
                        dealershipId: item?.notification_object?.dealership_id,
                        user_id: user.id,
                      })
                    }
                  >
                    Reject
                  </Button>
                </HStack>
              ) : (
                <Text
                  textStyle="h5"
                  fontWeight="500"
                  color={
                    item.notification_object?.voicemial_approval
                      ? "green"
                      : "var(--red-500)"
                  }
                >
                  {item.notification_object?.notes}{" "}
                  {item.notification_object?.approved_at
                    ? `at ${moment(
                        item.notification_object?.approved_at
                      ).format("MM-DD-YYYY hh:mm A")}`
                    : ""}
                  {item.notification_object?.rejected_at
                    ? `at ${moment(
                        item.notification_object?.rejected_at
                      ).format("MM-DD-YYYY hh:mm A")}`
                    : ""}
                </Text>
              )
            ) : (
              <></>
            )}
            {item.notification_object.report_url ? (
              <Link
                href={item.notification_object.report_url}
                isExternal
                textStyle="h5"
                fontWeight="500"
              >
                Download
              </Link>
            ) : (
              <></>
            )}

            {item.notification_object.broadcast_message &&
            item.notification_object.visible ? (
              !(
                item.notification_object?.message_campaign_approval ||
                item.notification_object?.message_campaign_rejected
              ) ? (
                <HStack>
                  <Button
                    w="100%"
                    size="sm"
                    onClick={() =>
                      handleUserImpersonate(
                        item.notification_object?.dealership_id,
                        true
                      )
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="outline"
                    w="100%"
                    size="sm"
                    background="green"
                    onClick={() =>
                      messageStatusMutation.mutate({
                        status: "approve",
                        campaignId: item?.notification_object?.campaign_id,
                        id: item?.notification_object?.broadcast_message_id,
                        dealershipId: item?.notification_object?.dealership_id,
                        user_id: user.id,
                      })
                    }
                  >
                    Approve
                  </Button>
                  <Button
                    w="100%"
                    size="sm"
                    background="var(--red-700)"
                    onClick={() =>
                      messageStatusMutation.mutate({
                        status: "reject",
                        campaignId: item?.notification_object?.campaign_id,
                        id: item?.notification_object?.broadcast_message_id,
                        dealershipId: item?.notification_object?.dealership_id,
                        user_id: user.id,
                      })
                    }
                  >
                    Reject
                  </Button>
                </HStack>
              ) : (
                <Text
                  textStyle="h5"
                  fontWeight="500"
                  color={
                    item.notification_object?.message_campaign_approval
                      ? "green"
                      : "var(--red-500)"
                  }
                >
                  {item.notification_object?.notes}{" "}
                  {item.notification_object?.approved_at
                    ? `at ${moment(
                        item.notification_object?.approved_at
                      ).format("MM-DD-YYYY hh:mm A")}`
                    : ""}
                  {item.notification_object?.rejected_at
                    ? `at ${moment(
                        item.notification_object?.rejected_at
                      ).format("MM-DD-YYYY hh:mm A")}`
                    : ""}
                </Text>
              )
            ) : (
              <></>
            )}
          </VStack>
        </HStack>
      ))}
      {isLoading && <Spinner />}
    </VStack>
  );
};

export default NotificationCard;
