import { Button, HStack, Text, useDisclosure, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import DealershipListingDrawer from "../../../pages/(global-admin-routes)/announcements/DealershipListingDrawer";
import { getSaraSettings } from "../../../utils/api/sara-ai";
import { Input, Spinner } from "../../common";
import SwitchToggle from "../../common/Switch";

export default function SaraAI() {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["getSaraSettings"],
    queryFn: getSaraSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setSettingsHash(data?.data?.chat_gpt);
    },
  });

  const [settingsHash, setSettingsHash] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleInputChange = (property: string, value: any) => {
    setSettingsHash((prev: any) => ({
      ...prev,
      [property]: value,
    }));
  };

  return (
    <VStack
      alignItems="flex-start"
      background="var(--grey-50)"
      border="1px solid var(--grey-300)"
      padding="1rem"
      borderRadius="0.5rem"
      w="100%"
      gap="1.5rem"
    >
      {isLoading && <Spinner />}
      <Text fontWeight="700" textStyle="h4">
        Sarah.ai Integration
      </Text>
      <VStack w="100%" gap="1.25rem" alignItems="flex-start">
        <HStack>
          <SwitchToggle
            label="Sarah.ai Integration"
            defaultChecked={settingsHash?.chat_gpt_integration}
            onChange={(val) => handleInputChange("chat_gpt_integration", val)}
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="Website Chat"
            isTextarea={true}
            defaultValue="Generate an SMS to introduce yourself as a non-aggressive car salesman namely Sarah at [company_name] to customers, involving saying how this is the perfect car for fun and work. Your goal is to set up an appointment for today, schedule a phone call, or get a credit application filled"
            value={settingsHash?.website_chat}
            onChange={(e) => {
              handleInputChange("website_chat", e?.target?.value);
            }}
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <VStack alignItems="flex-start" w="100%">
            <Input
              maxW="100%"
              label="Offering"
              isTextarea={true}
              value={settingsHash?.offering}
              onChange={(e) => {
                handleInputChange("offering", e?.target?.value);
              }}
            />
          </VStack>
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <VStack alignItems="flex-start" w="100%">
            <Input
              maxW="100%"
              label="Not Offering"
              isTextarea={true}
              value={settingsHash?.not_offering}
              onChange={(e) => {
                handleInputChange("not_offering", e?.target?.value);
              }}
            />
          </VStack>
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="Hours of Operation"
            value={settingsHash?.hours_of_operation}
            onChange={(e) => {
              handleInputChange("hours_of_operation", e?.target?.value);
            }}
          />

          <Input
            maxW="100%"
            label="Wait time for Sarah.ai turnover"
            value={settingsHash?.wait_time}
            onChange={(e) => {
              handleInputChange("wait_time", e);
            }}
            type="number"
            isNumeric
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="Response if the contact says no"
            value={settingsHash?.response_if_contact_say_no}
            onChange={(e) => {
              handleInputChange("response_if_contact_say_no", e?.target?.value);
            }}
          />

          <Input
            maxW="100%"
            label="Response if the contact wants to make a call"
            value={settingsHash?.response_if_contact_wants_to_make_call}
            onChange={(e) => {
              handleInputChange(
                "response_if_contact_wants_to_make_call",
                e?.target?.value
              );
            }}
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="Sequence id to enrol if 5 attempts no response"
            value={
              settingsHash?.chat_gpt
                ?.sequence_id_to_enroll_5_attempts_no_response
            }
            onChange={(e) => {
              handleInputChange(
                "sequence_id_to_enroll_5_attempts_no_response",
                e?.target?.value
              );
            }}
          />

          <Input
            maxW="100%"
            label="1st followup message"
            value={settingsHash?.first_follow_up_message}
            onChange={(e) => {
              handleInputChange("first_follow_up_message", e?.target?.value);
            }}
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="2nd followup message"
            value={settingsHash?.second_follow_up_message}
            onChange={(e) => {
              handleInputChange("second_follow_up_message", e?.target?.value);
            }}
          />

          <Input
            maxW="100%"
            label="3rd followup message"
            value={settingsHash?.third_follow_up_message}
            onChange={(e) => {
              handleInputChange("third_follow_up_message", e?.target?.value);
            }}
          />
        </HStack>
        <HStack w="100%" gap="1.25rem" alignItems="flex-start">
          <Input
            maxW="100%"
            label="4th followup message"
            value={settingsHash?.fourth_follow_up_message}
            onChange={(e) => {
              handleInputChange("fourth_follow_up_message", e?.target?.value);
            }}
          />

          <Input
            maxW="100%"
            label="5th followup message"
            value={settingsHash?.fifth_follow_up_message}
            onChange={(e) => {
              handleInputChange("fifth_follow_up_message", e?.target?.value);
            }}
          />
        </HStack>
        <Button width="8rem" onClick={onOpen}>
          Save
        </Button>
      </VStack>
      {isOpen && (
        <DealershipListingDrawer
          onClose={onClose}
          refetch={refetch}
          settingsHash={settingsHash}
        />
      )}
    </VStack>
  );
}
