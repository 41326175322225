import { Button, DrawerFooter, Text, useToast, VStack } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  updateTemplate,
  updateTemplateWithDealershipId,
} from "../../../utils/api/scorecard-settings.api";
import { Drawer } from "../../common";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};
interface ApiResult {
  data: any;
}
interface ApiRequest {
  scorecard: {
    status: string;
  };
}

const ActivateTemplate = (props: data) => {
  const { isOpen, onClose, refetchParent, id: rowId } = props;
  const toast = useToast();
  const { id } = useParams();

  const mutation = useMutation<ApiResult, Error, ApiRequest, unknown>({
    mutationFn: async (payload) => {
      if (id) {
        try {
          const response = await updateTemplateWithDealershipId(
            rowId,
            payload,
            id
          );
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const response = await updateTemplate(rowId, payload);
          return { data: response.data };
        } catch (error) {
          throw error;
        }
      }
    },
    onSuccess: () => {
      refetchParent();
      onClose();
      toast({
        description: "Activated it successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
    onError: (error: any) => {
      toast({
        description: `Error activating it: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const requestData: ApiRequest = {
      scorecard: {
        status: "active",
      },
    };

    mutation.mutate(requestData);
  };

  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Activate Template"
      isLoading={mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Are you sure you want to activate this template and deactivate the
          rest?
        </Text>
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default ActivateTemplate;
