import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import ActivityLogPage from "./ActivityLogPage";

const MainListing = () => {
  const [activeTab, setActiveTab] = useState(0);

  const [firstIp, setFirstIp] = useState<string>("");
  const [lastIp, setLastIp] = useState<string>("");
  const [dealerships, setDealerships] = useState<SelectOptionProps[]>([]);
  const [users, setUsers] = useState<SelectOptionProps[]>([]);
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [selectedMenus, setSelectedMenus] = useState<SelectOptionProps[]>([]);
  const [selectedSubmenus, setSelectedSubmenus] = useState<SelectOptionProps[]>(
    []
  );
  const [selectedActions, setSelectedActions] = useState<SelectOptionProps[]>(
    []
  );
  const [selectedCountry, setSelectedCountry] =
    useState<SelectOptionProps | null>();
  const [selectedState, setSelectedState] =
    useState<SelectOptionProps | null>();

  const resetFields = () => {
    setFirstIp("");
    setLastIp("");
    setUsers([]);
    setDealerships([]);
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      selected: false,
    });
    setSelectedMenus([]);
    setSelectedSubmenus([]);
    setSelectedActions([]);
    setSelectedCountry(null);
    setSelectedState(null);
  };

  const handleTabChange = (index: any) => {
    resetFields();
    setActiveTab(index);
  };

  return (
    <VStack padding="1.5rem" w="100%" alignItems="flex-start">
      <Tabs
        variant="table"
        w="100%"
        background="white"
        borderRadius="0.5rem"
        defaultIndex={0}
        onChange={handleTabChange}
      >
        <TabList borderTopRightRadius="0.5rem">
          <Tab w="14rem">Activity Log</Tab>
          <Tab w="14rem">BDC Activity Log</Tab>
        </TabList>
        <TabPanels w="100%" h="100%">
          <TabPanel w="100%" h="100%">
            {activeTab === 0 && (
              <ActivityLogPage
                firstIp={firstIp}
                setFirstIp={setFirstIp}
                lastIp={lastIp}
                setLastIp={setLastIp}
                dealerships={dealerships}
                setDealerships={setDealerships}
                users={users}
                setUsers={setUsers}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                selectedMenus={selectedMenus}
                setSelectedMenus={setSelectedMenus}
                selectedSubmenus={selectedSubmenus}
                setSelectedSubmenus={setSelectedSubmenus}
                selectedActions={selectedActions}
                setSelectedActions={setSelectedActions}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
            )}
          </TabPanel>

          <TabPanel w="100%" h="100%">
            {activeTab === 1 && (
              <ActivityLogPage
                isBdc={true}
                firstIp={firstIp}
                setFirstIp={setFirstIp}
                lastIp={lastIp}
                setLastIp={setLastIp}
                dealerships={dealerships}
                setDealerships={setDealerships}
                users={users}
                setUsers={setUsers}
                selectionRange={selectionRange}
                setSelectionRange={setSelectionRange}
                selectedMenus={selectedMenus}
                setSelectedMenus={setSelectedMenus}
                selectedSubmenus={selectedSubmenus}
                setSelectedSubmenus={setSelectedSubmenus}
                selectedActions={selectedActions}
                setSelectedActions={setSelectedActions}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default MainListing;
