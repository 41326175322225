import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { humanize } from "../../../../utils";
import { fetchGraphData, fetchUsage } from "../../../../utils/api/usage";
import StackedColumnChart from "../../../Charts/StackedColumnChart";
import { Select } from "../../../common";
import DatePicker from "../../../common/DateRangePicker";
import moment from "moment";
import Loader from "../../../common/Spinner";

const keyMapper: any = {
  inbound_voice_minutes: 'calls-inbound',
  inbound_calls_local_toll_free: 'calls',
  outbound_voice_minutes: 'calls-outbound',
  phone_numbers: 'phonenumbers',
  SMS_inbound: 'sms-inbound',
  SMS_outbound: 'sms-outbound',
  MMS_inbound: 'mms-inbound',
  MMS_outbound: 'mms-outbound',
  SMS_messages_carrier_fees: 'sms-messages-carrierfees',
  MMS_messages_carrier_fees: 'mms-messages-carrierfees',
  A2P_registration_fees: 'a2p-registration-fees'
};

const transformData = (data: any, selectedOption: string, isUsage = true) => {
  const categories = Object.keys(data);

  const cost = categories.map((category: string) => data[category].total_price);
  const count = categories.map((category: string) => data[category].count);

  return {
    categories,
    series: [
      {
        name: selectedOption,
        data: isUsage ? count : cost,
      },
    ],
  };
};

function GraphUsage() {
  const { id } = useParams();
  const [selectionRange, setSelectionRange] = useState<any>({
    startDate: '',
    endDate: new Date(),
    key: "selection",
    selected: false,
  });
  const [selectedOption, setSelectedOption] = useState<SelectOptionProps>();
  const [activeIndex, setActiveIndex] = useState<number>(1);

  const getParams = (isGraph: boolean) => {
    let params: any = {};
    if (selectionRange && selectionRange?.selected) {
      if (selectionRange?.startDate)
        params.start_time = selectionRange?.startDate.toString();
      if (selectionRange?.endDate)
        params.end_time = selectionRange?.endDate.toString();
    }
    if (isGraph) {
      if (selectedOption) {
        params.category = selectedOption?.value;
      }
    }
    return params;
  };

  const {
    data: usageResData,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["fetchUsage", id, selectionRange],
    queryFn: () => fetchUsage(id, getParams(false)),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess: (data) => {
      const keys = Object.keys(data?.data?.data || {});
      setSelectedOption({
        label: humanize(keys?.[0] || "", false),
        value: keyMapper[keys?.[0] || ""],
      });
    },
  });

  const { data, isFetching: isFetchingGraph } = useQuery({
    queryKey: ["fetchGraphData", id, selectionRange, selectedOption],
    queryFn: () => fetchGraphData(id, getParams(true)),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {},
  });

  const { categories, series } = transformData(
    data?.data?.graph_data || {},
    (selectedOption?.label || "") as any,
    activeIndex === 1
  );

  const { data: usageData } = usageResData?.data || {};
  const { total_price: totalCost, ...remainingUsageInfo } = usageData || {};

  const titleOptions = Object.keys(remainingUsageInfo || {}).map(
    (key: string) => {
      return {
        label: humanize(key, false),
        value: keyMapper[key],
      };
    }
  );

  return (
    <VStack
      w="75%"
      border="1px solid var(--grey-300)"
      background="white"
      borderRadius="1rem"
      gap="0"
    >
      {isFetching && <Loader />}
      <HStack
        w="100%"
        padding="0.88rem 1rem"
        justifyContent="space-between"
        borderBottom="1px solid var(--grey-300)"
      >
        <Text textStyle="h4" fontWeight="700">
          Usage Information
        </Text>
      </HStack>
      <VStack
        w="100%"
        alignItems="flex-start"
        padding="1.25rem 1.5rem"
        gap="1rem"
      >
        <HStack w="100%" justifyContent="flex-end">
          <Text textStyle="h6" color="var(--red-600)">
            Last Updated at:{" "}
            <i style={{ color: "var(--grey-900)" }}>
              {moment(data?.data?.updated_at).format("MM-DD-YYYY hh:mm A")}
            </i>
          </Text>
          <DatePicker
            value={selectionRange}
            onChange={(value) => {
              setSelectionRange(value);
              refetch();
            }}
            shouldShowClear
            label="Select Date Range"
            shouldShowLabel={false}
          />
        </HStack>
        <HStack w="100%" gap="1rem" alignItems="unset">
          <VStack
            w="40%"
            gap="0"
            alignItems="flex-start"
            border="1px solid var(--grey-300)"
            borderRadius="1rem"
          >
            <HStack
              w="100%"
              gap="0"
              padding="0.88rem 0.75rem"
              borderBottom="1px solid var(--grey-300)"
            >
              <Text
                textStyle="h6"
                fontSize="0.85rem"
                fontWeight="700"
                flex="1.2"
              >
                Resource
              </Text>
              <Text textStyle="h6" fontSize="0.85rem" fontWeight="700" flex="0">
                Usage
              </Text>
              <Text
                textStyle="h6"
                fontSize="0.85rem"
                fontWeight="700"
                flex="0.9"
                textAlign="right"
              >
                Cost
              </Text>
            </HStack>
            <VStack w="100%" alignItems="flex-start" gap="0" >
              {Object.keys(remainingUsageInfo).map((key) => {
                const { total_cost, total_value } = remainingUsageInfo[key];
                const title = humanize(key, false);
                return (
                  <HStack
                    w="100%"
                    gap="0"
                    padding="0.56rem 0.75rem 0.81rem"
                    borderBottom="1px solid var(--grey-300)"
                    sx={{
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <Text
                      textStyle="h6"
                      fontSize="0.85rem"
                      fontWeight="400"
                      flex="1.2"
                    >
                      {title}
                    </Text>
                    <Text
                      textStyle="h6"
                      fontSize="0.85rem"
                      fontWeight="400"
                      flex="0.9"
                      textAlign="center"
                    >
                      {total_value}
                    </Text>
                    <Text
                      textStyle="h6"
                      fontSize="0.85rem"
                      fontWeight="400"
                      flex="0.9"
                      textAlign="right"
                    >
                      ${Number(total_cost).toFixed(2)}
                    </Text>
                  </HStack>
                );
              })}
            </VStack>
          </VStack>
          <VStack
            padding="1rem 0rem 0"
            alignItems="flex-start"
            border="1px solid var(--grey-300)"
            borderRadius="1rem"
            w="60%"
          >
            <Tabs
              variant="pill"
              defaultIndex={activeIndex}
              onChange={(index) => setActiveIndex(index)}
              index={activeIndex}
            >
              <HStack padding="1rem">
                <TabList>
                  <Tab minW="4rem">Cost</Tab>
                  <Tab minW="4rem">Usage</Tab>
                </TabList>
                <Select
                  onChange={(value: any) => setSelectedOption(value)}
                  placeholder="Total"
                  options={titleOptions}
                  variant="default"
                  value={selectedOption?.value}
                  w={"100%"}
                />
              </HStack>
              <TabPanels>
                <TabPanel>
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                    style={{ height: 430, width: 560 }}
                    type="line"
                  />
                </TabPanel>
                <TabPanel>
                  <StackedColumnChart
                    series={series ?? []}
                    categories={categories ?? []}
                    isLoading={isFetchingGraph}
                    style={{ height: 430, width: 560 }}
                    type="line"
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </VStack>
        </HStack>
      </VStack>
    </VStack>
  );
}

export default GraphUsage;
