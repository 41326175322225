import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import moment from "moment";
import "moment-timezone";
import InfiniteScroll from "react-infinite-scroll-component";

type DataItem = {
  id: string;
  ip_address: string;
  user_name: string;
  dealership_name: string;
  role: string;
  time: string;
};

type InfiniteListProps = {
  data?: DataItem[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  count?: number;
  target?: string;
};

const InfiniteList: React.FC<InfiniteListProps> = ({
  data = [],
  setPage,
  count = 0,
  target = "scrollableDiv",
}) => {
  const hasMoreItems = data?.length < count;

  const isActive = (time: string) => {
    const lastActivity = moment.tz(time, "America/New_York").toDate();
    const currentTime = moment().tz("America/New_York").toDate();
    const differenceInMilliseconds =
      currentTime.getTime() - lastActivity.getTime();
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    return differenceInMinutes <= 5;
  };

  return (
    <InfiniteScroll
      dataLength={data?.length}
      next={() => setPage((prev) => prev + 1)}
      hasMore={hasMoreItems}
      loader={<h4>Loading...</h4>}
      scrollableTarget={target}
    >
      <VStack gap="0.5">
        {data?.map((item) => (
          <Box
            key={item.id}
            w="100%"
            padding="1rem"
            onClick={() => {}}
            borderRadius="md"
            boxShadow="md"
          >
            <HStack alignItems="flex-start" gap="0.75rem">
              <VStack gap="0.25rem" alignItems="flex-start">
                <Text fontWeight="bold" color="blue.600">
                  IP: {item?.ip_address}
                </Text>
                <Text fontWeight="medium" color="black">
                  User: {item?.user_name}
                </Text>
                {item?.dealership_name ? (
                  <Text fontWeight="semibold" color="gray.500">
                    Dealership: {item?.dealership_name}
                  </Text>
                ) : (
                  <Text fontWeight="semibold" color="gray.500">
                    Role: {item?.role}
                  </Text>
                )}

                {item?.time && (
                  <>
                    <Text fontWeight="semibold" color="gray.600">
                      Last Activity Time: {moment(item?.time).format("hh:mm A")}
                    </Text>
                    <Text
                      fontWeight="bold"
                      color={isActive(item.time) ? "green.500" : "red.500"}
                    >
                      {isActive(item.time) ? "Online" : ""}
                    </Text>
                  </>
                )}
              </VStack>
            </HStack>
          </Box>
        ))}
      </VStack>
    </InfiniteScroll>
  );
};

export default InfiniteList;
