import {
  Box,
  Button,
  HStack,
  Icon,
  Skeleton,
  SkeletonText,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import USMap from "../../../../components/Charts/USMap";
import WorldMap from "../../../../components/Charts/WorldMap";
import { fetchMap } from "../../../../utils/api/global-dashboard";
import UsersDrawer from "./UsersDrawer";
import { CalendarIcon } from "../../../../components/icons/svg";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function BottomPane(props: any) {
  const { isGlobalView, setIsGlobalView, selectedMonth, setSelectedMonth } =
    props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [stateValue, setStateValue] = useState(null);
  const [country, setCountry] = useState(null);
  const [page, setPage] = useState(1);
  const { data, isFetching } = useQuery({
    queryKey: ["fetchMap", isGlobalView, selectedMonth],
    queryFn: () =>
      fetchMap({
        map: isGlobalView ? "world" : "us",
        date: selectedMonth,
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const CustomDatePickerInput = forwardRef<HTMLButtonElement, any>(
    ({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        variant="outline"
        border="1px solid var(--grey-300)"
        borderRadius="0.5rem"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Text padding="0.75rem 0rem">{value || "Select Date"}</Text>

        {/* Divider line */}
        <Box
          height="100%"
          width="1px"
          backgroundColor="var(--grey-300)"
          marginX="1rem"
        />

        <Icon as={ChevronDownIcon} fontSize="1.25rem" />
      </Button>
    )
  );

  return (
    <VStack border="1px solid var(--grey-300)" borderRadius="0.75rem" w="100%">
      <HStack
        w="100%"
        p="1rem"
        borderBottom="1px solid var(--grey-300)"
        justifyContent="space-between"
      >
        <HStack gap="2rem">
          <SkeletonText isLoaded={!isFetching} noOfLines={1}>
            <Text textStyle="h5" fontWeight="700">
              Users Location Heatmap
            </Text>
          </SkeletonText>
          <HStack
            border="1px solid var(--grey-300)"
            h="2.25rem"
            borderRadius="0.5rem"
            alignItems="normal"
            gap="0"
          >
            <Button
              variant="none"
              w="6.25rem"
              h="100%"
              textAlign="center"
              borderRight="1px solid var(--grey-300)"
              background={
                !isGlobalView ? "var(--secondary-600)" : "transparent"
              }
              color={!isGlobalView ? "white" : "var(--grey-900)"}
              borderTopLeftRadius="0.5rem"
              borderBottomLeftRadius="0.5rem"
              onClick={() => setIsGlobalView(false)}
            >
              <Skeleton isLoaded={!isFetching}>
                <Text textStyle="h6" fontWeight="500">
                  {!isGlobalView && (
                    <Box
                      as="span"
                      mr="0.32rem"
                      background="var(--secondary-50)"
                      borderRadius="0.35rem"
                      p="0.12rem 0.25rem"
                      color="var(--secondary-600)"
                      fontSize="0.75rem"
                    >
                      {data?.data?.count}
                    </Box>
                  )}
                  US Map
                </Text>
              </Skeleton>
            </Button>

            <Button
              variant="none"
              w="6.25rem"
              h="100%"
              textAlign="center"
              borderTopRightRadius="0.5rem"
              borderBottomRightRadius="0.5rem"
              background={isGlobalView ? "var(--secondary-600)" : "transparent"}
              color={isGlobalView ? "white" : "var(--grey-900)"}
              onClick={() => setIsGlobalView(true)}
            >
              <Skeleton isLoaded={!isFetching}>
                <Text textStyle="h6" fontWeight="500">
                  {isGlobalView && (
                    <Box
                      as="span"
                      mr="0.32rem"
                      background="var(--secondary-50)"
                      borderRadius="0.35rem"
                      p="0.12rem 0.25rem"
                      color="var(--secondary-600)"
                      fontSize="0.75rem"
                    >
                      {data?.data?.count}
                    </Box>
                  )}
                  Global
                </Text>
              </Skeleton>
            </Button>
          </HStack>
        </HStack>
        <HStack>
          <Skeleton isLoaded={!isFetching}>
            <HStack className="locationDatePicker">
              {/* <DatePicker
                selected={selectedMonth}
                onChange={(date: Date | null) => {
                  setSelectedMonth(date);
                  setPage(1);
                }}
                dateFormat="MMMM,d YYYY"
                maxDate={new Date()}
              /> */}
              <DatePicker
                selected={selectedMonth}
                onChange={(date: Date | null) => {
                  setSelectedMonth(date);
                  setPage(1);
                }}
                dateFormat="MMMM d, yyyy"
                maxDate={new Date()}
                customInput={<CustomDatePickerInput />}
              />
            </HStack>
          </Skeleton>
        </HStack>
      </HStack>
      <HStack w="100%" padding="1rem" justifyContent="space-between">
        <Box w="90%">
          <Skeleton isLoaded={!isFetching} height="100%">
            {isGlobalView ? (
              <WorldMap
                count={data?.data?.user_per_location}
                onOpen={onOpen}
                setCountry={setCountry}
              />
            ) : (
              <USMap
                count={data?.data?.user_per_location}
                onOpen={onOpen}
                setStateValue={setStateValue}
              />
            )}
          </Skeleton>
        </Box>
      </HStack>
      {isOpen && (
        <UsersDrawer
          page={page}
          setPage={setPage}
          onClose={onClose}
          country={isGlobalView ? country : "United States of America"}
          state={isGlobalView ? null : stateValue}
          selectedMonth={selectedMonth}
        />
      )}
    </VStack>
  );
}
