import {
  Box,
  Button,
  DrawerFooter,
  HStack,
  ListItem,
  Text,
  UnorderedList,
  useToast,
  VStack,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@tanstack/react-query";
import moment from "moment";
import {
  deleteAppointment,
  deleteContactReminder,
  editAppointment,
  editContactReminder,
  getAppointmentDetail,
  updateStatus,
  viewContactReminder,
} from "../../utils/api/meeting.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { Drawer } from "../common";
import Loader from "../common/Spinner";
import {
  FilledClockIcon,
  GroupDiscussionMeetingIcon,
  MeetingCalendarIcon,
  PenIcon,
  UserIcon,
} from "../icons/svg";
import { simpAdminChecker,dealershipAdminChecker } from "../../utils";
import { useNavigate } from "react-router-dom";
export const AppointmentDetail = ({
  isOpen,
  onClose,
  selectedID,
  myCalendar,
  editMeeting,
  apiCall,
  contactid,
}: any) => {
  const toast = useToast();
  const navigate=useNavigate()
  const dealership = useUserInfo("dealership");
  const userId = useUserInfo("id");
  const role = useUserInfo("role");
  const impSSRole = useUserInfo("impSSRole");
  const { data, isLoading } = useQuery({
    queryKey: [
      "Appointment-detail",
      dealership?.id,
      myCalendar,
      selectedID,
      contactid,
    ],
    queryFn: contactid ? viewContactReminder : getAppointmentDetail,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const editmutation = useMutation(
    contactid && data?.data?.event_type === "reminder"
      ? editContactReminder
      : editAppointment
  );
  const statusmutation = useMutation(updateStatus);
  const handleEditFormSubmit = (status: string) => {
    editmutation.mutate(
      {
        id: dealership.id,
        meetingId: selectedID,
        contactid: contactid,
        data: {
          event: {
            status: status,
          },
        },
      },
      {
        onSuccess(data, variables, context) {
          onClose();
          apiCall();
          toast({
            description: "Appointment editted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const handleStatus = () => {
    statusmutation.mutate(
      {
        id: dealership.id,
        meetingId: selectedID,
        data: {
          status: "attending",
        },
      },
      {
        onSuccess(data, variables, context) {
          onClose();
          apiCall();
          toast({
            description: "Reminder marked as completed successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const deletemutation = useMutation(
    contactid && data?.data?.event_type === "reminder"
      ? deleteContactReminder
      : deleteAppointment
  );
  const handleDelete = () => {
    deletemutation.mutate(
      {
        id: dealership.id,
        meetingId: selectedID,
        contactid: contactid,
      },
      {
        onSuccess(data, variables, context) {
          onClose();
          apiCall();
          toast({
            description: "Event deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  return (
    <Drawer isOpen={isOpen} onClose={onClose} title="Appointment Detail">
      <VStack padding="1rem 1.3rem" alignItems="flex-start" w="100%">
        <VStack
          gap="1.25rem"
          background="var(--grey-50)"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="1rem"
          w="100%"
        >
          <Text
            textStyle="h4"
            w="100%"
            fontWeight="600"
            paddingBottom="1rem"
            borderBottom="1px solid var(--grey-300)"
          >
            Appointment:{" "}
            <Box as="span" color="var(--primary-600)">
              {data?.data?.title}
            </Box>
          </Text>
          <HStack w="100%">
            <HStack w="50%">
              <Box
                padding="0.5rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                background="white"
              >
                <MeetingCalendarIcon />
              </Box>
              <Text textStyle="h6" fontWeight="500">
                {moment(data?.data?.start_time).format("dddd DD MMMM YY")}
              </Text>
            </HStack>
            <HStack w="50%">
              <Box
                padding="0.5rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                background="white"
              >
                <FilledClockIcon />
              </Box>
              <Text textStyle="h6" fontWeight="500">
                {moment(data?.data?.start_time).format("h:mm a")} to{" "}
                {moment(data?.data?.end_time).format("h:mm a")}
              </Text>
            </HStack>
          </HStack>
          <HStack w="100%">
            <HStack w="50%">
              <Box
                padding="0.5rem"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                background="white"
              >
                <GroupDiscussionMeetingIcon />
              </Box>
              <Text textStyle="h6" fontWeight="500">
                Appointment type:{" "}
                {data?.data?.event_type?.charAt(0).toUpperCase() +
                  data?.data?.event_type?.slice(1)}
              </Text>
            </HStack>
            {data?.data?.contact && (
              <HStack w="50%">
                <Box
                  padding="0.5rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  background="white"
                  sx={{
                    svg: {
                      width: "1.25rem",
                      height: "1.25rem",
                    },
                  }}
                >
                  <UserIcon />
                </Box>
                <Text textStyle="h6" fontWeight="500" onClick={()=> navigate(`/leads/lead-details?search=${data?.data?.contact?.phone}`)}
                  cursor={'pointer'} color="var(--primary-600)">
                  Contact: {data?.data?.contact?.name} (
                  {data?.data?.contact?.phone})
                </Text>
              </HStack>
            )}
          </HStack>
          <HStack w="100%">
            {data?.data?.created_by && (
              <HStack w="50%">
                <Box
                  padding="0.5rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  background="white"
                  sx={{
                    svg: {
                      width: "1.25rem",
                      height: "1.25rem",
                    },
                  }}
                >
                  <UserIcon />
                </Box>
                <Text textStyle="h6" fontWeight="500">
                  Created By: {data?.data?.created_by?.name??'N/A' } 
                </Text>
              </HStack>
            )}
            <HStack w="50%">
                <Box
                  padding="0.5rem"
                  border="1px solid var(--grey-300)"
                  borderRadius="0.5rem"
                  background="white"
                  sx={{
                    svg: {
                      width: "1.25rem",
                      height: "1.25rem",
                    },
                  }}
                >
                  <MeetingCalendarIcon />
                </Box>
                <Text textStyle="h6" fontWeight="500">
                  Created At: {moment(data?.data?.created_at).format("dddd DD MMMM YY")}

                </Text>
              </HStack>
          </HStack>
        </VStack>

        {!myCalendar &&
          data?.data?.event_type === "reminder" &&
          data?.data?.participants.length > 0 && (
            <>
              <VStack
                gap="1.25rem"
                background="var(--grey-50)"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                padding="1rem"
                w="100%"
              >
                <Text
                  textStyle="h4"
                  w="100%"
                  fontWeight="600"
                  paddingBottom="1rem"
                  borderBottom="1px solid var(--grey-300)"
                >
                  Participant's Status:{" "}
                </Text>
                <HStack w="100%" paddingLeft="0.5rem">
                  <UnorderedList>
                    {data?.data?.participants?.map((participant: any) => (
                      <ListItem>
                        {" "}
                        <Text textStyle="h6" fontWeight="500">
                          {participant?.full_name} :{" "}
                          {participant?.status === "pending_response"
                            ? "Pending"
                            : "Completed"}
                        </Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </HStack>{" "}
              </VStack>
            </>
          )}

        {(data?.data?.event_type === "reminder" || !myCalendar) && (
          <DrawerFooter
            position="absolute"
            bottom="0"
            left="0"
            width="100%"
            borderTop="1px solid var(--grey-300)"
            display="flex"
            alignItems="center"
            gap="0.81rem"
          >
            {(userId == data?.data?.created_by?.id||simpAdminChecker(impSSRole)||dealershipAdminChecker(role)) &&
              (data?.data?.participants?.length === 0 || !myCalendar) && (
                <>
                  <Button
                    variant="outline"
                    padding="0.5rem"
                    size="lg"
                    onClick={() => handleDelete()}
                  >
                    <DeleteIcon />
                  </Button>
                  <Button
                    variant="outline"
                    padding="0.5rem"
                    size="lg"
                    onClick={() => editMeeting(data?.data)}
                  >
                    <PenIcon />
                  </Button>
                </>
              )}
            {data?.data?.event_type === "reminder" &&
              (data?.data?.participants?.find(
                (item: any) => item?.id == userId
              ) ||
                myCalendar) && (
                <>
                  <Button
                    w="100%"
                    size="xl"
                    onClick={() => {
                      data?.data?.participants?.length === 0
                        ? handleEditFormSubmit("completed_reminder")
                        : handleStatus();
                    }}
                    isDisabled={
                      data?.data?.status === "completed_reminder" ||
                      data?.data?.participants?.find(
                        (item: any) => item?.id == userId
                      )?.status === "attending"
                    }
                  >
                    Mark As Completed
                  </Button>
                  {/* <Button
                variant="danger"
                w="100%"
                size="xl"
                onClick={() => handleEditFormSubmit("missed_reminder")}
                isDisabled={data?.data?.status === "missed_reminder"}
              >
                Cancel
              </Button> */}
                </>
              )}
          </DrawerFooter>
        )}
      </VStack>
      {(editmutation?.isLoading || deletemutation?.isLoading) && <Loader />}
    </Drawer>
  );
};
