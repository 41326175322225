import {
  Box,
  Button,
  HStack,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { humanize, isImageLink } from "../../../utils";
import { fetchData } from "../../../utils/api/sms-templates.api";
import { Dropdown, PaginatedTable, Spinner } from "../../common";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import { EditIcon } from "../../icons/svg";
import CreateSMSTemplate from "./CreateSMSTemplate";
import DeleteSMSTemplate from "./DeleteSMSTemplate";
import EditSMSTemplate from "./EditSMSTemplate";

const SMSTemplateGlobalConfig = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(20);

  const [templates, setTemplates] = useState([
    {
      id: 0,
      name: "",
      body: "",
      media_urls: [],
    },
  ]);

  const [clickedRow, setClickedRow] = useState({
    id: 0,
    name: "",
    body: "",
    media_urls: [],
  });

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <EditIcon color="var(--grey-800)" />
          Edit
        </Box>
      ),
      value: "edit",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DeleteIcon style={{ color: "var(--grey-800)", width: "1.2rem" }} />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];
  const handleSelect = (option: SelectOptionProps) => {
    setSelectedOption(option);
  };
  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "Body", label: "body" },
    { label: "media_urls", value: "Attachment Urls" },
    { label: "type", value: "type" },
    { value: "Actions", label: "actions" },
  ];

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["fetchSMSTemplateGC", page, perPage],
    queryFn: () => {
      const params: any = {
        page: page,
        per_page: perPage,
      };
      const endpoint = `/api/admin/sms_templates`;
      return fetchData(endpoint, params);
    },
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setTemplates(data?.sms_templates);
      setCount(data?.sms_templates_count ?? 0);
    },
  });

  const tableData = templates?.map((item: any) => ({
    id: item.id,
    name: item.name,
    body: item.body,
    type: humanize(item.type),
    media_urls: (
      <>
        {item.media_urls?.map((attachment: any, index: number) => (
          <Text key={index} textStyle="h6" fontWeight="500">
            <TooltipIfOverflow>
              {`• ${attachment?.filename}` ?? "-"}
            </TooltipIfOverflow>
            {isImageLink(attachment.url) ? (
              <img
                src={attachment.url}
                alt={attachment.url}
                width={100}
                style={{ whiteSpace: "nowrap" }}
              />
            ) : (
              <></>
            )}
          </Text>
        ))}
      </>
    ),
    actions: <Dropdown options={options} onSelect={handleSelect} />,
  }));

  return (
    <VStack
      w="100%"
      alignItems="flex-start"
      gap="0"
      overflow="auto"
      h="calc(100vh - 172px)"
    >
      <HStack
        w="100%"
        justifyContent="space-between"
        padding="1.25rem 1.5rem"
        background="white"
      >
        <HStack gap="0.625rem" w="100%" justifyContent="flex-start"></HStack>
        <Button onClick={onOpen}>Create Template</Button>
      </HStack>
      <VStack w="100%" alignItems="flex-start" padding="1.5rem">
        <Box
          w="100%"
          alignItems="flex-start"
          background="white"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
        >
          <Text
            textStyle="caption"
            padding="1.5rem 1rem 0.75rem"
            w="100%"
            color="var(--grey-600)"
          >
            Total Templates ({count})
          </Text>
          <PaginatedTable
            header={tableHeader}
            data={tableData}
            itemsPerPage={perPage}
            maxPageNumbers={3}
            isPaginated={true}
            lastCenter={false}
            hasMultiBody={false}
            rowOnClick={(rowItem) => {
              setClickedRow(rowItem);
            }}
            perPageChange={(value) => {
              setPerPage(Number(value?.label));
              setPage(1);
            }}
            currentPage={page}
            setCurrentPage={setPage}
            rowCount={count}
            isLoadingData={isLoading}
          />
        </Box>
      </VStack>

      {isOpen && (
        <CreateSMSTemplate
          isOpen={isOpen}
          onClose={onClose}
          refetchParent={refetch}
          endpoint={`/api/admin/sms_templates`}
          headers={{
            menu: "Global Configurations",
            "Sub-Menu": "SMS Template",
          }}
        ></CreateSMSTemplate>
      )}

      {selectedOption?.value === "edit" && (
        <EditSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/admin/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Global Configurations",
            "Sub-Menu": "SMS Template",
          }}
        />
      )}
      {selectedOption?.value === "delete" && (
        <DeleteSMSTemplate
          isOpen={true}
          onClose={() => setSelectedOption(null)}
          refetchParent={refetch}
          id={clickedRow?.id}
          endpoint={`/api/admin/sms_templates/${clickedRow?.id}`}
          headers={{
            menu: "Global Configurations",
            "Sub-Menu": "SMS Template",
          }}
        />
      )}
    </VStack>
  );
};
export default SMSTemplateGlobalConfig;
