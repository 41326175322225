
import ManagePermission from "../components/UserSettings/ManagePermission";
import { ProfileSetting,InboundSetting,NotificationSetting,OutboundSetting,TwoFactor,VisibilitySetting } from "../components/UserSettings";
import { NotificationSettingsGlobal } from "../components/UserSettings/NotificationSettingsGlobal";
import SMSTemplate from "../components/Reports/SMSTemplate/SMSTemplate"
import IPSetting from "../components/UserSettings/IPSetting";
 

export const tabConfigurationAdmin = [
    { label: "Profile Settings", component: <ProfileSetting allowed={true}/> },
    { label: "Two-Factor Authentication", component: <TwoFactor allowed={true} /> },
    { label: "Notification Settings", component: <NotificationSettingsGlobal allowed={true} /> },
    { label: "IP Settings", component: <IPSetting allowed={false} /> },
  ];
  export const tabConfigurationSimpUsers = [
    { label: "Profile Settings", component: <ProfileSetting allowed={true}/> },
    { label: "Two-Factor Authentication", component: <TwoFactor allowed={true} /> },
    { label: "Notification Settings", component: <NotificationSettingsGlobal allowed={false} /> },
    { label: "IP Settings", component: <IPSetting allowed={false} /> },
  ];
  export const tabConfigurationSimpAdminUsers = [
    { label: "Notification Settings", component: <NotificationSettingsGlobal allowed={true} /> },
    { label: "IP Settings", component: <IPSetting allowed={true} /> },
  ];
  export const tabConfigurationUser = [
    { label: "Profile Settings", component: <ProfileSetting  allowed={true}/> },
    // { label: "Visibility Settings", component: <VisibilitySetting  allowed={true} /> },
    { label: "Inbound Call Settings", component: <InboundSetting  allowed={true}/> },
    { label: "Voicemail Library", component: <OutboundSetting  allowed={true}/> },
    { label: "Notification Settings", component: <NotificationSetting  allowed={true}/> },
    { label: "SMS Templates", component: <SMSTemplate  allowed={true}/> },
    { label: "Manage permissions", component: <ManagePermission  allowed={false}/> },
    { label: "Two-Factor Authentication", component: <TwoFactor  allowed={true}/> },
    { label: "IP Settings", component: <IPSetting allowed={false} /> },
  ];

  // from the users menu settings option in dealership portal
  export const tabConfigurationUserAdmin = [
    { label: "Profile Settings", component: <ProfileSetting  allowed={true}/>},
    { label: "Visibility Settings", component: <VisibilitySetting  allowed={true}/>},
    { label: "Inbound Call Settings", component: <InboundSetting  allowed={true}/>},
    { label: "Voicemail Library", component: <OutboundSetting  allowed={true}/>},
    { label: "Notification Settings", component: <NotificationSetting  allowed={true}/>},
    { label: "SMS Templates", component: <SMSTemplate  allowed={true}/>},
    { label: "Manage permissions", component: <ManagePermission  allowed={true}/>},
    // { label: "Two-Factor Authentication", component: <TwoFactor  allowed={true}/>},
    { label: "IP Settings", component: <IPSetting allowed={true} /> },

  ];
  export const tabConfigurationRestrictedDealershipUser = [
    { label: "Profile Settings", component: <ProfileSetting  allowed={true}/> },
    // { label: "Visibility Settings", component: <VisibilitySetting  allowed={true}/> },
    { label: "Inbound Call Settings", component: <InboundSetting  allowed={true}/>},
    { label: "Voicemail Library", component: <OutboundSetting  allowed={true}/>},
    { label: "Notification Settings", component: <NotificationSetting  allowed={false}/> },
    { label: "SMS Templates", component: <SMSTemplate  allowed={false}/>},
    { label: "Manage permissions", component: <ManagePermission  allowed={false}/>},
    { label: "Two-Factor Authentication", component: <TwoFactor  allowed={true}/>},
    { label: "IP Settings", component: <IPSetting allowed={false} /> },
  ];