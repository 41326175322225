export const prioritiesOption = [
  {
    title: "Priority# 1 Follow up",
    subTitle: "Leads 1-7 Days - Not Sold/No Appointment Set",
  },
  {
    title: "Priority# 2 Follow up",
    subTitle: "Leads 8-15 Days - Not Sold/No Appointment Set",
  },
  {
    title: "Priority# 3 Follow up",
    subTitle: "Leads 16-60 Days - Not Sold/No Appointment Set",
  },
];

export const leadDistributionOptions = [
  { label: "Round Robin regardless who is logged in", value: "regardless" },
  { label: "Round Robin based on the users logged in", value: "users" },
  {
    label: "Up for grabs (Lead sources not assigned will be up for grabs)",
    value: "grabs",
  },
];
