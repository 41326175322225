import { Button, DrawerFooter, useToast, VStack } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchKeywordById,
  fetchKeywordDealershipById,
  updateKeyword,
  updateKeywordDealership,
} from "../../../utils/api/global-config.api";
import { Drawer, Input } from "../../common";

type data = {
  id: number;
  isOpen: boolean;
  onClose: any;
  refetchParent: any;
};

const EditKeyword = (props: data) => {
  const { id: dId } = useParams();
  const { isOpen, onClose, refetchParent, id } = props;
  const toast = useToast();
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const mutation = useMutation(updateKeyword);
  const mutationDealership = useMutation(updateKeywordDealership);

  const handleSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!name?.trim()) {
      setNameError("Name cannot be empty");
      return;
    }

    setNameError("");

    const mutationFunc = dId ? mutationDealership : mutation;

    mutationFunc.mutate(
      {
        payload: {
          keyword: {
            name: name,
          },
        },
        id,
        dId,
      },
      {
        onSuccess: () => {
          refetchParent();
          onClose();
          toast({
            description: "Keyword created successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error creating keyword: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const { data, isLoading } = useQuery({
    queryKey: ["fetchKeyword", id, dId],
    queryFn: dId ? fetchKeywordDealershipById : fetchKeywordById,
  });

  useEffect(() => {
    setName(data?.data?.name);
  }, [data]);
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      title="Edit Keyword"
      isLoading={isLoading || mutation?.isLoading}
    >
      <VStack alignItems="flex-start" padding="1rem 1.5rem" w="100%" gap="1rem">
        <Input
          placeholder="Write Keyword"
          label="Enter Keyword"
          onChange={(e: any) => setName(e.target.value)}
          value={name}
          isRequired={true}
          error={nameError}
        />
      </VStack>
      <DrawerFooter
        position="absolute"
        bottom="0"
        left="0"
        width="100%"
        borderTop="1px solid var(--grey-300)"
        display="flex"
        alignItems="center"
        gap="0.81rem"
      >
        <Button variant="outline" w="100%" size="xl" onClick={onClose}>
          Cancel
        </Button>
        <Button w="100%" size="xl" onClick={handleSubmit}>
          Confirm
        </Button>
      </DrawerFooter>
    </Drawer>
  );
};

export default EditKeyword;
