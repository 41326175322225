import {
  Box,
  HStack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { capitalize } from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "../../../components/common";
import ScheduleModal from "../../../components/Drawers/SmsDrawer/ScheduleModal";
import {
  createAnnouncement,
  createSmsAnnouncement,
  createSmsTemplate,
  createTemplate,
  editAnnouncement,
  editSmsAnnouncement,
  fetchAnnouncementById,
  fetchSmsAnnouncementById,
  fetchUsers,
} from "../../../utils/api/announcements";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import CommonFields from "./CommonFields";
import Footer from "./Footer";
import Header from "./Header";
import MessageEditor from "./MessageEditor";
import Subject from "./Subject";
import UserListingDrawer from "./UsersListingDrawer";
import {
  ComposeBlastForm,
  composeBlastValidation,
  defaultValues,
  getPayload,
} from "./utils";

const ComposeBlast = () => {
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();
  const id = searchParam.get("id");
  const type = searchParam.get("type") || undefined;
  const user = useUserInfo("user");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const editorRef = useRef();
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [removedUsers, setRemovedUsers] = useState<any[]>([]);
  const [removedUsersParam, setRemovedUsersParam] = useState<any[]>([]);
  const [countEdit, setCountEdit] = useState<any>(null);
  const [users, setUsers] = useState<any[]>([]);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const mutation = useMutation(createAnnouncement);
  const mutationEdit = useMutation(editAnnouncement);
  const mutationSms = useMutation(createSmsAnnouncement);
  const mutationEditSms = useMutation(editSmsAnnouncement);
  const mutationSaveAsTemplate = useMutation(createTemplate);
  const mutationSaveAsTemplateSms = useMutation(createSmsTemplate);
  const toast = useToast();

  const { handleSubmit, control, setValue, watch, getValues } =
    useForm<ComposeBlastForm>({
      defaultValues: defaultValues,
      resolver: yupResolver(composeBlastValidation(type || "")) as any,
    });

  const formVals = watch();

  const { isLoading: isLoadingAnnouncement } = useQuery({
    queryKey: ["fetchAnnouncementById", id, type],
    queryFn: type ? fetchSmsAnnouncementById : fetchAnnouncementById,
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!id,
    onSuccess(data) {
      setValue(
        "dealerships",
        data?.data?.dealerships?.map((item: any) => ({
          value: item?.value?.toString(),
          label: item?.label === "all" ? "All" : item?.label,
        }))
      );
      setValue(
        "role",
        data?.data?.roles?.map((item: any) => ({
          value: item?.value?.toString(),
          label: item?.label === "all" ? "All" : item?.label,
        }))
      );
      setValue(
        "status",
        data?.data?.dealership_status?.map((item: any) => ({
          value: item,
          label: capitalize(item),
        }))
      );
      setValue("users", data?.data?.contacts_count);
      setValue("throttling_rate", data?.data?.throttling_rate);
      setValue("messageBody", type ? data?.data?.content : data?.data?.body);
      setValue("subject", type ? data?.data?.name : data?.data?.subject);

      setRemovedUsersParam(data?.data?.removed_users); // added this so that the announcement dealership users api does not refetch on each removed contact
      setAttachmentUrls(
        type ? data?.data?.media_urls : data?.data?.attachment_urls
      );
      setCountEdit(data?.data?.contacts_count);
    },
  });
  const { data: usersListing, isLoading: isLoadingUsers } = useQuery({
    queryKey: [
      "fetchUsers",
      {
        per_page: 20,
        page,
        roles: formVals?.role?.map((role: any) =>
          role.label === "All" ? role.value : role.label
        ),
        ids: formVals?.dealerships?.map((dealership: any) => dealership.value),
        removed_users: removedUsersParam,
      },
    ],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
    retry: false,
    enabled:
      formVals?.role?.length > 0 &&
      formVals?.dealerships?.length > 0 &&
      formVals?.status?.length > 0,
    onSuccess(data: any) {
      if (page === 1) {
        setUsers(data?.data?.dealership_users);
      } else {
        setUsers((prev: any) => [...prev, ...data?.data?.dealership_users]);
      }
    },
  });

  const makeCountEditNull = () => {
    if (id) {
      setCountEdit(null);
      setRemovedUsersParam([]);
    }
  };

  const handleOnChange = (value: any) => {
    if (value?.length === 0) {
      setUsers([]);
    }
    setRemovedUsers([]);
    setPage(1);
    makeCountEditNull();
  };

  const disableButtons =
    formVals?.role?.length <= 0 ||
    formVals?.dealerships?.length <= 0 ||
    formVals?.status?.length <= 0 ||
    users?.length - removedUsers?.length === 0 ||
    !formVals?.messageBody ||
    formVals?.messageBody === "<p><br></p>" ||
    !formVals?.subject;

  const disableForEmailTemplate =
    !formVals?.subject ||
    !formVals?.messageBody ||
    formVals?.messageBody === "<p><br></p>";

  const createOrEditAnnouncementMethod = (dateFields: any) => {
    const mutationFunc = id
      ? type
        ? mutationEditSms
        : mutationEdit
      : type
      ? mutationSms
      : mutation;

    mutationFunc.mutate(
      getPayload(dateFields, formVals, removedUsers, attachmentUrls, type, id),
      {
        onSuccess: (data) => {
          toast({
            description: `Announcement ${id ? "edited" : "sent"} successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          navigate("/announcements");
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleFormSubmit = (values: ComposeBlastForm) => {
    createOrEditAnnouncementMethod({});
  };
  const handleSaveAsTemplate = (values: ComposeBlastForm) => {
    const mutationFunc = type
      ? mutationSaveAsTemplateSms
      : mutationSaveAsTemplate;

    const emailPayload = {
      email_template: {
        subject: formVals.subject,
        body: formVals.messageBody,
        template_type: "announcement",
        status: "active",
        attachment_urls: attachmentUrls.map(
          (attachment: any) => attachment.url
        ),
      },
      subMenu: "Email",
    };

    const smsPayload = {
      sms_template: {
        name: formVals.subject,
        body: formVals.messageBody,
        media_urls: attachmentUrls.map((attachment: any) => attachment.url),
        sms_templates_type: "announcement",
      },
      subMenu: "SMS",
    };

    mutationFunc.mutate(
      {
        payload: type ? smsPayload : emailPayload,
      },
      {
        onSuccess: (data: any) => {
          toast({
            description: `Template created successfully.`,
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
          navigate("/announcements");
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const handleSchedule = (date: Date) => {
    const dateFields = {
      is_scheduled: true,
      date_scheduled: date.toUTCString(),
      broadcast_date: date.toUTCString(),
      broadcast_time: date.toUTCString(),
    };

    createOrEditAnnouncementMethod(dateFields);
    setIsScheduleModalOpen(false);
  };

  useEffect(() => {
    setValue("status", [{ label: "Active", value: "active" }]);
  }, []);
  return (
    <>
      {isLoadingUsers || (id && isLoadingAnnouncement && <Spinner />)}
      <Box p="1.5rem" h="100%" overflow="auto">
        <VStack
          w="100%"
          alignItems="flex-start"
          borderRadius="0.5rem"
          border="1px solid var(--grey-300)"
          background="white"
          padding="1rem"
          h="100%"
          overflow="auto"
        >
          <Header />
          <Box h="100%" w="100%" overflow="auto">
            <HStack w="100%" justifyContent="space-between" mb="1rem">
              <Text textStyle="h4" fontWeight="500">
                Compose
              </Text>
            </HStack>

            <Box
              border="1px solid var(--grey-300)"
              borderRadius="0.5rem"
              minH="calc(100vh - 310px)"
              // h="100%"
              overflow="auto"
            >
              <VStack gap="0" className="emailBlastDropdown">
                <CommonFields
                  control={control}
                  setValue={setValue}
                  handleOnChange={handleOnChange}
                  status={formVals?.status?.map((item: any) => item?.value)}
                  countEdit={countEdit}
                  users={Number(usersListing?.data?.count ?? 0)}
                  removedUsers={removedUsers}
                  onOpen={onOpen}
                />{" "}
                <Subject control={control} type={type} />
                <MessageEditor
                  control={control}
                  type={type}
                  setValue={setValue}
                  getValues={getValues}
                  attachmentUrls={attachmentUrls}
                  setAttachmentUrls={setAttachmentUrls}
                  editorRef={editorRef}
                />
              </VStack>
            </Box>
            <Box mr="1.5rem" pb="1.5rem">
              <Footer
                userEmail={user?.email}
                disableForEmailTemplate={disableForEmailTemplate}
                disableButtons={disableButtons}
                handleSaveAsTemplate={() =>
                  handleSubmit((values) => handleSaveAsTemplate(values))()
                }
                handleFormSubmit={() =>
                  handleSubmit((values) => handleFormSubmit(values))()
                }
                setIsScheduleModalOpen={setIsScheduleModalOpen}
                id={id}
              />
            </Box>
          </Box>
        </VStack>

        <UserListingDrawer
          isOpen={isOpen}
          onClose={onClose}
          users={users}
          setPage={setPage}
          removedUsers={removedUsers}
          setRemovedUsers={setRemovedUsers}
          type={type}
          count={countEdit ?? Number(usersListing?.data?.count ?? 0)}
        />
        <ScheduleModal
          isOpen={isScheduleModalOpen}
          onClose={() => setIsScheduleModalOpen(false)}
          handleSchedule={handleSchedule}
        />
      </Box>
    </>
  );
};

export default ComposeBlast;
