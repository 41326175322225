import { Dropdown, PaginatedTable, Spinner } from "../../common";
import { DownloadIcon, ImportIcon } from "../../icons/svg";
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Delete } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import ImportContacts from "../../../pages/(dashboard-routes)/leads/ImportContacts";
import { useParams } from "react-router";
import {
  deleteRow,
  downloadFileAPI,
  fetchRows,
} from "../../../utils/api/import-export";
import ConfirmationDrawer from "../../common/ConfirmationDrawer";
import { useMutation, useQuery } from "@tanstack/react-query";
import TooltipIfOverflow from "../../common/TooltipIfOverflow";
import moment from "moment";

const ImportsAndExports = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const role = useUserInfo("user")?.role;
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const { id: dId } = useParams();
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [clickedRow, setClickedRow] = useState<any>();
  const [count, setCount] = useState(0);
  const toast = useToast();
  const [rows, setRows] = useState<any>([]);

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["fetchRows", dId, activeTab],
    queryFn: () => fetchRows(dId, activeTab === 0),
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setCount(
        activeTab === 0 ? data?.data?.imports_count : data?.data?.exports_count
      );
      setRows(activeTab === 0 ? data?.data?.imports : data?.data?.exports);
    },
  });
  const mutation = useMutation(deleteRow);

  const handleDelete = () => {
    mutation.mutate(
      { dId: dId, id: clickedRow?.id, isImport: activeTab === 0 },
      {
        onSuccess: () => {
          refetch();
          setActiveModal(null);
          toast({
            description: "Deleted successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error deleting: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };
  const downloadFile = async () => {
    try {
      setIsFileDownloading(true);
      const response = await downloadFileAPI(
        dId,
        clickedRow?.id,
        activeTab === 0
      );
      setIsFileDownloading(false);
      const blob = new Blob([response?.data]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = clickedRow?.filename;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading", error);
    } finally {
      setActiveModal(null);
    }
  };
  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  useEffect(() => {
    if (activeModal === "download") {
      downloadFile();
    }
  }, [activeModal]);
  const tableHeader = [
    { id: 1, value: "File Imported", label: "fileImported" },
    { id: 2, value: "Imported By", label: "importedBy" },
    { id: 2, value: "Imported At", label: "importedAt" },
    { id: 5, value: "Sales Person", label: "salesPerson" },
    { id: 5, value: "BDC Agent", label: "bdcAgent" },
    { id: 5, value: "Manager", label: "manager" },
    { id: 6, value: "Rows", label: "rows" },
    { id: 7, value: "Imported", label: "imported" },
    { id: 12, value: "Actions", label: "actions" },
  ];
  const tableHeaderTwo = [
    { id: 1, value: "Requested By", label: "reqby" },
    { id: 2, value: "Requested At", label: "reqat" },
    { id: 3, value: "Menu", label: "menu" },
    { id: 12, value: "Actions", label: "actions" },
  ];

  const options: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <DownloadIcon />
          Download
        </Box>
      ),
      value: "download",
    },
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <Delete />
          Delete
        </Box>
      ),
      value: "delete",
    },
  ];
  const createDateTimeField = (item: string) => {
    return (
      <HStack gap="1rem">
        <VStack alignItems="flex-start" gap="0">
          <Text textStyle="h6" fontWeight="500">
            {moment(item).format("MM/DD/yy") || "-"}
          </Text>
          <Text textStyle="h6" fontWeight="500">
            {moment(item).format("h:mm A") || null}
          </Text>
        </VStack>
      </HStack>
    );
  };
  const tableData = rows?.map((item: any) => ({
    fileImported: (
      <Box>
        <Text textStyle="h6" color="var(--primary-600)" whiteSpace="nowrap">
          {item?.name || "-"}
        </Text>
        <TooltipIfOverflow>{item?.filename || "-"}</TooltipIfOverflow>
        <Text>ID: {item?.id || "-"}</Text>
      </Box>
    ),
    importedBy: item?.imported_by || "-",
    importedAt: createDateTimeField(item?.imported_at),
    salesPerson: item?.sales_person || "-",
    bdcAgent: item?.bdc_agent || "-",
    manager: item?.manager || "-",
    rows: item?.rows || "-",
    imported: item?.imported || "-",
    actions: (
      <Dropdown
        options={options}
        onSelect={handleSelect}
        disabled={role !== "SimpSocial Owner"}
      />
    ),
    id: item?.id,
    filename: item?.filename,
  }));

  const tableDataTwo = rows?.map((item: any) => ({
    reqby: (
      <Box>
        <Text textStyle="h6" color="var(--primary-600)" whiteSpace="nowrap">
          {item?.requested_by || "-"}
        </Text>
        <TooltipIfOverflow>{item?.filename || "-"}</TooltipIfOverflow>
      </Box>
    ),
    reqat: createDateTimeField(item?.requested_at),
    menu: item?.category || "-",
    actions: (
      <Dropdown
        options={options}
        onSelect={handleSelect}
        disabled={role !== "SimpSocial Owner"}
      />
    ),
    id: item?.id,
    filename: item?.filename,
  }));

  return (
    <Box>
      <Tabs
        variant="primary"
        defaultIndex={0}
        onChange={(index: any) => {
          setActiveTab(index);
        }}
      >
        <TabList>
          <Tab>Import</Tab>
          <Tab>Export</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            {activeTab === 0 && (
              <>
                <HStack justifyContent="space-between" mt="1rem">
                  <Text textStyle="h6" color=" var(--grey-600)">
                    Imports Found {count}
                  </Text>
                  <Button
                    variant="outlineBlue"
                    leftIcon={<ImportIcon color="#4199F7" />}
                    onClick={onOpen}
                  >
                    Import Contact
                  </Button>
                </HStack>
                <Box mt="1rem">
                  <PaginatedTable
                    header={tableHeader}
                    data={tableData}
                    itemsPerPage={10}
                    maxPageNumbers={5}
                    hasPadding={false}
                    lastCenter={false}
                    rowOnClick={(item) => setClickedRow(item)}
                    isLoadingData={isFetching}
                  />
                </Box>
              </>
            )}
          </TabPanel>

          <TabPanel>
            {activeTab === 1 && (
              <>
                <HStack justifyContent="space-between" mt="1rem">
                  <Text textStyle="h6" color=" var(--grey-600)">
                    Exports Found {count}
                  </Text>
                </HStack>
                <Box mt="1rem">
                  <PaginatedTable
                    header={tableHeaderTwo}
                    data={tableDataTwo}
                    itemsPerPage={10}
                    maxPageNumbers={5}
                    hasPadding={false}
                    lastCenter={false}
                    rowOnClick={(item) => setClickedRow(item)}
                    isLoadingData={isFetching}
                  />
                </Box>
              </>
            )}
          </TabPanel>
        </TabPanels>
        {isOpen && (
          <ImportContacts
            onClose={() => {
              refetch();
              onClose();
            }}
          ></ImportContacts>
        )}
        {activeModal === "delete" && (
          <ConfirmationDrawer
            isOpen={true}
            onClose={() => {
              refetch();
              setActiveModal(null);
            }}
            content={{
              name: "Delete Note",
              description: "Are you sure you want to delete this note?",
            }}
            apiCall={handleDelete}
            loading={mutation?.isLoading}
          />
        )}
      </Tabs>
      {(isFileDownloading) && <Spinner />}
    </Box>
  );
};

export default ImportsAndExports;
