import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileIcon from "../../../components/icons/profile.png";
import {
  CloseIcon,
  CloudUploadIcon,
  GeneralIcon,
  IntegrationIcon,
} from "../../../components/icons/svg";
import {
  editDealershipData,
  getDealershipData,
} from "../../../utils/api/dealership-setting.api";
import Loader from "../../common/Spinner";
import General from "./General/General";
import Integration from "./Integration/Integration";

const DealerProfile = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [image, setImage] = useState<any>();
  const mutation = useMutation(editDealershipData);
  const { id } = useParams();
  const toast = useToast();
  const {
    data,
    isFetching: isLoading,
    refetch,
  } = useQuery({
    queryKey: ["dealershipdata", id],
    queryFn: getDealershipData,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setDetail(data?.data);
      setImage(data?.data?.image_url);
    },
  });
  const [selectedTab, setSelectedTab] = useState(0);
  useEffect(() => {
    // Parse the search query parameters
    const queryParams = new URLSearchParams(window.location.search);
    const isFacebook = queryParams.has("facebook");
    const isTikTok = queryParams.has("tiktok");

    if (isFacebook || isTikTok) {
      setSelectedTab(1);
    }
  }, []);

  const [detail, setDetail] = useState<any>();
  const handleFileChange = (event: ChangeEvent) => {
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
    }
  };

  const handleSubmit = async () => {
    mutation.mutate(
      {
        id: id,
        data: {
          dealership: {
            name: detail?.name,
            time_zone: detail?.time_zone,
            website: detail?.website,
            address: detail?.address,
          },
          image: selectedFile,
        },
      },
      {
        onSuccess: (response: any) => {
          setImage(response?.image_url);
          setSelectedFile(null);
          toast({
            description: "Image updated successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.errors?.toString() ??
              "Something went wrong",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  const TabsData = [
    {
      id: 0,
      title: "General",
      content: <General detail={detail} refetch={refetch} />,
      icon: <GeneralIcon />,
    },
    {
      id: 1,
      title: "Integration",
      content: <Integration />,
      icon: <IntegrationIcon />,
    },
  ];
  const handleClearFile = (event: ChangeEvent) => {
    const fileUrl = ProfileIcon;
    fetch(fileUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "profile.png", { type: "image/png" });
        setSelectedFile(file);
        setImage(URL.createObjectURL(file));
      })
      .catch((err) => {
        console.error("Error resetting image file:", err);
      });
  };
  return (
    <Box h="calc(100vh - 280px)">
      <HStack
        w="100%"
        justifyContent="space-between"
        alignItems="center"
        padding="1rem 0.5rem 1rem"
      >
        <HStack gap="1.5rem">
          <Box
            borderRadius="50%"
            boxShadow="0px 9px 22px rgba(0, 0, 0, 0.15)"
            background="white"
            sx={{
              img: {
                borderRadius: "50%",
              },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="7.875rem"
            height="7.875rem"
            position="relative"
          >
            {selectedFile || image ? (
              <>
                <Image
                  src={selectedFile ? URL.createObjectURL(selectedFile) : image}
                  width="100%"
                  height="100%"
                  objectFit="contain"
                  alt="banner"
                />

                <Box
                  position="absolute"
                  top="0.25rem"
                  right="0.25rem"
                  cursor="pointer"
                  onClick={handleClearFile}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  bg="white"
                  borderRadius="50%"
                  p="0.25rem"
                  boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)"
                >
                  <CloseIcon />
                </Box>
              </>
            ) : (
              <Image
                src={ProfileIcon}
                width="100%"
                height="100%"
                objectFit="contain"
                alt="banner"
              />
            )}
            <Box position="absolute" bottom="0" right="0">
              <Box position="relative" width="100%" textAlign="center">
                <Input
                  type="file"
                  onChange={handleFileChange}
                  variant="file"
                  accept="image/jpeg,image/png"
                />
                <Box
                  as="span"
                  borderRadius="50%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    svg: {
                      width: "2.25rem",
                      height: "2.25rem",
                    },
                  }}
                >
                  <CloudUploadIcon />
                </Box>
              </Box>
            </Box>
          </Box>

          <VStack alignItems="flex-start" pt="2rem">
            <Text textStyle="h3" fontWeight="700">
              {data?.data?.name}
            </Text>
            <Text textStyle="h5"> {data?.data?.website ?? ""}</Text>
            <Text textStyle="h5">{data?.data?.address ?? ""}</Text>
            {selectedFile && (
              <Button onClick={handleSubmit}>Update Profile Picture</Button>
            )}
          </VStack>
        </HStack>
      </HStack>
      <Box padding="1.5rem 0.5rem">
        <Tabs
          variant="primary"
          onChange={(index) => setSelectedTab(index)}
          defaultIndex={0}
          index={selectedTab}
          isLazy
        >
          <TabList>
            {TabsData.map((item, index) => (
              <Tab key={item?.id} background="white">
                <HStack>
                  {item?.icon}
                  <Text> {item?.title}</Text>
                </HStack>
              </Tab>
            ))}
          </TabList>
          <TabPanels mt="1.5rem">
            {TabsData.map((item, index) => (
              <TabPanel key={item.id} paddingBottom="4.5rem">
                {selectedTab === index && item.content}{" "}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </Box>
      {(isLoading || mutation?.isLoading) && <Loader />}
    </Box>
  );
};

export default DealerProfile;
