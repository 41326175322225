import {
  Box,
  Button,
  HStack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Dropdown, Input, PaginatedTable } from "../../../components/common";
import { PDFIcon, SearchIcon } from "../../../components/icons/svg";
import { downloadBlob } from "../../../utils";
import {
  activateCampaign,
  downloadFile,
  fetchMainListing,
} from "../../../utils/api/dms-equity-mining";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import CommunicationLogs from "./CommunicationLogs";
import SalesDMSEquityFilter from "./SalesDMSEquityFilter";
import ServicesDMSEquityFilter from "./ServicesDMSEquityFilter.tsx";

export default function MainListing(props: any) {
  const { activeIndex } = props;
  const dId = useUserInfo("dealership")?.id;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState<any>(20);
  const [activeModal, setActiveModal] = useState<string | null>(null);
  const [clickedRow, setClickedRow] = useState<any>(null);
  const [buttonValue, setButtonValue] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [isSms, setIsSms] = useState<boolean | null>(null);
  const mutation = useMutation(activateCampaign);

  const { data, refetch, isLoading } = useQuery({
    queryKey: [
      "fetchMainListing",
      {
        page,
        per_page: perPage,
        q: searchValue,
        category:
          activeIndex === 2
            ? "ro_trade_up"
            : activeIndex === 1
            ? "services"
            : "sales",
      },
      dId,
    ],
    queryFn: fetchMainListing,
    refetchOnWindowFocus: false,
    retry: false,
  });

  const [selectedOption, setSelectedOption] =
    useState<SelectOptionProps | null>(null);
  const exportOptions: SelectOptionProps[] = [
    {
      label: (
        <Box as="span" display="flex" alignItems="center" gap="0.5rem">
          <PDFIcon />
          PDF
        </Box>
      ),
      value: "pdf",
    },
  ];
  const handleSelectExport = async (option: SelectOptionProps) => {
    try {
      setIsFileDownloading(true);
      const fileType = activeIndex === 1 ? "services" : "sales";
      const response = await downloadFile(dId, fileType);
      setIsFileDownloading(false);

      if (response?.data) {
        downloadBlob(
          response.data,
          activeIndex === 1
            ? "Services DMS Equity Mining.pdf"
            : "Sales DMS Equity Mining.pdf"
        );
      } else {
        throw new Error("No data in response");
      }
    } catch (error) {
      console.error("Error downloading", error);
    }
  };

  const options: SelectOptionProps[] = [
    {
      label: "Edit",
      value: "edit",
    },
    {
      label: "Communication Logs",
      value: "logs",
    },
  ];
  const tableHeader = [
    { value: "Name", label: "name" },
    { value: "contact count", label: "contact_count" },
    { value: "Status", label: "status" },
    { value: "Action Buttons", label: "actionButtons" },
    { value: "Actions", label: "actions" },
  ];

  const handleSelect = (option: SelectOptionProps) => {
    setActiveModal(option.value);
  };

  const tableData = data?.data?.dms_equity_minings?.map((item: any) => {
    return {
      id: item?.id,
      name: item?.name,
      contact_count: (
        <Text
          cursor={item?.campaign_start_at ? "pointer" : ""}
          color={item?.campaign_start_at ? "blue" : ""}
          onClick={() => {
            if (item?.campaign_start_at) {
              setActiveModal("logs");
            }
          }}
        >
          {item?.contact_count}
        </Text>
      ),
      status: item?.campaign_start_at ? "Activated" : "Pending",
      actions: (
        <Dropdown
          options={
            !item?.campaign_start_at ? options.slice(0, 1) : options.slice(1)
          }
          onSelect={handleSelect}
        />
      ),
      actionButtons: item?.campaign_start_at ? null : (
        <HStack
          alignItems="center"
          justifyContent="flex-start"
          padding="0"
          margin="0"
        >
          {item?.filters?.subject ? (
            <Button onClick={() => setButtonValue("email")}>
              Email Blast Now
            </Button>
          ) : (
            <Button onClick={() => setButtonValue("sms")}>
              Broadcast Message Now
            </Button>
          )}
        </HStack>
      ),
      email_logs_id: item.email_logs_id,
      message_campaigns_id: item.message_campaigns_id,
      isSms: item?.filters?.subject ? false : true,
    };
  });
  const handleActivateCampaign = async (campaign_type: string) => {
    mutation.mutate(
      { dId, id: clickedRow?.id, campaign_type },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "DMS Equity Mining activated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
        onError: (error: any) => {
          toast({
            description: `Error activating DMS Equity Mining: ${
              error?.response?.data?.message?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
    setButtonValue("");
  };
  useEffect(() => {
    if (buttonValue) {
      handleActivateCampaign(buttonValue);
    }
  }, [buttonValue]);

  useEffect(() => {
    if (activeModal === "edit" && clickedRow) {
      setIsSms(clickedRow?.isSms);
      onOpen();
    }
  }, [activeModal, clickedRow]);

  const onCloseModal = () => {
    onClose();
    setActiveModal(null);
    setIsSms(null);
  };

  return (
    <>
      <Tabs variant="pill">
        {activeModal !== "logs" && (
          <>
            <HStack
              background="white"
              padding="1.5rem"
              w="100%"
              justifyContent="space-between"
            >
              <HStack w="100%" h="2.25rem">
                <Input
                  maxW="320px"
                  type="text"
                  height="2.5rem"
                  hasIcon={true}
                  isIconRight={false}
                  icon={<SearchIcon />}
                  placeholder="Type here to search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </HStack>
              <HStack alignItems="flex-start">
                <HStack alignItems="flex-start" width="100%">
                  <Button
                    variant="primary"
                    fontWeight="500"
                    onClick={() => {
                      onOpen();
                      setIsSms(true);
                    }}
                  >
                    Create Bulk SMS
                  </Button>
                  <Button
                    variant="primary"
                    fontWeight="500"
                    onClick={() => {
                      onOpen();
                      setIsSms(false);
                    }}
                  >
                    Compose Blast
                  </Button>
                </HStack>

                <Dropdown
                  options={exportOptions}
                  onSelect={handleSelectExport}
                  hasText={true}
                  text="Export"
                />
              </HStack>
            </HStack>

            <HStack
              justifyContent="space-between"
              alignItems="flex-end"
              padding="1rem"
              w="100%"
            >
              <Text textStyle="caption" color="var(--grey-600)">
                Entries Found: {data?.data?.dms_equity_mining_count}
              </Text>
            </HStack>
          </>
        )}
        <TabPanels>
          <TabPanel padding="0">
            {activeModal !== "logs" ? (
              <PaginatedTable
                header={tableHeader}
                data={tableData}
                maxPageNumbers={3}
                isPaginated={true}
                hasMultiBody={false}
                itemsPerPage={perPage}
                rowOnClick={(rowItem) => {
                  setClickedRow(rowItem);
                }}
                perPageChange={(value) => {
                  setPage(1);
                  setPerPage(Number(value?.label));
                }}
                currentPage={page}
                setCurrentPage={setPage}
                rowCount={data?.data?.dms_equity_mining_count}
                isLoadingData={isFileDownloading || isLoading}
              />
            ) : (
              <CommunicationLogs
                activeIndex={activeIndex}
                setActiveModal={setActiveModal}
                clickedRow={clickedRow}
              />
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isOpen && activeIndex === 0 && (
        <SalesDMSEquityFilter
          onClose={onCloseModal}
          refetch={refetch}
          id={activeModal === "edit" ? clickedRow?.id : null}
          isSms={isSms}
        />
      )}

      {isOpen && activeIndex === 1 && (
        <ServicesDMSEquityFilter
          onClose={onCloseModal}
          refetch={refetch}
          id={activeModal === "edit" ? clickedRow?.id : null}
          isSms={isSms}
        />
      )}
    </>
  );
}
