import { Box, HStack } from "@chakra-ui/react";
import React from "react";
import { Dropdown } from "../../../../common";
import { ExcelIcon, PDFIcon } from "../../../../icons/svg";
import axios from "axios";
import { SERVICES } from "../../../../../config";
import { useUserInfo } from "../../../../../utils/hooks/useUserInfo";
import fileDownload from "js-file-download";

const ExportOptions: SelectOptionProps[] = [
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <ExcelIcon />
        Excel
      </Box>
    ),
    value: "xlsx",
  },
  {
    label: (
      <Box as="span" display="flex" alignItems="center" gap="0.5rem">
        <PDFIcon />
        PDF
      </Box>
    ),
    value: "pdf",
  },
];

type ExportProps = {
  type?: string;
  days?: string;
  sub_type?: string;
  id: string;
};

function Export({ type, days, id, sub_type }: ExportProps) {
  const dealership = useUserInfo("dealership");

  const handleExport = async (value: string) => {
    axios
      .get(
        `${SERVICES.apiBaseUrl}/api/dealerships/${
          dealership.id
        }/contacts/${id}/histories.${value}?type=${type || ""}&sub_type=${
          sub_type || ""
        }&days=${days || ""}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(
          response.data,
          `${type ? `${type}` : "ContactHistory"}.${value}`
        );
      })
      .catch((response) => {});
  };

  return (
    <HStack background="white" padding="1rem">
      <Dropdown
        options={ExportOptions}
        hasText={true}
        text="Export"
        onSelect={(value: any) => handleExport(value.value)}
      />
    </HStack>
  );
}

export default Export;
