import React, { useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  Box,
  InputLeftElement,
  FormLabel,
  TextareaProps,
  BoxProps,
  Text,
  Button,
  Flex,
  ResponsiveValue
} from "@chakra-ui/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";
import TooltipIfOverflow from "./TooltipIfOverflow";
import { EMAIL_REGEX } from "../../config";

interface InputProps extends BoxProps {
  type?: string;
  value?: any;
  onChange?: (value: any | any[]) => void;
  onKeyDown?: (value: any | any[]) => void;
  hasIcon?: boolean;
  isIconRight?: boolean;
  icon?: React.ReactNode;
  label?: string;
  maxW?: string;
  minW?: string | undefined;
  isTextarea?: boolean;
  fullHeight?: boolean;
  placeholder?: string;
  defaultValue?: string;
  isRequired?: boolean;
  error?: string;
  onAdd: (value: string) => void;
  onRemove: (id: number) => void;
  checkDuplicate?: boolean;
  minLength?: number;
  pillStyles?: any;
  isDisabled?: boolean;
  flexDirection?: ResponsiveValue<"row" | "column">;
}

const TagsInput = ({
  type = "text",
  value,
  onChange,
  icon,
  hasIcon = false,
  isIconRight,
  label = "",
  maxW = "100%",
  minW = "auto",
  isTextarea = false,
  fullHeight = false,
  isRequired,
  error,
  defaultValue,
  onKeyDown,
  onAdd,
  onRemove,
  checkDuplicate = false,
  minLength,
  pillStyles = {},
  isDisabled = false,
  flexDirection = "row",
  ...rest
}: InputProps & TextareaProps) => {
  const [inputValue, setInputValue] = useState("");
  const [fieldError, setFieldError] = useState("");

  const renderPills = () => {
    return value?.map((option: { id: number; value: string }) => (
      <Flex
        key={option.id}
        alignItems="center"
        padding="0.125rem 0.3125rem"
        gap="0.5rem"
        borderRadius="1.5rem"
        background="var(--grey-200)"
        mt={2}
      >
        <Text
          color="var(--grey-900)"
          fontSize="0.875rem"
          font-weight="400"
          lineHeight="1.25rem"
          mr={1}
        >
          <TooltipIfOverflow>{option.value}</TooltipIfOverflow>
        </Text>
        <Box
          as="span"
          display="flex"
          alignItems="center"
          cursor="pointer"
          onClick={() => onRemove(option.id)}
        >
          <CancelIcon
            style={{
              color: "var(--bluegray-300)",
              width: "1rem",
              height: "1rem",
            }}
          />
        </Box>
      </Flex>
    ));
  };

  const handleAddition = () => {
    if (!inputValue) {
      setFieldError("Please enter value");
      return;
    } else {
      setFieldError("");
    }
    if (type === "email" && !inputValue.match(EMAIL_REGEX)) {
      setFieldError("Invalid Email!");
      return;
    } else {
      setFieldError("");
    }

    if (minLength && inputValue.length < minLength) {
      setFieldError(`Min ${minLength} characters required.`);
      return;
    } else {
      setFieldError("");
    }

    if (
      checkDuplicate &&
      value?.find((option: any) => option.value === inputValue)
    ) {
      return setInputValue("");
    }
    onAdd(inputValue);
    setInputValue("");
  };

  const handleKeyAdd = (event: any) => {
    if (event.key === "Enter") {
      handleAddition();
    }
    onKeyDown && onKeyDown(event);
  };

  return (
    <Box
      maxW={maxW}
      minW={minW}
      w="100%"
      height={fullHeight ? "100%" : "auto"}
      display="flex"
      flexDirection={flexDirection}
    >
      {label && (
        <FormLabel
          mb="0.31rem"
          color="var(--grey-900)"
          fontSize="0.875rem"
          fontWeight="600"
          lineHeight="1rem"
        >
          {label}
          {isRequired && (
            <Box as="span" color="var(--red-600)">
              *
            </Box>
          )}
        </FormLabel>
      )}
      <InputGroup height={fullHeight ? "100%" : "auto"}>
        {hasIcon && !isIconRight && (
          <InputLeftElement pointerEvents="none">{icon}</InputLeftElement>
        )}
        <Input
          type={type}
          value={inputValue}
          onChange={({ target: { value } }) => setInputValue(value)}
          onKeyDown={handleKeyAdd}
          paddingRight={hasIcon && isIconRight ? "20px" : ""}
          paddingLeft={hasIcon && !isIconRight ? "42px" : ""}
          borderColor={
            error || fieldError ? "var(--red-600)" : "var(--grey-300)"
          }
          {...rest}
        />
        <InputRightElement
          background="white"
          right="2px"
          top="50%"
          transform="translateY(-50%)"
          height="30px"
          cursor="pointer"
        >
          <Button
            onClick={() => {
              handleAddition();
            }}
            isDisabled={isDisabled}
          >
            <AddCircleOutlineIcon />
          </Button>
        </InputRightElement>
      </InputGroup>
      <Text textStyle="captionSmall" color="var(--red-600)">
        {error || fieldError}
      </Text>

      <Box
        display="flex"
        gap="0.31rem"
        alignItems="center"
        flexWrap="wrap"
        style={{ ...pillStyles }}
      >
        {renderPills()}
      </Box>
    </Box>
  );
};

export default TagsInput;
