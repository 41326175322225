import { VStack, Text, Button, useToast, HStack } from "@chakra-ui/react";
import { CustomRadio, Input } from "../common";
import { useState } from "react";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import { dealershipAdminChecker } from "../../utils";
import { useQuery, useMutation } from "@tanstack/react-query";
import { getIPSettings, updateIPSettings } from "../../utils/api/ipsetting.api";
import { useParams } from "react-router-dom";
import Loader from "../common/Spinner";
export default function IPSetting(props: any) {
  const [restrictionType, setRestrictionType] = useState("no_restriction"); // Manage radio selection state
  const [ipRanges, setIpRanges] = useState<any>({
    start_1: "",
    end_1: "",
    start_2: "",
    end_2: "",
    start_3: "",
    end_3: "",
  }); // Manage IP ranges state
  const [errors, setErrors] = useState<any>({}); // Manage validation errors
  const role = useUserInfo("role");
  const impSSRole = useUserInfo("impSSRole");
  const userID = useUserInfo("id");
  const toast = useToast();
  const { id } = useParams();
  const mutation = useMutation(updateIPSettings);
  const { data, refetch, isSuccess } = useQuery({
    queryKey: ["getIPsettigs", id ?? userID],
    queryFn: getIPSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setIpRanges({
        start_1: data?.data?.ip_restrictions?.range_1?.start_ip,
        end_1: data?.data?.ip_restrictions?.range_1?.start_ip,
        start_2: data?.data?.ip_restrictions?.range_2?.start_ip,
        end_2: data?.data?.ip_restrictions?.range_2?.start_ip,
        start_3: data?.data?.ip_restrictions?.range_3?.start_ip,
        end_3: data?.data?.ip_restrictions?.range_3?.start_ip,
      });
      setRestrictionType(data?.data?.ip_restrictions_enabled?'limited_restriction':'no_restriction')
    },
  });
  const ranges = [
    { label: "IP Range 1", start: "start_1", end: "end_1" },
    { label: "IP Range 2", start: "start_2", end: "end_2" },
    { label: "IP Range 3", start: "start_3", end: "end_3" },
  ];

  const handleRadioChange = (value: string) => {
    setRestrictionType(value);
    if (value === "no_restriction") {
      // Clear IP range values if "No Restriction" is selected
      setIpRanges({
        start_1: "",
        end_1: "",
        start_2: "",
        end_2: "",
        start_3: "",
        end_3: "",
      });
    }
  };

  // Handle input change
  const handleInputChange = (e: any, key: string) => {
    setIpRanges((prev: any) => ({ ...prev, [key]: e.target.value }));
  };

  // Validate IP format (basic IPv4 regex)
  const validateIP = (ip: string) => {
    const ipPattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return ipPattern.test(ip);
  };

  // Validate IP ranges on Save
  const handleSave = () => {
    let newErrors: any = {};
    let hasAtLeastOneRange = false; // To check if at least one range is filled

    if (restrictionType === "limited_restriction") {
      ranges.forEach((range) => {
        const startIP = ipRanges[range.start];
        const endIP = ipRanges[range.end];

        if (startIP || endIP) {
          hasAtLeastOneRange = true; // At least one range is filled

          // Validate start and end IPs only if one of them is present
          if (startIP && !validateIP(startIP)) {
            newErrors[range.start] = "Invalid Start IP";
          }
          if (endIP && !validateIP(endIP)) {
            newErrors[range.end] = "Invalid End IP";
          }

          // Ensure both start and end IPs are provided
          if (startIP && !endIP) {
            newErrors[range.end] = "Invalid End IP";
          }
          if (endIP && !startIP) {
            newErrors[range.start] = "Invalid Start IP";
          }
        }
      });

      // If no range is entered, throw an error
      if (!hasAtLeastOneRange) {
        newErrors["ranges"] = "At least one IP range must be entered";
      }
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      // Proceed with form submission if no errors
      mutation.mutate(
        {
          payload: {
            ip_range_1_start: ipRanges?.start_1,
            ip_range_1_end: ipRanges?.end_1,
            ip_range_2_start: ipRanges?.start_2,
            ip_range_2_end: ipRanges?.end_2,
            ip_range_3_start: ipRanges?.start_3,
            ip_range_3_end: ipRanges?.end_3,
            ip_restrictions_enabled:
              restrictionType === "no_restriction" ? false : true,
          },
          userID: id ?? userID,
        },
        {
          onSuccess: () => {
            toast({
              description: "IP Settings updated successfully",
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.error?.toString() ??
                "Something went wrong",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    }
  };
  return (
    <VStack
      w="100%"
      gap="0.62rem"
      position="relative"
      alignItems="flex-start"
      h="100%"
      opacity={
        (props.allowed &&
          (dealershipAdminChecker(role) || role === "SimpSocial Owner")) ||
        impSSRole === "SimpSocial Owner"
          ? 1
          : 0.5
      } // Set opacity based on props.allowed
      pointerEvents={
        (props.allowed &&
          (dealershipAdminChecker(role) || role === "SimpSocial Owner")) ||
        impSSRole === "SimpSocial Owner"
          ? "auto"
          : "none"
      } // Enable/disable pointer events based on props.allowed
    >
      <VStack
        background="var(--grey-50)"
        borderRadius="0.5rem"
        border="1px solid var(--grey-300)"
        padding="1rem"
        gap="1rem"
        alignItems="flex-start"
        w="50%"
      >
        <Text textStyle="h4" fontWeight="500">
          IP Settings
        </Text>
        <CustomRadio
          options={[
            { label: "No Restriction", value: "no_restriction" },
            { label: "Limited Restriction", value: "limited_restriction" },
          ]}
          value={restrictionType}
          setValue={handleRadioChange}
          direction={"column"}
        />

        {restrictionType === "limited_restriction" && (
          <>
            <Text textStyle="h6" fontWeight="400" color="var(--grey-800)">
              The following IP ranges are allowed for this user
            </Text>
            {ranges.map((item: any) => (
              <HStack key={item.start} w="100%">
                <Text
                  textStyle="h6"
                  fontWeight="500"
                  w="26%"
                  padding="1.25rem"
                  paddingLeft="0"
                >
                  {item.label}
                </Text>
                <HStack w="60%">
                  <Input
                    label="Starting IP"
                    value={ipRanges[item.start]}
                    onChange={(e) => handleInputChange(e, item.start)}
                    variant="default"
                    w="100%"
                    maxW="10rem"
                    isInvalid={!!errors[item.start]}
                    error={errors[item.start]}
                  />
                  <Input
                    label="Ending IP"
                    value={ipRanges[item.end]}
                    onChange={(e) => handleInputChange(e, item.end)}
                    variant="default"
                    w="100%"
                    maxW="10rem"
                    isInvalid={!!errors[item.end]}
                    error={errors[item.end]}
                  />
                </HStack>
              </HStack>
            ))}
          </>
        )}
      </VStack>

      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        zIndex="0"
      >
        <Button width="8rem" onClick={handleSave}>
          Save
        </Button>
      </HStack>
      {mutation?.isLoading && <Loader />}
    </VStack>
  );
}
