// SimplePagination.tsx

import { Box, Button, HStack } from "@chakra-ui/react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

interface SimplePaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  maxPageNumbers?: number;
  loader?: boolean;
  isEmpty?: boolean;
}

const SimplePagination = ({
  currentPage,
  totalPages,
  onPageChange,
  maxPageNumbers = 5,
  loader,
  isEmpty,
}: SimplePaginationProps) => {
  const renderPageNumbers = () => {
    const middlePage = Math.floor(maxPageNumbers / 2);
    let startPage = currentPage - middlePage;
    if (startPage < 1) startPage = 1;

    let endPage = startPage + maxPageNumbers - 1;
    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - maxPageNumbers + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return pageNumbers.map((pageNumber) => (
      <Box
        as="span"
        key={pageNumber}
        padding="0.62rem 0.75rem"
        cursor="pointer"
        color={currentPage === pageNumber ? "white" : "var(--grey-900)"}
        fontSize="1rem"
        fontWeight="400"
        lineHeight="1.25rem"
        borderRadius="0.375rem"
        minWidth="2.0625rem"
        textAlign="center"
        background={
          currentPage === pageNumber ? "var(--secondary-500)" : "none"
        }
        onClick={() => onPageChange(pageNumber)}
      >
        {!loader && !isEmpty && pageNumber}
      </Box>
    ));
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      padding="16px"
      borderRadius="0.5rem"
      background="white"
      w="100%"
    >
      <HStack spacing="2">
        <Button
          variant="none"
          display="flex"
          alignItems="center"
          onClick={() => onPageChange(currentPage - 1)}
          isDisabled={currentPage === 1 || loader || isEmpty}
        >
          <KeyboardArrowLeftIcon />
        </Button>
        {!loader && !isEmpty && renderPageNumbers()}
        <Button
          display="flex"
          variant="none"
          alignItems="center"
          onClick={() => onPageChange(currentPage + 1)}
          isDisabled={currentPage >= totalPages || loader || isEmpty}
        >
          <KeyboardArrowRightIcon />
        </Button>
      </HStack>
    </Box>
  );
};

export default SimplePagination;
