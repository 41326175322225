import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";

interface FooterProps {
  userEmail: string | undefined;
  disableForEmailTemplate: boolean;
  disableButtons: boolean;
  handleSaveAsTemplate: () => void;
  handleFormSubmit: () => void;
  setIsScheduleModalOpen: (open: boolean) => void;
  id?: string | null;
}

const Footer: React.FC<FooterProps> = ({
  userEmail,
  disableForEmailTemplate,
  disableButtons,
  handleSaveAsTemplate,
  handleFormSubmit,
  setIsScheduleModalOpen,
  id,
}) => (
  <HStack justifyContent="space-between" w="100%" mt="2rem">
    <Text whiteSpace="nowrap">
      Logged in as: <span style={{ color: "blue" }}>{userEmail}</span>
    </Text>
    <HStack justifyContent="flex-end" w="100%">
      <Button
        w="10rem"
        onClick={handleSaveAsTemplate}
        isDisabled={disableForEmailTemplate}
        variant="outline"
      >
        Save Template
      </Button>
      <Button
        variant="outline"
        w="10rem"
        onClick={() => setIsScheduleModalOpen(true)}
        isDisabled={disableButtons}
      >
        Schedule
      </Button>
      {!id && (
        <Button
          w="10rem"
          onClick={handleFormSubmit}
          isDisabled={disableButtons}
        >
          Send
        </Button>
      )}
    </HStack>
  </HStack>
);

export default Footer;
