import General from "../../../../components/RingGroupSettings/General/General";
import {
  GeneralIcon,
} from "../../../../components/icons/svg";
import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate,useParams } from "react-router-dom";

export default function CreateRingGroupPage() {
  const{id}=useParams()
  const { ringId } = useParams();
  const navigate = useNavigate();
  return (
    <Box>
      <HStack background="white" padding="0.8rem 1.5rem" onClick={()=>{navigate(`/dealerships/${id}?tab=${3}&active=${1}`)}}>
        <Button
          variant="none"
          border="1px solid var(--grey-300)"
          borderRadius="0.5rem"
          padding="0.38rem"
          display="flex"
        >
          <KeyboardBackspaceIcon
            style={{
              color: "var(--grey-900)",
            }}
          />
        </Button>
        <Text textStyle="h4" fontWeight="700">
          {ringId?"Update Ring Group":"Create Ring Group"}
        </Text>
      </HStack>
      <Box padding="1.5rem">
        <Tabs variant="primary">
          <TabList pointerEvents={'none'}>
            <Tab background="white" padding="1rem" w="33%">
              <HStack>
                <GeneralIcon />
                <Text textStyle="h6" fontWeight="700">
                  General
                </Text>
              </HStack>
            </Tab>          
          </TabList>
          <TabPanels>
            <TabPanel>
              <General />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
}
